import React from "react";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import { IText } from "Shared/Context/LangContextDTO";
import useUser from "Shared/Context/UserContext";
import { DeskStateText } from "Shared/Functions/DeskStateText";
import { DeskState, FloorPage } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { FloorStyle } from "../Style";
import { curDeskState } from "./DeskState";

export const DeskBtn = (props: {
    deskgroupid: string;
    deskid: string;
    isActive?: boolean;
    page: FloorPage;
    highlightedDeskId?: string;
    selectedTo: Date;
    selectedFrom: Date;
    onSelect: (id: string) => void;
}) => {
    const { curLangData } = useLang();
    const { deskGroupData } = useFloor();
    const { currentUser, profileData } = useUser();

    const dgData = React.useMemo(() => {
        return deskGroupData.find(dg => dg.name === props.deskgroupid)
    }, [deskGroupData, props.deskgroupid])

    const bookings = React.useMemo(() => {
        return dgData?.bookings?.filter((b) => b.seat === props.deskid)
    }, [dgData?.bookings, props.deskid])

    const currentState: DeskState = React.useMemo(() => {
        return curDeskState(
            props.deskid,
            props.page,
            { to: props.selectedTo, from: props.selectedFrom },
            { id: currentUser?.id as string, units: profileData?.orgUnitIds as number[] },
            props.highlightedDeskId,
            bookings,
            dgData?.units
        )
    }, [bookings, currentUser?.id, dgData?.units, profileData?.orgUnitIds, props.deskid, props.highlightedDeskId, props.page, props.selectedFrom, props.selectedTo]);

    return (
        <FloorStyle.ButtonWrapper>
            <FloorStyle.Button
                isActive={props.isActive}
                state={currentState}
                seatType={dgData?.deskGroupType}
                onClick={() => props.onSelect(props.deskid)}
                title={props.deskid + " - " + DeskStateText(curLangData?.text as IText, currentState)}
                data-testid={`${TestId.floor}_desk${props.deskid}`}
            />
        </FloorStyle.ButtonWrapper>
    )
}