import { FloorPage, DeskState, BookingData } from "Shared/Globals";

const checkIfDeskIsBlocked = (
    page: FloorPage,
    curUserUnits?: number[],
    units?: number[],
) => {
    let isNotBlocked = true;
    if (units != null && units.length > 0) {
        isNotBlocked = units.some((unit) => curUserUnits?.includes(unit))
    }
    return (!isNotBlocked && page !== FloorPage.admin);
}

const getCurrentResState = (
    page: FloorPage,
    selectedFrom: Date,
    selectedTo: Date,
    currentUser?: string,
    highlightedDeskId?: string,
    bookings?: BookingData[],
) => {
    const state = { disabled: 0, booked: 0, mine: 0 };
    bookings?.forEach(r => {
        if (r.date >= selectedTo || r.endDate <= selectedFrom) return;
        if (r.type === DeskState.booked &&
            r.userName === currentUser &&
            ((highlightedDeskId == null && page !== FloorPage.colleague) || (highlightedDeskId != null && page === FloorPage.colleague))
        ) state.mine++;
        else if (r.type === DeskState.booked) state.booked++;
        else if (r.type === DeskState.disabled) state.disabled++;
    })
    return state;
};

export const curDeskState = (
    deskId: string,
    page: FloorPage,
    dates: { from: Date, to?: Date },
    user: { id: string, units?: number[] },
    highlightedDeskId?: string,
    bookings?: BookingData[],
    units?: number[],
) => {
    const date = new Date(dates.from);
    const toDate = dates.to ?? new Date(date.setDate(date.getDate() + 1));
    const currentResState = getCurrentResState(page, dates.from, toDate, user.id, highlightedDeskId, bookings);

    if (highlightedDeskId === deskId) return page !== FloorPage.colleague ? DeskState.mine : DeskState.colleague;
    if (currentResState.mine > 0) return DeskState.mine;
    if (checkIfDeskIsBlocked(page, user.units, units)) return DeskState.blocked;
    if (currentResState.disabled > 0) return DeskState.disabled;
    if (currentResState.booked > 0) return DeskState.booked;
    return DeskState.available;
}