import { RoutingPage } from 'Main/Page/RoutingPage';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { ContextCollection } from './Shared/Context';
import { FontStyle } from './Style/FontStyle';
import { GlobalStyle } from './Style/GlobalStyle';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <FontStyle />
        <GlobalStyle />
        <HashRouter>
            <ContextCollection>
                <RoutingPage />
            </ContextCollection>
        </HashRouter>
    </React.StrictMode>
);