import React from "react";
import { GetWindowWidth } from "Shared/Hooks/windowWidth";

export interface LayoutContextState {
    currentPageTitle: string | undefined;
    updatePageTitle: (value: string | undefined) => void;
    expandNav: boolean;
    updateNav: (expand: boolean) => void;
    isMobile: (width: number) => boolean;
    expandSidePanel: boolean;
    updateSidePanel: (expand: boolean) => void;
}


export const LayoutContext = React.createContext<LayoutContextState>({
    currentPageTitle: undefined,
    updatePageTitle: () => {/* init */ },
    expandNav: false,
    updateNav: () => {/* init */ },
    isMobile: () => false,
    expandSidePanel: false,
    updateSidePanel: () => {/* init */ },
})

const defaultTitle = "Deskfinder";

export const LayoutProvider = (props: { children: JSX.Element }) => {
    const [currentPageTitle, setCurrentPageTitle] = React.useState<string | undefined>();
    const [expandNav, setNavExpanded] = React.useState(false);
    const [expandSidePanel, setSidePanelExpanded] = React.useState(false);
    const windowWidth = GetWindowWidth();

    const isMobile = React.useCallback((width: number) => {
        return windowWidth != null && windowWidth < width;
    }, [windowWidth]);

    const updatePageTitle = React.useCallback((value: string | undefined) => {
        document.title = value === undefined ? defaultTitle : (value + " | " + defaultTitle);
        setCurrentPageTitle(value);
    }, []);

    const updateNav = React.useCallback((expand: boolean) => {
        setNavExpanded(expand);
    }, []);

    const updateSidePanel = React.useCallback((expand: boolean) => {
        setSidePanelExpanded(expand);
        if (expand === true) updateNav(false);
    }, [updateNav]);

    const providerValue = React.useMemo(() => (
        { currentPageTitle, updatePageTitle, expandNav, updateNav, isMobile, expandSidePanel, updateSidePanel }
    ), [currentPageTitle, updatePageTitle, expandNav, updateNav, isMobile, expandSidePanel, updateSidePanel])

    return (
        <LayoutContext.Provider value={providerValue}>
            {props.children}
        </LayoutContext.Provider>
    )
}

const useLayout = () => React.useContext(LayoutContext);
export default useLayout;