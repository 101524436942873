import { createGlobalStyle } from 'styled-components'
import { color, font, scrollStyle } from './Globals';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        width: 100%;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
        background-color: ${color.lightmodeBgBody};
        color: ${color.black};
        font-family: ${font.regular};
        ${scrollStyle}

        > #root {
            width: 100%;
            height: 100%;
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgBody};
            color: ${color.white};
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: normal;
    }

    a {
        &,
        :hover,
        :focus,
        :visited {
            color: ${color.black};
            text-decoration: none;

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }
    }

    button {
        background-color: ${color.transparent};
        border: none;
        border-radius: 0;

        :hover,
        :focus {
            border: none;
            outline: none;
        }
    }

    li {
        list-style: none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        :root {
            zoom: 0.8;
        }
    }
`