import React from "react"
import { LoadingStyle } from "./Style"

interface LoadingProps {
    position?: string,
    size?: string,
    color?: string,
    visible?: boolean,
}

export const Loading = (props: React.PropsWithChildren<LoadingProps>) => {

    const renderContainer = () => {
        return (
            <LoadingStyle.Container position={props.position ?? "absolute"}>
                <LoadingStyle.Inner size={props.size} color={props.color} />
            </LoadingStyle.Container>
        )
    }

    if (props.children) return (
        <LoadingStyle.Outer visible={props.visible}>
            {props.children}
            {props.visible && renderContainer()}
        </LoadingStyle.Outer>
    )
    return renderContainer();
}