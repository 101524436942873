import React from "react";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { Floor } from "Main/Floor/Floor";
import { BookingSettings } from "Main/Settings/BookingSettings";
import { FloorPage } from "Shared/Globals";

export const AdminBlock = (props: { navHeight?: number }) => {
    const [selectedFloor, setSelectedFloor] = React.useState<DropdownOption>();
    const [selectedDate, setSelectedDate] = React.useState<Date>();

    const calcScrollHeight = React.useMemo(() => {
        const navH = props.navHeight ?? 49;
        const headerH = 85;
        const margin = 30;
        const settingsHeight = 95;
        return navH + headerH + margin + settingsHeight;
    }, [props.navHeight]);

    return (
        <>
            <BookingSettings
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                page={FloorPage.admin}
            />
            {selectedFloor != null && selectedDate != null &&
                <Floor
                    selectedFloor={selectedFloor}
                    selectedDate={selectedDate}
                    scrollHeight={calcScrollHeight}
                    page={FloorPage.admin}
                />
            }
        </>
    )
}