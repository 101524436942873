import { useMsal } from "@azure/msal-react";
import React from "react";
import { useApi } from "./API";
import { UserData, UserProfileData } from "Shared/Globals";


interface IUserTokenData {
    currentUser?: UserData;
    isAdmin: boolean;
    isPodManager: boolean;
}

export interface IUserContextState extends IUserTokenData {
    profileData?: UserProfileData
    setProfile: ((update: Partial<UserProfileData>) => void)
}

export const UserContext = React.createContext<IUserContextState>({
    currentUser: undefined,
    isAdmin: false,
    isPodManager: false,
    setProfile: (update: Partial<UserProfileData>) => {/* init */ }
})

const userProfileKey: string = 'userProfile';

export const UserProvider = (props: { children: JSX.Element }) => {

    const { accounts } = useMsal();
    const [userProfile, setUserProfile] = React.useState<UserProfileData>(() => {
        const userProfileJsonString = sessionStorage.getItem(userProfileKey);
        if (userProfileJsonString !== null) {
            return JSON.parse(userProfileJsonString);
        }
        return null;
    });

    const api = useApi();

    const userTokenData = React.useMemo<IUserTokenData>(() => {
        const account = accounts[0];

        const roles = ((account.idTokenClaims as any)?.roles as string[]);

        if (roles?.includes("User")) {
            return {
                currentUser: {
                    name: account.name as string,
                    id: account.username,
                },
                isAdmin: roles?.includes("Admin"),
                isPodManager: roles?.includes("PodManager")
            }
        }
        else {
            return {
                currentUser: undefined,
                isAdmin: false,
                isPodManager: false
            }
        }
    }, [accounts]);

    React.useEffect(() => {
        let active = true;
        if (userProfile == null) {
            api.GetUserProfile().then(profile => {
                if (active) {
                    setUserProfile(profile);
                    sessionStorage.setItem(userProfileKey, JSON.stringify(profile));
                }
            })
        }
        return () => {
            active = false;
        }
    }, [api, userProfile]);

    const updateProfile = React.useCallback((update: Partial<UserProfileData>) => {
        setUserProfile(curProfile => {
            const newProfileData = { ...curProfile, ...update };
            api.SaveUserProfile(newProfileData);
            sessionStorage.setItem(userProfileKey, JSON.stringify(newProfileData));
            return newProfileData;
        })
    }, [api])

    const providerValue = React.useMemo<IUserContextState>(() => {
        let value: IUserContextState = {
            ...userTokenData,
            profileData: userProfile,
            setProfile: updateProfile
        };
        return value;
    }, [updateProfile, userProfile, userTokenData])

    if (userProfile == null) {
        return null;
    }

    return (
        <UserContext.Provider value={providerValue}>
            {props.children}
        </UserContext.Provider>
    )
}

const useUser = () => React.useContext(UserContext);
export default useUser;