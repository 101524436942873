import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { Config } from "./ConfigContext";
import React from "react";

export interface PersonDTO {
    // just picked out the needed properties
    // https://docs.microsoft.com/en-us/graph/api/resources/user?view=graph-rest-1.0#properties
    displayName: string,
    id: string,
    userPrincipalName: string
}

export interface PeopleSearchResultDTO {
    value: PersonDTO[]
}

export interface PeopleSuggestionModel {
    userName: string,
    displayName: string,
    img: string
}

export class GraphAPI {
    private instance: IPublicClientApplication;
    private config: Config;
    static cachedProfilePics: { [userId: string]: string } = {};
    static cachedPersonData: { [userId: string]:  Promise<PersonDTO> } = {};

    constructor(msalInstance: IPublicClientApplication, config: Config) {
        this.instance = msalInstance;
        this.config = config;
    }

    public getPeopleSuggestions(searchString: string): Promise<PeopleSuggestionModel[]> {
        return this.searchPeople(searchString)
            .then(res => {

                const peoplePromises = res.value.map((person) => {
                    return new Promise<PeopleSuggestionModel>((resolve, reject) => {
                        let personModel: PeopleSuggestionModel = {
                            displayName: person.displayName,
                            userName: person.userPrincipalName,
                            img: ''
                        };

                        return this._getOrAddCachedProfilePicture(person.id)
                            .then(imgBase64 => {
                                personModel.img = imgBase64;
                                resolve(personModel);
                            });
                    });
                });

                return Promise.all(peoplePromises);
            });
    }

    public searchPeople(searchString: string): Promise<PeopleSearchResultDTO> {
        let req: RequestInit = {
            method: 'GET',
            headers: { "ConsistencyLevel": "eventual" }
        };
        const maxCount = 10;
        const properties = "displayName,id,userPrincipalName";

        let params = `?$select=${properties}&$search="displayName:${searchString}"&$top=${maxCount}&$filter=endsWith(userPrincipalName,'@${this.config.domainName}') and (startsWith(userPrincipalName,'S') or (startsWith(userPrincipalName,'U')))`;

        return this.fetchWithToken("/users" + params, req)
            .then(res => this._responseToObj<PeopleSearchResultDTO>(res));
    }

    public searchSpecificPerson(userPrincipalName: string): Promise<PersonDTO> {

        if (GraphAPI.cachedPersonData[userPrincipalName] == null)
            {
            let req: RequestInit = {
                method: 'GET'
            };
            const properties = "displayName,id,userPrincipalName";

            GraphAPI.cachedPersonData[userPrincipalName] =  this.fetchWithToken(`/users/${userPrincipalName}?$select=${properties}`, req)
                .then(res => this._responseToObj<PersonDTO>(res));
        }

        return GraphAPI.cachedPersonData[userPrincipalName]
    }

    public getProfilePicture(userId: string): Promise<Blob> {
        let req: RequestInit = {
            method: 'GET',
            headers: { "Accept": "image/jpg" }
        };

        return this.fetchWithToken(`/users/${userId}/photos/64x64/$value`, req)
            .then(res => {
                if (res.ok) {
                    return Promise.resolve(res.blob());
                }
                if (res.status === 404) {
                    return Promise.resolve(new Blob());
                }
                return Promise.reject();
            });
    }

    private _getOrAddCachedProfilePicture(userId: string): Promise<string> {
        if (!this._isProfilePicCached(userId)) {
            return this.getProfilePicture(userId)
                .then(imgBlob => {

                    return this._blobToBase64String(imgBlob)
                        .then(imgBase64 => {

                            this._setCachedProfilePic(userId, imgBase64)
                            return imgBase64;
                        });
                });
        } else {
            return Promise.resolve(this._getCachedProfilePic(userId));
        }
    }

    private _getCachedProfilePic(userId: string): string {
        let img = GraphAPI.cachedProfilePics[userId];

        if (img !== undefined) {
            return img;
        }
        return '';
    }

    private _isProfilePicCached(userId: string): boolean {
        return (GraphAPI.cachedProfilePics[userId] !== undefined);
    }

    private _setCachedProfilePic(userId: string, imgBase64: string) {
        GraphAPI.cachedProfilePics[userId] = imgBase64;
    }

    private _blobToBase64String(blob: Blob): Promise<string> {
        let reader = new FileReader();

        if (!blob || !blob.size) {
            return Promise.resolve("");
        }

        let readPromise = new Promise<string>((resolve, reject) => {
            reader.onload = () => resolve(reader.result as string ?? "");
        });

        reader.readAsDataURL(blob);
        return readPromise;
    }

    private _responseToObj<T>(res: Response): Promise<T> {
        if (res.ok) {
            return res.json()
                .then(jres => jres as T);
        }

        if (res.status === 404) { // no results found
            return Promise.resolve({} as T);
        }

        if (res.bodyUsed) { // no 200 and no 404, so I assume response is faulty
            return res.json().then(jres => Promise.reject(jres));
        }
        return Promise.reject();
    }

    private fetchWithToken(path: string, init?: RequestInit): Promise<Response> {
        const innerRequestInit = init ?? {};
        const silentRequest =
        {
            scopes: this.config.scopes,
            account: this.instance.getActiveAccount() as AccountInfo
        };

        return this.instance.acquireTokenSilent(silentRequest)
            .then((jres) => {
                const headers = new Headers(innerRequestInit.headers);
                headers.append('Authorization', `Bearer ${jres.accessToken}`);
                innerRequestInit.headers = headers;

                return fetch(this.config.graphBaseAddress + path, innerRequestInit);
            });
    }
}

export const GraphApiContext = React.createContext<GraphAPI>({} as GraphAPI)

export const useGraphApi = () => React.useContext(GraphApiContext);