import React from "react";
import { ButtonRow, Datepicker, InfoBox, InfoBoxType } from "Shared/Components";
import { useApi } from "Shared/Context/API";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import useUser from "Shared/Context/UserContext";
import { compareDates, getDateRangeString } from "Shared/Functions/DateMath";
import { Desk } from "Shared/Functions/Desk";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import { BookingData, DeskState, FloorPage } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { curDeskState } from "../DeskState";
import { DefaultInfo } from "./DefaultInfo";

export const DeskInfoAdmin = (props: {
    deskid: string;
    selectedDate: Date;
    onDismiss: (reload?: boolean) => void;
}) => {
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date>();
    const [isLoading, setLoading] = React.useState(false);
    const [showBookedInfo, setBookedInfoVisible] = React.useState(false);
    const [disabledInfo, setDisabledInfo] = React.useState<BookingData[]>([]);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);

    const { currentUser } = useUser();
    const { deskGroupData } = useFloor();
    const { curLang, curLangData } = useLang();
    const { updateMyBookings } = useMyBookings();
    const api = useApi();

    const isMounted = React.useRef(false);
    const desk = React.useMemo(() => { return new Desk(props.deskid) }, [props.deskid]);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    const bookings = React.useMemo(() => {
        const data = deskGroupData.find((d) => d.name === props.deskid.slice(0, 10));
        return data?.bookings?.filter((b) => b.seat === props.deskid)
    }, [deskGroupData, props.deskid])

    const curState = React.useMemo(() => {
        return curDeskState(props.deskid, FloorPage.admin, { from: props.selectedDate }, { id: currentUser?.id as string }, undefined, bookings)
    }, [bookings, currentUser?.id, props.deskid, props.selectedDate])

    React.useEffect(() => {
        if (selectedEndDate != null) {
            setLoading(true);
            const d = new Date(selectedEndDate);
            const endDate = new Date(d.setDate(d.getDate() + 1));
            api
                .GetBookingsBySeat(desk, props.selectedDate, endDate)
                .then((res) => {
                    setBookedInfoVisible(res.some((item) => item.type === 1));
                    setDisabledInfo(res.filter((item) => item.type === 2));
                })
                .catch(() => setErrorMsg(curLangData?.error.DetectBookings))
                .finally(() => setLoading(false));

        }
    }, [api, desk, props.selectedDate, selectedEndDate, curLangData]);

    const onDismiss = React.useCallback((reload?: boolean) => {
        updateMyBookings();
        const _onDismiss = props.onDismiss;
        _onDismiss(reload);

        if (isMounted.current) {
            setErrorMsg(undefined);
            setBookedInfoVisible(false);
        }
    }, [props.onDismiss, updateMyBookings]);

    const blockProm = React.useCallback(() => {
        const date = selectedEndDate == null ? new Date(props.selectedDate) : new Date(selectedEndDate);
        const toDate = new Date(date.setDate(date.getDate() + 1));
        return api.Admin
            .blockSeat(desk, props.selectedDate, toDate)
            .then((res) => {
                return api.Admin
                    .unblockSeat(desk, toDate)
                    .then(() => onDismiss(true))
            })
    }, [api.Admin, desk, onDismiss, props.selectedDate, selectedEndDate]);

    const adminchange = React.useCallback(() => {
        setLoading(true);
        (curState === DeskState.disabled ?
            api.Admin.unblockSeat(desk, props.selectedDate).then(() => onDismiss(true))
            :
            blockProm()
        )
            .then(() => onDismiss(true))
            .catch((err) => {
                let msg = curLangData?.error.StatusChange;
                if (err && err.messageId) msg = ErrorMsg(err.messageId, true, false, curLangData?.error) ?? msg;
                setErrorMsg(msg);
            })
            .finally(() => setLoading(false));
    }, [curState, api.Admin, desk, props.selectedDate, blockProm, onDismiss, curLangData?.error]);

    const adminConfirmText = React.useMemo(() => {
        if (curState === DeskState.disabled) return curLangData?.text.DeskRelease as string;
        return curLangData?.text.DeskBlock as string;
    }, [curState, curLangData?.text.DeskRelease, curLangData?.text.DeskBlock]);

    const getDisabledDates = () => {
        if (disabledInfo.length > 0) {
            let text = "(";
            disabledInfo.forEach((d, i) => {
                text += getDateRangeString(d.date, d.endDate, curLang?.browser as string);
                if (i !== disabledInfo.length - 1) text += ", ";
            })
            return text + ")";
        }
    }

    const minDate = () => {
        const date = new Date(props.selectedDate);
        return new Date(date.setDate(date.getDate() + 1));
    };

    return (
        <>
            <DefaultInfo
                deskId={props.deskid}
                page={FloorPage.admin}
                selectedFrom={props.selectedDate}
                deskState={curState}
                bookingData={bookings?.find((b) => compareDates(b.date, props.selectedDate))}
            >
                {curState !== DeskState.disabled &&
                    <Datepicker
                        label={curLangData?.text.Enddate}
                        width={"100%"}
                        defaultText={curLangData?.text.DateSelectOptional}
                        selectedDate={selectedEndDate}
                        setSelectedDate={(d) => setSelectedEndDate(d)}
                        minDate={minDate()}
                        nopadding
                        openStatic
                        id={`${TestId.deskinfo}_rowEndDatePicker`}
                    />
                }
            </DefaultInfo>


            {disabledInfo.length > 0 &&
                <InfoBox
                    type={InfoBoxType.default}
                    title={curLangData?.text.Info}
                    text={curLangData?.info.AlreadyBlocked + " " + getDisabledDates()}
                    marginTop={"20px"}
                />
            }

            {disabledInfo.length === 0 && (curState === DeskState.booked || curState === DeskState.mine || showBookedInfo) &&
                <InfoBox
                    type={InfoBoxType.default}
                    title={curLangData?.text.Info}
                    text={curLangData?.info.AlreadyBooked}
                    marginTop={"20px"}
                />
            }

            {errorMsg &&
                <InfoBox
                    type={InfoBoxType.error}
                    text={errorMsg}
                    title={curLangData?.text.Attention}
                    marginTop={"20px"}
                />
            }

            <ButtonRow
                id={`${TestId.deskinfo}_button`}
                primaryText={adminConfirmText}
                primaryType={"button"}
                onPrimaryClick={adminchange}
                secondaryText={curLangData?.text.Cancel}
                secondaryType={"button"}
                onSecondaryClick={() => onDismiss()}
                isLoading={isLoading}
                marginTop={"20px"}
            />

        </>
    )
}