import React from "react";
import { DefaultButton } from "Shared/Components/DefaultButton/Style";
import { Dropdown, DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import useLang from "Shared/Context/LangContext";
import { MultiselectItem } from "./MultiselectItem";
import { MultiselectStyle } from "./Style";

export const Multiselect = (props: {
    defaultText: string;
    options: DropdownOption[];
    selectedOptions?: DropdownOption[];
    onSelect: (options: DropdownOption[]) => void;
    icon?: React.ReactNode;
    width?: string;
    fontSize?: string;
    id: string;
    nopadding?: boolean;
    openStatic?: boolean;
    label?: string;
    enabledOptions?: DropdownOption[];
}) => {
    const [selOptions, setSelOptions] = React.useState<string[]>(props.selectedOptions ? props.selectedOptions.map((o) => o.id) : []);
    const [text, setText] = React.useState<string>(props.defaultText);
    const [shrinkDropdown, setDropdownShrinked] = React.useState(false);
    const { curLangData } = useLang();
    const selectAll = curLangData?.text.SelectAll as string;
    const deselectAll = curLangData?.text.DeselectAll as string;
    const [selectAllText, setSelectAllText] = React.useState<string>(selectAll);

    const defaultSelect = React.useMemo(() => {
        return props.selectedOptions ? [...props.selectedOptions] : []
    }, [props.selectedOptions])

    const renderOptions = React.useCallback(() => {
        return props.options?.map((o) => {
            const optionDisabled = props.enabledOptions != null ? !props.enabledOptions.includes(o) : false;
            return (
                <MultiselectItem
                    key={o.id}
                    option={o}
                    selectedOptions={selOptions}
                    setSelectedOptions={setSelOptions}
                    id={`${props.id}_item`}
                    disabled={optionDisabled}
                />
            );
        });
    }, [props.options, props.enabledOptions, props.id, selOptions]);

    React.useEffect(() => {
        setSelOptions(props.selectedOptions ? props.selectedOptions.map((o) => o.id) : [])
    }, [props.selectedOptions])

    const checkOption = React.useCallback((id: string, txt?: string) => {
        let tmptext = "";
        if (selOptions.includes(id)) {
            tmptext += txt ? (txt + ", ") : (id + ", ");
        }
        return tmptext;
    }, [selOptions]);

    const setTmpTxt = React.useCallback(() => {
        let txt = "";
        props.options.forEach((o) => {
            if (o.children && o.children?.length > 0) {
                o.children.forEach((c) => {
                    txt += checkOption(c.id, c.text);
                });
            } else {
                txt += checkOption(o.id, o.text);
            }
        });
        return txt
    }, [checkOption, props.options]);

    React.useEffect(() => {
        let tmpText = props.defaultText;
        if (selOptions.length > 0) {
            tmpText = setTmpTxt();
        }
        if (tmpText.length === 0) {
            tmpText = props.defaultText;
        } else {
            if (tmpText !== props.defaultText) tmpText = tmpText.slice(0, -2);
        }
        setText(tmpText);
    }, [selOptions, props.defaultText, setTmpTxt]);

    const selectOptions = React.useCallback((options: DropdownOption[]) => {
        let counter = 0;
        options.forEach((o) => {
            if (o.children && o.children?.length > 0) {
                o.children.forEach((c) => {
                    counter++;
                });
            } else {
                counter++;
            }
            setSelectAllText(counter === selOptions.length ? deselectAll : selectAll);
        });
    }, [deselectAll, selOptions.length, selectAll])

    React.useEffect(() => {
        selectOptions(props.enabledOptions ?? props.options);
    }, [props.enabledOptions, props.options, selectOptions]);

    const selectAllOptions = React.useCallback(() => {
        const selected: string[] = [];
        let counter = 0;
        if (!props.enabledOptions) {
            props.options.forEach((o) => {
                if (o.children && o.children?.length > 0) {
                    o.children.forEach((c) => {
                        selected.push(c.id);
                        counter++;
                    });
                }
                else {
                    selected.push(o.id);
                    counter++;
                }
            });
        } else {
            props.enabledOptions.forEach((o) => {
                selected.push(o.id);
                counter++;
            })
        }
        setSelOptions(counter === selOptions.length ? [] : selected);
    }, [props.enabledOptions, props.options, selOptions.length]);

    const saveSelection = () => {
        const options: DropdownOption[] = [];
        props.options.forEach((o) => {
            if (o.children && o.children?.length > 0) {
                o.children.forEach((c) => {
                    if (selOptions.includes(c.id)) { options.push(c); }
                });
            } else {
                if (selOptions.includes(o.id)) { options.push(o); }
            }
        });
        props.onSelect(options);
        setDropdownShrinked(true);
        window.setTimeout(() => {
            setDropdownShrinked(false);
        }, 50)
    };

    const disabledSeatTypeMultiselectButton = props.enabledOptions !== undefined ? props.enabledOptions.length === 0 : false;

    return (
        <Dropdown
            defaultText={text}
            options={[]}
            shrinkContent={shrinkDropdown === false ? undefined : shrinkDropdown}
            icon={props.icon}
            width={props.width}
            fontSize={props.fontSize}
            id={props.id}
            nopadding={props.nopadding}
            openStatic={props.openStatic}
            label={props.label}
            handleOutsideClick={() => props.onSelect(defaultSelect)}
        >
            <MultiselectStyle.Content>
                <MultiselectStyle.ScrollContent>
                    {renderOptions()}
                </MultiselectStyle.ScrollContent>
            </MultiselectStyle.Content>
            <MultiselectStyle.ButtonRow>
                <DefaultButton type={"button"} onClick={selectAllOptions} secondary disabled={disabledSeatTypeMultiselectButton} data-testid={`${props.id}_buttonSelectAll`}>{selectAllText}</DefaultButton>
                <DefaultButton type={"button"} onClick={saveSelection} disabled={disabledSeatTypeMultiselectButton} data-testid={`${props.id}_buttonConfirm`}>{curLangData?.text.Confirm}</DefaultButton>
            </MultiselectStyle.ButtonRow>
        </Dropdown>
    )
}