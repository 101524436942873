import styled from "styled-components";
import { color } from "Style/Globals";

export const SearchStyle = {
    Form: styled.form`
        position: relative;
    `,
    Button: styled.button`
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        & > i {
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        :hover,
        :focus {
            > i:before {
                color: ${color.blue};
            }
        }
    `,
}