import styled from 'styled-components';
import { color, font, breakpoint } from 'Style/Globals';

const Fieldbutton = styled.button`
    position: absolute;
    left: 0;
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    height: 50px;

    & > i {
        color: ${color.black};
        transition: 200ms ease-in;
        
        :before {
            font-weight: bold;

            @media ${breakpoint.min701} {
                font-weight: normal;
            }
        }

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }
    }

    &:hover {
        & > i {
            color: ${color.blue};
        }
    }

    @media ${breakpoint.min701} {
        height: 20px;
    }
`;

export const TimepickerStyle = {
    Container: styled.div`
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
    `,
    Label: styled.label`
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        text-transform: uppercase;
    `,
    Field: styled.div`
        width: 50px;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;

        @media ${breakpoint.min701} {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    `,
    FieldButtonUp: styled(Fieldbutton)`
        top: 0;

        & > i {
            margin-top: -10px;
        }
    `,
    FieldButtonDown: styled(Fieldbutton)`
        bottom: 0;

        & > i {
            margin-top: 10px;

            @media ${breakpoint.min701} {
                        margin-top: -5px;
            }
        }
    `,
    FieldValue: styled.div`
        color: ${color.black};
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 0;
        font-family: ${font.regular};

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }
    `,
    Divider: styled.div`
        width: 5px;
        align-self: center;
    `,
    Button: styled.div`
        & > button {
            display: block;
            width: 100%;
        }
    `
}