import styled from "styled-components";
import { color, font } from "Style/Globals";

export const PagerStyle = {
    Container: styled.div`
        margin: 20px 10px 0;

        @media print {
            display: none;
        }
    `,
    Inner: styled.div`
        text-align: center;
    `,
    Button: styled.button<{ isCurrent?: boolean, isDisabled?: boolean }>`
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-width: 2px;
        border-style: solid;
        border-color: ${props => {
            if (props.isDisabled) return color.buttonDisabled;
            if (props.isCurrent) return color.blueActive;
            return color.blue;
        }};
        background-color: ${props => {
            if (props.isCurrent) return color.blueActive;
            return color.white;
        }};
        margin: 0 5px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        cursor: ${props => (props.isCurrent || props.isDisabled) ? "default" : "pointer"};
        transition: 200ms ease-in;
        pointer-events: ${props => (props.isCurrent || props.isDisabled) ? "none" : "all"};

        & > i {
            position: absolute;
            top: -2px;
            left: -2px;

            :before {
                color: ${props => {
            if (props.isDisabled) return color.buttonDisabled;
            return color.blue;
        }};
            }
        }

        & > span {
            color: ${props => {
            if (props.isCurrent) return color.white;
            return color.blue;
        }};
            font-family: ${font.bold};
        }

        :hover,
        :focus  {
            border-width: 2px;
            border-style: solid;
            border-color: ${props => {
            if (props.isCurrent) return color.blueActive;
            if (props.isDisabled) return color.buttonDisabled;
            return color.blueHover;
        }};
            box-shadow: 0 0 1px ${props => (props.isCurrent || props.isDisabled) ? color.transparent : color.blueHover};
        }
    `,
}