import { getQuarter } from "Shared/Functions/DateMath";
import { ExtendedPodResData, PodResData } from "Shared/Globals";

const getAllTeamNames = (data: PodResData[]) => {
    if (data.length > 0) {
        const teamnames: string[] = [];
        data.forEach((item) => {
            if (!teamnames.includes(item.text)) teamnames.push(item.text);
        })
        return teamnames.sort();
    }
    return null;
}

const convertTeamData = (data: PodResData[]) => {
    const teamNames = getAllTeamNames(data);
    if (teamNames) {
        const tmpArr: ExtendedPodResData[] = teamNames?.map((team) => {
            return { teamname: team, children: [] }
        })
        tmpArr.forEach((item) => {
            data.forEach((d) => {
                if (item.teamname === d.text) {
                    item.children?.push(d.id as string);
                    item.year = d.day.getFullYear();
                    item.podId = d.podId;
                    item.quarter = getQuarter(d.day)
                }
            })
        })
        return tmpArr;
    }
    return null;
}

export const mapTeamData = (q1: PodResData[], q2: PodResData[]) => {
    const q1Data = convertTeamData(q1);
    const q2Data = convertTeamData(q2);
    let arr: ExtendedPodResData[] = [];

    if (q1Data && q2Data) arr = [...q1Data, ...q2Data];
    else if (q1Data) arr = q1Data;
    else if (q2Data) arr = q2Data;

    arr.sort((a, b) => (a.quarter as number) - (b.quarter as number));
    return arr.sort((a, b) => {
        let na = a.teamname.toLowerCase();
        let nb = b.teamname.toLowerCase();
        if (na < nb) return -1;
        if (na > nb) return 1;
        return 0;
    })
}