import useLayout from "Shared/Context/LayoutContext";
import { Floor } from "Main/Floor/Floor";
import { getTimeString } from "Shared/Functions/DateMath";
import { Desk } from "Shared/Functions/Desk";
import { availableFloors, BookingData, FloorPage } from "Shared/Globals";
import { Icon } from "Shared/Components";
import { FinderStyle } from "./Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const PeopleFinderSearchResultItem = (props: {
    result: BookingData;
    isExpanded: boolean;
    setExpanded: (item: BookingData | undefined) => void;
    idx: number;
}) => {

    const { isMobile } = useLayout();
    const { curLang, curLangData } = useLang();

    const desk = new Desk(props.result.seat);
    const option = availableFloors.filter((f) => f.id === desk.Floor.Id)[0];

    return (
        <FinderStyle.ResultItem>
            <FinderStyle.ResultItemHeader
                onClick={() => props.setExpanded(props.isExpanded === true ? undefined : props.result)}
                data-testid={`${TestId.peopleFinderResult}_listitem${props.idx}`}
            >
                <FinderStyle.ResultItemDate>{props.result.date.toLocaleDateString(curLang?.browser, { dateStyle: isMobile(801) ? "short" : "long" })}</FinderStyle.ResultItemDate>
                <FinderStyle.ResultItemHeaderColumn>{getTimeString(
                    props.result.date,
                    curLangData?.text.FullTime as string,
                    props.result.endDate,
                    curLang?.id === 1 ? curLangData?.text.Clock : undefined
                )}</FinderStyle.ResultItemHeaderColumn>
                {!isMobile(900) &&
                    <FinderStyle.ResultItemHeaderColumn>{desk.Floor.Name}</FinderStyle.ResultItemHeaderColumn>
                }
                {!isMobile(410) &&
                    <FinderStyle.ResultItemHeaderColumn>{desk.UniqueId}</FinderStyle.ResultItemHeaderColumn>
                }
                <FinderStyle.ResultItemIcon isExpanded={props.isExpanded}>
                    <Icon.PfeilRechts isBold />
                </FinderStyle.ResultItemIcon>
            </FinderStyle.ResultItemHeader>
            {props.isExpanded &&
                <FinderStyle.ResultItemMap>
                    <Floor
                        selectedFloor={option}
                        selectedDate={props.result.date}
                        selectedEndDate={props.result.endDate}
                        highlightedDeskId={props.result.seat}
                        page={FloorPage.colleague}
                    />
                </FinderStyle.ResultItemMap>
            }
        </FinderStyle.ResultItem >
    )
}