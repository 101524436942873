import { compareDates, getTimeString } from "Shared/Functions/DateMath";
import { BookingData } from "Shared/Globals";

export const checkIfFullTimeBookingExists = (str: string, selectedFrom: Date, selectedTo?: Date, myCurrentBookings?: BookingData[]) => {
    let isBooking = false;
    if (myCurrentBookings != null) {
        const myBookedDesks = myCurrentBookings.filter((d) => compareDates(d.date, selectedFrom)).filter((d) => getTimeString(d.date, str, d.endDate) === str);
        isBooking = myBookedDesks.length > 0 && getTimeString(selectedFrom, str, selectedTo) === str;
    }
    return isBooking
};

export const checkIfUserHas3Bookings = (selectedFrom: Date, myCurrentBookings?: BookingData[]) => {
    let hasBookings = false;
    if (myCurrentBookings != null) {
        hasBookings = myCurrentBookings.filter((d) => compareDates(d.date, selectedFrom)).length === 3;
    }
    return hasBookings
};