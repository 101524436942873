import styled from "styled-components";

const DefaultIcon = styled.i<{ size?: string, isBold?: boolean }>`
    display: inline-block;
    line-height: 1;

    :before {
        font-family: "PostIcons" !important;
        display: inline-block;
        speak: none;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: ${props => props.size ?? "30px"};
        font-weight: ${props => props.isBold ? "bold" : "normal"};
    }
`;

export const Icon = {
    Posthorn: styled(DefaultIcon)`
        :before {
            content: "\\e001";
        }
    `,
    Achtung: styled(DefaultIcon)`
        :before {
            content: "\\e007";
        }
    `,
    Einstellungen: styled(DefaultIcon)`
        :before {
            content: "\\e073";
        }
    `,
    Essen: styled(DefaultIcon)`
        :before{
            content: "\\e085";
        }
    `,
    FAQ: styled(DefaultIcon)`
            :before{
            content: "\\e088";
        }
    `,
    Gluehbirne: styled(DefaultIcon)`
        :before {
            content: "\\e101";
        }
    `,
    Kalender: styled(DefaultIcon)`
        :before {
            content: "\\e113";
        }
    `,
    Lupe: styled(DefaultIcon)`
        :before {
            content: "\\e130";
        }
    `,
    Mitarbeiter: styled(DefaultIcon)`
        :before {
            content: "\\e137";
        }
    `,
    Papierkorb: styled(DefaultIcon)`
        :before {
            content: "\\e161";
        }
    `,
    ArrowRechts: styled(DefaultIcon)`
        :before {
            content: "\\e168";
        }
    `,
    Print: styled(DefaultIcon)`
        :before {
            content: "\\e185";
        }
    `,
    StandortPin: styled(DefaultIcon)`
        :before {
            content: "\\e198";
        }
    `,
    Uhr: styled(DefaultIcon)`
        :before {
            content: "\\e206";
        }
    `,
    Werkzeug: styled(DefaultIcon)`
        :before {
            content: "\\e217";
        }
    `,
    PfeilLinks: styled(DefaultIcon)`
        :before {
            content: "\\e233";
        }
    `,
    PfeilOben: styled(DefaultIcon)`
        :before {
            content: "\\e234";
        }
    `,
    PfeilRechts: styled(DefaultIcon)`
        :before {
            content: "\\e237";
        }
    `,
    PfeilUnten: styled(DefaultIcon)`
        :before {
            content: "\\e238";
        }
    `,
    Kaffee: styled(DefaultIcon)`
        :before {
            content: "\\e281";
        }
    `,
    PersonSearch: styled(DefaultIcon)`
        :before {
            content: "\\e282";
        }
    `,
    Haken: styled(DefaultIcon)`
        :before {
            content: "\\e336";
        }
    `,
    X: styled(DefaultIcon)`
        :before {
            content: "\\e339";
        }
    `,
    KalenderHaken: styled(DefaultIcon)`
        :before {
            content: "\\e367";
        }
    `,
    Tortendiagramm: styled(DefaultIcon)`
        :before {
            content: "\\e375";
        }
    `,
    Download: styled(DefaultIcon)`
        :before {
            content: "\\e065";
     }
    `,
    Upload: styled(DefaultIcon)`
        :before {
            content: "\\e401";
        }
    `,
    Minus: styled(DefaultIcon)`
        :before {
            content: "\\e413";
        }
    `,
    LaptopPflanze: styled(DefaultIcon)`
        :before {
            content: "\\e519";
        }
    `,
}