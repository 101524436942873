import { color, font, scrollStyle } from "Style/Globals";
import styled from "styled-components";

export const Table = styled.div`
    overflow: auto;
    ${scrollStyle};
`;

export const TableRow = styled.div<{ gridTemplateColumns: string, minWidth: string, padding?: string, isHeader?: boolean }>`
    width: 100%;
    min-width: ${props => props.minWidth};
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns};
    border-top: ${props => props.isHeader ? "2px solid " + color.black : "1px solid" + color.white};
    border-bottom: ${props => props.isHeader ? "2px solid " + color.black : "none"};

    @media (prefers-color-scheme: dark) {
        border-top: ${props => props.isHeader ? "2px solid " + color.white : "1px solid" + color.black};
        border-bottom: ${props => props.isHeader ? "2px solid " + color.white : "none"};
    }

    :nth-child(odd) {
        background-color: ${props => props.isHeader ? color.transparent : color.lightgrey1};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => props.isHeader ? color.transparent : color.darkmodeBgBody};
        }
    }

    :hover {
        background-color: ${props => props.isHeader ? color.transparent : color.lightgrey2};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => props.isHeader ? color.transparent : color.darkgrey1};
        }
    }

    
    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: ${props => props.padding ?? "10px 5px"};
        font-family: ${props => props.isHeader ? font.bold : font.regular};
    }
`;