import { breakpoint, color, defaultTransition, font, scrollStyle } from "Style/Globals";
import styled from "styled-components";

export const SidePanelStyle = {
    Container: styled.div<{ isExpanded: boolean, isSticky?: boolean; }>`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: ${props => props.isExpanded ? "100%" : "0"};
        overflow: hidden;
        padding-left: 10px;
        ${defaultTransition};

        @media ${breakpoint.min1050} {
            height: auto;
            position: ${props => props.isSticky ? "fixed" : "absolute"};
            width: ${props => props.isExpanded ? "400px" : "0"};
        }

        @media ${breakpoint.min1300} {
            width: ${props => props.isExpanded ? "500px" : "0"};
        }
    `,
    Header: styled.header`
        padding-bottom: 30px;
        
        & > h3 {
            font-size: 25px;
            color: ${color.black};
            font-family: ${font.bold};
            padding-top: 5px;
            position: relative;
            display: inline-block;

            
            @media (prefers-color-scheme: dark) {
                color: ${color.white}
            }

            :before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                border-top: 3px solid ${color.black};

                @media (prefers-color-scheme: dark) {
                    border-top: 3px solid ${color.white};
                }
            }
        }
    `,
    Inner: styled.div`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 10px;
        padding: 20px;
        box-shadow: -1px 0 5px ${color.lightgrey1};
        background-color: ${color.white};
        width: calc(100% - 10px);
        min-width: 300px;
        ${defaultTransition};

        @media (prefers-color-scheme: dark) {
            background-color: ${color.black};
            box-shadow: -1px 0 5px ${color.darkmodeBgBody};
        }

        @media ${breakpoint.min701} {
            padding: 50px;
            padding-left: 100px;
        }

        @media ${breakpoint.min1050} {
            padding: 30px;
            padding-left: 30px;
            padding-top: 50px;
        }

        @media ${breakpoint.min1300} {
            padding: 50px;
            padding-left: 50px;
        }
    `,
    ScrollContent: styled.div`
        height: calc(100% - 65px);
        ${scrollStyle};
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
        margin-right: -10px;
    `,
    DismissBtn: styled.button`
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        & > i:before {
            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        @media ${breakpoint.min701} {
            top: 50px;
            right: 50px;
        }
    `
}