import styled from 'styled-components'
import { color, font, scrollStyle } from 'Style/Globals'

export const DropdownStyle = {
    Wrapper: styled.div<{ size?: string, nopadding?: boolean }>`
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: ${props => props.size ?? "auto"};
        padding: ${props => props.nopadding ? "0" : "5px"};
    `,
    Container: styled.div`
        position: relative;
    `,
    Header: styled.div`
        font-family: ${font.bold};
        position: relative;

        > label {
            top: 0;
            left: 10px;
            padding: 0 5px;
            position: absolute;
            background-color: ${color.white};
            white-space: nowrap;
            text-overflow: ellipsis;
            letter-spacing: 2px;
            color: ${color.black};
            height: 1.2em;
            display: block;
            overflow: hidden;
            font-size: 14px;
            max-width: 91%;
            margin-top: -8px;
            font-family: ${font.bold};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
                background-color: ${color.black};
            }
        }
    `,
    HeaderButton: styled.button<{ isExpanded?: boolean, isReset?: boolean, fontSize?: string, hasError?: boolean }>`
        display: block;
        border-width: 2px !important;
        border-style: solid !important;
        border-color: ${props => {
            if (props.isExpanded) return color.blue;
            if (props.hasError) return color.red;
            return color.black;
        }};
        border-bottom-color: ${props => {
            if (props.isExpanded) return color.transparent;
            if (props.hasError) return color.red;
            return color.black;
        }};
        background-color: ${color.white};
        color: ${color.black};
        position: relative;
        padding: 13px;
        padding-right: ${props => props.isReset ? "60px" : "35px"};
        width: 100%;
        text-align: left;
        
        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            background-color: ${color.black};
            border-color: ${props => {
            if (props.isExpanded) return color.blue;
            if (props.hasError) return color.red;
            return color.white;
        }};
            border-bottom-color: ${props => {
            if (props.isExpanded) return color.transparent;
            if (props.hasError) return color.red;
            return color.white;
        }};
        }

        & > span {
            font-family: ${font.bold};
            font-size: ${props => props.fontSize ?? "16px"};
        }

        :hover,
        :focus {
            outline: none;
            border-color: ${props => {
            if (props.hasError && !props.isExpanded) return color.red;
            return color.blue;
        }};
            border-bottom-color: ${props => {
            if (props.isExpanded) return color.transparent;
            if (props.hasError) return color.red;
            return color.blue;
        }};
        }
    `,
    HeaderForm: styled.form`
        display: block;
    `,
    HeaderInput: styled.input<{ isExpanded?: boolean, fontSize?: string }>`
        display: block;
        border: 2px solid ${props => props.isExpanded ? color.blue : color.black};
        border-bottom-color: ${props => props.isExpanded ? color.transparent : color.black};
        background-color: ${color.white};
        color: ${color.black};
        position: relative;
        padding: 13px;
        padding-right: 35px;
        width: 100%;
        text-align: left;
        font-family: ${font.regular};
        font-size: ${props => props.fontSize ?? "16px"};

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            background-color: ${color.black};
            border-bottom-color: ${props => props.isExpanded ? color.transparent : color.white};
        }

        & > span {
            font-family: ${font.bold};
            font-size: ${props => props.fontSize ?? "16px"};
        }

        :hover,
        :focus {
            border: 2px solid ${color.blue};
            border-bottom-color: ${props => props.isExpanded ? color.transparent : color.blue};
            outline: none;

            @media (prefers-color-scheme: dark) {
                border: 2px solid ${props => props.isExpanded ? color.blue : color.white};
                border-bottom-color: ${props => props.isExpanded ? color.transparent : color.blue};
            }
        }
    `,
    HeaderInputButton: styled.button`
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        cursor: pointer;

        & > i {
            color: ${color.black};
            transition: 200ms ease-in;
            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        :hover {
            & > i {
                color: ${color.blue};
            }
        }
    `,
    HeaderButtonIcon: styled.div<{ isExpanded?: boolean }>`
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%) ${props => props.isExpanded ? "rotate(-180deg)" : ""};
        transition: 200ms ease-in;
    `,
    HeaderReset: styled.button`
        position: absolute;
        display: block;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;

        & > i{
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        :hover {
            & > i {
                color: ${color.blue};
            }
        }
    `,
    Content: styled.div<{ openStatic?: boolean }>`
        position: ${props => props.openStatic ? "static" : "absolute"};
        z-index: 1;
        top: 100%;
        width: auto;
        left: 0;
        right: 0;
        margin-top: -2px;
        background-color: ${color.white};
        border: 2px solid ${color.blue};
        border-top: none;

        @media (prefers-color-scheme: dark) {
            background-color: ${color.black};
        }
    `,
    List: styled.ul`
        display: block;
        width: 100%;
        padding: 0;
        position: relative;
        max-height: 140px;
        overflow: auto;
        ${scrollStyle}
    `,
    ListItem: styled.li`
        display: block;
        width: 100%;
    `,
    Link: styled.a`
        display: block;
        color: ${color.black};
        padding: 6px 10px 7px;
        background-color: ${color.white};
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;

        :hover,
        :focus {
            background-color: ${color.lightgrey1};
        }

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            background-color: ${color.black};

            :hover,
            :focus {
                background-color: ${color.darkgrey1};
            }
        }
    `,
}