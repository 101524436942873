import styled from "styled-components";
import { color, font, scrollStyle } from "Style/Globals";


export const FlyoutStyle = {
    Overlay: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${color.flyoutOverlayBg};
        z-index: 99998;
        display: grid;
        place-items: center;

        & + & {
            background-color: ${color.transparent};  
        }
    `,
    Container: styled.div<{ size?: string }>`
        position: relative;
        width: ${props => props.size ?? "100%"};
        height: auto;
        max-height: 100%;
        z-index: 99999;
        background-color: ${color.white};
        box-shadow: 0 0 5px 0 ${color.flyoutBoxShadowlightmode};

        @media (prefers-color-scheme: dark) {
            background-color: ${color.black};
            box-shadow: 0 0 5px 0 ${color.flyoutBoxShadowdarkmode};
        }
    `,
    Inner: styled.div`
        position: relative;
    `,
    Header: styled.header`
        background-color: ${color.yellow};
        color: ${color.black};
        padding: 23px 20px 16px;
        position: relative;
        font-size: 23px;
        font-family: ${font.bold};

        :before {
            content: "";
            position: absolute;
            top: 20px;
            left: 20px;
            right: 20px;
            border-bottom: 3px solid ${color.black};
        }
    `,
    HeaderButton: styled.button`
        position: absolute;
        top: 20px;
        right: 13px;
        cursor: pointer;
    `,
    Content: styled.div`
        padding: 20px;
        font-size: 16px;
        font-family: ${font.regular};
        max-height: 80vh;
        overflow: auto;
        ${scrollStyle}
    `,
    ButtonRow: styled.div`
        text-align: right;
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 10px;

        & > div {
            position: relative;
            font-size: 5px;
        }
    `,
    Animation: styled.div``,
}