import useLayout from "Shared/Context/LayoutContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { BookingStyle } from "Main/MyBookings/Style";
import React from "react";
import { compareDates, getTimeString, isInTimeRange } from "Shared/Functions/DateMath";
import { Desk } from "Shared/Functions/Desk";
import { Path, BookingData } from "Shared/Globals";
import { InfoBox, InfoBoxType, Loading } from "Shared/Components";
import { PageStyle } from "./Style";
import { Confirmation } from "Main/MyBookings/Confirm";
import { Navigate, useParams } from "react-router-dom";
import useLang from "Shared/Context/LangContext";

export const ConfirmPage = () => {
    const [selectedBooking, setSelectedBooking] = React.useState<BookingData>();
    const [bookings, setBookings] = React.useState<BookingData[]>([]);

    const { myCurrentBookings, myBookingsLoading, myBookingsError } = useMyBookings();
    const { updatePageTitle } = useLayout();
    const { curLang, curLangData } = useLang();

    const { seat } = useParams();

    React.useEffect(() => {
        if (curLangData) updatePageTitle(curLangData.text.CheckIn)
    }, [updatePageTitle, curLangData]);

    React.useEffect(() => {
        const today = new Date();

        if (myCurrentBookings != null) {
            const availableBookings = myCurrentBookings.filter((b) => compareDates(today, b.date) && isInTimeRange(today, b.date, b.endDate, curLangData?.text.FullTime as string) && !b.confirmed);
            setBookings(availableBookings);
            if (seat != null) {
                const selected = availableBookings.filter((b) => b.seat === seat)[0];
                if (selected) setSelectedBooking(selected);
            }
        }
    }, [myCurrentBookings, seat, curLangData]);

    const renderDeskInfo = React.useCallback(() => {
        const info = selectedBooking as BookingData;
        const desk = new Desk(info.seat);
        return (
            <BookingStyle.Info style={{ paddingTop: "40px" }}>
                <BookingStyle.InfoRow>
                    <strong>{curLangData?.text.Date}</strong>
                    <span>{info.date.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}</span>
                </BookingStyle.InfoRow>
                <BookingStyle.InfoRow>
                    <strong>{curLangData?.text.Time}</strong>
                    <span>{getTimeString(
                        info.date,
                        curLangData?.text.FullTime as string,
                        info.endDate,
                        curLang?.id === 1 ? curLangData?.text.Clock : undefined
                    )}</span>
                </BookingStyle.InfoRow>
                <BookingStyle.InfoRow>
                    <strong>{curLangData?.text.Location}</strong>
                    <span>{desk.Floor.Name}</span>
                </BookingStyle.InfoRow>
                {desk.Area != null &&
                    <BookingStyle.InfoRow>
                        <strong>{curLangData?.text.Area}</strong>
                        <span>{desk.Area}</span>
                    </BookingStyle.InfoRow>
                }
                <BookingStyle.InfoRow>
                    <strong>{curLangData?.text.DeskId}</strong>
                    <span>{desk.UniqueId}</span>
                </BookingStyle.InfoRow>

                <BookingStyle.InfoRowButton>
                    <Confirmation booking={info} isPage />
                </BookingStyle.InfoRowButton>
            </BookingStyle.Info>
        )
    }, [selectedBooking, curLangData, curLang]);


    if (myBookingsLoading === true) return <Loading position={"absolute"} size={"10px"} />;
    if (myBookingsLoading === false && myBookingsError != null) return <InfoBox type={InfoBoxType.error} text={myBookingsError} title={curLangData?.text.Attention} marginTop={"40px"} />;
    if (selectedBooking != null) return renderDeskInfo();
    if (bookings.length > 0 && !seat) {
        if (bookings.length === 1) return <Navigate to={Path.Confirm + "/" + bookings[0].seat} />;
        return <>
            <PageStyle.Text>{curLangData?.info.DeskConfirm}</PageStyle.Text>
            {bookings.map((b) => {
                return <PageStyle.Link to={Path.Confirm + "/" + b.seat} key={b.seat}>{b.seat}</PageStyle.Link>
            })}
        </>
    }
    return <PageStyle.Text>
        {curLangData?.info.DeskConfirmInfo1} <br />
        {curLangData?.info.DeskConfirmInfo2}
    </PageStyle.Text>;
}