import { useApi } from "Shared/Context/API";
import { ConfAnimation, DefaultButton, Loading, InfoBox, InfoBoxType } from "Shared/Components";
import React from "react";
import { BookingStyle } from "./Style";
import { BookingData } from "Shared/Globals";
import { compareDates, isInTimeRange } from "Shared/Functions/DateMath";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const Confirmation = (props: {
    booking: BookingData;
    isPage?: boolean;
    setError?: (msg: string | undefined) => void;
}) => {
    const [confirmationLoading, setConfirmationLoading] = React.useState(false);
    const [isConfirmed, setConfirmed] = React.useState(props.booking.confirmed ?? false);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>();
    const api = useApi();
    const { updateMyBookings } = useMyBookings();
    const { curLangData } = useLang();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setConfirmed(props.booking.confirmed ?? false);
            setErrorMsg(undefined);
            const _setError = props.setError;
            if (_setError) _setError(undefined);
        }
        return () => { isMounted = false }
    }, [props.booking.confirmed, props.setError]);

    const onConfirm = React.useCallback(() => {
        setConfirmationLoading(true);
        const _setError = props.setError;
        api
            .confirmBooking(props.booking.id as string)
            .then(() => {
                setConfirmed(true);
                setErrorMsg(undefined);
                if (!props.isPage && _setError) _setError(undefined);
                props.booking.confirmed = true;
                updateMyBookings();
            })
            .catch((err) => {
                let msg = curLangData?.error.Confirm;
                if (err && err.messageId) msg = ErrorMsg(err.messageId, false, false, curLangData?.error) ?? msg;
                setErrorMsg(msg);
                if (!props.isPage && _setError) _setError(msg);
            })
            .finally(() => setConfirmationLoading(false))
    }, [api, props.booking, props.isPage, props.setError, updateMyBookings, curLangData]);

    if (confirmationLoading) return <BookingStyle.Confirmation size={"180px"}><Loading position={"absolute"} size={"10px"} /></BookingStyle.Confirmation>;
    if (isConfirmed) return (
        <BookingStyle.ConfirmationContainer>
            <BookingStyle.Confirmation size={"50px"}><ConfAnimation /></BookingStyle.Confirmation>
            <BookingStyle.ConfirmationText><span>{curLangData?.text.ConfText1}</span>{curLangData?.text.ConfText2}</BookingStyle.ConfirmationText>
        </BookingStyle.ConfirmationContainer>
    );
    if (compareDates(new Date(), props.booking.date) && isInTimeRange(new Date(), props.booking.date, props.booking.endDate, curLangData?.text.FullTime as string)) return (
        <BookingStyle.ConfirmationButtonContainer>
            <DefaultButton
                onClick={() => onConfirm()}
                data-testid={`${TestId.mybookings}_buttonCheckin`}
            >{curLangData?.text.CheckInButton}</DefaultButton>
            {errorMsg != null && props.isPage &&
                <InfoBox type={InfoBoxType.error} text={errorMsg} title={curLangData?.text.Attention} marginTop={"20px"} />
            }
        </BookingStyle.ConfirmationButtonContainer>
    );
    return null;
}