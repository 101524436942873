import React from "react";
import useLayout from "Shared/Context/LayoutContext";
import { Path } from "Shared/Globals";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminBlock } from "Main/Admin/AdminBlock";
import { AdminNav } from "Main/Admin/AdminNav";
import { AdminSection } from "Main/Admin/AdminSection";
import useLang from "Shared/Context/LangContext";
import { AdminPods } from "Main/Admin/AdminPods";
import useUser from "Shared/Context/UserContext";

export const AdminPage = () => {
    const [navHeight, setNavHeight] = React.useState(0);
    const { updatePageTitle } = useLayout();
    const menu = React.useRef<HTMLElement>(null);
    const { curLangData } = useLang();
    const { isAdmin, isPodManager } = useUser();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.Admin)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle]);

    const getHeight = React.useCallback(() => {
        if (menu.current) setNavHeight(menu.current.clientHeight);
    }, []);

    React.useEffect(() => {
        getHeight();

        window.addEventListener("resize", getHeight);
        return () => {
            window.removeEventListener("resize", getHeight);
        }
    }, [getHeight]);

    const podMgmt = isPodManager ? <AdminPods /> : <AdminBlock navHeight={navHeight} />;

    return (
        <>
            <AdminNav ref={menu} />
            <Routes>
                <Route index element={<Navigate to={isAdmin ? Path.AdminBlock : Path.AdminPods} />} />
                <Route path={Path.AdminBlock} element={isAdmin ? <AdminBlock navHeight={navHeight} /> : <AdminPods />} />
                <Route path={Path.AdminSection} element={isAdmin ? <AdminSection navHeight={navHeight} /> : <AdminPods />} />
                <Route path={Path.AdminPods} element={podMgmt}>
                    <Route path={":teamname"} element={podMgmt}>
                        <Route path={":quarter"} element={podMgmt} />
                    </Route>
                </Route>
            </Routes>
        </>
    )
}