import { TeamdaysList } from "Main/PodMgmt/TeamdaysList";
import React from "react";
import { InfoBox, InfoBoxType } from "Shared/Components";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import usePodReservation from "Shared/Context/PodReservationContext";
import { PodState } from "Main/Floor/Pod/Functions/PodState";
import { FloorPage, PodData, PodStateName } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { FloorStyle } from "Main/Floor/Style";
import { DefaultPodInfo } from "./DefaultPodInfo";
import { ExistingReservations } from "Main/Floor/Shared/ExistingReservations";
import { PodStateRow } from "./PodStateRow";
import { useNavigate } from "react-router-dom";
import { encodeText } from "Shared/Functions/URI";

export const PodInfoMgmt = (props: {
    podId: string;
    onDismiss: (backToResList?: boolean) => void;
    page: FloorPage;
    highlightedDeskId?: string;
}) => {
    const { curLangData } = useLang();
    const { podData } = useFloor();
    const { resData } = usePodReservation();
    let navigate = useNavigate();

    const data = React.useMemo(() => {
        return podData.find((p) => p.position.id === props.podId);
    }, [podData, props.podId])

    const state = React.useMemo(() => {
        if (data) return PodState(data, props.page, resData, undefined, undefined, props.highlightedDeskId);
        return PodStateName.disabled;
    }, [data, props.highlightedDeskId, props.page, resData])

    const renderTeamName = () => {
        let teamname: string | undefined = undefined;
        if (resData.teamname.length > 0) teamname = resData.teamname;
        else if (state === PodStateName.mine && data?.reservations && data.reservations.length > 0) teamname = data?.reservations[0].text;

        if (teamname) return (
            <>
                <br />
                <FloorStyle.DeskInfoRow
                    data-testid={`${TestId.podinfo}_rowTeamname`}
                >
                    <strong>{curLangData?.text.TeamName2}</strong> <span>{teamname}</span>
                </FloorStyle.DeskInfoRow>
            </>
        )
    }

    return (
        <>
            <DefaultPodInfo
                podId={props.podId}
            />
            {props.page === FloorPage.podmgmtNew &&
                <PodStateRow
                    data={data as PodData}
                    page={props.page}
                    state={state}
                />
            }

            <FloorStyle.DeskInfoRow
                data-testid={`${TestId.podinfo}_rowCapacity`}
            >
                <strong>{curLangData?.text.Desks}</strong> <span>{data?.position.count}</span>
            </FloorStyle.DeskInfoRow>


            {renderTeamName()}

            {resData.teamsize != null && resData.teamsize > 0 &&
                <FloorStyle.DeskInfoRow
                    data-testid={`${TestId.podinfo}_rowTeamsize`}
                >
                    <strong>{curLangData?.text.TeamSize}</strong> <span>{resData.teamsize}</span>
                </FloorStyle.DeskInfoRow>
            }

            {state === PodStateName.notRecommended &&
                <InfoBox
                    type={InfoBoxType.default}
                    text={curLangData?.info.PodSize}
                    marginTop={"20px"}
                />
            }

            {(state === PodStateName.reserved || state === PodStateName.disabled) ?
                <ExistingReservations
                    podId={props.podId}
                    showDetails={props.page === FloorPage.podmgmt}
                />
                :
                <>
                    <br />
                    <br />
                </>
            }

            {state !== PodStateName.disabled &&
                <TeamdaysList
                    onConfirm={() => navigate(`${encodeText(resData.teamname)}/${resData.quarter}`)}
                    onDismiss={props.onDismiss}
                    podState={state}
                    page={props.page}
                    podId={props.podId}
                />
            }
        </>
    )
}