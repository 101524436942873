import React from "react";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import useFloor from "Shared/Context/FloorContext";
import { DeskData, FloorPage } from "Shared/Globals"
import { TestId } from "Test/TestId";
import { FloorStyle } from "../Style";
import { DeskBtn } from "./DeskBtn";

export const DeskGroup = (props: {
    page: FloorPage;
    selectedFloor: DropdownOption;
    selectedFrom?: Date;
    selectedTo?: Date;
    selectedSeatTypes?: DropdownOption[];
    highlightedDeskId?: string;
    onSelect: (id: string, isDeskgroup?: boolean) => void;
    id: string;
    notScrollable?: boolean;
    activeEl?: string;
    containerWidth: number;
}) => {
    const smallerView = props.containerWidth < 801 && !props.notScrollable;
    const { deskGroupData } = useFloor();

    const isUZD = props.id.includes("UZD");

    const data = React.useMemo(() => {
        return deskGroupData.find((d) => props.id.includes(d.name))
    }, [deskGroupData, props.id]);

    const desks = data?.desks.length ?? 0;
    const dgType = data?.deskGroupType ?? 0;

    const isSelectedType = React.useMemo(() => {
        return props.selectedSeatTypes == null || props.selectedSeatTypes.length === 0 || props.selectedSeatTypes.some((s) => parseInt(s.id) === dgType);
    }, [dgType, props.selectedSeatTypes])

    const posData = React.useMemo(() => {
        const pos = data?.position;
        if (pos) return {
            smallTop: pos[0],
            top: pos[1],
            smallLeft: pos[2],
            left: pos[3],
            rotation: pos[4],
            rotateDesk: (pos.length === 6 && pos[5] === "1") ? true : false
        }
        return null;
    }, [data?.position]);

    const width = () => {
        let w: string;
        if (desks === 6) {
            w = isUZD ? "9.8%" : "6%";
        }
        else if (desks === 4) {
            w = isUZD ? "6.5%" : "4%";
        }
        else if (posData?.rotateDesk) {
            w = "2%";
        }
        else {
            w = isUZD ? "3.4%" : "2.2%";
        }
        return w;
    }

    const height = () => {
        let h: string;
        if (props.selectedFrom == null && desks === 3) h = "6.8%";
        else if (posData?.rotateDesk) h = "4.4%";
        else h = isUZD ? "5.9%" : "3.8%";
        return h;
    }

    const renderDesk = (desk: DeskData) => {
        return (
            <DeskBtn
                deskid={desk.id}
                deskgroupid={props.id}
                isActive={props.activeEl === desk.id}
                page={props.page}
                highlightedDeskId={props.highlightedDeskId}
                selectedFrom={props.selectedFrom as Date}
                selectedTo={props.selectedTo as Date}
                onSelect={props.onSelect}
            />
        )
    }

    const renderDeskBtns = () => {
        if (props.page !== FloorPage.adminUnit && data != null) return (
            <>
                {desks === 3 ?
                    <div>
                        {renderDesk(data.desks[0])}
                        {renderDesk(data.desks[1])}
                        {renderDesk(data.desks[2])}
                    </div>
                    :
                    <div>
                        {renderDesk(data?.desks[0])}
                        {renderDesk(data.desks[1])}
                    </div>
                }

                {desks > 3 &&
                    <div>
                        {renderDesk(data.desks[2])}
                        {renderDesk(data.desks[3])}
                    </div>
                }

                {desks === 6 &&
                    <div>
                        {renderDesk(data.desks[4])}
                        {renderDesk(data.desks[5])}
                    </div>
                }
            </>
        )
    }


    if (desks === 0) return null;

    if (posData != null && isSelectedType) return (
        <FloorStyle.ButtonGroup
            isActive={props.activeEl === props.id}
            desknumber={desks}
            top={smallerView ? posData.smallTop : posData.top}
            left={smallerView ? posData.smallLeft : posData.left}
            rotation={posData.rotation}
            width={width()}
            height={height()}
            clickable={props.selectedFrom == null}
            onClick={props.selectedFrom == null ? () => props.onSelect(props.id, true) : undefined}
            data-testid={props.selectedFrom == null ? `${TestId.floor}_deskgroup${data?.name}` : undefined}
        >
            {renderDeskBtns()}
        </FloorStyle.ButtonGroup>
    )
    return null;
}