import styled from "styled-components";
import { breakpoint, color, font, scrollStyle } from "Style/Globals";

export const FinderStyle = {
    Settings: styled.div<{ smallerView?: boolean }>`
        display: ${props => props.smallerView ? "block" : "flex"};
        padding: 5px 0 0;
        max-width: 700px;
        margin: 0 -5px 0 -10px;

        @media ${breakpoint.min701} {
            padding: 33px 0 0;
        }
    `,
    SettingsInner: styled.div<{ size: string }>`
        width: ${props => props.size};
    `,
    SettingsDropdownContent: styled.div`
        position: relative;
        min-height: 50px;
        max-height: 150px;
        overflow: auto;
        ${scrollStyle}
    `,
    SettingsPersonBtn: styled.button`
        display: block;
        position: relative;
        cursor: pointer;
        padding: 10px;
        padding-left: 60px;
        background-color: ${color.lightgrey1};
        width: 100%;
        height: 50px;
        transition: 200ms ease-in;

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgBody};
        }

        :hover,
        :focus {
            background-color: ${color.lightgrey2};

            @media (prefers-color-scheme: dark) {
                background-color: ${color.SubMenuDarkmode};
            }
        }
    `,
    SettingsPersonImg: styled.img`
        display: block;
        object-fit: cover;
        width: 100%;
    `,
    SettingsPersonImgContainer: styled.div<{ isAvatar?: boolean }>`
        width: 50px;
        height: 50px;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        @media (prefers-color-scheme: dark) {
            filter: ${props => props.isAvatar ? "invert(1)" : "invert(0)"};
        }
    `,
    SettingsPersonName: styled.div`
        font-family: ${font.regular};
        font-size: 16px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: ${color.black};

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }
    `,
    SettingsInfoText: styled.div<{ isError?: boolean }>`
        padding: 10px;
        font-family: ${font.regular};
        font-size: 14px;
        color: ${props => props.isError ? color.red : color.black};

        @media (prefers-color-scheme: dark) {
            color: ${props => props.isError ? color.red : color.white};
        }
    `,
    CheckboxContainer: styled.div`
        padding: 10px 0 0;
        max-width: 700px;

        @media ${breakpoint.min701} {
            padding: 20px 0 0;
        }
    `,
    Results: styled.div`
        padding-top: 20px;
        @media ${breakpoint.min701} {
            padding-top: 40px;
        }
    `,
    ResultsHeader: styled.header`
    `,
    ResultsTitle: styled.h3`
        font-family: ${font.regular};
        font-size: 20px;

        @media ${breakpoint.min701} {
            font-size: 25px;
        }

        & > strong {
            font-weight: normal;
            font-family: ${font.bold};
        }
    `,
    ResultsList: styled.ul`
        padding-top: 10px;
        @media ${breakpoint.min701} {
            padding-top: 20px;
        }
    `,
    ResultItemHeader: styled.header`
        display: flex;
        position: relative;
        padding-right: 30px;
        background-color: ${color.lightgrey1};
        cursor: pointer;

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgBody};
        }
    `,
    ResultItem: styled.li`
        & + & {
            margin-top: 2px;
        }

        :nth-child(even) > header {
            background-color: ${color.lightgrey2};

            @media (prefers-color-scheme: dark) {
                background-color: ${color.SubMenuDarkmode};
            }
        }
    `,
    ResultItemHeaderColumn: styled.div`
        padding: 6px 8px;
    `,
    ResultItemDate: styled.div`
        font-family: ${font.bold};
        padding: 6px 8px;

        & + div {
            width: 150px;
        }

        @media ${breakpoint.min801} {
            width: 130px;
        }
    `,
    ResultItemIcon: styled.div<{ isExpanded?: boolean }>`
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(${props => props.isExpanded ? "90deg" : "0"});
    `,
    ResultItemMap: styled.div`
        margin-bottom: 20px;
        position: relative;
        min-height: 100px;
    `,
}