import useLang from "Shared/Context/LangContext";
import { Desk } from "Shared/Functions/Desk";
import { TestId } from "Test/TestId";
import { FloorStyle } from "Main/Floor/Style";

export const DefaultPodInfo = (props: {
    podId: string;
    selectedFrom?: Date;
}) => {
    const { curLang, curLangData } = useLang();
    const desk = new Desk(props.podId);

    return (
        <>
            {props.selectedFrom &&
                <>
                    <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowDate`}>
                        <strong>{curLangData?.text.Date}</strong>
                        <span>{props.selectedFrom.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}</span>
                    </FloorStyle.DeskInfoRow>
                    <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowTime`}>
                        <strong>{curLangData?.text.Time}</strong>
                        <span>{curLangData?.text.FullTime}</span>
                    </FloorStyle.DeskInfoRow>
                </>
            }

            <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowFloor`}>
                <strong>{curLangData?.text.Location}</strong> <span>{desk.Floor.Name}</span>
            </FloorStyle.DeskInfoRow>

            {(desk.Area != null && desk.Area.length > 0) &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowArea`}>
                    <strong>{curLangData?.text.Area}</strong> <span>{desk.Area}</span>
                </FloorStyle.DeskInfoRow>
            }

            <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowName`}>
                <strong>{curLangData?.text.PodId}</strong>
                <span>{props.podId}</span>
            </FloorStyle.DeskInfoRow>
        </>
    )
}