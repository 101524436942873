import { BookingSettings } from "Main/Settings/BookingSettings";
import React from "react";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import useUser from "Shared/Context/UserContext";
import { availableFloors, FloorPage } from "Shared/Globals";
import { Floor } from "../Floor/Floor";

export const BookingPage = () => {
    const [selectedFloor, setSelectedFloor] = React.useState<DropdownOption>();
    const [selectedSeatTypes, setSelectedSeatTypes] = React.useState<DropdownOption[]>();
    const [selectedDate, setSelectedDate] = React.useState<Date>();
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date>();
    const [settingsHeight, setSettingsHeight] = React.useState<number>(0);

    const { updatePageTitle } = useLayout();
    const { profileData } = useUser();
    const { curLangData } = useLang();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.Booking)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle]);

    React.useEffect(() => {
        let isMounted = true;
        if (profileData?.lastFloor != null && isMounted) {
            const floor = availableFloors.filter((f) => f.id === profileData.lastFloor);
            if (floor.length > 0) setSelectedFloor(floor[0])
        }
        return () => { isMounted = false }
    }, [profileData])

    const calcScrollHeight = React.useMemo(() => {
        const headerH = 85;
        const margin = 45;
        return headerH + margin + settingsHeight;
    }, [settingsHeight]);

    return (
        <>
            <BookingSettings
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                setSelectedSeatTypes={setSelectedSeatTypes}
                selectedSeatTypes={selectedSeatTypes}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setSelectedEndDate={setSelectedEndDate}
                setSettingsHeight={setSettingsHeight}
                page={FloorPage.booking}
            />

            {selectedFloor != null && selectedDate != null &&
                <Floor
                    selectedFloor={selectedFloor}
                    selectedDate={selectedDate}
                    selectedEndDate={selectedEndDate}
                    selectedSeatTypes={selectedSeatTypes}
                    scrollHeight={calcScrollHeight}
                    page={FloorPage.booking}
                />
            }
        </>
    )
}