import useLang from "Shared/Context/LangContext"
import useLayout from "Shared/Context/LayoutContext"
import { getTimeString } from "Shared/Functions/DateMath"
import { Path, BookingData } from "Shared/Globals"
import { TestId } from "Test/TestId"
import { BookingStyle } from "./Style"

export const MyBookingsTimeSubMenu = (props: {
    todaysBookings: BookingData[];
}) => {
    const { expandNav } = useLayout();
    const { curLangData, curLang } = useLang();
    return (
        <BookingStyle.SubMenu expandNav={expandNav}>
            {props.todaysBookings.map((tb, i) => {
                return (
                    <BookingStyle.SubMenuItem key={tb.id}>
                        <BookingStyle.SubMenuLink
                            to={Path.MyBookings + "/" + tb.date.toLocaleDateString('de-DE').replace(/\./g, "-") + "/" + tb.id}
                            data-testid={`${TestId.mybookingsnav}_item${i}`}
                        >
                            {getTimeString(
                                tb.date,
                                curLangData?.text.FullTime as string,
                                tb.endDate,
                                curLang?.id === 1 ? curLangData?.text.Clock : undefined
                            )}
                        </BookingStyle.SubMenuLink>
                    </BookingStyle.SubMenuItem>
                )
            })}
        </BookingStyle.SubMenu>
    )
}