import React from "react";
import useLayout from "Shared/Context/LayoutContext";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { Checkbox, Dropdown, TimePicker } from "Shared/Components";
import { availableHoursDE, availableHoursEN } from "Shared/Globals";
import { PageStyle } from "Main/Page/Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const TimeSettings = (props: {
    smallerContainer?: boolean;
    setStartTime: (time: string[]) => void;
    setEndTime: (time: string[]) => void;
}) => {
    const { isMobile } = useLayout();
    const [withHours, setWithHours] = React.useState<boolean>(false);
    const [selectedTime, setSelectedTime] = React.useState<string[]>([]);
    const [selectedHours, setSelectedHours] = React.useState<DropdownOption | undefined>();
    const { curLang, curLangData } = useLang();

    React.useEffect(() => {
        const _setStartTime = props.setStartTime;
        const _setEndTime = props.setEndTime;

        if (withHours === false && (selectedTime.length > 0 || selectedHours != null)) {
            setSelectedTime([]);
            setSelectedHours(undefined);
            _setStartTime([]);
            _setEndTime([]);
        }

        else if (withHours === true && (selectedTime.length === 0 || selectedHours == null)) {
            const startTime = ["09", "00"];
            const endTime = ["11", "00"];

            const duration = { id: "2", text: curLang?.text === "EN" ? "two hours" : "zwei Stunden" };
            setSelectedTime(startTime);
            setSelectedHours(duration);
            _setStartTime(startTime);
            _setEndTime(endTime);
        }
    }, [withHours, props.setStartTime, props.setEndTime, selectedTime, selectedHours, curLang]);

    const timetext = React.useMemo(() => {
        if (selectedTime.length > 0) return selectedTime[0] + ":" + selectedTime[1];
        return curLangData?.text.TimeSelect;
    }, [selectedTime, curLangData]);

    const possibleHours = React.useMemo(() => {
        const timetoNum = parseInt(selectedTime[0] + selectedTime[1]);
        const hours = curLang?.text === "EN" ? [...availableHoursEN] : [...availableHoursDE];
        if (timetoNum > 2000) return hours.filter((o) => parseInt(o.id) < 2);
        if (timetoNum > 1900) return hours.filter((o) => parseInt(o.id) < 3);
        if (timetoNum > 1800) return hours.filter((o) => parseInt(o.id) < 4);
        if (timetoNum > 1700) return hours.filter((o) => parseInt(o.id) < 5);
        if (timetoNum > 1600) return hours.filter((o) => parseInt(o.id) < 6);
        return hours;
    }, [selectedTime, curLang]);

    const getEndTime = React.useCallback((time: string[], option: DropdownOption) => {
        const _setEndTime = props.setEndTime;
        const selHour = parseInt(time[0]) + parseInt(option.id);
        _setEndTime([selHour.toString(), time[1]]);
    }, [props.setEndTime]);

    React.useEffect(() => {
        if (selectedTime.length > 0 && selectedHours != null) {
            const available = possibleHours.filter((h) => h.id === selectedHours.id);
            if (available.length === 0) {
                const hour = possibleHours[possibleHours.length - 1];
                getEndTime(selectedTime, hour);
                setSelectedHours(hour);
            }
        }
    }, [selectedTime, selectedHours, possibleHours, getEndTime])

    return (
        <PageStyle.Flex>
            <PageStyle.SettingsCheckbox
                width={props.smallerContainer ? "100%" : "33.333%"}
            >
                <Checkbox
                    id={TestId.timecheckbox}
                    label={curLangData?.text.HourBooking as string}
                    onChange={() => setWithHours(!withHours)}
                    checked={withHours}
                    fontSize={"18px"}
                />
            </PageStyle.SettingsCheckbox>
            {withHours &&
                <PageStyle.SettingsTime width={props.smallerContainer ? "100%" : "66.666%"}>
                    <TimePicker
                        defaultText={timetext}
                        width={isMobile(450) ? "100%" : "50%"}
                        defaultValue={selectedTime.length > 0 ? selectedTime : ["09", "00"]}
                        setTime={(time) => {
                            setSelectedTime(time);
                            props.setStartTime(time);
                            if (selectedHours != null) {
                                getEndTime(time, selectedHours)
                            }
                        }}
                        fontSize={"18px"}
                        id={TestId.timepicker}
                        label={curLangData?.text.Starttime as string}
                    />
                    {selectedTime.length > 0 &&
                        <Dropdown
                            options={possibleHours}
                            defaultText={curLangData?.text.DurationSelect as string}
                            onSelect={(option) => {
                                setSelectedHours(option);
                                getEndTime(selectedTime, option);
                            }}
                            selectedOption={selectedHours}
                            width={isMobile(450) ? "100%" : "50%"}
                            fontSize={"18px"}
                            label={curLangData?.text.Duration as string}
                            id={TestId.durationSelect}
                        />
                    }
                </PageStyle.SettingsTime>
            }
        </PageStyle.Flex>
    )
}