export const color = {
    transparent: "rgba(0,0,0,0)",
    black: "#000",
    white: "#fff",
    whiteAlpha: "rgba(255,255,255,.7)",

    lightgrey: "#f0f3f8",
    lightgrey1: "#eceff4",
    lightgrey2: "#dfe3ec",

    palegraydark: "#c6cfde",
    darkgrey1: "#171717",

    yellow: "#ffdc00",
    yellowActive: "#fac800",
    yellowHover: "#ffe66e",

    blue: "#0078c8",
    blueActive: "#005fa0",
    blueHover: "#008ce6",

    orange: "#c85000",
    orangActive: "#a04100",
    orangeHover: "#e65f00",

    red: "#d20019",
    redActive: "#b40014",
    redHover: "#f0001e",

    green: "#2d8700",
    greenActive: "#1e5f00",
    greenHover: "#4baf19",

    violet: "#4b007d",
    violetAlpha: "rgba(75, 0, 125, .6)",
    violetHover: "#7800c3",
    violetHoverAlpha: "rgba(120, 0, 195, .7)",

    petrol: "#1c6863",
    petrolAlpha: "rgba(28,104,99,.8)",

    darkmodeBgBody: "#26292a",
    lightmodeBgBody: "#eceff4",

    darkmodeBgContent: "#000",
    lightmodeBgContent: "#fff",

    flyoutOverlayBg: "rgba(0,0,0,.7)",
    flyoutBoxShadowlightmode: "rgba(0,0,0,.4)",
    flyoutBoxShadowdarkmode: "rgba(255,255,255,.5)",

    asideSectionBg: "rgba(255,255,255,.5)",

    checkboxShadowlightmode: "rgba(0,0,0,.2)",
    checkboxShadowdarkmode: "rgba(255,255,255,.4)",

    placeHolderDark: "rgba(255,255,255,.5)",
    placeHolderLight: "rgba(0,0,0,.6)",

    SubMenuDarkmode: "#1d1f20",
    SubMenuLightmode: "#dfe3ec",

    buttonDisabled: "#c6cfde",
}