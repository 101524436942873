import React from "react";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import { PageStyle } from "./Style"

export const PageLinks = () => {
    const { expandNav, isMobile } = useLayout();
    const { curLang, curLangData } = useLang();

    const dataUrl = React.useMemo(() => {
        if (curLang?.text === "EN") return ["https://www.post.at/en/i/c/legal-notice", "https://www.post.at/en/i/c/data-protection"]
        return ["https://www.post.at/co/c/impressum", "https://www.post.at/co/c/datenschutz"]
    }, [curLang]);

    return (
        <PageStyle.Bottom isMobile={isMobile(700)} expandNav={expandNav}>
            <PageStyle.BottomLink
                href={dataUrl[0]}
                target="_blank"
                isMobile={isMobile(700)}
            >
                {curLangData?.text.Imprint}
            </PageStyle.BottomLink>
            <span>|</span>
            <PageStyle.BottomLink
                href={dataUrl[1]}
                target="_blank"
                isMobile={isMobile(700)}
            >
                {curLangData?.text.DataProtection}
            </PageStyle.BottomLink>
        </PageStyle.Bottom>
    )
}