import React from "react";
import useFloor from "Shared/Context/FloorContext";
import usePodReservation from "Shared/Context/PodReservationContext";
import { FloorPage, PodData, PodStateName } from "Shared/Globals";
import { PodState } from "Main/Floor/Pod/Functions/PodState";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { PodStyle } from "./Pod.style";
import { PodStateText } from "Main/Floor/Pod/Functions/PodStateText";
import useLang from "Shared/Context/LangContext";
import { IText } from "Shared/Context/LangContextDTO";
import { TestId } from "Test/TestId";

export const Pod = (props: {
    podId: string;
    onClick: (podId: string, reload?: boolean) => void;
    date?: Date;
    page: FloorPage;
    highlightedDeskId?: string;
    isActive?: boolean;
}) => {
    const [podPosition, setPodPosition] = React.useState<any>(null);
    const { podData } = useFloor();
    const { resData } = usePodReservation();
    const { myCurrentBookings } = useMyBookings();
    const { curLangData } = useLang();

    const data = React.useMemo(() => {
        if (podData && podData.length > 0) return podData.find((pd) => pd.position.id === props.podId) as PodData;
    }, [podData, props.podId]);

    React.useEffect(() => {
        let isMounted = true;
        const pos = data?.position?.position;
        let posprops = null;
        if (pos) posprops = {
            top: pos[0],
            left: pos[1],
            rotation: pos[2],
            width: pos[3],
            height: pos[4],
            squareTop: pos[5],
            squareLeft: pos[6],
            squareWidth: pos[7],
            squareHeight: pos[8],
            square2Top: pos[9],
            square2Left: pos[10],
            square2Width: pos[11],
            square2Height: pos[12],
        };

        if (isMounted) setPodPosition(posprops);

        return () => { isMounted = false }
    }, [data]);

    const podState = React.useMemo(() => {
        return PodState(
            data as PodData,
            props.page,
            resData,
            props.date,
            myCurrentBookings,
            props.highlightedDeskId
        )
    }, [data, myCurrentBookings, props.date, props.highlightedDeskId, props.page, resData])

    const podTitle = React.useMemo(() => {
        const name = data?.position?.name as string;
        const teamname = data?.reservations != null && data.reservations.length > 0 ? data.reservations[0].text : undefined;
        const showTeamname = (props.page === FloorPage.podmgmt && podState === PodStateName.mine) || props.page === FloorPage.booking || props.page === FloorPage.myBooking;
        const stateText = PodStateText(curLangData?.text as IText, props.page, podState, showTeamname ? teamname : undefined);
        if (podState === PodStateName.disabled) return name;
        return `${name} - ${stateText}`;
    }, [curLangData?.text, data, podState, props.page]);

    if (podPosition) return (
        <PodStyle
            position={podPosition}
            state={podState}
            onClick={() => props.onClick(props.podId, props.page === FloorPage.podmgmtNew)}
            title={podTitle}
            isActive={props.isActive}
            data-testid={`${TestId.pod}_${props.podId}`}
        />
    );
    return null;
}