import styled, { css } from "styled-components";
import { DeskState, FloorAbbr, PodStateName } from "Shared/Globals";
import { breakpoint, color, font, scrollStyle } from "Style/Globals";

const defaultBg = (state: DeskState, type?: number, isActive?: boolean) => css`
    background-color: ${() => {
        if (state === DeskState.available) return isActive ? color.greenActive : color.green;
        if (state === DeskState.booked) return isActive ? color.redActive : color.red;
        if (state === DeskState.mine) return isActive ? color.yellowActive : color.yellow;
        if (state === DeskState.colleague) return isActive ? color.blueActive : color.blue;
        return color.black
    }};
    background-image: ${() => {
        if (type === 1 && state !== DeskState.disabled) return 'repeating-linear-gradient(45deg, white, white 2px, transparent 3px, transparent 5px)';
        if (type === 2 && state !== DeskState.disabled) return 'linear-gradient(90deg, transparent 70%, white 30%)';
    }};
    background-size:  ${() => {
        if (type === 2 && state !== DeskState.disabled) return '5px 5px';
    }};

    @media (prefers-color-scheme: dark) {
        background-color: ${(state === DeskState.disabled || state === DeskState.blocked) && color.white};
    }
`;

const hoverBg = (state: DeskState, isActive?: boolean) => css`
        background-color: ${() => {
        if (state === DeskState.available) return isActive ? color.greenActive : color.greenHover;
        if (state === DeskState.booked) return isActive ? color.redActive : color.redHover;
        if (state === DeskState.mine) return isActive ? color.yellowActive : color.yellowHover;
        if (state === DeskState.colleague) return isActive ? color.blueActive : color.blueHover;
        return color.black
    }};
    @media (prefers-color-scheme: dark) {
        background-color: ${(state === DeskState.disabled || state === DeskState.blocked) && color.white};
    }
`;

const buttonBackground = (state: DeskState, type?: number, isActive?: boolean) => css`
    opacity: ${isActive ? "0.8" : "0.4"};
    ${defaultBg(state, type, isActive)};

    :hover,
    :focus {
        opacity: ${isActive ? "0.8" : "0.6"};
        ${hoverBg(state, isActive)}
    }
`;

export const FloorStyle = {
    Container: styled.section<{ notScrollable?: boolean, scrollHeight?: string, width: number }>`
        margin-top: 20px;
        height: ${props => {
            if (!props.notScrollable && props.width < 801) {
                const h = props.scrollHeight ?? "220px";
                return "calc(100vh - " + h + ")";
            }
            return "auto";
        }};
        overflow: auto;
        ${scrollStyle}

        @media ${breakpoint.min1050} {
            margin-top: 50px;
        }
    `,
    Image: styled.div<{ floor: FloorAbbr, notScrollable?: boolean, containerWidth: number }>`
        width: 100%;
        min-width: ${props => {
            if (!props.notScrollable && props.containerWidth < 801) return "1000px";
            return "100%"
        }};
        padding-top: ${props => {
            if (props.floor === FloorAbbr.UZD2) {
                if (props.notScrollable || props.containerWidth > 800) return "96.5%";
                return "970px"
            } else {
                if (props.notScrollable || props.containerWidth > 800) return "88.2%";
                return "890px"
            }
        }};
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        background-color: ${color.lightmodeBgContent};
        background-image: url('/images/floors/${props => props.floor}s.jpg');

        @media ${breakpoint.min1050} {
            background-image: url('/images/floors/${props => props.floor}.jpg');
        }

        @media (prefers-color-scheme: dark) {
            filter: invert(1);
        }
    `,
    ButtonGroup: styled.div<{
        desknumber: number,
        top: string,
        left: string,
        rotation: string,
        width: string,
        height: string,
        clickable?: boolean;
        isActive?: boolean;
    }>`
        position: absolute;
        display: ${props => props.desknumber > 3 ? "flex" : "block"};
        top: ${props => props.top};
        left: ${props => props.left};
        transform: rotate(${props => props.rotation});
        width: ${props => props.width};
        height: ${props => props.height};
        background-color: ${props => {
            if (props.isActive) return color.blueActive;
            if (props.clickable) return color.blue;
            return color.transparent;
        }};
        opacity: ${props => {
            if (props.isActive) return ".8";
            if (props.clickable) return ".4";
            return "1";
        }};
        transition: 200ms ease-in;
        cursor: ${props => props.clickable ? "pointer" : "default"};

        @media (prefers-color-scheme: dark) {
            filter: ${props => props.clickable ? "invert(1)" : "invert(0)"};
        }

        :hover,
        :focus {
            opacity: ${props => {
            if (props.isActive) return ".8";
            if (props.clickable) return ".7";
            return "1";
        }}; 
        }
        
        & > div {
            height: 100%;
            width: ${props => {
            if (props.desknumber === 4) return "50%";
            if (props.desknumber === 6) return "33.333%";
            return "100%";
        }};}
    `,
    Button: styled.button<{ state: DeskState, seatType?: number, rotation?: string, isActive?: boolean }>`
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transition: 200ms ease-in;
        cursor: pointer;
        ${props => buttonBackground(props.state, props.seatType, props.isActive)};
        border-radius: ${props => (props.seatType === 1 || props.seatType === 2) ? "50%" : "0%"}; // circle/oval shaped seats
    `,
    ButtonWrapper: styled.div`
        width: 100%;
        height: 50%;
        position: relative;

        :hover > div,
        :focus > div {
            opacity: 1;
        }

        @media (prefers-color-scheme: dark) {
            filter: invert(1);
        }
    `,
    DeskInfoRow: styled.div<{ likeTextInput?: boolean, isExpanded?: boolean, hasButton?: boolean, podState?: PodStateName, deskState?: DeskState }>`
        display: block;
        font-size: 14px;
        margin: ${props => props.isExpanded ? "0 -20px" : "0"};
        padding: ${props => props.isExpanded ? "0 20px" : "0"};
        background-color: ${props => props.isExpanded ? color.lightgrey1 : color.white};
        color: ${props => {
            if (props.podState != null || props.deskState != null) {
                if (props.podState === PodStateName.reserved ||
                    props.podState === PodStateName.booked ||
                    props.deskState === DeskState.booked ||
                    props.deskState === DeskState.disabled ||
                    props.deskState === DeskState.blocked ||
                    props.deskState === DeskState.colleague) return color.red;
                if (props.podState === PodStateName.notRecommended) return color.orange;
                if (props.podState === PodStateName.mine || props.deskState === DeskState.mine) return color.yellowActive;
                return color.green;
            }
            return color.black;
        }};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => props.isExpanded ? color.darkgrey1 : color.black};
            color: ${props => {
            if (props.podState != null || props.deskState != null) {
                if (props.podState === PodStateName.reserved ||
                    props.podState === PodStateName.booked ||
                    props.deskState === DeskState.booked ||
                    props.deskState === DeskState.disabled ||
                    props.deskState === DeskState.blocked ||
                    props.deskState === DeskState.colleague) return color.redHover;
                if (props.podState === PodStateName.notRecommended) return color.orangeHover;
                if (props.podState === PodStateName.mine || props.deskState === DeskState.mine) return color.yellow;
                return color.greenHover
            }
            return color.white;
        }};
        }

        @media ${breakpoint.min501} {
            display: flex;
        }

        & > strong {
            display: block;
            font-family: ${font.bold};
            width: 100%;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 16px;
            padding: ${props => props.likeTextInput ? "8px 0" : "0"};

            @media ${breakpoint.min501} {
                width: 40%;
            }
        }

        & > ul {
            width: 100%;

            @media ${breakpoint.min501} {
                width: 60%;
            }

            & > li {
                font-size: 16px;
            }
        }

        & > span {
            display: block;
            width: 100%;
            font-size: 16px;
            position: relative;
            padding-right: ${props => props.hasButton ? "41px" : "0"};

            > strong {
                font-family: ${font.bold};
                font-weight: normal;
            }

            @media ${breakpoint.min501} {
                width: 60%;
            }
        }

        & > mark {
            background-color: ${color.transparent};
            font-size: 16px;
            display: inline-block;
            padding: 5px 0 0 5px;
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        & + & {
            margin-top: 5px;
            padding-top: 5px;
        }
    `,
    DeskInfoDelButton: styled.button`
        position: absolute;
        top: 13px;
        right: 10px;
        width: 31px;
        height: 31px;
        background-color: ${color.red};
        cursor: pointer;
        transition: 200ms ease-in;

        & > i {
            color: ${color.white};

            :before {
                font-size: 25px;
            }
        }

        &:hover,
        &:focus {
            background-color: ${color.redHover};
        }
    `,
    DeskInfoBarContainer: styled.div`
        margin-top: 10px;
    `,
    FixedContainer: styled.div<{
        top: string,
        left: string,
        rotation: string,
        width: string,
        height: string,
    }>`
        position: absolute;
        background-color: ${color.violetAlpha};
        width: ${props => props.width};
        height: ${props => props.height};
        top: ${props => props.top};
        left: ${props => props.left};
        transform: rotate(${props => props.rotation});
        border-radius: 50%;

        @media (prefers-color-scheme: dark) {
            background-color: ${color.violetHoverAlpha};
            filter: invert(1);
        }
    `,
    FixedIcon: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & > i:before {
            transition: 200ms ease-in;
            color: ${color.white};
            text-shadow: 0px 0px 2px ${color.black};
        }
    `,
    DeskInfoForm: styled.form`
        margin-top: 20px;
    `,
    DeskInfoFormRow: styled.div`
        position: relative;
        & + &  {
            margin-top: 20px;
        }
    `,
}