import { IText } from "Shared/Context/LangContextDTO";
import { DeskState } from "Shared/Globals";

export const DeskStateText = (
    str: IText,
    state?: DeskState,
    teamName?: string
) => {
    if (teamName) return teamName;
    if (state) {
        if (state === DeskState.booked || state === DeskState.colleague) return str.StateOcc;
        if (state === DeskState.disabled || state === DeskState.blocked) return str.StateBlock;
        if (state === DeskState.mine) return str.StateMy;
        return str.StateFree;
    }
    return str.StateFree;
}