import React from "react";
import useLayout from "Shared/Context/LayoutContext";
import { useApi } from "Shared/Context/API";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { availableFloors, BookingData } from "Shared/Globals";
import { InfoBox, InfoBoxType, Loading } from "Shared/Components";
import { ReportComponent } from "Main/Report/Report";
import { ReportChartBooked } from "Main/Report/ReportChartBooked";
import { ReportSettings } from "Main/Settings/ReportSettings";
import { ReportStyle } from "Main/Report/Style";
import { PageStyle } from "./Style";
import { ReportChartCheckIn } from "Main/Report/ReportChartCheckIn";
import useLang from "Shared/Context/LangContext";

export const ReportPage = () => {
    const [selectedFloors, setSelectedFloors] = React.useState<DropdownOption[]>();
    const [selectedDate, setSelectedDate] = React.useState<Date>();
    const [selectedToDate, setSelectedToDate] = React.useState<Date>();
    const [selectedSeatTypes, setSelectedSeatTypes] = React.useState<DropdownOption[]>();
    const [selectedUnits, setSelectedUnits] = React.useState<DropdownOption[]>();
    const [reportData, setReportData] = React.useState<BookingData[]>();
    const [isLoading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>();
    const { updatePageTitle, isMobile } = useLayout();
    const api = useApi();
    const { curLangData } = useLang();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.Report)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle])

    const fetchReportData = React.useCallback(() => {
        const isFloors = selectedFloors && selectedFloors.length > 0;

        if (selectedDate && (isFloors || (selectedUnits && selectedUnits.length > 0))) {
            setLoading(true);
            let tempToDate: Date | undefined = undefined;
            if (selectedToDate != null) {
                tempToDate = new Date(selectedToDate);
                tempToDate.setDate(tempToDate.getDate() + 1);
            }
            const seatType = selectedSeatTypes != null ? selectedSeatTypes.map(st => st.id) : undefined;
            const units = selectedUnits != null ? selectedUnits.map((u) => u.id) : undefined;
            const floors = isFloors ? selectedFloors : availableFloors;
            const floorData = floors.map(f => api.Admin.getReportData(f.id, selectedDate, tempToDate, seatType, units));

            Promise
                .all(floorData)
                .then(results => results.flat())
                .then(res => {
                    setErrorMsg(undefined);
                    setReportData(res);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    setErrorMsg(curLangData?.error.ReportData)
                });
        }
    }, [selectedFloors, selectedDate, selectedToDate, selectedSeatTypes, selectedUnits, api.Admin, curLangData?.error.ReportData]);

    React.useEffect(() => {
        setReportData(undefined);
    }, [selectedFloors, selectedDate, selectedToDate, selectedSeatTypes, selectedUnits],)

    const onReset = () => {
        setSelectedFloors(undefined);
        setSelectedToDate(undefined);
        setSelectedDate(undefined);
        setSelectedSeatTypes(undefined);
        setSelectedUnits(undefined);
        setReportData(undefined);
        setErrorMsg(undefined);
    }

    const renderChart = React.useCallback(() => {
        return (
            <ReportStyle.Charts>
                <ReportChartCheckIn
                    resData={reportData}
                    containerWidth={"100%"}
                />
                <ReportChartBooked
                    reportData={reportData}
                    fromDate={selectedDate as Date}
                    toDate={selectedToDate}
                    floors={selectedFloors}
                    units={selectedUnits}
                    seatTypes={selectedSeatTypes}
                    containerWidth={"100%"}
                />
            </ReportStyle.Charts>
        )
    }, [reportData, selectedDate, selectedFloors, selectedSeatTypes, selectedToDate, selectedUnits])

    return (
        <PageStyle.Flex>
            <ReportStyle.Left>
                {curLangData != null &&
                    <ReportSettings
                        selectedFloors={selectedFloors}
                        setSelectedFloors={setSelectedFloors}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedToDate={selectedToDate}
                        setSelectedToDate={setSelectedToDate}
                        toText={curLangData?.text.EnddateSelectOptional}
                        onSearch={fetchReportData}
                        onReset={onReset}
                        setSelectedSeatTypes={setSelectedSeatTypes}
                        selectedSeatTypes={selectedSeatTypes}
                        selectedUnits={selectedUnits}
                        setSelectedUnits={setSelectedUnits}
                    />
                }
                {isLoading && <Loading position={"absolute"} size={"10px"} />}
                {!isLoading && errorMsg != null &&
                    <InfoBox
                        type={InfoBoxType.error}
                        text={errorMsg}
                        title={curLangData?.text.Attention}
                        marginTop={"40px"}
                    />
                }
                {reportData != null && !isLoading &&
                    <>
                        {isMobile(1300) && reportData.length > 0 &&
                            renderChart()
                        }
                        <ReportComponent
                            reportData={reportData}
                        />
                    </>
                }
            </ReportStyle.Left>

            {reportData != null && !isMobile(1300) &&
                <ReportStyle.Right>
                    {renderChart()}
                </ReportStyle.Right>
            }
        </PageStyle.Flex>
    )
}