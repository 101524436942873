import styled from "styled-components";
import { color } from "Style/Globals";

export const Form = {
    CheckboxCheckmark: styled.span`
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        transition: 200ms ease-in;

        :after,
        :before {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            transition: 200ms ease-in;
        }

        :before {
            border: 2px solid ${color.blue};
            content: "";
        }
    `,
    CheckboxLabel: styled.label<{ width?: string }>`
        font-size: 16px;
        line-height: 25px;
        display: inline-block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        user-select: none;
        width: ${props => props.width ?? "auto"};

        :hover,
        :focus {
            & > span:before {
                box-shadow: 0 3px 9px ${color.checkboxShadowlightmode};

                @media (prefers-color-scheme: dark) {
                    box-shadow: 0 3px 9px ${color.checkboxShadowdarkmode};
                }
            }
        }

        :hover {
            & > span {
                :before,
                :after {
                    border-color: ${color.blueHover};
                }
            }
        }

        :focus {
            & > span {
                :before,
                :after {
                    border-color: ${color.blueActive};
                }
            }
        }
    `,
    Checkbox: styled.input`
        position: absolute;
        opacity: 0;
        z-index: -1;

        :checked {
            + span:after {
                border-width: 0 0 2px 2px;
                border-style: solid;
                border-color: ${color.blue};
                content: "";
                display: block;
                width: 10px;
                height: 5px;
                position: absolute;
                top: 6.5px;
                left: 6px;
                transform-origin: center center;
                transform: rotate(-45deg);
            }
        }

        :disabled {
            + span {
                pointer-events: none;
                cursor: default;

                :before,
                :after {
                    box-shadow: none !important;
                    border-color: ${color.palegraydark};

                    @media (prefers-color-scheme: dark) {
                        border-color: ${color.darkgrey1};
                    }
                }
            }

            ~ mark {
                pointer-events: none;
                cursor: default;
                color: ${color.black} !important;

                @media (prefers-color-scheme: dark) {
                    color: ${color.white} !important;
                }
            }
        }
    `,
    CheckboxText: styled.mark<{ size?: string }>`
        background-color: ${color.transparent};
        font-size: ${props => props.size ?? "16px"};
        color: ${color.black};
        text-overflow: ellipsis;

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }
    `,
}