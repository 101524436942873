import React from "react";
import { Icon } from "Shared/Components";
import { TableRow } from "Shared/Components/Table/Style";
import { useGraphApi } from "Shared/Context/GraphAPI";
import useLang from "Shared/Context/LangContext";
import { PodResData } from "Shared/Globals"
import { TestId } from "Test/TestId";
import { ExistingItemsStyle } from "./Style";

export const ExistingReservationsItem = (props: {
    data?: PodResData;
    header?: boolean;
}) => {
    const { curLang, curLangData } = useLang();
    const [name, setName] = React.useState<string | undefined>(curLangData?.text.Unknown);
    const graphApi = useGraphApi();

    React.useEffect(() => {
        let isMounted = true;
        if (props.data?.reservedBy && props.data.reservedBy.length > 0) {
            graphApi
                .searchSpecificPerson(props.data.reservedBy)
                .then(person => { if (isMounted) setName(person.displayName) })
                .catch(() => { if (isMounted) setName(curLangData?.text.Unknown) })

        }
        return () => { isMounted = false };
    }, [curLangData?.text.Unknown, graphApi, props.data?.reservedBy])

    const renderUser = () => {
        if (name != null && name === curLangData?.text.Unknown) {
            return <ExistingItemsStyle.ContentCell>{name}</ExistingItemsStyle.ContentCell>
        }
        if (name != null && name !== curLangData?.text.Unknown) {
            const uuser = props.data?.reservedBy?.split(("@"))[0];
            return (
                <ExistingItemsStyle.ContentCell><a
                    href={`https://postat.sharepoint.com/sites/oskar/MeinBereich/ProfilSeite.aspx?accountname=i%3A0%23.f%7Cmembership%7C${uuser}%40post.at`}
                    target="_blank"
                    rel="noreferrer"
                    title={curLangData?.text.OSKAR}
                >{name}<Icon.ArrowRechts isBold size="20px" /></a></ExistingItemsStyle.ContentCell>
            )
        }
        return <ExistingItemsStyle.ContentCell></ExistingItemsStyle.ContentCell>;
    }

    if (props.header) return (
        <TableRow
            isHeader
            minWidth={"100%"}
            gridTemplateColumns={"85px repeat(2, 1fr)"}
            padding={"5px"}
            data-testid={`${TestId.existingReservationItem}_header`}
        >
            <ExistingItemsStyle.ContentCell>{curLangData?.text.Date}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>{curLangData?.text.TeamName2}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>{curLangData?.text.ReservedBy}</ExistingItemsStyle.ContentCell>
        </TableRow>
    )

    if (props.data) return (
        <TableRow
            minWidth={"100%"}
            gridTemplateColumns={"85px repeat(2, 1fr)"}
            padding={"5px"}
            data-testid={TestId.existingReservationItem}
        >
            <ExistingItemsStyle.ContentCell>{props.data?.day.toLocaleDateString(curLang?.browser, { dateStyle: "short" })}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>{props.data?.text}</ExistingItemsStyle.ContentCell>
            {renderUser()}
        </TableRow >
    );

    return null;
}