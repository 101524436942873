import React from "react";
import { DeskState, BookingData, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { ReportStyle } from "./Style";
import { useReactToPrint } from 'react-to-print';
import { DefaultButton, Icon, Pager } from "Shared/Components";
import { ReportGridRow } from "./ReportGridRow";
import useLayout from "Shared/Context/LayoutContext";
import useLang from "Shared/Context/LangContext";
import useOrgUnits from "Shared/Context/OrgUnitContext";
import { TestId } from "Test/TestId";
import { Table } from "Shared/Components/Table/Style";
import { CSVLink } from "react-csv";

const itemsPerPage = 20;
const pagingSize = 3;

export const ReportComponent = (props: {
    reportData?: BookingData[],
}) => {
    const [activePage, setActivePage] = React.useState(1);

    const { isMobile } = useLayout();
    const { curLang, curLangData } = useLang();
    const { level2Units } = useOrgUnits();

    const seatTypes = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;

    const printcontainer = React.useRef<HTMLDivElement>(null);

    const handleprint = useReactToPrint({
        content: () => printcontainer.current,
    })

    const pagedItems = React.useMemo(() => {
        if (props.reportData && props.reportData.length > 0) {
            let pagedBookings: (BookingData[])[] = [];
            let totalCount = 0;
            let currentPageData = pagedBookings[0] = new Array<BookingData>();
            props.reportData.forEach(booking => {
                if (booking.type !== DeskState.booked) return;

                currentPageData.push(booking);

                totalCount++;

                if (totalCount % itemsPerPage === 0) {
                    currentPageData = pagedBookings[pagedBookings.length] = new Array<BookingData>();
                }

            })

            return pagedBookings;
        }
        return [];
    }, [props.reportData]);

    const printList = React.useMemo(() => {
        return (
            <ReportStyle.Grid ref={printcontainer} printOnly>
                <ReportStyle.GridHeadline printOnly>{curLangData?.text.Evaluation}</ReportStyle.GridHeadline>
                <ReportGridRow isHeader />
                {
                    pagedItems.map(page => page.map(booking => <ReportGridRow data={booking} key={booking.id + "_print"} />))
                }
            </ReportStyle.Grid>
        )
    }, [curLangData?.text.Evaluation, pagedItems]);

    let currentPage = pagedItems[activePage - 1];

    const unitsIDsAndNames = React.useCallback((units: number[] | undefined) => {
        if (units != null && units.length > 0 && level2Units != null) {
            const unitsWithIdsAndNames: string[] = []
            units.forEach((unit) => {
                const findunit = level2Units.find((u) => Number.parseInt(u.id) === unit);
                if (findunit) unitsWithIdsAndNames.push(findunit.id + (findunit.text ? " - " + findunit.text : ""));
            })
            return unitsWithIdsAndNames.length > 0 ? unitsWithIdsAndNames : undefined
        }
    }, [level2Units]);

    const renderCSVRow: any = React.useCallback((rd: BookingData) => {
        if (rd.type === DeskState.booked) {
            const rowCSVData = [
                rd.date?.toLocaleString(),
                rd.endDate?.toLocaleString(),
                rd.seat,
                rd.seat.substring(0, rd.seat.indexOf('-')),
                rd.units !== undefined && rd.units.length > 0 ? unitsIDsAndNames(rd.units) : "",
                seatTypes[rd.deskGroupType ?? 0].text,
                rd.confirmed ? "true" : "false"
            ];
            return rowCSVData;
        } else return null;
    }, [seatTypes, unitsIDsAndNames]);

    const renderCSVData: any[] = React.useMemo(() => {
        const curLangDataText = curLangData?.text;
        const CSVHeaders = curLangDataText ?
            [curLangDataText.Starttime,
            curLangDataText.Endtime,
            curLangDataText.DeskId,
            curLangDataText.Location,
            curLangDataText.OrgUnit,
            curLangDataText.DeskType,
            curLangDataText.CheckIn] : undefined;
        const CSVData: any[] = [CSVHeaders];
        props.reportData?.forEach(row => {
            const csvRow = renderCSVRow(row);
            if (csvRow) {
                CSVData.push(csvRow);
            }
        });
        return CSVData;
    }, [props.reportData, renderCSVRow, curLangData]);

    return (
        <ReportStyle.Container>
            {props.reportData != null &&
                <>
                    {currentPage?.length > 0 ?
                        <>
                            {printList}
                            <ReportStyle.Grid data-testid={`${TestId.report}_results`}>
                                <ReportStyle.GridHeadline>{curLangData?.text.Evaluation} {pagedItems.length > 1 && <span>{activePage}/{pagedItems.length}</span>}</ReportStyle.GridHeadline>
                                <Table>
                                    <ReportGridRow isHeader />
                                    {currentPage.length > 0 && currentPage.map((rd) =>
                                        <ReportGridRow data={rd} key={rd.id} />
                                    )}
                                </Table>
                                {pagedItems.length > 1 &&
                                    <Pager
                                        showPager
                                        totalPages={pagedItems.length}
                                        pagingSize={pagingSize}
                                        currentPage={activePage}
                                        onPageChange={setActivePage}
                                    />
                                }
                            </ReportStyle.Grid>
                            {!isMobile(701) &&
                                <ReportStyle.ReportRowButton>
                                    <DefaultButton
                                        title={curLangData?.text.PrintEvaluation}
                                        onClick={handleprint}
                                        data-testid={`${TestId.report}_buttonPrint`}
                                    >
                                        <Icon.Print size={"25px"} /> <span>{curLangData?.text.PrintEvaluation}</span>
                                    </DefaultButton>
                                    <CSVLink data={renderCSVData} enclosingCharacter={` `} separator={";"} filename={new Date().toISOString().split('T')[0].replaceAll('-', '') + "_DeskfinderReport.csv"}>
                                        <DefaultButton
                                            title={curLangData?.text.CsvDownload}
                                            data-testid={`${TestId.report}_buttonCSVDownload`}
                                        >
                                            <Icon.Download size={"25px"} /> <span>{curLangData?.text.CsvDownload}</span>
                                        </DefaultButton>
                                    </CSVLink>
                                </ReportStyle.ReportRowButton>
                            }
                        </>
                        :
                        <ReportStyle.Grid data-testid={`${TestId.report}_noresults`}>
                            <ReportStyle.GridHeadline>{curLangData?.info.NoResults}</ReportStyle.GridHeadline>
                        </ReportStyle.Grid>
                    }
                </>
            }
        </ReportStyle.Container>
    )
}