import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "Shared/Context/API";
import useLang from "Shared/Context/LangContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { Desk } from "Shared/Functions/Desk";
import { BookingData, FloorPage, Path, PodData, PodStateName } from "Shared/Globals";
import { FilteredPodData } from "Main/Floor/Pod/Functions/FilteredPodData";
import { TestId } from "Test/TestId";
import { FloorStyle } from "Main/Floor/Style";
import { DefaultPodInfo } from "./DefaultPodInfo";
import useFloor from "Shared/Context/FloorContext";
import { ButtonRow } from "Shared/Components/ButtonRow/ButtonRow";
import { PodState } from "../Functions/PodState";
import { PodStateRow } from "./PodStateRow";
import { BarChart, InfoBox, InfoBoxType } from "Shared/Components";
import { color } from "Style/Globals";
import useUser from "Shared/Context/UserContext";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import { checkIfFullTimeBookingExists, checkIfUserHas3Bookings } from "Main/Floor/Shared";
import { ExistingBookings } from "Main/Floor/Shared/ExistingBookings";
import { MyBookingsInfo } from "Main/MyBookings/MyBookingsInfo";
import { compareDates } from "Shared/Functions/DateMath";
import useLayout from "Shared/Context/LayoutContext";

export const PodInfoUser = (props: {
    podId: string;
    selectedFrom: Date;
    onDismiss: () => void;
    page: FloorPage;
    highlightedDeskId?: string;
}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);

    const { curLangData } = useLang();
    const { podData } = useFloor();
    const { setProfile } = useUser();
    const { isMobile } = useLayout();

    const api = useApi();
    let navigate = useNavigate();

    const { bookingid } = useParams();

    const { myCurrentBookings, updateMyBookings } = useMyBookings();

    const desk = new Desk(props.podId);
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    React.useEffect(() => {
        setError(undefined);
        setInfo(undefined);
    }, [props.podId])

    const data = React.useMemo(() => {
        return FilteredPodData(props.podId, props.selectedFrom, podData);
    }, [podData, props.podId, props.selectedFrom]);

    const state = React.useMemo(() => {
        return PodState(data as PodData, props.page, undefined, props.selectedFrom, myCurrentBookings, props.highlightedDeskId)
    }, [data, myCurrentBookings, props.highlightedDeskId, props.page, props.selectedFrom])

    const hasReservation = data?.reservations && data.reservations.length > 0 && data.reservations[0].text && data.reservations[0].text.length > 0;
    const resCapacity = data?.reservations && data.reservations.length > 0 ? data.reservations[0].capacity : undefined;
    const podCapacity = resCapacity ?? data?.position?.count;
    const availableDesks = data?.bookings && data.bookings.length > 0 ? (podCapacity as number - data.bookings.length) : podCapacity;

    const isBookingAvailable = availableDesks !== 0 &&
        state !== PodStateName.mine &&
        props.page !== FloorPage.myBooking &&
        !checkIfUserHas3Bookings(props.selectedFrom, myCurrentBookings) &&
        !checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedFrom, undefined, myCurrentBookings);

    const bookDesk = () => {
        setInfo(undefined);
        setLoading(true);
        const selected = new Date(props.selectedFrom);
        const date = new Date(selected.setHours(0, 0, 0, 0));
        api
            .bookSeat(desk, date)
            .then((resData) => {
                setProfile({ lastFloor: desk.Floor.Id });
                setError(undefined);
                updateMyBookings();
                navigate(Path.MyBookings + "/" + props.selectedFrom?.toLocaleDateString("de-DE").replace(/\./g, "-") + "/" + resData.id);
            })
            .catch((err) => {
                let msg = curLangData?.error.PodUserBooking;
                if (err && err.messageId) msg = ErrorMsg(err.messageId, false, false, curLangData?.error) ?? msg;
                setError(msg);
            })
            .finally(() => setLoading(false))
    }
    const onConfirm = () => {
        const text = hasReservation ? data.reservations[0].text : undefined;
        if (info != null || text == null) bookDesk();
        else {
            const infoText = `<strong>${text}</strong>`;
            const podText = curLangData?.info.AreYouSureInfoBox1 + infoText + curLangData?.info.AreYouSureInfoBox2;
            setInfo(podText);
        }
    }

    const hideBookings = React.useMemo(() => {
        return data?.bookings && data?.bookings.length === 1 && state === PodStateName.mine;
    }, [data?.bookings, state]);


    const renderResText = () => {
        if (hasReservation) return (
            <>
                <br />
                <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowTeam`}>
                    <strong>{curLangData?.text.ReservedFor}</strong>
                    <span><strong>{data.reservations[0].text}</strong></span>
                </FloorStyle.DeskInfoRow>
            </>
        )
    }

    const chartInfoText = React.useMemo(() => {
        if (availableDesks as number > 0) return curLangData?.text.ChartFree;
        return curLangData?.text.ChartOcc;
    }, [availableDesks, curLangData?.text.ChartFree, curLangData?.text.ChartOcc])

    const renderAvailableSeats = () => {
        return <>
            <FloorStyle.DeskInfoRow data-testid={`${TestId.podinfo}_rowCapacity`}>
                <strong>{curLangData?.text.Desks}</strong>
                <span>{availableDesks}/{podCapacity}</span>
            </FloorStyle.DeskInfoRow>

            <FloorStyle.DeskInfoBarContainer>
                <BarChart
                    containerColor={color.redActive}
                    barColor={color.greenHover}
                    size={(((availableDesks as number) / (podCapacity as number)) * 100).toString() + "%"}
                    text={chartInfoText}
                    textColor={color.white}
                />
            </FloorStyle.DeskInfoBarContainer>
        </>
    };


    const renderTooMuchBookingsInfoBox = React.useCallback(() => {
        if (state === PodStateName.available && props.page !== FloorPage.myBooking &&
            (checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedFrom, undefined, myCurrentBookings) === true ||
                checkIfUserHas3Bookings(props.selectedFrom, myCurrentBookings) === true)) {
            return (
                <InfoBox
                    type={InfoBoxType.default}
                    title={curLangData?.text.Info}
                    text={checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedFrom, undefined, myCurrentBookings) ? curLangData?.info.FullTime : curLangData?.info.MaxBookings}
                    marginTop={"20px"}
                />
            )
        }
    }, [state, props.page, props.selectedFrom, curLangData?.text.FullTime, curLangData?.text.Info, curLangData?.info.FullTime, curLangData?.info.MaxBookings, myCurrentBookings]);


    const renderErrorMsg = React.useCallback(() => {
        if (error) return (
            <InfoBox
                type={InfoBoxType.error}
                text={error}
                title={curLangData?.text.Attention}
                marginTop={"20px"}
            />
        )
    }, [error, curLangData]);

    const curBooking = React.useMemo(() => {
        if (props.page === FloorPage.myBooking && state === PodStateName.mine && myCurrentBookings && myCurrentBookings.length > 0 && bookingid != null) return myCurrentBookings.find((b) => b.id === bookingid);
        return null;
    }, [bookingid, myCurrentBookings, props.page, state])

    return (
        <>
            <DefaultPodInfo
                podId={props.podId}
                selectedFrom={props.selectedFrom}
            />

            {state === PodStateName.mine &&
                <PodStateRow
                    data={data as PodData}
                    page={props.page}
                    state={state}
                />
            }

            {renderResText()}
            {renderAvailableSeats()}

            {info != null &&
                <InfoBox
                    marginTop={"20px"}
                    type={InfoBoxType.default}
                    htmlText={info}
                />
            }

            {renderTooMuchBookingsInfoBox()}
            {renderErrorMsg()}

            {!hideBookings &&
                <ExistingBookings
                    id={props.podId}
                    selectedDate={props.selectedFrom}
                />
            }

            {isBookingAvailable &&
                <ButtonRow
                    id={`${TestId.podinfo}_button`}
                    primaryText={curLangData?.text.Booking as string}
                    primaryType={"button"}
                    onPrimaryClick={onConfirm}
                    secondaryText={curLangData?.text.Cancel as string}
                    secondaryType={"button"}
                    onSecondaryClick={props.onDismiss}
                    isLoading={isLoading}
                    marginTop={"20px"}
                />
            }

            {curBooking != null && !isMobile(1050) &&
                <MyBookingsInfo
                    booking={curBooking}
                    allBookings={(myCurrentBookings as BookingData[]).filter((b) => compareDates(b.date, props.selectedFrom))}
                />
            }
        </>
    )
}