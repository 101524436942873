import React from "react";
import { BookingData } from "Shared/Globals"
import { PeopleSuggestionModel } from "Shared/Context/GraphAPI";
import { InfoBox, InfoBoxType, Loading } from "Shared/Components";
import { PeopleFinderSearchResultItem } from "./SearchResultItem";
import { FinderStyle } from "./Style";
import { useApi } from "Shared/Context/API";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const PeopleFinderSearchResults = (props: {
    person: PeopleSuggestionModel
    date?: Date;
}) => {
    const [results, setResults] = React.useState<BookingData[] | undefined>([]);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>();
    const [expanded, setExpanded] = React.useState<BookingData | undefined>();
    const { curLangData } = useLang();

    const api = useApi();

    React.useEffect(() => {
        if (props.person) {
            setResults(undefined);
            let endDate: Date | undefined = undefined;
            if (props.date) {
                const d = new Date(props.date);
                endDate = new Date(d.setDate(d.getDate() + 1));
            }
            api
                .GetBookingsByUser(props.person.userName, props.date, endDate)
                .then((resp) => {
                    resp.sort((a, b) => {
                        if (a.date < b.date) return -1;
                        if (a.date > b.date) return 1;
                        return 0;
                    })
                    setResults(resp);
                    setErrorMsg(undefined);
                })
                .catch(() => setErrorMsg(curLangData?.error.PeopleResults))
        }
    }, [props.person, props.date, api, curLangData]);

    const resulttext = results?.length === 1 ? curLangData?.text.Result : curLangData?.text.Results;

    if (errorMsg != null) return <InfoBox type={InfoBoxType.error} text={errorMsg} />

    return (
        <FinderStyle.Results>
            {results == null ?
                <Loading position={"absolute"} />
                :
                <>
                    <FinderStyle.ResultsHeader>
                        <FinderStyle.ResultsTitle>
                            {results.length} {resulttext} für <strong>{props.person.displayName}</strong>
                        </FinderStyle.ResultsTitle>
                    </FinderStyle.ResultsHeader>
                    <FinderStyle.ResultsList data-testid={`${TestId.peopleFinderResult}_list`}>
                        {results.map((r, i) => {
                            return <PeopleFinderSearchResultItem
                                result={r}
                                key={r.date.toISOString() + r.seat + i}
                                isExpanded={expanded === r}
                                setExpanded={setExpanded}
                                idx={i}
                            />
                        })}
                    </FinderStyle.ResultsList>
                </>
            }
        </FinderStyle.Results>
    )
}