import { useNavigate } from "react-router-dom";
import useLang from "Shared/Context/LangContext";
import { Path } from "Shared/Globals";
import { PodMgmtStyle } from "./Style";

export const PodMgmtNav = (props: {
    activeEl: number;
    disabledEl?: number;
    onClick: () => void;
}) => {
    const { curLangData } = useLang();
    let navigate = useNavigate();

    if (curLangData) return (
        <PodMgmtStyle.Nav activeEl={props.activeEl}>
            <PodMgmtStyle.NavBtn
                isActive={props.activeEl === 0}
                onClick={() => {
                    props.onClick();
                    navigate(`${Path.AdminView}/${Path.AdminPods}`)
                }}
            >
                {curLangData.text.Reservations}
            </PodMgmtStyle.NavBtn>
            <PodMgmtStyle.NavBtn
                isActive={props.activeEl === 1}
                isDisabled={props.disabledEl === 1}
            >
                {curLangData.text.Map}
            </PodMgmtStyle.NavBtn>
        </PodMgmtStyle.Nav>
    )
    return null;
}