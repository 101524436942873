import useMyBookings from "Shared/Context/MyBookingsContext";
import useLayout from "Shared/Context/LayoutContext";
import useUser from "Shared/Context/UserContext";
import { Icon } from "Shared/Components";
import { HeaderStyle } from "./Style";

export const Header = () => {
    const { currentPageTitle, expandNav, updateNav, isMobile } = useLayout();
    const { myCurrentBookings } = useMyBookings();
    const { isAdmin } = useUser();

    return (
        <HeaderStyle.Container expandNav={expandNav}>
            <HeaderStyle.Icon>
                <Icon.Posthorn size={isMobile(701) ? "30px" : "70px"} isBold={isMobile(701)} />
            </HeaderStyle.Icon>
            <HeaderStyle.Title onClick={!isMobile(701) ? () => updateNav(!expandNav) : undefined}>
                Desk&shy;finder
            </HeaderStyle.Title>
            {isMobile(701) &&
                <>
                    <HeaderStyle.Subtitle
                        hasPadding={(myCurrentBookings != null && myCurrentBookings.length > 0) || isAdmin}
                        onClick={(!isMobile(701) || (myCurrentBookings != null && myCurrentBookings.length > 0) || isAdmin) ? () => updateNav(!expandNav) : undefined}
                    >
                        {currentPageTitle}
                    </HeaderStyle.Subtitle>

                    <HeaderStyle.MobileButton
                        expandNav={expandNav}
                        onClick={() => updateNav(!expandNav)}
                    >
                        <i /> <i /> <i />
                    </HeaderStyle.MobileButton>
                </>
            }

            {!isMobile(701) &&
                <HeaderStyle.Button
                    expandNav={expandNav}
                    onClick={() => updateNav(!expandNav)}
                >
                    <Icon.PfeilLinks size={"40px"} isBold />
                </HeaderStyle.Button>
            }
        </HeaderStyle.Container>
    )
}