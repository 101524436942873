import React from "react";
import { OrgUnitDTO, useApi } from "./API";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";


export interface OrgUnitContextState {
    units: OrgUnitDTO[] | undefined;
    level2Units: DropdownOption[] | undefined;
    unitsLoading: boolean;
}


export const OrgUnitContext = React.createContext<OrgUnitContextState>({
    units: undefined,
    level2Units: undefined,
    unitsLoading: false,
})

export const OrgUnitProvider = (props: { children: JSX.Element }) => {
    const [units, setUnits] = React.useState<OrgUnitDTO[] | undefined>();
    const [level2Units, setLevel2Units] = React.useState<DropdownOption[] | undefined>();
    const [unitsLoading, setUnitsLoading] = React.useState(false);

    const api = useApi();

    React.useEffect(() => {
        setUnitsLoading(true);

        api.OrgUnit.getOrgUnits()
            .then(res => {
                if (res && res.length > 0) setUnits(res);
                let dropdownOpts: DropdownOption[] = [];
                res.forEach((item: OrgUnitDTO) => {
                    if (item.treeLevel === 2) {
                        dropdownOpts.push({ id: item.id != null ? item.id.toString() : "", text: item.name })
                    }
                })
                dropdownOpts.sort((a, b) => {
                    const atext = a.text as string;
                    const btext = b.text as string;
                    return atext.toLowerCase().localeCompare(btext.toLowerCase())
                });
                if (dropdownOpts.length > 0) setLevel2Units(dropdownOpts)
            })
            .finally(() => setUnitsLoading(false));
    }, [api]);

    const providerValue = React.useMemo(() => (
        { units, level2Units, unitsLoading }), [units, level2Units, unitsLoading]);

    return (
        <OrgUnitContext.Provider value={providerValue}>
            {props.children}
        </OrgUnitContext.Provider>
    )
}

const useOrgUnits = () => React.useContext(OrgUnitContext);
export default useOrgUnits;