import { AuthenticationLayer } from "./AuthenticationLayer"
import { ConfigProvider } from "./ConfigContext"
import { FloorProvider } from "./FloorContext"
import { LangProvider } from "./LangContext"
import { LayoutProvider } from "./LayoutContext"
import { LogProvider } from "./LogContext"
import { MyBookingsProvider } from "./MyBookingsContext"
import { OrgUnitProvider } from "./OrgUnitContext"
import { PodReservationProvider } from "./PodReservationContext"
import { TeamdaysProvider } from "./TeamdaysContext"
import { UserProvider } from "./UserContext"

export const ContextCollection = (props: { children: JSX.Element }) => {
    return (
        <ConfigProvider>
            <AuthenticationLayer>
                <LogProvider>
                    <UserProvider>
                        <LangProvider>
                            <LayoutProvider>
                                <MyBookingsProvider>
                                    <PodReservationProvider>
                                        <OrgUnitProvider>
                                            <TeamdaysProvider>
                                                <FloorProvider>
                                                    {props.children}
                                                </FloorProvider>
                                            </TeamdaysProvider>
                                        </OrgUnitProvider>
                                    </PodReservationProvider>
                                </MyBookingsProvider>
                            </LayoutProvider>
                        </LangProvider>
                    </UserProvider>
                </LogProvider>
            </AuthenticationLayer>
        </ConfigProvider>
    )
}