import React from "react";

export const GetWindowWidth = () => {
    const isClient = typeof window === "object";

    const getWidth = React.useCallback(() => {
        if (isClient) { return window.innerWidth; }
    }, [isClient]);

    const [windowWidth, setWidth] = React.useState(getWidth);

    React.useEffect(() => {
        if (isClient) {
            const handleResize = () => setWidth(getWidth());
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [getWidth, isClient]);

    return windowWidth;
};
