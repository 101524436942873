import React from "react";
import { ButtonRow, Checkbox, InfoBox, InfoBoxType } from "Shared/Components";
import { useApi } from "Shared/Context/API";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext"
import usePodReservation from "Shared/Context/PodReservationContext";
import useTeamdays, { Teamday, TeamdaysItem } from "Shared/Context/TeamdaysContext";
import { compareDates } from "Shared/Functions/DateMath";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import { FloorAbbr, FloorPage, PodResData, PodStateName } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { PodMgmtStyle } from "./Style";

export const TeamdaysList = (props: {
    onDismiss: (backToResList?: boolean) => void;
    onConfirm: () => void;
    podState?: PodStateName;
    page?: FloorPage;
    podId?: string;
}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const { teamdays, updateTeamdays } = useTeamdays();
    const [tmpTeamdays, setTmpTeamdays] = React.useState<TeamdaysItem>(teamdays);
    const { resData } = usePodReservation();
    const { curLang, curLangData } = useLang();
    const { isFloorDataLoading, podData } = useFloor();

    const api = useApi();

    React.useEffect(() => {
        if ((teamdays.selected.length > 0 || teamdays.disabled.length > 0) && tmpTeamdays.selected.length === 0 && tmpTeamdays.disabled.length === 0) {
            setTmpTeamdays(teamdays);
        }
    }, [teamdays, tmpTeamdays.disabled.length, tmpTeamdays.selected.length])

    React.useEffect(() => {
        setInfo(undefined);
        setError(undefined);
    }, [props.podId])

    const allDates = React.useMemo(() => {
        if (tmpTeamdays.selected.length > 0) {
            if (tmpTeamdays.disabled.length > 0) {
                return [...tmpTeamdays.selected, ...tmpTeamdays.disabled].sort((a, b) => a.date.getTime() - b.date.getTime())
            }
            return tmpTeamdays.selected.sort((a, b) => a.date.getTime() - b.date.getTime())
        }
        if (tmpTeamdays.disabled.length > 0 && tmpTeamdays.selected.length === 0) return tmpTeamdays.disabled.sort((a, b) => a.date.getTime() - b.date.getTime());
        return [];
    }, [tmpTeamdays.selected, tmpTeamdays.disabled])

    const maxDaysReached = React.useCallback((selected?: boolean) => {
        if ((!props.page || props.page === FloorPage.podmgmtNew) && selected) return tmpTeamdays.selected.length < 5;
        return false;
    }, [props.page, tmpTeamdays.selected.length]);

    const onDeleteOrAddDay = (day: Teamday) => {
        const isSelectedDay = tmpTeamdays.selected.includes(day);
        const isDeletedDay = tmpTeamdays.disabled.includes(day);

        const selDayArr = [...tmpTeamdays.selected];
        const delDayArr = [...tmpTeamdays.disabled];

        if (isSelectedDay) {
            const idx = tmpTeamdays.selected.indexOf(day);
            if (idx > -1) selDayArr.splice(idx, 1);
            if (!isDeletedDay) delDayArr.push(day);
        }
        else if (isDeletedDay) {
            const idx = tmpTeamdays.disabled.indexOf(day);
            if (idx > -1) delDayArr.splice(idx, 1);
            selDayArr.push(day);
        }
        setTmpTeamdays({ selected: selDayArr, disabled: delDayArr });
        if (props.page === FloorPage.podmgmtNew) updateTeamdays({ selected: selDayArr, disabled: delDayArr });
    };

    const item = (day: Teamday, i: number) => {
        const dayString = day.date.toLocaleDateString(curLang?.browser, { dateStyle: "long" });
        const isSelected = tmpTeamdays.selected.includes(day);
        const isDisabled = isFloorDataLoading || maxDaysReached(isSelected);
        return <PodMgmtStyle.TeamdaysListItem key={`${TestId.teamdays}_item${i}`}>
            <Checkbox
                id={`${TestId.teamdays}_item${i}`}
                label={`${curLangData?.text.WeekDays[day.date.getDay() - 1]}, ${dayString}`}
                onChange={isDisabled ? undefined : () => onDeleteOrAddDay(day)}
                checked={isSelected}
                disabled={isDisabled}
            />
        </PodMgmtStyle.TeamdaysListItem>
    };

    const getHeaderText = React.useMemo(() => {
        const selDays = tmpTeamdays.selected.length;
        const days = allDates.length
        const selText = props.podState != null ? curLangData?.text.SelectedTeamdays2 : `${curLangData?.text.SelectedTeamdays} ${resData.teamname}`;
        return `${selDays}/${days} ${selText}`;
    }, [tmpTeamdays.selected.length, allDates.length, props.podState, curLangData?.text.SelectedTeamdays2, curLangData?.text.SelectedTeamdays, resData.teamname]);

    const checkSelectedDays = React.useCallback(() => {
        const _onConfirm = props.onConfirm;
        setLoading(true);
        const podResProm = api.Pod.getPodReservations(tmpTeamdays.selected[0].date, tmpTeamdays.selected[tmpTeamdays.selected.length - 1].date);
        const podProm = api.getPodIds();

        Promise
            .all([podProm, podResProm])
            .then((res) => {
                setError(undefined);
                let count = 0;
                const selectedDays: PodResData[] = [];
                tmpTeamdays.selected.forEach((d) => {
                    res[1].forEach((r) => {
                        if (compareDates(r.day, d.date)) selectedDays.push(r);
                    })
                })
                res[0].forEach((p) => {
                    if (selectedDays.some((r) => r.podId === p)) count++
                })
                if (count < res[0].length) {
                    setInfo(undefined);
                    updateTeamdays(tmpTeamdays);
                    _onConfirm()
                }
                else setInfo(curLangData?.info.NoPodAvailable)
            })
            .catch(() => setError(curLangData?.error.ReservationCheck))
            .finally(() => setLoading(false))
    }, [api, curLangData?.error.ReservationCheck, curLangData?.info.NoPodAvailable, props.onConfirm, tmpTeamdays, updateTeamdays]);

    const deleteSelectedDays = React.useCallback(() => {
        setLoading(true);
        const promArr = tmpTeamdays.selected.map((d) => api.Pod.deletePodReservation(d.id as string))
        Promise.all(promArr)
            .then(() => {
                setInfo(undefined);
                setError(undefined);
                setTmpTeamdays({ ...tmpTeamdays, selected: [] })
                if (tmpTeamdays.disabled.length === 0) props.onDismiss(true);
            })
            .catch(() => setError(curLangData?.error.PodAdminDelete))
            .finally(() => { setLoading(false) })
    }, [api.Pod, curLangData?.error.PodAdminDelete, props, tmpTeamdays]);

    const onSave = () => {
        const proms = teamdays.selected.map((day) => api.Pod.getPodReservationsByDate(day.date, FloorAbbr.PAR2))
        const reservations: PodResData[] = teamdays.selected.map((day) => {
            return {
                text: resData.teamname,
                day: day.date,
                podId: props.podId as string,
                capacity: podData.find((pd) => pd.position.id === props.podId)?.position.count as number
            }
        })
        setLoading(true);
        setInfo(undefined);
        setError(undefined);
        Promise
            .all(proms)
            .then((res) => {
                const atLeastOnePodIsAlreadyReserved = res.some(r => r.some(pr => pr.podId === props.podId));
                if (atLeastOnePodIsAlreadyReserved) {
                    setError(curLangData?.error.PodAlreadyReserved)
                    setLoading(false);
                }
                else {
                    api.Pod.addPodReservations(reservations)
                        .then(() => {
                            setTmpTeamdays({ selected: [], disabled: [] });
                            updateTeamdays({ selected: [], disabled: [] });
                            props.onConfirm();
                        })
                        .catch((err) => {
                            let msg = curLangData?.error.PodReservationFailed;
                            if (err && err.messageId) msg = ErrorMsg(err.messageId, false, false, curLangData?.error) ?? msg;
                            setError(msg);
                        })
                        .finally(() => setLoading(false))
                }
            })
            .catch(() => {
                setError(curLangData?.error.PodReservationFailed);
                setLoading(false);
            })
    }

    const renderInfo = () => {
        if (info != null && error == null) return <PodMgmtStyle.FormRow>
            <InfoBox
                type={InfoBoxType.default}
                htmlText={info}
            />
        </PodMgmtStyle.FormRow>
    }

    const renderError = () => {
        if (error != null) return <PodMgmtStyle.FormRow>
            <InfoBox
                type={InfoBoxType.error}
                text={error}
            />
        </PodMgmtStyle.FormRow>
    }

    const primaryText = React.useMemo(() => {
        if (props.page === FloorPage.podmgmtNew) return curLangData?.text.ReservePod as string;
        if (props.page === FloorPage.podmgmt) {
            if (tmpTeamdays.disabled.length === 0) return curLangData?.text.DeleteReservation2 as string;
            return curLangData?.text.DeleteReservation3 as string;
        }
        return curLangData?.text.Confirm as string;
    }, [curLangData?.text.Confirm, curLangData?.text.DeleteReservation2, curLangData?.text.DeleteReservation3, curLangData?.text.ReservePod, props.page, tmpTeamdays.disabled.length]);

    const onPrimaryClick = () => {
        if (!props.page) checkSelectedDays();
        if (props.page === FloorPage.podmgmt) {
            if (!info) setInfo(curLangData?.info.DelPodRes);
            if (info) deleteSelectedDays();
        }
        if (props.page === FloorPage.podmgmtNew) onSave();
    }

    const disablePrimary = React.useMemo(() => {
        return (info != null && props.page !== FloorPage.podmgmt) ||
            props.podState === PodStateName.reserved ||
            (props.page === FloorPage.podmgmt && (tmpTeamdays.selected.length === 0 || (tmpTeamdays.disabled.length > 0 && tmpTeamdays.disabled.length < 4)));
    }, [info, props.page, props.podState, tmpTeamdays.disabled.length, tmpTeamdays.selected.length]);

    if (allDates.length > 0) return (
        <PodMgmtStyle.TeamdaysContainer>
            <PodMgmtStyle.TeamdaysHeadline>
                {getHeaderText}
            </PodMgmtStyle.TeamdaysHeadline>
            <PodMgmtStyle.TeamdaysList>
                {allDates.map(item)}
            </PodMgmtStyle.TeamdaysList>
            {renderInfo()}
            {renderError()}
            <ButtonRow
                id={""}
                isLoading={isLoading}
                primaryText={primaryText}
                primaryType={"button"}
                onPrimaryClick={onPrimaryClick}
                secondaryText={props.podState != null ? curLangData?.text.Cancel : curLangData?.text.GoBack}
                secondaryType={"button"}
                onSecondaryClick={() => props.onDismiss()}
                disablePrimary={disablePrimary}
                marginTop={(info || error) ? "20px" : undefined}
            />
        </PodMgmtStyle.TeamdaysContainer>
    )
    return null;
}