import {
    addDays,
    compareDates,
    getMonthEnd,
    getMonthStart,
    isInDateRangeArray,
} from "Shared/Functions/DateMath";
import React from "react";
import { CalendarStyle } from "./Style";
import { Icon } from "Shared/Components/Icon/Style";
import useLang from "Shared/Context/LangContext";

const startDayOWeek = 1;

export enum CalendarDefaultDay {
    First,
    Last,
}

export interface CalendarProps {
    selectedDates?: Date[];
    onSelectDate: (date: Date) => void;
    defaultDayOnMonthClick?: CalendarDefaultDay;
    yearOnly?: boolean;
    minDate?: Date;
    maxDate?: Date;
    disableWeekend?: boolean;
    simplified?: boolean;
    id: string;
}

interface DayInfo {
    key: string;
    date: string;
    originalDate: Date;
    isInMonth: boolean;
    isToday: boolean;
    isSelected: boolean;
    isInBounds: boolean;
    isWeekDay: boolean;
}

export const Calendar = (props: CalendarProps) => {
    const now = new Date();
    const [selectedDates, setSelectedDates] = React.useState<Date[]>(props.selectedDates ?? new Array<Date>());
    const [curMonth, setCurMonth] = React.useState<Date>((props.selectedDates && !!props.selectedDates[0]) ?
        new Date(props.selectedDates[0].getFullYear(), props.selectedDates[0].getMonth(), 1) :
        new Date(now.getFullYear(), now.getMonth(), 1),
    );

    const { curLangData } = useLang();

    const selectDate = React.useCallback((selectedDate: Date, isDaySelect?: boolean) => {
        const _onSelectDate = props.onSelectDate;
        let tmpSelectedDate: Date;
        if (selectedDates.length > 0 && selectedDates[0] === selectedDate) {
            return;
        }

        if (isDaySelect === true && selectedDates[0] !== selectedDate) {
            tmpSelectedDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate() > getMonthEnd(selectedDate).getDate() ?
                    getMonthEnd(selectedDate).getDate() : selectedDate.getDate(),
            );
        } else if (selectedDates.length > 0 &&
            selectedDates[0] != null) {
            tmpSelectedDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDates[0].getDate() > getMonthEnd(selectedDate).getDate() ?
                    getMonthEnd(selectedDate).getDate() : selectedDates[0].getDate(),
            );
        } else {
            tmpSelectedDate = selectedDate;
        }
        setSelectedDates([tmpSelectedDate]);

        if (_onSelectDate && isDaySelect) { _onSelectDate(selectedDate); }
    }, [props.onSelectDate, selectedDates]);

    const setDateOnMonthClick = React.useCallback((clickDate: Date) => {
        const defaultDay = props.defaultDayOnMonthClick ?? CalendarDefaultDay.First;
        switch (defaultDay) {
            case CalendarDefaultDay.First: {
                selectDate(getMonthStart(clickDate));
                break;
            }
            case CalendarDefaultDay.Last: {
                selectDate(getMonthEnd(clickDate));
                break;
            }
        }
    }, [props.defaultDayOnMonthClick, selectDate]);

    const getDays = React.useCallback((): DayInfo[][] => {
        const weeks: DayInfo[][] = [];
        const startDate = addDays(curMonth, -(7 + curMonth.getDay() - startDayOWeek) % 7);
        const endDate = getMonthEnd(curMonth);
        const todaysDate = new Date();
        endDate.setDate(endDate.getDate() + ((6 - endDate.getDay() + startDayOWeek) % 7));

        const tempDate = startDate;
        let curWeek = -1;

        while (tempDate <= endDate) {
            const originalDate = new Date(tempDate.toString());
            const dayInfo: DayInfo = {
                key: tempDate.toString(),
                date: tempDate.getDate().toString(),
                originalDate,
                isInMonth: tempDate.getMonth() === curMonth.getMonth(),
                isToday: compareDates(todaysDate, tempDate),
                isWeekDay: originalDate.getDay() % 6 !== 0,
                isSelected: isInDateRangeArray(tempDate, selectedDates),
                isInBounds: (props.minDate ? props.minDate <= originalDate : true) && (props.maxDate ? props.maxDate >= originalDate : true),
            };

            if (originalDate.getDay() === startDayOWeek) {
                curWeek++;
                weeks[curWeek] = new Array<DayInfo>();
            }

            weeks[curWeek].push(dayInfo);

            tempDate.setDate(tempDate.getDate() + 1);
        }

        return weeks;
    }, [curMonth, props.maxDate, props.minDate, selectedDates]);

    const renderMonths = React.useCallback((): JSX.Element => {
        const currentMonth = curMonth.getMonth() + 1;
        const months = [];
        for (let i = 1; i <= 12; i++) {
            months.push(
                <CalendarStyle.MonthsItem key={`month${i}`}>
                    <CalendarStyle.MonthsItemInner>
                        <CalendarStyle.MonthsButton
                            selected={currentMonth === i}
                            onClick={() => setCurMonth(new Date(curMonth.getFullYear(), i - 1))}
                            data-testid={`${props.id}_buttonMonth${i}`}
                            type={"button"}
                        >
                            <span>{i}</span>
                        </CalendarStyle.MonthsButton>
                    </CalendarStyle.MonthsItemInner>
                </CalendarStyle.MonthsItem>,
            );
        }
        return <CalendarStyle.Months>{months}</CalendarStyle.Months>;
    }, [curMonth, props.id]);

    const switchMonth = React.useCallback((next?: boolean) => {
        if (next) {
            if (curMonth.getMonth() === 11) {
                setCurMonth(new Date(curMonth.getFullYear() + 1, 0))
            } else {
                setCurMonth(new Date(curMonth.getFullYear(), curMonth.getMonth() + 1))
            }
        } else {
            if (curMonth.getMonth() === 0) {
                setCurMonth(new Date(curMonth.getFullYear() - 1, 11))
            } else {
                setCurMonth(new Date(curMonth.getFullYear(), curMonth.getMonth() - 1))
            }
        }
    }, [curMonth]);

    const renderSimpleMonth = React.useCallback((): JSX.Element => {
        const monthName = curLangData?.text.MonthNames as string[];
        return (
            <CalendarStyle.SimpleMonth>
                <CalendarStyle.YearsChevron
                    direction={"prev"}
                    onClick={() => switchMonth(false)}
                    title={curLangData?.text.LastMonth}
                    data-testid={`${props.id}_buttonMonthPrev`}
                    type={"button"}
                >
                    <Icon.PfeilLinks size={"70px"} />
                </CalendarStyle.YearsChevron>
                <CalendarStyle.SimpleMonthText>
                    {monthName[curMonth.getMonth()] + " " + curMonth.getFullYear()}
                </CalendarStyle.SimpleMonthText>
                <CalendarStyle.YearsChevron
                    direction={"next"}
                    onClick={() => switchMonth(true)}
                    title={curLangData?.text.NextMonth}
                    data-testid={`${props.id}_buttonMonthNext`}
                    type={"button"}
                >
                    <Icon.PfeilRechts size={"70px"} />
                </CalendarStyle.YearsChevron>
            </CalendarStyle.SimpleMonth>
        )
    }, [curMonth, switchMonth, props.id, curLangData]);

    const setYear = React.useCallback((year: number, month: number) => {
        const currentMonth: Date = new Date(year, month);
        setCurMonth(currentMonth);
        setDateOnMonthClick(currentMonth);
    }, [setDateOnMonthClick]);

    const renderYears = React.useCallback((): JSX.Element => {
        const lastYear = curMonth.getFullYear() - 1;
        const nextYear = curMonth.getFullYear() + 1;
        return (
            <CalendarStyle.Years>
                <CalendarStyle.YearsContainer>
                    <CalendarStyle.YearsChevron
                        direction={"prev"}
                        onClick={() => setYear(lastYear, curMonth.getMonth())}
                        data-testid={`${props.id}_buttonYearPrev`}
                        type={"button"}
                    >
                        <Icon.PfeilLinks size={"70px"} />
                    </CalendarStyle.YearsChevron>
                    <CalendarStyle.YearsYear
                        onClick={() => setYear(lastYear, curMonth.getMonth())}
                        data-testid={`${props.id}_buttonYear${lastYear.toString()}`}
                        type={"button"}
                    >
                        <span>{lastYear}</span>
                    </CalendarStyle.YearsYear>
                    <CalendarStyle.YearsYearSelected>
                        <span>{curMonth.getFullYear()}</span>
                    </CalendarStyle.YearsYearSelected>
                    <CalendarStyle.YearsYear
                        onClick={() => setYear(nextYear, curMonth.getMonth())}
                        data-testid={`${props.id}_buttonYear${nextYear.toString()}`}
                        type={"button"}
                    >
                        <span>{nextYear}</span>
                    </CalendarStyle.YearsYear>
                    <CalendarStyle.YearsChevron
                        direction={"next"}
                        onClick={() => setYear(nextYear, curMonth.getMonth())}
                        data-testid={`${props.id}_buttonYearNext`}
                        type={"button"}
                    >
                        <Icon.PfeilRechts size={"70px"} />
                    </CalendarStyle.YearsChevron>
                </CalendarStyle.YearsContainer>
            </CalendarStyle.Years>
        );
    }, [curMonth, setYear, props.id]);

    const renderWeekDayRow = () => {
        const weekDays = curLangData?.text.WeekDays as string[];
        return (
            <CalendarStyle.TableRow key={"weekDayRow"}>
                {weekDays.map((day) => {
                    return <CalendarStyle.TableLabel key={day}>{day}</CalendarStyle.TableLabel>;
                })}
            </CalendarStyle.TableRow>
        );
    };

    const renderCalendar = () => {
        const dayInfo = getDays();

        return (
            <CalendarStyle.Table>
                {renderWeekDayRow()}
                {dayInfo.map((week, idx) => {
                    return (
                        <CalendarStyle.TableRow key={idx}>
                            {week.map((day) => {
                                const isAvailable = day.isInBounds || day.isSelected;
                                return (
                                    <CalendarStyle.TableRowItem key={day.key}>
                                        <CalendarStyle.TableRowItemInner>
                                            <CalendarStyle.TableRowItemButton
                                                selected={day.isSelected}
                                                weekend={!day.isWeekDay}
                                                isInMonth={day.isInMonth}
                                                disabled={!day.isInBounds || (props.disableWeekend && !day.isWeekDay)}
                                                onClick={isAvailable ? () => selectDate(day.originalDate, true) : undefined}
                                                data-testid={`${props.id}_buttonMonth${day.originalDate.getMonth() + 1}Day${day.date}`}
                                                type={"button"}
                                            >
                                                <span>{day.date}</span>
                                            </CalendarStyle.TableRowItemButton>
                                        </CalendarStyle.TableRowItemInner>
                                    </CalendarStyle.TableRowItem>
                                )
                            })}
                        </CalendarStyle.TableRow>
                    )
                })}
            </CalendarStyle.Table>
        );
    };

    return (
        <CalendarStyle.Container>
            {!props.simplified && renderYears()}
            {!props.simplified && renderMonths()}
            {props.simplified && renderSimpleMonth()}
            {renderCalendar()}
        </CalendarStyle.Container>
    )
}