import { FixedElementType } from "Shared/Globals";
import { Icon } from "Shared/Components";
import { FloorStyle } from "./Style";
import useLayout from "Shared/Context/LayoutContext";
import { TestId } from "Test/TestId";

export const mapFixedElements = (data?: string[][]): IFixedElement[] | undefined => {
    if (data && data.length > 0) return data.map((el) => {
        return {
            desc: el[0],
            smallTop: el[1],
            top: el[2],
            smallLeft: el[3],
            left: el[4],
            rotation: el[5],
            width: el[6],
            height: el[7]
        };
    })
    return [];
};

export const FixedElement = (props: {
    data: IFixedElement;
    notScrollable?: boolean;
}) => {
    const { isMobile } = useLayout();
    const smallerView = isMobile(1051) && !props.notScrollable;

    return (
        <FloorStyle.FixedContainer
            top={smallerView ? props.data.smallTop : props.data.top}
            left={smallerView ? props.data.smallLeft : props.data.left}
            rotation={props.data.rotation}
            width={props.data.width}
            height={props.data.height}
            title={props.data.desc}
            data-testid={TestId.fixedElement}
        >
            <FloorStyle.FixedIcon>
                {props.data.desc === FixedElementType.BigKitchen && <Icon.Essen size={(smallerView || !isMobile(1200)) ? "50px" : undefined} />}
                {props.data.desc === FixedElementType.SmallKitchen && <Icon.Kaffee />}
            </FloorStyle.FixedIcon>
        </FloorStyle.FixedContainer>
    )
}