import React from "react";
import { Datepicker, Dropdown, Multiselect } from "Shared/Components";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { availableFloors, FloorPage, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import useResizeObserver from "use-resize-observer";
import { PageStyle } from "Main/Page/Style";
import { TimeSettings } from "./TimeSettings";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import useFloor from "Shared/Context/FloorContext";

interface BookingSettingsProps {
    selectedFloor?: DropdownOption;
    setSelectedFloor: (floor: DropdownOption) => void;
    selectedDate?: Date;
    setSelectedDate?: (date: Date) => void;
    setSelectedEndDate?: (date: Date | undefined) => void;
    setSettingsHeight?: (h: number) => void;
    selectedSeatTypes?: DropdownOption[];
    setSelectedSeatTypes?: (types: DropdownOption[]) => void;
    page: FloorPage;
}

const fontSize = "18px";

export const BookingSettings = (props: BookingSettingsProps) => {
    const [smallerContainer, setContainerSmaller] = React.useState<boolean>(false);
    const settingsContainer = React.useRef<HTMLDivElement>(null);
    const { curLangData, curLang } = useLang();
    const { updateSidePanel } = useLayout();

    const { deskGroupData } = useFloor();

    const allSeatTypeOptions = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;

    const enabledSeatTypeOptions = React.useMemo(() => {
        const enabledOptions: DropdownOption[] = [];
        deskGroupData.forEach((o) => {
            if (o.deskGroupType === undefined && enabledOptions.every((eO) => eO.id !== "0")) enabledOptions.push(allSeatTypeOptions[0]);
            else if (o.deskGroupType != null && enabledOptions.every((eO) => eO.id !== o.deskGroupType?.toString())) enabledOptions.push(allSeatTypeOptions[o.deskGroupType]);
        });
        return enabledOptions;
    }, [allSeatTypeOptions, deskGroupData])

    const selectedSeatTypeOptions = enabledSeatTypeOptions ? enabledSeatTypeOptions.filter(o => props.selectedSeatTypes?.includes(o)) : props.selectedSeatTypes;

    const { width = 0, height = 0 } = useResizeObserver({ ref: settingsContainer });

    const selectedDay = React.useRef<Date>();
    const selectedStartTime = React.useRef<string[]>();
    const selectedEndTime = React.useRef<string[]>();

    const updateTimeRange = () => {
        if (selectedDay.current == null)
            return;

        const startTime = new Date(selectedDay.current);

        if (selectedStartTime.current?.length) {
            startTime.setHours(parseInt(selectedStartTime.current[0]))
            startTime.setMinutes(parseInt(selectedStartTime.current[1]))
        }

        if (props.setSelectedDate) props.setSelectedDate(startTime);

        if (props.setSelectedEndDate) {
            if (selectedEndTime.current?.length) {
                const endTime = new Date(selectedDay.current);
                endTime.setHours(parseInt(selectedEndTime.current[0]))
                endTime.setMinutes(parseInt(selectedEndTime.current[1]))
                props.setSelectedEndDate(endTime);
            }
            else {
                props.setSelectedEndDate(undefined);
            }
        }
    }

    React.useEffect(() => {
        const _setSettingsHeight = props.setSettingsHeight;
        if (_setSettingsHeight) _setSettingsHeight(height);
        setContainerSmaller(width != null && width < 700);
    }, [width, height, props.setSettingsHeight]);

    const getMinDate = () => {
        const today = new Date();
        if (props.page !== FloorPage.booking) {
            const weekday = today.getDay();
            const addition = weekday === 5 ? 3 : 1;
            return new Date(today.setDate(today.getDate() + addition));
        }
        return new Date(today.setDate(today.getDate() - 1));
    }

    const getMaxDate = () => {
        if (props.page !== FloorPage.booking) return undefined;
        return new Date(Date.now() + 12096e5)
    }

    return (
        <PageStyle.Settings ref={settingsContainer}>
            <Dropdown
                options={availableFloors}
                defaultText={curLangData?.text.LocationSelect as string}
                onSelect={(floor) => {
                    updateSidePanel(false);
                    props.setSelectedFloor(floor);
                }}
                selectedOption={props.selectedFloor}
                width={props.page === FloorPage.admin && !smallerContainer ? "40%" : "100%"}
                fontSize={fontSize}
                id={TestId.floorselect}
                label={`${curLangData?.text.Location as string} *`}
            />
            {props.setSelectedDate != null &&
                <>
                    <Datepicker
                        width={smallerContainer ? "100%" : "60%"}
                        selectedDate={props.selectedDate}
                        setSelectedDate={(day) => {
                            selectedDay.current = day;
                            updateTimeRange();
                        }}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        disableWeekend
                        simplified
                        fontSize={fontSize}
                        id={TestId.datepicker}
                        label={`${curLangData?.text.Date as string} *`}
                    />
                    {props.page === FloorPage.booking && props.setSelectedSeatTypes != null && curLangData?.text &&
                        <Multiselect
                            options={allSeatTypeOptions}
                            width={smallerContainer ? "100%" : "40%"}
                            selectedOptions={selectedSeatTypeOptions}
                            onSelect={props.setSelectedSeatTypes}
                            defaultText={curLangData.text.DeskTypeSelect}
                            fontSize={fontSize}
                            id={TestId.reportDeskType}
                            label={curLangData?.text.DeskType}
                            enabledOptions={deskGroupData != null && deskGroupData?.length ? enabledSeatTypeOptions : []}
                        />
                    }
                </>
            }

            {props.page === FloorPage.booking &&
                <TimeSettings
                    smallerContainer={smallerContainer}
                    setStartTime={(startTime) => {
                        selectedStartTime.current = startTime;
                        updateTimeRange();
                    }}
                    setEndTime={(endTime) => {
                        selectedEndTime.current = endTime;
                        updateTimeRange();
                    }}
                />
            }
        </PageStyle.Settings>
    )
};