import useLayout from "Shared/Context/LayoutContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getTimeString } from "Shared/Functions/DateMath";
import { DefaultButton, Flyout, InfoBox, InfoBoxType } from "Shared/Components";
import { Desk } from "Shared/Functions/Desk";
import { Path, BookingData, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { BookingStyle } from "./Style";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import { useApi } from "Shared/Context/API";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { Confirmation } from "./Confirm";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";


export const MyBookingsInfo = (props: {
    booking: BookingData;
    allBookings: BookingData[];
}) => {
    const [showFlyout, setFlyout] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [showConfirmAnimation, setConfirmAnimationVisible] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);

    const { updateMyBookings } = useMyBookings();
    const { isMobile } = useLayout();
    const { curLang, curLangData } = useLang();
    const navigate = useNavigate();
    const api = useApi();

    const timer = React.useRef<number>();

    const redirectPath = React.useMemo(() => {
        if (props.allBookings.length > 1 && props.booking) {
            return Path.MyBookings + "/" + props.booking.date.toLocaleDateString("de-DE").replace(/\./g, "-");
        }
        return Path.Booking as string;
    }, [props.allBookings, props.booking]);

    const onDismiss = React.useCallback(() => {
        clearTimeout(timer.current);
        if (showConfirmAnimation) {
            updateMyBookings();
            timer.current = window.setTimeout(() => {
                navigate(redirectPath);
                if (isMounted.current) {
                    setConfirmAnimationVisible(false);
                    setFlyout(false);
                }
            }, 200);
        } else setFlyout(false);
        setErrorMsg(undefined);
    }, [navigate, redirectPath, showConfirmAnimation, updateMyBookings]);

    const deleteBooking = () => {
        setDeleteLoading(true);
        api
            .unbookSeat(desk, props.booking.id as string)
            .then(() => {
                setDeleteLoading(false);
                setErrorMsg(undefined);
                setConfirmAnimationVisible(true);
            })
            .catch((err) => {
                let msg = curLangData?.error.DeleteBooking;
                if (err && err.messageId) msg = ErrorMsg(err.messageId, false, true, curLangData?.error) ?? msg;
                setDeleteLoading(false);
                setErrorMsg(msg)
            })
    }

    const renderFlyoutTitle = React.useCallback(() => {
        if (showConfirmAnimation) return curLangData?.text.SuccessCancel;
        if (errorMsg != null) return curLangData?.text.Error;
        return curLangData?.text.Sure;
    }, [errorMsg, showConfirmAnimation, curLangData]);

    const cancelText = React.useMemo(() => {
        if (!showConfirmAnimation && errorMsg == null && props.booking) {
            const text1 = curLangData?.text.CancelBooking1 as string;
            const text2 = curLangData?.text.CancelBooking2 as string;
            const date = props.booking.date.toLocaleDateString(curLang?.browser, { dateStyle: "short" });
            const time = getTimeString(
                props.booking.date,
                curLangData?.text.FullTime as string,
                props.booking.endDate,
                curLang?.id === 1 ? curLangData?.text.Clock : undefined
            )
            return text1 + date + " " + time + text2;
        }
        return "";
    }, [curLang, curLangData, errorMsg, props.booking, showConfirmAnimation]);

    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    const deskType = () => {
        const deskTypes = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;
        if (props.booking.deskGroupType != null && props.booking.deskGroupType !== 0) return deskTypes[props.booking.deskGroupType].text;
        return null;
    };

    const desk = new Desk(props.booking.seat);
    return (
        <>
            <BookingStyle.Info>
                {isMobile(1050) && <>
                    {!isMobile(701) &&
                        <>
                            <BookingStyle.InfoRow>
                                <strong>{curLangData?.text.Date}</strong>
                                <span>{props.booking.date.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}</span>
                            </BookingStyle.InfoRow>
                            <BookingStyle.InfoRow>
                                <strong>{curLangData?.text.Time}</strong>
                                <span>{getTimeString(
                                    props.booking.date,
                                    curLangData?.text.FullTime as string,
                                    props.booking.endDate,
                                    curLang?.id === 1 ? curLangData?.text.Clock : undefined
                                )}</span>
                            </BookingStyle.InfoRow>
                        </>
                    }
                    <BookingStyle.InfoRow>
                        <strong>{curLangData?.text.Location}</strong>
                        <span>{desk.Floor.Name}</span>
                    </BookingStyle.InfoRow>
                    {desk.Area != null &&
                        <BookingStyle.InfoRow>
                            <strong>{curLangData?.text.Area}</strong>
                            <span>{desk.Area}</span>
                        </BookingStyle.InfoRow>
                    }
                    {deskType() != null &&
                        <BookingStyle.InfoRow>
                            <strong>{curLangData?.text.DeskType}</strong>
                            <span>{deskType()}</span>
                        </BookingStyle.InfoRow>
                    }
                    <BookingStyle.InfoRow>
                        <strong>{curLangData?.text.DeskId}</strong>
                        <span>{desk.UniqueId}</span>
                    </BookingStyle.InfoRow>
                </>}
                <BookingStyle.InfoRowButton>
                    <DefaultButton
                        secondary
                        onClick={() => {
                            setFlyout(true);
                            setErrorMsg(undefined);
                        }}
                        data-testid={`${TestId.mybookings}_buttonCancel`}
                    >
                        {curLangData?.text.BookingCancel}
                    </DefaultButton>
                    <Confirmation booking={props.booking} setError={setErrorMsg} />
                </BookingStyle.InfoRowButton>
                {!showFlyout && errorMsg &&
                    <InfoBox
                        type={InfoBoxType.error}
                        text={errorMsg}
                        title={curLangData?.text.Attention}
                        marginTop={"20px"}
                    />
                }
            </BookingStyle.Info>

            <Flyout
                visible={showFlyout}
                onDismiss={onDismiss}
                width={isMobile(500) ? "95vw" : "500px"}
                title={renderFlyoutTitle()}
                isLoading={deleteLoading}
                onConfirm={errorMsg !== curLangData?.error.BookingNotFound ? () => deleteBooking() : undefined}
                onConfirmText={curLangData?.text.OnConfirm as string}
                onConfirmAnimation={showConfirmAnimation}
                onConfirmAnimationEnded={onDismiss}
            >
                {cancelText}
                {errorMsg &&
                    <InfoBox
                        type={InfoBoxType.error}
                        text={errorMsg}
                        title={curLangData?.text.Attention}
                    />
                }
            </Flyout>
        </>
    )
}