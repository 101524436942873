import styled from 'styled-components'
import { breakpoint, color, defaultTransition } from "Style/Globals";

export const AsideStyle = {
    Container: styled.aside<{ expandNav?: boolean, expandedHeight: number }>`
        background-color: ${color.yellow};
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        height: ${props => props.expandNav ? props.expandedHeight + "px" : "85px"};
        ${defaultTransition}

        @media ${breakpoint.min701} {
            top: 20px;
            left: 20px;
            right: auto;
            width: ${props => props.expandNav ? "270px" : "130px"};
            overflow: visible;
            height: auto;
        }

        @media ${breakpoint.min1050} {
            top: 50px;
            left: 50px;
        }
    `,
}