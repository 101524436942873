import { Icon } from "Shared/Components/Icon/Style";
import { InfoStyle } from "./Style";
import useLayout from "Shared/Context/LayoutContext";
import React from "react";
import { TestId } from "Test/TestId";


export enum InfoBoxType {
    default, error
}

export const InfoBox = (props: {
    type: InfoBoxType;
    title?: string;
    text?: string;
    marginTop?: string;
    isAside?: boolean;
    htmlText?: string;
    id?: string;
}) => {
    const { expandNav, updateNav } = useLayout();
    const [smallView, setSmallView] = React.useState(false);
    const infoContainer = React.useRef<HTMLDivElement>(null);

    const setView = React.useCallback(() => {
        const width = infoContainer.current?.clientWidth;
        if (width) setSmallView(width < 350);
    }, [])

    React.useEffect(() => {
        setView();
        window.addEventListener("resize", setView);

        return () => {
            window.removeEventListener("resize", setView);
        }
    }, [setView])

    const renderText = () => {
        if (props.htmlText) {
            const text = props.title ? `<mark>${props.title}</mark>${props.htmlText}` : props.htmlText;
            return (
                <InfoStyle.Text isAside={props.isAside} dangerouslySetInnerHTML={{ __html: text }} />
            )
        }

        return (
            <InfoStyle.Text isAside={props.isAside}>
                {props.title && <mark>{props.title}</mark>}
                {props.text}
            </InfoStyle.Text>
        )
    }

    return (
        <InfoStyle.Container
            ref={infoContainer}
            type={props.type}
            marginTop={props.marginTop}
            isAside={props.isAside}
            expandNav={expandNav}
            small={smallView}
        >
            <InfoStyle.Icon
                type={props.type}
                isAside={props.isAside}
                onClick={props.isAside ? () => updateNav(!expandNav) : undefined}
                small={smallView}
            >
                {props.type === InfoBoxType.default && <Icon.Gluehbirne size={"100px"} data-testid={`${props.id ?? TestId.infobox}_icondefault`} />}
                {props.type === InfoBoxType.error && <Icon.Achtung size={"105px"} data-testid={`${props.id ?? TestId.infobox}_iconwarning`} />}
            </InfoStyle.Icon>
            {((props.isAside && expandNav) || !props.isAside) && renderText()}
        </InfoStyle.Container >
    )
}