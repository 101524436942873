import React from "react";
import { InputfieldStyle } from "./Style";

interface InputfieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    id: string;
    error?: boolean;
    width?: string;
    required?: boolean;
    padding?: string;
}

export const Inputfield: React.FC<InputfieldProps> = ({ label, id, error, width, required, padding, ...rest }) => {

    return (
        <InputfieldStyle.Container>
            <InputfieldStyle.Label htmlFor={id}>
                {label}
                {required && " *"}
            </InputfieldStyle.Label>
            <InputfieldStyle.Field
                aria-required={required}
                required={required}
                error={error}
                id={id}
                padding={padding}
                {...rest}
            />
        </InputfieldStyle.Container>
    )
}