import React from "react";
import useLang from "Shared/Context/LangContext"
import { FilterButton } from "../FilterButton/FilterButton";
import { WeekdayPickerStyle } from "./Style"

export const WeekdayPicker = (props: {
    onBtnClick: (day: number, remove?: boolean) => void;
    selectedDays: number[];
    id: string;
    hideWeekend?: boolean;
    hasError?: boolean;
    maxDays?: number;
}) => {
    const { curLangData } = useLang();

    const days = React.useMemo(() => {
        const d = curLangData?.text.WeekDays as string[];
        if (props.hideWeekend) d.splice(5, 2);
        return d;
    }, [curLangData, props.hideWeekend])

    const renderBtn = (day: string, i: number) => {
        const dayNum = i === 6 ? 0 : (i + 1);
        const isActive = props.selectedDays.includes(dayNum);
        return (
            <FilterButton
                isActive={isActive}
                isDisabled={!isActive && props.maxDays != null && props.selectedDays.length === props.maxDays}
                onClick={() => props.onBtnClick(dayNum, isActive)}
                id={`${props.id}_day${dayNum}`}
                hasError={props.hasError}
                key={"day" + dayNum}
            >
                {day}
            </FilterButton>
        )
    }

    return (
        <WeekdayPickerStyle.Container data-testid={`${props.id}_container`}>
            {days.map(renderBtn)}
        </WeekdayPickerStyle.Container>
    )
}