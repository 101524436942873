import React from "react";
import { Icon, InfoBox, InfoBoxType, Inputfield } from "Shared/Components";
import { ButtonRow } from "Shared/Components/ButtonRow/ButtonRow";
import { useApi } from "Shared/Context/API";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import { FilteredPodData } from "Main/Floor/Pod/Functions/FilteredPodData";
import { PodResData } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { FloorStyle } from "Main/Floor/Style";
import { DefaultPodInfo } from "./DefaultPodInfo";

export const PodInfoAdmin = (props: {
    podId: string;
    selectedFrom: Date;
    onDismiss: () => void;
    onSave: () => void;
}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [fieldError, setFieldError] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [delMsg, setDelMsg] = React.useState(false);

    const { curLangData } = useLang();
    const { podData } = useFloor();

    const api = useApi();

    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    })

    const onDismiss = (dismiss?: boolean) => {
        setFieldError(false);
        setDelMsg(false);
        setError(undefined);

        if (dismiss) props.onDismiss();
        else props.onSave();
    }

    const data = React.useMemo(() => {
        return FilteredPodData(props.podId, props.selectedFrom, podData)
    }, [podData, props.podId, props.selectedFrom]);

    const emptyPod: PodResData = React.useMemo(() => {
        return {
            id: undefined,
            podId: props.podId,
            text: "",
            day: props.selectedFrom,
            capacity: data?.position?.count as number
        }
    }, [data?.position?.count, props.podId, props.selectedFrom]);

    const [newPodData, setNewPodData] = React.useState<PodResData>(data?.reservations[0] ?? emptyPod);

    React.useEffect(() => {
        if (data) {
            setNewPodData(data?.reservations[0] ?? emptyPod)
        }
    }, [data, emptyPod])

    const fieldValidation = React.useMemo(() => {
        const c = newPodData.capacity;
        return {
            team: newPodData.text.length > 0,
            size: !isNaN(c) && c <= (data?.position?.count as number) && c >= (data?.bookings.length as number),
        }
    }, [data?.bookings.length, data?.position?.count, newPodData.capacity, newPodData.text.length]);

    const onSave = (e: any) => {
        e.preventDefault();
        if (fieldValidation.team && fieldValidation.size) {
            setFieldError(false);
            setError(undefined);
            setLoading(true);
            api.Admin
                .addPodReservation(newPodData)
                .then(() => onDismiss())
                .catch(() => setError(curLangData?.error.PodAdminBooking))
                .finally(() => { if (isMounted.current) setLoading(false) })
        }
        else {
            setFieldError(true);
            if (data && data.bookings.length > 0 && newPodData.capacity < data.bookings.length) {
                setError(curLangData?.error.WrongPodCapacity as string)
            }
            else if (newPodData.capacity > (data?.position?.count as number)) {
                setError((curLangData?.error.PodCapacity1 as string) + (data?.position?.count as number) + (curLangData?.error.PodCapacity2 as string));
            }
            else if (isNaN(newPodData.capacity) || newPodData.text.length === 0) {
                setError(curLangData?.error.PodAdminRequiredFields);
            }
        }
    }

    const delBooking = (e: any) => {
        e.preventDefault();
        setLoading(true);
        api.Pod
            .deletePodReservation(newPodData.id as string)
            .then(() => onDismiss())
            .catch(() => setError(curLangData?.error.PodAdminDelete))
            .finally(() => {
                if (isMounted.current)
                    setLoading(false);
            });
    }

    return (
        <>
            <DefaultPodInfo
                podId={props.podId}
                selectedFrom={props.selectedFrom}
            />
            <FloorStyle.DeskInfoForm onSubmit={delMsg ? delBooking : onSave}>
                <FloorStyle.DeskInfoFormRow>
                    <Inputfield
                        id={`${TestId.podinfo}_rowTeam_input`}
                        label={curLangData?.text.ReservedFor as string}
                        placeholder={curLangData?.text.TeamName}
                        value={newPodData.text}
                        onChange={(e) => {
                            if (e.target.value.length > 0) setError(undefined);
                            setNewPodData({ ...newPodData, text: e.target.value })
                        }
                        }
                        required
                        error={fieldError && !fieldValidation.team}
                        title={(fieldError && !fieldValidation.team) ? curLangData?.info.NoEmptyField : undefined}
                        padding={"13px 50px 13px 13px"}
                    />
                    {data?.reservations[0] != null &&
                        <FloorStyle.DeskInfoDelButton
                            type={"button"}
                            title={curLangData?.text.DeleteReservation}
                            onClick={() => setDelMsg(true)}
                            data-testid={`${TestId.podinfo}_rowTeam_buttonDel`}
                        >
                            <Icon.Papierkorb />
                        </FloorStyle.DeskInfoDelButton>
                    }
                </FloorStyle.DeskInfoFormRow>

                <FloorStyle.DeskInfoFormRow>
                    <Inputfield
                        id={`${TestId.podinfo}_rowCapacity_input`}
                        label={curLangData?.text.AvailableDesks as string}
                        required
                        placeholder={data?.position?.count.toString()}
                        max={data?.position?.count}
                        value={newPodData.capacity}
                        onChange={(e) => {
                            const val = isNaN(parseInt(e.target.value)) ? "0" : e.target.value;
                            setNewPodData({ ...newPodData, capacity: parseInt(val) })
                        }}
                        error={fieldError && !fieldValidation.size}
                        title={isNaN(newPodData.capacity) ? curLangData?.info.NoEmptyField : undefined}
                    />
                </FloorStyle.DeskInfoFormRow>

                {error &&
                    <InfoBox
                        type={InfoBoxType.error}
                        text={error}
                        title={curLangData?.text.Attention}
                        marginTop={"20px"}
                    />
                }

                {delMsg && !error &&
                    <InfoBox
                        type={InfoBoxType.default}
                        text={curLangData?.info.DelPodRes}
                        marginTop={"20px"}
                    />
                }

                <ButtonRow
                    id={`${TestId.podinfo}_button`}
                    primaryText={delMsg ? (curLangData?.text.Delete as string) : (curLangData?.text.Save as string)}
                    primaryType={"submit"}
                    onPrimaryClick={delMsg ? delBooking : onSave}
                    secondaryText={curLangData?.text.Cancel as string}
                    secondaryType={"button"}
                    onSecondaryClick={() => onDismiss(true)}
                    marginTop={"20px"}
                    isLoading={isLoading}
                />
            </FloorStyle.DeskInfoForm>
        </>
    )
}