import React from "react";
import useLayout from "Shared/Context/LayoutContext";
import useUser from "Shared/Context/UserContext";
import { Aside } from "Main/Aside/Aside";
import { Icon } from "Shared/Components";
import { PageStyle } from "./Style";
import { PageLinks } from "./PageLinks";
import useLang from "Shared/Context/LangContext";

export const DefaultPage = (props: React.PropsWithChildren) => {
    const { currentPageTitle, expandNav, isMobile, expandSidePanel } = useLayout();
    const { currentUser } = useUser();
    const { curLangData } = useLang();

    return (
        <>
            <Aside />
            <PageStyle.ContainerInner expandNav={expandNav} expandSidePanel={expandSidePanel}>
                {!isMobile(701) &&
                    <PageStyle.Header>
                        <PageStyle.Title>{currentUser != null ? currentPageTitle : curLangData?.text.MissingAuth}</PageStyle.Title>
                    </PageStyle.Header>
                }
                {currentUser != null ?
                    <PageStyle.Content>
                        {props.children}
                        <PageStyle.FeedbackLink href="https://postat.sharepoint.com/sites/oskar/feedbacksystem.aspx#id=12" target={"_blank"}>
                            <Icon.FAQ size={"50px"} />
                            <span>{curLangData?.text.FAQButton}</span>
                        </PageStyle.FeedbackLink>
                    </PageStyle.Content>
                    :
                    <PageStyle.Text>
                        {curLangData?.info.NoAuth1} <br />
                        {curLangData?.info.NoAuth2} <a href="mailto:deskfinder@post.at?subject=Berechtigung">deskfinder@post.at</a>
                    </PageStyle.Text>
                }
                {!isMobile(701) && <PageLinks />}
                <div id={"df_sidepanel"} />
            </PageStyle.ContainerInner>
        </>
    )
}