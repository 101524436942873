import styled from "styled-components";
import { color, font } from "Style/Globals";

export const DefaultButton = styled.button<{ secondary?: boolean, disabled?: boolean }>`
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.disabled ? color.palegraydark : color.blue};
    background-color: ${props => {
        if (props.secondary) return color.white;
        if (props.disabled) return color.palegraydark;
        return color.blue;
    }};
    color: ${props => {
        if (props.secondary && props.disabled) return color.palegraydark;
        if (props.secondary && !props.disabled) return color.blue;
        return color.white;
    }};
    text-decoration: none;
    padding: 6px 10px;
    font-family: ${font.bold};
    transition: 200ms ease-in;
    pointer-events:${props => props.disabled && "none"};

    :hover {
        border: 2px solid ${color.blueHover};
        background-color: ${props => {
        if (props.secondary) return color.white;
        if (props.disabled) return color.palegraydark;
        return color.blueHover
    }};
        color: ${props => props.secondary ? color.blueHover : color.white};
    }

    :focus {
        border: 2px solid ${color.blueActive};
        background-color: ${props => {
        if (props.secondary) return color.white;
        if (props.disabled) return color.palegraydark;
        return color.blueActive
    }};
        color: ${props => props.secondary ? color.blueActive : color.white};
    }

    @media (prefers-color-scheme: dark) {
        border-color: ${props => props.disabled ? color.darkmodeBgBody : color.blue};
        background-color: ${props => {
        if (props.secondary) return color.black;
        if (props.disabled) return color.darkmodeBgBody;
        return color.blue;
    }};
        color: ${props => {
        if (props.secondary && props.disabled) return color.darkmodeBgBody;
        if (props.secondary && !props.disabled) return color.blue;
        if (!props.secondary && props.disabled) return color.black;
        return color.white;
    }};

        :hover {
            background-color: ${props => props.secondary ? color.black : color.blueHover};
        }

        :focus {
            background-color: ${props => props.secondary ? color.black : color.blueActive};
        }
    }
`;