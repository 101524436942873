import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";
import useConfig from "./ConfigContext";
import { createHashHistory } from "history";

const reactPlugin = new ReactPlugin();
const browserHistory = createHashHistory();
const appInsights = new ApplicationInsights({
    config: {
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

export const LogProvider = (props: { children: JSX.Element }) => {
    const config = useConfig();
    const aiInitialized = React.useRef(false);

    if (config.instrumentationKey?.length > 0) {
        const aiConfig = appInsights.config;
        if (aiConfig.instrumentationKey == null && aiConfig.instrumentationKey !== config.instrumentationKey) {
            aiConfig.instrumentationKey = config.instrumentationKey;
            aiConfig.appId = config.appId;
            appInsights.loadAppInsights();
            aiInitialized.current = true;
        }
    }

    if (!aiInitialized.current) {
        return <>
            {props.children}
        </>
    }

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {props.children}
        </AppInsightsContext.Provider>
    )
}