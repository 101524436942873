import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { breakpoint, color, defaultTransition, font } from "Style/Globals";

export const NavStyle = {
    Container: styled.nav<{ expandNav: boolean }>`
        display: block;
        padding: 0 10px 10px;
        ${defaultTransition}

        @media ${breakpoint.min701} {
            padding: ${props => props.expandNav ? "20px" : "10px"};
        }
    `,
    List: styled.ul`
        display: block;
    `,
    Link: styled(NavLink)`
        display: block;
        padding: 10px;
        font-size: 20px;
        border-left: 3px solid ${color.black};
        position: relative;
        height: auto;
        ${defaultTransition};
        margin-bottom: 5px;
        color: ${color.black} !important;
        overflow: hidden;

        @media ${breakpoint.min701} {
            border-color: ${color.transparent};
            margin-bottom: 0;
        }

        :hover {
            font-family: ${font.bold};
        }

        &.active {
            font-family: ${font.bold};
            border-color: ${color.black};
            background-color: ${color.yellowHover};
            margin-right: 0;
            display: none;

            @media ${breakpoint.min701} {
                display: block;
                margin-right: -30px;
            }
        }

        & > span {
            opacity: 1;
            white-space: nowrap;
            display: block;
            margin-top: -1px;
            transition: 200ms ease-in;
            padding-left: 30px;
        }
    `,
    Item: styled.li<{ expandNav?: boolean }>`
        & > a {
            &.active {
                @media ${breakpoint.min701} {
                    margin-right: ${props => props.expandNav && "-40px"};
                }
            }

            > span {
                transition-delay: ${props => props.expandNav ? "200ms" : "0ms"};

                @media ${breakpoint.min701} {
                    opacity: ${props => props.expandNav ? "1" : "0"}; 
                }
            }
        }
    `,
    Icon: styled.div<{ expandNav?: boolean }>`
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        transition: 200ms ease-in;
        left: 2px;
        text-align: center;
        transition-delay: ${props => props.expandNav ? "0ms" : "200ms"};

        @media ${breakpoint.min701} {
            width: ${props => !props.expandNav ? "100%" : "0 !important"};

            .active & {
                width:${props => !props.expandNav && "calc(100% - 30px)"};
            }
        }
    `,
    Arrow: styled.div`
        display: none;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 2px;
        .active & {
            display: block;
        }
    `,
}