
import { ConfAnimation } from "Shared/Components/Animation/Confirmation";
import { DefaultButton } from "Shared/Components/DefaultButton/Style";
import { Icon } from "Shared/Components/Icon/Style";
import { Loading } from "Shared/Components/Loading/Loading";
import useLang from "Shared/Context/LangContext";
import { FlyoutStyle } from "./Style";

interface FlyoutContentProps {
    onConfirm?: () => void;
    showAnimation?: boolean;
    isLoading?: boolean;
    onDismiss?: () => void;
    onConfirmText?: string;
    title?: string;
    id?: string;
}

export const FlyoutContent = (props: React.PropsWithChildren<FlyoutContentProps>) => {
    const { curLangData } = useLang();

    return (
        <>
            {props.title &&
                <FlyoutStyle.Header>
                    {props.title}
                    <FlyoutStyle.HeaderButton
                        onClick={props.onDismiss}
                        data-testid={props.id ? `${props.id}_buttonClose` : undefined}
                    >
                        <Icon.X size={"35px"} isBold />
                    </FlyoutStyle.HeaderButton>
                </FlyoutStyle.Header>
            }
            <FlyoutStyle.Content>
                {props.children}
                {props.onConfirm &&
                    <FlyoutStyle.ButtonRow>
                        {props.showAnimation ?
                            <FlyoutStyle.Animation data-testid={props.id ? `${props.id}_animation` : undefined}>
                                <ConfAnimation />
                            </FlyoutStyle.Animation>
                            :
                            <>
                                <div>
                                    {props.isLoading && <Loading position={"absolute"} size={"7px"} />}
                                </div>
                                <DefaultButton
                                    secondary
                                    onClick={props.onDismiss}
                                    disabled={props.isLoading}
                                    data-testid={props.id ? `${props.id}_buttonDismiss` : undefined}
                                >{curLangData?.text.Cancel}</DefaultButton>
                                <DefaultButton
                                    onClick={props.onConfirm}
                                    disabled={props.isLoading}
                                    data-testid={props.id ? `${props.id}_buttonConfirm` : undefined}
                                >{props.onConfirmText ?? curLangData?.text.Confirm}</DefaultButton>
                            </>
                        }
                    </FlyoutStyle.ButtonRow>
                }
            </FlyoutStyle.Content>
        </>
    )
}