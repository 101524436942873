import { Teamday } from "Shared/Context/TeamdaysContext";
import { getFirstAndLastDayOfQuarter } from "Shared/Functions/DateMath";
import { TempPodResData } from "Shared/Globals";

export const calcTeamDays = (data: TempPodResData) => {
    const start = data.startDate as Date;
    const end = getFirstAndLastDayOfQuarter(data.quarter, start.getFullYear()).to;
    const weekDays = (data.weekDays as number[]).sort((a, b) => a - b);
    const interval = data.weekInterval as number * 7;

    let firstDayWeek = new Date(start);
    firstDayWeek.setDate(firstDayWeek.getDate() - start.getDay());

    const validDays: Teamday[] = [];

    while (firstDayWeek <= end) {
        weekDays.forEach(dayNumber => {
            let candidate = new Date(firstDayWeek);
            candidate.setDate(candidate.getDate() + dayNumber);
            if (candidate >= start && candidate < end) {
                validDays.push({ date: candidate });
            }
        });
        firstDayWeek.setDate(firstDayWeek.getDate() + interval);
    }

    validDays.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    return validDays.filter((d) => d.date > yesterday && d.date <= end)
}