import styled, { keyframes } from "styled-components";
import GChart from "react-google-charts";
import { breakpoint, font } from "Style/Globals";

const widthAnim = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
`;

export const ChartStyle = {
    Container: styled.div<{ width?: string, height?: string }>`
        width: ${props => props.width ?? "100%"};
        height: ${props => props.height ?? "400px"};

        @media ${breakpoint.min1600} {
            height: ${props => props.height ?? "500px"};
        }
    `,
    Pie: styled(GChart)`
    
    `,
    Legend: styled.div`
        text-align: center;
    `,
    LegendItem: styled.p<{ color: string }>`
    position: relative;
    padding-left: 30px;
    display: inline-block;

        :before {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: ${props => props.color};
            position: absolute;
            left: 0;
            bottom: 0;
        }

        & > span {
            font-size: 20px;
            font-family: ${font.regular};
        }

        & + & {
            margin-left: 30px;
        }
    `,
    Bar: styled.div<{ color: string, size: string }>`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ${props => props.size};

        :before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            animation: ${widthAnim} 1000ms linear forwards;
            background: ${props => props.color};
        }
    `,
    BarContainer: styled.div<{ color: string }>`
        width: 100%;
        position: relative;
        background-color: ${props => props.color};
        height: 25px;
    `,
    BarText: styled.p<{ color?: string }>`
        color: ${props => props.color};
        position: relative;
        font-size: 16px;
        padding: 3px;
    `,
}