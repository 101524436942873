import React from "react";

export const GetScrollOffset = () => {
    const isClient = typeof window === "object";

    const [scrollOffset, setScrollOffset] = React.useState<number>(0);

    const handleScroll = React.useCallback(() => {
        if (isClient) {
            const offset = window.scrollY;
            setScrollOffset(offset);
        }
    }, [isClient]);

    React.useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return scrollOffset;
};
