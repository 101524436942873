import React from "react";
import { DefaultButton, InfoBox, InfoBoxType, Loading, SidePanel } from "Shared/Components"
import { useApi } from "Shared/Context/API";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import { getFirstAndLastDayOfQuarter, getQuarter } from "Shared/Functions/DateMath";
import { ExtendedPodResData } from "Shared/Globals";
import { mapTeamData } from "./convertTeamData";
import { PodMgmtStyle } from "./Style";
import { ReservationListItem } from "./ReservationListItem";
import useResizeObserver from "use-resize-observer/polyfilled";
import { TeamForm } from "./TeamForm";
import { TeamdaysList } from "./TeamdaysList";
import usePodReservation from "Shared/Context/PodReservationContext";
import { Table } from "Shared/Components/Table/Style";

export const ReservationList = (props: {
    onConfirmTeamDays: () => void;
    openAndResetForm?: boolean;
    formOpen?: () => void;
}) => {
    const [teamData, setTeamData] = React.useState<ExtendedPodResData[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const [showForm, setFormVisible] = React.useState(true);
    const { expandSidePanel, updateSidePanel } = useLayout();
    const { curLangData } = useLang();
    const [error, setError] = React.useState<string | undefined>(undefined)
    const { resData, clearResData } = usePodReservation();
    const api = useApi();

    const listContainer = React.useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({ ref: listContainer });

    let isMounted = React.useRef(false);

    const onDismiss = () => {
        setFormVisible(true);
        clearResData();
        updateSidePanel(false);
    }

    React.useEffect(() => {
        if (resData?.teamname.length > 0 && props.openAndResetForm) {
            updateSidePanel(true);
            if (props.formOpen) props.formOpen();
        }
    }, [props, props.openAndResetForm, resData, updateSidePanel])

    const loadData = React.useCallback(() => {
        setLoading(true);

        const now = new Date();
        const quarter = getQuarter(now);
        const thisYear = now.getFullYear();
    
        const thisQuarterDates = getFirstAndLastDayOfQuarter(quarter, thisYear);

        const nextQuarterDates = quarter === 4 ? 
            getFirstAndLastDayOfQuarter(1, thisYear + 1) : 
            getFirstAndLastDayOfQuarter(quarter + 1, thisYear);
            
        const thisQuarterPromise = api.Pod.getPodReservations(now, thisQuarterDates.to);
        const nextQuarterPromise = api.Pod.getPodReservations(nextQuarterDates.from, nextQuarterDates.to);

        Promise
            .all([thisQuarterPromise, nextQuarterPromise])
            .then((res) => {
                if (res && isMounted.current) {
                    setError(undefined)
                    setTeamData(mapTeamData(res[0], res[1]))
                }
            })
            .catch(() => setError(curLangData?.error.ReservationCheck))
            .finally(() => isMounted.current && setLoading(false))
    }, [api.Pod, curLangData?.error.ReservationCheck]);

    React.useEffect(() => {
        isMounted.current = true;
        loadData();
        return () => { isMounted.current = false; }
    }, [loadData]);

    const renderButton = () => {
        if (!expandSidePanel && !isLoading && !error) {
            return <PodMgmtStyle.TeamListBtn>
                <DefaultButton onClick={() => updateSidePanel(true)}>
                    {curLangData?.text.CreateNewReservation}
                </DefaultButton>
            </PodMgmtStyle.TeamListBtn>;
        }
    };

    const renderLoading = () => {
        if (isLoading) return <Loading position={"absolute"} />
    };

    const renderList = React.useCallback(() => {
        if (teamData.length > 0 && !isLoading && !error) return (
            <Table>
                <ReservationListItem isHeader key={"header"} containerWidth={width} />
                {teamData.map((d) => <ReservationListItem
                    containerWidth={width}
                    data={d}
                    key={d.teamname + d.podId + d.quarter}
                    onDelete={loadData}
                />)}
            </Table>
        )
    }, [error, isLoading, loadData, teamData, width]);

    const renderInfo = () => {
        if (teamData.length === 0 && !isLoading && error == null) return <PodMgmtStyle.TeamListInfo>{curLangData?.info.NoReservationFound}</PodMgmtStyle.TeamListInfo>
    }

    const renderError = () => {
        if (error != null && !isLoading) return <InfoBox type={InfoBoxType.error} text={error} />
    }

    return (
        <>
            <PodMgmtStyle.Section>
                <PodMgmtStyle.SectionInner>
                    <PodMgmtStyle.SectionTitle>
                        {curLangData?.text.TeamReservationList}
                    </PodMgmtStyle.SectionTitle>
                    <PodMgmtStyle.TeamListContainer ref={listContainer}>
                        {renderButton()}
                        {renderLoading()}
                        {renderList()}
                        {renderInfo()}
                        {renderError()}
                    </PodMgmtStyle.TeamListContainer>
                </PodMgmtStyle.SectionInner>
            </PodMgmtStyle.Section>

            <SidePanel
                headline={showForm ? curLangData?.text.NewReservation as string : curLangData?.text.ConfirmTeamdays as string}
                onDismiss={onDismiss}
            >
                {showForm && <TeamForm
                    onFormComplete={() => setFormVisible(false)}
                    onDismiss={onDismiss}
                />}
                {!showForm && <TeamdaysList
                    onConfirm={() => {
                        updateSidePanel(false);
                        props.onConfirmTeamDays();
                    }}
                    onDismiss={() => setFormVisible(true)}
                />}
            </SidePanel>
        </>

    )
}