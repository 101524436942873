import { DeskArea, Floor, FloorAbbr, Floors } from "../Globals";

export class Desk {
    public readonly UniqueId: string
    public readonly Floor: Floor
    public readonly Area?: DeskArea
    public readonly Group: string
    public readonly GroupRelativeId: string
    constructor(deskString: string) {
        this.UniqueId = deskString;

        const splitted = deskString.split("-");
        this.Floor = Floors[splitted[0]];
        if (this.Floor?.Id === FloorAbbr.UZD2) {
            splitted.splice(1, 0, "");
        } else {
            this.Area = DeskArea[splitted[1] as keyof typeof DeskArea];
        }

        this.Group = this.Area ? splitted[1] + "-" + splitted[2] : splitted[2];
        this.GroupRelativeId = splitted[3];
    }
}