import { color, font } from "Style/Globals";
import styled from "styled-components";

export const PodMgmtStyle = {
    Container: styled.div`
        /* display: flex; */
    `,
    Section: styled.section`
        width: 100%;
        padding-top: 33px;
    `,
    SectionInner: styled.div<{ bgColor?: string }>`
        background-color: ${props => props.bgColor ?? color.transparent};
        padding: ${props => props.bgColor ? "10px" : "0"};
        position: relative;
        width: 100%;
        max-width: 1000px;
    `,
    SectionTitle: styled.h3`
        font-size: 25px;
        color: ${color.black};
        font-family: ${font.bold};
        padding-bottom: 20px;

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }
    `,
    Form: styled.form`
    `,
    FormRow: styled.div<{ flex?: boolean }>`
        display: ${props => props.flex ? "flex" : "block"};

        & + & {
            margin-top: 20px;
        }

        > button + button {
            margin-left: 10px;
        }
    `,
    SpinnerContainer: styled.div`
        display: inline-block;
        position: relative;
        width: 80px;
        height: 32px;
        vertical-align: bottom;
    `,
    TeamListContainer: styled.div``,
    TeamListCell: styled.div`
        > button {
            display: inline-block;
            color: ${color.blue};
            text-decoration: underline;
            font-family: ${font.bold};
            font-size: 16px;
            cursor: pointer;

            :hover {
                text-decoration: none;
                color: ${color.blueHover};
            }

            :focus {
                text-decoration: none;
                color: ${color.blueActive};
            }
        }

        :nth-child(even) {
            text-align: center;
        }
    `,
    TeamListInfo: styled.div`
        margin-bottom: 20px;
    `,
    TeamListBtn: styled.div`
        position: absolute;
        top: 0;
        right: 0;
    `,
    TeamListDelBtn: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        > button {
            height: 100%;
            padding-left: 10px;
            cursor: pointer;

            > i:before {
                color: ${color.black};

                @media (prefers-color-scheme: dark) {
                    color: ${color.white};
                }
            }

            :hover {
                > i:before {
                    font-weight: bold;
                }
            }
        }
    `,
    FlyoutText: styled.div`
        > p {
            margin-bottom: 10px;
            line-height: 1.5;
        }
    `,
    TeamdaysContainer: styled.div``,
    TeamdaysHeadline: styled.p`
        font-size: 18px;
        font-family: ${font.bold};
        margin-bottom: 20px;
    `,
    TeamdaysList: styled.ul`
        + div {
            margin-top: 20px;
        }
    `,
    TeamdaysListItem: styled.li`
        padding: 5px 0;
    `,
    PodResContainer: styled.div`
        margin: 20px 0;
    `,
    PodResHeader: styled.button`
        position: relative;
        cursor: pointer;
        padding-left: 15px;
        color: ${color.blue};
    `,
    PodResHeaderIcon: styled.div<{ active: boolean }>`
        position: absolute;
        top: -6px;
        left: -13px;
        transform: rotate(${props => props.active ? "90deg" : "0"});
        transition: 200ms ease-in;
    `,
    PodResHeaderText: styled.div`
        font-size: 18px;
        font-family: ${font.bold};
    `,
    PodResContentCell: styled.div` {
        & > a {
            display: inline-block;
            font-size: 16px;
            position: relative;
            padding-right: 25px;
            transition: 200ms ease-in;
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }

            & > i {
                position: absolute;
                top: 0;
                right: 0;
                transition: 200ms ease-in;
                color: ${color.black};

                @media (prefers-color-scheme: dark) {
                    color: ${color.white};
                }
            }

            &:hover {
                color: ${color.blueHover};

                & > i {
                    right: -5px;
                    color: ${color.blueHover};
                }
            }
        }
    `,
    Nav: styled.div<{ activeEl: number }>`
        border-bottom: 2px solid ${color.lightgrey};
        position: relative;
        margin-top: 20px;

        :before {
            content: "";
            position: absolute;
            bottom: 1px;
            left: ${props => props.activeEl === 1 ? "200px" : "0"};
            background-color: ${color.blue};
            height: 2px;
            width: 200px;
            transition: 200ms ease-in;
        }
    `,
    NavBtn: styled.button<{ isActive: boolean, isDisabled?: boolean }>`
        width: 200px;
        text-align: center;
        font-size: 18px;
        padding: 10px;
        pointer-events: ${props => props.isDisabled && "none"};
        color: ${props => props.isDisabled ? color.palegraydark : color.black};
        font-family: ${font.bold};
        cursor: ${props => (props.isDisabled || props.isActive) ? "auto" : "pointer"};

        
        @media (prefers-color-scheme: dark) {
            color: ${props => props.isDisabled ? color.darkgrey1 : color.white};
        }

        :hover,
        :focus {
            color: ${props => (props.isDisabled || props.isActive) ? color.black : color.blue};

            @media (prefers-color-scheme: dark) {
                color: ${props => (props.isDisabled || props.isActive) ? color.white : color.blue};
            }
        }
    `,
}