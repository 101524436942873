import React from "react";
import { getTimeString } from "Shared/Functions/DateMath";
import { BookingData, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { useGraphApi } from "Shared/Context/GraphAPI";
import { Icon } from "Shared/Components";
import { ReportStyle } from "./Style";
import useLang from "Shared/Context/LangContext";
import { TableRow } from "Shared/Components/Table/Style";

export const ReportGridRow = (props: {
    data?: BookingData;
    isHeader?: boolean;
}) => {
    const [user, setUser] = React.useState<string | null>(null);
    const graphApi = useGraphApi();
    const { curLang, curLangData } = useLang();
    const seatTypes = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;

    React.useEffect(() => {
        let isMounted = true;
        if (props.data?.userName) {
            graphApi
                .searchSpecificPerson(props.data.userName)
                .then(person => isMounted && setUser(person.displayName))
                .catch(() => isMounted && setUser(null))
        }
        return () => { isMounted = false }
    }, [graphApi, props.data])

    if (props.isHeader) return (
        <TableRow isHeader minWidth={"650px"} gridTemplateColumns={"repeat(5, 1fr) 80px"}>
            {curLangData && 
            <>
                <ReportStyle.GridRowColumn>{curLangData.text.Date}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{curLangData.text.Time}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{curLangData.text.DeskId}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{curLangData.text.Name}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{curLangData.text.DeskType}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{curLangData.text.CheckIn}</ReportStyle.GridRowColumn>
            </> }
        </TableRow>
    )

    if (props.data != null) {
        return (
            <TableRow minWidth={"650px"} gridTemplateColumns={"repeat(5, 1fr) 80px"}>
                <ReportStyle.GridRowColumn>
                    {props.data.date?.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}
                </ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{getTimeString(
                    props.data.date,
                    curLangData?.text.FullTime as string,
                    props.data.endDate,
                    curLang?.id === 1 ? curLangData?.text.Clock : undefined
                )}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn title={props.data.seat}>
                    {props.data.seat}
                </ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn title={user ?? curLangData?.text.Unknown}>{user ?? curLangData?.text.Unknown}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{seatTypes[props.data.deskGroupType ?? 0].text}</ReportStyle.GridRowColumn>
                <ReportStyle.GridRowColumn>{props.data.confirmed ? <div><Icon.Haken isBold /></div> : null}</ReportStyle.GridRowColumn>
            </TableRow>
        )
    }
    return null;
}