import styled, { keyframes } from "styled-components";
import { color } from "Style/Globals";

const load = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const SpinnerStyle = {
    Container: styled.div<{ position?: string, inset?: string }>`
        position: ${props => props.position ?? "relative"};
        inset: ${props => props.inset ?? "auto"};
        transform: ${props => {
            if (props.position != null && props.inset != null && props.inset === "50% auto auto 50%") return "translate(-50%, -50%)";
            return " translate(0, 0)";
        }};
    `,
    Element: styled.div<{ size: string, color?: string }>`
        margin: auto;
        font-size: ${props => props.size};
        position: relative;
        text-indent: -9999em;
        border-top: 2px solid rgba(255, 255, 255, 0.2);
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        border-left: 2px solid ${props => props.color ?? color.yellow};
        transform: translateZ(0);
        animation: ${load} 1.5s infinite linear;

        &,
        :after {
            border-radius: 50%;
            width: 10em;
            height: 10em;
        }
    `,
}