import { checkIfFullTimeBookingExists, checkIfUserHas3Bookings } from "Main/Floor/Shared";
import { ExistingBookings } from "Main/Floor/Shared/ExistingBookings";
import { MyBookingsInfo } from "Main/MyBookings/MyBookingsInfo";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRow, InfoBox, InfoBoxType } from "Shared/Components";
import { useApi } from "Shared/Context/API";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import useUser from "Shared/Context/UserContext";
import { compareDates } from "Shared/Functions/DateMath";
import { Desk } from "Shared/Functions/Desk";
import { ErrorMsg } from "Shared/Functions/ErrorMsgs";
import { BookingData, DeskState, FloorPage, Path } from "Shared/Globals";
import { curDeskState } from "../DeskState";
import { DefaultInfo } from "./DefaultInfo"
import { DeskStateRow } from "./DeskStateRow";

export const DeskInfoUser = (props: {
    deskid: string;
    selectedDate: Date;
    selectedToDate?: Date;
    page: FloorPage;
    highlightedDeskId?: string;
    onDismiss: () => void;
}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);

    const { curLangData } = useLang();
    const { deskGroupData } = useFloor();
    const { currentUser, profileData, setProfile } = useUser();
    const { myCurrentBookings, updateMyBookings } = useMyBookings();
    const { isMobile } = useLayout();

    let navigate = useNavigate();
    const { bookingid } = useParams();
    const api = useApi();

    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    React.useEffect(() => {
        setErrorMsg(undefined);
    }, [props.deskid]);

    const desk = React.useMemo(() => { return new Desk(props.deskid) }, [props.deskid]);

    const userchange = React.useCallback(() => {
        setLoading(true);
        api.bookSeat(desk, props.selectedDate, props.selectedToDate)
            .then((BookingData) => {
                setProfile({ lastFloor: desk.Floor.Id })
                setErrorMsg(undefined);
                updateMyBookings();
                navigate(Path.MyBookings + "/" + props.selectedDate?.toLocaleDateString("de-DE").replace(/\./g, "-") + "/" + BookingData.id);
            })
            .catch((err) => {
                let msg = curLangData?.error.SeatBookingDefault;
                if (err && err.messageId) msg = ErrorMsg(err.messageId, false, false, curLangData?.error) ?? msg;
                setErrorMsg(msg);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [api, desk, props.selectedDate, props.selectedToDate, setProfile, updateMyBookings, navigate, curLangData?.error])

    const onDismiss = React.useCallback(() => {
        const _onDismiss = props.onDismiss;
        _onDismiss();

        if (isMounted.current) {
            setErrorMsg(undefined);
        }
    }, [props.onDismiss]);


    const data = React.useMemo(() => {
        return deskGroupData.find((d) => d.name === props.deskid.slice(0, 10));
    }, [deskGroupData, props.deskid])

    const bookings = React.useMemo(() => {
        return data?.bookings?.filter((b) => b.seat === props.deskid)
    }, [data?.bookings, props.deskid])


    const curState = React.useMemo(() => {
        return curDeskState(
            props.deskid,
            props.page,
            { from: props.selectedDate, to: props.selectedToDate },
            { id: currentUser?.id as string, units: profileData?.orgUnitIds },
            props.highlightedDeskId,
            bookings,
            data?.units
        )
    }, [bookings, currentUser?.id, data?.units, profileData?.orgUnitIds, props.deskid, props.highlightedDeskId, props.page, props.selectedDate, props.selectedToDate])

    const hideButton = React.useMemo(() => {
        if (
            curState === DeskState.available &&
            errorMsg !== curLangData?.error.SeatAlreadyBooked &&
            checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedDate, props.selectedToDate, myCurrentBookings) === false &&
            checkIfUserHas3Bookings(props.selectedDate, myCurrentBookings) === false &&
            props.page !== FloorPage.myBooking
        ) return false;
        return true;
    }, [curState, errorMsg, curLangData?.error.SeatAlreadyBooked, curLangData?.text.FullTime, props.selectedDate, props.selectedToDate, props.page, myCurrentBookings]);

    const curBooking = React.useMemo(() => {
        if (props.page === FloorPage.myBooking && curState === DeskState.mine && myCurrentBookings && myCurrentBookings.length > 0 && bookingid) return myCurrentBookings.find((b) => b.id === bookingid);
        return null;
    }, [bookingid, curState, myCurrentBookings, props.page]);

    const hideBookings = React.useMemo(() => {
        return bookings && bookings.length === 1 && curState === DeskState.mine;
    }, [bookings, curState])

    return (
        <>
            <DefaultInfo
                page={props.page}
                deskId={props.deskid}
                selectedFrom={props.selectedDate}
                selectedTo={props.selectedToDate}
                deskState={curState}
                units={data?.units}
                seatType={data?.deskGroupType}
            />
            <DeskStateRow state={curState} />
            {!hideBookings &&
                <ExistingBookings
                    id={props.deskid}
                    selectedDate={props.selectedDate}
                />
            }
            {curState === DeskState.available && (checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedDate, props.selectedToDate, myCurrentBookings) === true || checkIfUserHas3Bookings(props.selectedDate, myCurrentBookings) === true) && props.page !== FloorPage.myBooking &&
                <InfoBox
                    type={InfoBoxType.default}
                    title={curLangData?.text.Info}
                    text={checkIfFullTimeBookingExists(curLangData?.text.FullTime as string, props.selectedDate, props.selectedToDate, myCurrentBookings) ? curLangData?.info.FullTime : curLangData?.info.MaxBookings}
                    marginTop={"20px"}
                />
            }

            {errorMsg &&
                <InfoBox
                    type={InfoBoxType.error}
                    text={errorMsg}
                    title={curLangData?.text.Attention}
                    marginTop={"20px"}
                />
            }

            {!hideButton &&
                <ButtonRow
                    id={""}
                    primaryText={curLangData?.text.Booking as string}
                    primaryType={"button"}
                    onPrimaryClick={userchange}
                    secondaryText={curLangData?.text.Cancel}
                    secondaryType={"button"}
                    onSecondaryClick={onDismiss}
                    marginTop={"20px"}
                    isLoading={isLoading}
                />
            }

            {curBooking != null && !isMobile(1050) &&
                <MyBookingsInfo
                    booking={curBooking}
                    allBookings={(myCurrentBookings as BookingData[]).filter((b) => compareDates(b.date, props.selectedDate))}
                />
            }
        </>
    )
}