import { PeopleFinderSettings } from "Main/PeopleFinder/Settings";
import { LangSettings } from "Main/Settings/LangSettings";
import React from "react";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import { PageStyle } from "./Style";

export const UserSettingsPage = () => {
    const { updatePageTitle, expandNav } = useLayout();
    const { curLangData } = useLang();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.Settings)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle]);


    return (
        <>
            <PageStyle.Heading expandNav={expandNav}>{curLangData?.text.Language}</PageStyle.Heading>
            <LangSettings />
            <PageStyle.Heading expandNav={expandNav}>{curLangData?.text.MyConsent}</PageStyle.Heading>
            <PeopleFinderSettings />
        </>
    )
}