import { color, font } from "Style/Globals";
import styled from "styled-components";

export const FilterButtonStyle = {
    Button: styled.button<{ isActive: boolean, isDisabled?: boolean, notSelectable?: boolean, hasError?: boolean }>`
        background-color: ${props => {
            if (props.notSelectable) return color.violet;
            if (props.hasError && props.isActive) return color.red;
            if (props.isActive && !props.hasError) return color.blue;
            return color.white;
        }};
        border-radius: 100px;
        position: relative;
        padding: 7px 10px;
        padding-left: ${props => props.isActive ? "30px" : "10px"};
        cursor: pointer;
        pointer-events:${props => (props.isDisabled || props.notSelectable) && "none"};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => {
            if (props.notSelectable) return color.violet;
            if (props.hasError && props.isActive) return color.red;
            if (props.isActive && !props.hasError) return color.blue;
            return color.black;
        }};
        }

        &,
        &:hover,
        &:focus {
            border-width: 2px;
            border-style: solid;
            border-color: ${props => {
            if (props.notSelectable) return color.violet;
            if (props.isDisabled) return color.palegraydark;
            if (props.hasError) return color.red;
            return color.blue;
        }};
        }

        & > span,
        & > i {
            color: ${props => {
            if (props.isDisabled) return color.palegraydark;
            if (!props.isActive && props.hasError) return color.red;
            if (props.isActive || props.notSelectable) return color.white;
            return color.blue;
        }};
        }

        & > span {
            font-family: ${font.bold};
            font-size: 
        }

        & > i {
            position: absolute;
            top: -1px;
            left: 0;
        }

        & + & {
            margin-left: 10px;
        }
    `,
}