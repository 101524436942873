import React from "react"
import { useApi } from "Shared/Context/API";
import { PieChart } from "Shared/Components"
import { compareDates, getDaysBetweenDates } from "Shared/Functions/DateMath";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { DeskState, FloorAbbr, FloorData, BookingData, availableFloors, DeskGroupData } from "Shared/Globals";
import useLang from "Shared/Context/LangContext";

const deskCountMinusDisabled = (deskcount: number, daycount: number, BookingData: BookingData[], fromDate: Date, toDate?: Date) => {
    // count all disabled desks
    const disabledDesksCount = BookingData.filter((d) => d.type === DeskState.disabled);

    // if to toDate exists and selected period is more than 1 day
    if (toDate && toDate > fromDate) {
        const disabledWithoutEnd: BookingData[] = [];
        const disabledWithEnd: BookingData[] = [];

        disabledDesksCount.forEach(d => {
            // checks if desk is disabled the whole selected period
            if (d.endDate == null || d.endDate > toDate) {
                disabledWithoutEnd.push(d);
            }
            // check if desk is not disabled the whole selected period
            if (d.endDate != null && d.endDate <= toDate) {
                disabledWithEnd.push(d);
            }
        });

        // remove disabled desks without end date from deskcount
        deskcount -= (disabledWithoutEnd.length * daycount);

        if (disabledWithEnd.length > 0) {
            disabledWithEnd.forEach((item) => {
                // calc days where desks are disabled and remove them from deskcount
                deskcount -= getDaysBetweenDates(fromDate, item.endDate);
            });
        }
    }
    else {
        deskcount -= disabledDesksCount.length;
    }

    return deskcount;
}

const calcDeskCount = (floorData: FloorData[], BookingData: BookingData[], fromDate: Date, toDate?: Date) => {
    let deskcount = 0;
    floorData.forEach(floor => {
        // count all desks in selected area
        if (floor.deskgroup?.length > 0) floor.deskgroup.forEach(group => {
            deskcount += group.desks.length;
        });
        // count all desks in pods
        if (floor.pods?.length > 0) floor.pods.forEach(pod => deskcount += pod.count)
    });

    let daycount = 1;
    if (toDate && toDate > fromDate) {
        // count all days in selected period
        daycount += getDaysBetweenDates(fromDate, toDate);
    }

    // multiply desks and days
    deskcount = deskcount * daycount;

    return deskCountMinusDisabled(
        deskcount, daycount, BookingData, fromDate, toDate,
    );
}

const getDeskGroupData = (floorData: FloorData[], sTypesOrUnits: number[], areSeatTypes?: boolean) => {
    const fData = floorData;
    floorData.forEach((floor, i) => {
        const dgData: DeskGroupData[] = [];
        floor.deskgroup.forEach((dg) => {
            if (areSeatTypes) {
                let dgType = dg.deskGroupType ?? 0;
                if (sTypesOrUnits.includes(dgType)) dgData.push(dg);
            } else if (dg.units != null && dg.units.some((u) => sTypesOrUnits.includes(u))) dgData.push(dg);
        })
        // pods will never show up/count with our filter since they can be booked by any unit
        if (!areSeatTypes || (areSeatTypes && !sTypesOrUnits.includes(0))) fData[i].pods = [];
        fData[i].deskgroup = dgData;
    })
}

const getFloorDataBasedOnSeatTypeOrUnits = (floorData: FloorData[], seatTypesOrUnits?: DropdownOption[], areSeatTypes?: boolean) => {
    if (seatTypesOrUnits != null && seatTypesOrUnits.length > 0) {
        const sTypesOrUnits = seatTypesOrUnits.map(sToU => parseInt(sToU.id));
        getDeskGroupData(floorData, sTypesOrUnits, areSeatTypes);
    }
    return floorData;
}

export const ReportChartBooked = (props: {
    reportData?: BookingData[];
    fromDate: Date,
    toDate?: Date,
    floors?: DropdownOption[],
    seatTypes?: DropdownOption[];
    units?: DropdownOption[];
    containerWidth?: string;
    containerHeight?: string;
}) => {
    const [pieData, setPieData] = React.useState<{} | any[]>();
    const api = useApi();
    const { curLangData } = useLang();

    const getBookedDesks = React.useCallback(() => {
        const bookedDesks: BookingData[] = [];
        props.reportData?.forEach((d) => {
            if (d.type === DeskState.booked) {
                // compare desks in array - every desk should be counted only once per day, except Pods
                if (d.seat.includes("POD")) {
                    bookedDesks.push(d);
                } else {
                    const findSeat = bookedDesks.filter((bd) => compareDates(bd.date, d.date) && bd.seat === d.seat);
                    if (findSeat.length === 0) bookedDesks.push(d);
                }

            }
        });
        return bookedDesks;
    }, [props.reportData]);

    React.useEffect(() => {
        const data: any[] = [["Status", "Prozent"]];

        let floorProms = availableFloors.map(f => api.getFloorData(f.id as FloorAbbr));
        if (props.floors != null && props.floors.length > 0) floorProms = props.floors.map(f => api.getFloorData(f.id as FloorAbbr));

        Promise
            .all(floorProms)
            .then((floorData) => {
                if (floorData == null || props.reportData == null) return;

                const seatData = getFloorDataBasedOnSeatTypeOrUnits(floorData, props.seatTypes, true);
                const unitData = getFloorDataBasedOnSeatTypeOrUnits(seatData, props.units);

                const deskcount = calcDeskCount(unitData, props.reportData, props.fromDate, props.toDate);
                if (deskcount === 0) return;

                const bookedDesks: BookingData[] = getBookedDesks();
                const bookedDesksCount = bookedDesks.length;
                const difference = deskcount - bookedDesksCount;

                data.push([curLangData?.text.PieFree, (difference / deskcount) * 100]);
                data.push([curLangData?.text.PieOcc, (bookedDesksCount / deskcount) * 100])

                setPieData(data);
            })
    }, [api, getBookedDesks, props.floors, props.fromDate, props.reportData, props.toDate, curLangData, props.seatTypes, props.units]);

    return (
        <PieChart data={pieData} showLegend containerHeight={props.containerHeight} containerWidth={props.containerWidth} />
    )
}
