import { PageStyle } from "Main/Page/Style"
import React from "react";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { FilterButton } from "Shared/Components/FilterButton/FilterButton";
import useLang, { AvailLangs } from "Shared/Context/LangContext"
import { langOptionsDE, langOptionsEN } from "Shared/Globals";
import { TestId } from "Test/TestId";


export const LangSettings = () => {
    const { curLang, updateLang } = useLang();

    const langOptions = React.useMemo(() => {
        if (curLang?.text === "EN") return langOptionsEN;
        return langOptionsDE;
    }, [curLang]);

    const [selectedLang, setSelectedLang] = React.useState<DropdownOption>(langOptions[0]);

    React.useEffect(() => {
        let isMounted = true;
        const lang = langOptions.find((a) => a.id === curLang?.browser);
        if (lang && isMounted) setSelectedLang(lang);
        return () => { isMounted = false }
    }, [curLang, langOptions])

    const onSelect = (lang: DropdownOption) => {
        const language = AvailLangs.find((a) => a.browser === lang.id)
        if (language) updateLang(language);
    }

    return (
        <PageStyle.LangContainer>
            <FilterButton
                id={TestId.langselect + langOptions[0].id}
                isActive={selectedLang === langOptions[0]}
                onClick={() => onSelect(langOptions[0])}
            >{langOptions[0].text}</FilterButton>
            <FilterButton
                id={TestId.langselect + langOptions[1].id}
                isActive={selectedLang === langOptions[1]}
                onClick={() => onSelect(langOptions[1])}
            >{langOptions[1].text}</FilterButton>
        </PageStyle.LangContainer>
    )
}