import React from "react";
import { compareDates } from "Shared/Functions/DateMath";
import useLayout from "Shared/Context/LayoutContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { Path } from "Shared/Globals";
import { Icon, InfoBox, InfoBoxType, Loading } from "Shared/Components";
import { color } from "Style/Globals";
import { BookingStyle } from "./Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const MyBookingsSection = () => {
    const { myCurrentBookings, myBookingsLoading, myBookingsError } = useMyBookings();
    const { expandNav } = useLayout();
    const { curLang, curLangData } = useLang();

    const bookedDates = React.useMemo(() => {
        const arr: Date[] = [];
        myCurrentBookings?.forEach((b) => {
            const checkArr = arr.filter((d) => compareDates(b.date, d));
            if (checkArr.length === 0) arr.push(b.date);
        });
        return arr.sort((a, b) => a.getTime() - b.getTime())
    }, [myCurrentBookings])

    return (
        <BookingStyle.Section expandNav={expandNav}>
            {myBookingsLoading && <Loading position={"absolute"} color={color.black} size={"5px"} />}
            {!myBookingsLoading && myBookingsError && <InfoBox type={InfoBoxType.error} title={curLangData?.text.Attention} text={myBookingsError} isAside />}
            {myCurrentBookings != null && myCurrentBookings.length > 0 && !myBookingsLoading &&
                <>
                    <BookingStyle.SectionHeader>
                        <BookingStyle.SectionHeaderIcon expandNav={expandNav}>
                            <Icon.KalenderHaken isBold />
                        </BookingStyle.SectionHeaderIcon>
                        <BookingStyle.SectionHeaderTitle expandNav={expandNav}>
                            {curLangData?.text.MyBookings}
                        </BookingStyle.SectionHeaderTitle>
                    </BookingStyle.SectionHeader>
                    <ul>
                        {bookedDates.map((date) => {
                            const d = date.toLocaleDateString('de-DE').replace(/\./g, "-");
                            return (
                                <BookingStyle.SectionItem
                                    key={date.toString()}
                                    expandNav={expandNav}>
                                    <BookingStyle.SectionLink
                                        to={Path.MyBookings + "/" + d}
                                        data-testid={`${TestId.nav}_item${d}`}
                                    >
                                        <span>
                                            <BookingStyle.SectionArrow>
                                                <Icon.PfeilRechts isBold />
                                            </BookingStyle.SectionArrow>
                                            {date.toLocaleDateString(curLang?.browser, { dateStyle: expandNav ? "long" : "short" })}
                                            <Icon.ArrowRechts size={"25px"} isBold />
                                        </span>
                                    </BookingStyle.SectionLink>
                                </BookingStyle.SectionItem>
                            )
                        })}
                    </ul>
                </>
            }
        </BookingStyle.Section>
    )
}