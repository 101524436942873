import styled from "styled-components";
import { scrollStyle } from 'Style/Globals'

export const MultiselectStyle = {
    Content: styled.div``,
    ScrollContent: styled.div`
        max-height: 140px;
        overflow-x: hidden;
        overflow-y: auto;
        ${scrollStyle}
    `,
    ButtonRow: styled.div`
        padding: 10px;
        display: flex;
        justify-content: space-between;

        & > button {
            width: 49%;
            display: block;
        }
    `,
    Item: styled.div<{ withChildren?: boolean }>`
        display: block;
        min-height: 30px;
        position: relative;
        padding: 3px 0;
        padding-left: ${props => props.withChildren ? "30px" : "10px"};
    `,
    ItemParentButton: styled.button<{ showChildren?: boolean }>`
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        height: 25px;
        overflow: hidden;
        cursor: pointer;

        & > i {
            margin-top: -5px;
            display: block; 
        }
    `,
}