import React from "react";
import { Icon } from "Shared/Components/Icon/Style";
import useLang from "Shared/Context/LangContext";
import { DropdownStyle } from "./Style";

export interface DropdownOption {
    id: string;
    text?: string;
    children?: DropdownOption[];
    content?: React.ReactNode;
}

interface DropdownProps {
    id: string;
    label?: string;
    options: DropdownOption[];
    defaultText: string;
    selectedOption?: DropdownOption;
    icon?: React.ReactNode;
    onSelect?: (option: DropdownOption) => void;
    onReset?: () => void;
    errorMsg?: string;
    error?: boolean;
    openStatic?: boolean;
    openFlyout?: boolean;
    width?: string;
    shrinkContent?: boolean;
    title?: string;
    isBlocking?: boolean;
    nopadding?: boolean;
    onSearch?: (value: string) => void;
    searchOnChange?: boolean;
    fontSize?: string;
    required?: boolean;
    handleOutsideClick?: () => void;
}

export const Dropdown = (props: React.PropsWithChildren<DropdownProps>) => {
    const [isExpanded, setExpanded] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const container = React.createRef<HTMLDivElement>();
    const { curLangData } = useLang();

    React.useEffect(() => {
        if (props.shrinkContent != null && props.shrinkContent !== !isExpanded) {
            setExpanded(!props.shrinkContent)
        }
    }, [props.shrinkContent, isExpanded])

    const handleOutsideClick = React.useCallback((e: any) => {
        if (isExpanded && container.current && !container.current.contains(e.target) && !props.isBlocking) {
            const _handleOutsideClick = props.handleOutsideClick;
            setExpanded(false);
            if (_handleOutsideClick) _handleOutsideClick();
        }
    }, [container, isExpanded, props.isBlocking, props.handleOutsideClick]);

    React.useEffect(() => {
        window.addEventListener("click", handleOutsideClick, true);
        return () => window.removeEventListener("click", handleOutsideClick);
    }, [handleOutsideClick])

    const renderOptions = React.useCallback(() => {
        const _onSelect = props.onSelect;
        return props.options.map((option, idx) => {
            const isText = !option.content;
            const text = option.text ?? option.id;
            const current = option.id === props.selectedOption?.id;
            if (current) return null;
            return (
                <DropdownStyle.ListItem key={option.id}>
                    {isText ?
                        <DropdownStyle.Link
                            href={"#"}
                            onClick={(e) => {
                                e.preventDefault();
                                if (_onSelect) _onSelect(option);
                                setExpanded(false);
                            }}
                            data-testid={`${props.id}_listItemLink${idx}`}
                        >
                            {text}
                        </DropdownStyle.Link>
                        :
                        option.content
                    }
                </DropdownStyle.ListItem>
            );
        })
    }, [props.options, props.onSelect, props.selectedOption?.id, props.id]);

    const renderIcon = React.useCallback(() => {
        const isDefaultIcon = !props.icon;

        return isDefaultIcon ?
            <Icon.PfeilUnten size={"22px"} isBold />
            :
            props.icon;
    }, [props.icon]);

    const renderHeaderText = React.useCallback(() => {
        return props.selectedOption ?
            <span>{props.selectedOption.text ?? props.selectedOption.id}</span>
            :
            <span>{props.defaultText}</span>
    }, [props.defaultText, props.selectedOption]);

    const onSearch = React.useCallback((e?: any, value?: string) => {
        if (e) e.preventDefault();
        const v = value ?? searchValue;
        const _onSearch = props.onSearch;
        if (_onSearch) _onSearch(v);
        setExpanded(true)
    }, [props.onSearch, searchValue])

    return (
        <DropdownStyle.Wrapper
            ref={container}
            size={props.width}
            title={props.title}
            nopadding={props.nopadding}
        >
            <DropdownStyle.Container>
                <DropdownStyle.Header>
                    {props.onSearch ?
                        <DropdownStyle.HeaderForm onSubmit={onSearch}>
                            <DropdownStyle.HeaderInput
                                isExpanded={isExpanded}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                    if (props.searchOnChange) {
                                        onSearch(undefined, e.target.value);
                                    }
                                }}
                                placeholder={props.defaultText}
                                fontSize={props.fontSize}
                                data-testid={`${props.id}_input`}
                                id={`${props.id}_input`}
                            />

                            <DropdownStyle.HeaderInputButton
                                onClick={onSearch}
                                data-testid={`${props.id}_buttonSearch`}
                            >
                                {props.icon ?? <Icon.Lupe size={"30px"} isBold />}
                            </DropdownStyle.HeaderInputButton>
                        </DropdownStyle.HeaderForm>
                        :
                        <DropdownStyle.HeaderButton
                            isExpanded={isExpanded}
                            isReset={props.onReset != null && props.selectedOption != null}
                            onClick={() => setExpanded(!isExpanded)}
                            fontSize={props.fontSize}
                            data-testid={`${props.id}_button`}
                            hasError={props.error}
                            type={"button"}
                        >
                            {renderHeaderText()}
                            <DropdownStyle.HeaderButtonIcon isExpanded={isExpanded}>
                                {renderIcon()}
                            </DropdownStyle.HeaderButtonIcon>
                        </DropdownStyle.HeaderButton>
                    }
                    {props.onReset != null && props.selectedOption != null &&
                        <DropdownStyle.HeaderReset
                            onClick={props.onReset}
                            title={curLangData?.text.Reset}
                            data-testid={`${props.id}_buttonReset`}
                            type={"button"}
                        >
                            <Icon.X />
                        </DropdownStyle.HeaderReset>
                    }
                    {props.label &&
                        <label
                            htmlFor={props.onSearch ? `${props.id}_input` : undefined}
                            onClick={!props.onSearch ? () => setExpanded(!isExpanded) : undefined}
                        >{props.label}{props.required && " *"}</label>
                    }
                </DropdownStyle.Header>
                {isExpanded &&
                    <DropdownStyle.Content openStatic={props.openStatic}>
                        {props.children ??
                            <DropdownStyle.List data-testid={`${props.id}_list`}>
                                {renderOptions()}
                            </DropdownStyle.List>
                        }
                    </DropdownStyle.Content>
                }
            </DropdownStyle.Container>
        </DropdownStyle.Wrapper>
    )
}