import { BookingData, FloorPage, PodData, PodStateName, TempPodResData } from "Shared/Globals";
import { compareDates } from "Shared/Functions/DateMath";

const TrafficLights = (podData: PodData, resData: TempPodResData) => {
    // red
    if (podData.reservations && podData.reservations.length > 0) return PodStateName.reserved;

    if (resData.teamsize) {
        const teamSize = resData.teamsize < 8 ? 7 : resData.teamsize;
        const capacity = podData.position.count;

        // green
        if (teamSize === capacity || (teamSize + 1 === capacity)) return PodStateName.recommended;
        // orange
        return PodStateName.notRecommended;
    }
    // orange
    return PodStateName.notRecommended;
}

const BookingPageStates = (podData: PodData, date?: Date, myBookings?: BookingData[], myPage?: boolean) => {
    // yellow
    if (myBookings && myBookings.find((b) => compareDates(b.date, date) && podData?.position?.id === b.seat) != null && !myPage) return PodStateName.mine;

    // red
    const res = (podData.reservations && podData.reservations.length > 0) ? podData.reservations[0].capacity : undefined;
    const podCapacity = res ?? podData?.position?.count;
    if (podData.bookings && podData.bookings.length >= podCapacity) return PodStateName.booked;

    // green
    return PodStateName.available;
}

export const PodState = (
    podData: PodData,
    page: FloorPage,
    resData?: TempPodResData,
    date?: Date,
    myBookings?: BookingData[],
    highlightedDeskId?: string,
) => {
    // Pod Management - show available pods
    if (resData && !date && page === FloorPage.podmgmtNew) return TrafficLights(podData, resData);

    // Pod Management - existing bookings
    if (page === FloorPage.podmgmt) {
        if (highlightedDeskId === podData?.position?.id) return PodStateName.mine;
        return PodStateName.disabled;
    }

    // Admin
    if (page === FloorPage.admin && podData.reservations?.some((r) => compareDates(r.day, date))) return PodStateName.reserved;

    // BookingPage
    if (page === FloorPage.booking) return BookingPageStates(podData, date, myBookings);

    // PeopleFinder
    if (page === FloorPage.colleague) {
        if (highlightedDeskId === podData?.position?.id) return PodStateName.colleague;
        return BookingPageStates(podData, date, myBookings);
    }

    // MyBookings
    if (page === FloorPage.myBooking) {
        if (highlightedDeskId === podData?.position?.id) return PodStateName.mine;
        else return BookingPageStates(podData, date, myBookings, true);
    }

    return PodStateName.available;
}