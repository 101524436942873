import { createGlobalStyle } from 'styled-components';

export const FontStyle = createGlobalStyle`
    @font-face {
        font-family: 'PostSans-Regular';
        src: local('Post Sans'), local('PostSans'), local('PostSans-Regular'),
        url("/fonts/postsans/postsans-regular.woff2") format('woff2'),
        url("/fonts/postsans/postsans-regular.woff") format('woff');
        font-style: normal;
    }

    @font-face {
        font-family: 'PostSans-Bold';
        src: local('Post Sans Bold'), local('PostSansBold'), local('PostSans-Bold'),
        url("/fonts/postsans/postsans-bold.woff2") format('woff2'),
        url("/fonts/postsans/postsans-bold.woff") format('woff');
        font-style: normal;
    }

    @font-face {
        font-family: 'PostIcons';
        src: url("/fonts/posticons/posticons.woff2") format('woff2'),
        url("/fonts/posticons/posticons.woff") format('woff');
        font-style: normal;
    }
`