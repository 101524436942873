
import React from "react";
import { Datepicker, DefaultButton, Multiselect } from "Shared/Components";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { availableFloors, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { PageStyle } from "Main/Page/Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";
import useResizeObserver from "use-resize-observer/polyfilled";
import useOrgUnits from "Shared/Context/OrgUnitContext";

interface ReportSettingsProps {
    selectedFloors?: DropdownOption[];
    setSelectedFloors: (floors: DropdownOption[]) => void;
    selectedDate?: Date;
    setSelectedDate: (date: Date) => void;
    selectedToDate?: Date;
    setSelectedToDate?: (date: Date) => void;
    selectedSeatTypes?: DropdownOption[];
    setSelectedSeatTypes: (types: DropdownOption[]) => void;
    selectedUnits?: DropdownOption[];
    setSelectedUnits: (units: DropdownOption[]) => void;
    fromText?: string;
    toText?: string;
    onSearch?: () => void;
    onReset?: () => void;
}

const fontSize = "18px";

export const ReportSettings = (props: ReportSettingsProps) => {
    const { curLang, curLangData } = useLang();
    const { level2Units, unitsLoading } = useOrgUnits();

    const settingsContainer = React.createRef<HTMLDivElement>();
    const { width = 0 } = useResizeObserver({ ref: settingsContainer });
    const smallerContainer = width < 600;
    const datePickerWidth = smallerContainer ? "100%" : "50%";

    const seatTypes = (curLang?.id != null && curLang.id === 1) ? seatTypeOptionsDE : seatTypeOptionsEN;

    return (
        <PageStyle.Settings ref={settingsContainer}>
            <PageStyle.SettingsRow>
                <Multiselect
                    options={availableFloors}
                    width={smallerContainer ? "100%" : "60%"}
                    selectedOptions={props.selectedFloors}
                    onSelect={props.setSelectedFloors}
                    defaultText={curLangData?.text.LocationSelect as string}
                    fontSize={fontSize}
                    id={TestId.reportFloors}
                    label={curLangData?.text.Location as string}
                />

                <Multiselect
                    options={seatTypes}
                    width={smallerContainer ? "100%" : "40%"}
                    selectedOptions={props.selectedSeatTypes ?? seatTypes}
                    onSelect={props.setSelectedSeatTypes}
                    defaultText={curLangData?.text.DeskTypeSelectOptional as string}
                    fontSize={fontSize}
                    id={TestId.reportDeskType}
                    label={curLangData?.text.DeskType as string}
                />
            </PageStyle.SettingsRow>
            <PageStyle.SettingsRow>
                <Datepicker
                    width={datePickerWidth}
                    selectedDate={props.selectedDate}
                    setSelectedDate={props.setSelectedDate}
                    disableWeekend
                    defaultText={props.fromText}
                    simplified
                    fontSize={fontSize}
                    id={TestId.reportStartDate}
                    label={`${curLangData?.text.Date as string} *`}
                />
                <Datepicker
                    width={datePickerWidth}
                    selectedDate={props.selectedToDate}
                    setSelectedDate={props.setSelectedToDate as (date: Date) => void}
                    minDate={props.selectedDate}
                    disableWeekend
                    defaultText={props.toText}
                    simplified
                    fontSize={fontSize}
                    id={TestId.reportEndDate}
                    label={curLangData?.text.Enddate as string}
                />
            </PageStyle.SettingsRow>
            {level2Units != null && !unitsLoading &&
                <PageStyle.SettingsRow>
                    <Multiselect
                        options={level2Units}
                        label={curLangData?.text.DeskGroupAuth}
                        selectedOptions={props.selectedUnits}
                        width={"100%"}
                        id={`${TestId.deskgroupinfo}_rowUnitSelect`}
                        onSelect={props.setSelectedUnits}
                        fontSize={fontSize}
                        defaultText={curLangData?.text.OrgUnitSelect as string}
                    />
                </PageStyle.SettingsRow>
            }
            {((props.selectedFloors && props.selectedFloors.length > 0) || (props.selectedUnits != null && props.selectedUnits.length > 0)) && props.selectedDate &&
                <PageStyle.SettingsButton>
                    {props.onReset &&
                        <DefaultButton
                            secondary
                            onClick={props.onReset}
                            data-testid={`${TestId.report}_buttonReset`}
                        >
                            {curLangData?.text.Reset}
                        </DefaultButton>
                    }
                    {props.onSearch &&
                        <DefaultButton
                            onClick={props.onSearch}
                            data-testid={`${TestId.report}_buttonConfirm`}
                        >
                            {curLangData?.text.ShowEvaluation}
                        </DefaultButton>
                    }
                </PageStyle.SettingsButton>
            }
        </PageStyle.Settings>
    )
}