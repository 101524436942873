export const encodeText = (text: string) => {
    text = encodeURI(text);
    text = text.replace(/&/g, "%26");
    text = text.replace(/#/g, "%23");
    text = text.replace(/\//g, "%2F");
    text = text.replace(/\?/g, "%3F");
    text = text.replace(/=/g, "%3D");
    text = text.replace(/!/g, "%21");
    text = text.replace(/\$/g, "%24");
    return text;
}

export const decodeText = (text: string) => {
    return decodeURI(text);
}