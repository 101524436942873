import styled from "styled-components";
import { color, font, placeholder } from "Style/Globals";

export const InputStyle = {
    Container: styled.div<{ noPadding?: boolean, width?: string }>`
        padding: ${props => props.noPadding ? "0" : "10px"};
        width: ${props => props.width ?? "auto"};
    `,
    Label: styled.label`
    
    `,
    Field: styled.input<{ textAlignment?: string, error?: boolean }>`
        width: 100%;
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: ${props => props.error ? color.red : color.black};
        padding: 5px 0;
        color: ${color.black};
        background-color: ${color.transparent};
        font-family: ${font.regular};
        font-size: 18px;
        text-align: ${props => props.textAlignment ?? "left"};

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none; 
            margin: 0;
        }

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            border-color: ${props => props.error ? color.red : color.white};
        }

        :focus {
            outline: none;
            border-color: ${props => {
            if (props.readOnly) return color.black;
            if (props.error) return color.red;
            return color.blue;
        }};

            @media (prefers-color-scheme: dark) {
                border-color: ${props => {
            if (props.readOnly) return color.white;
            if (props.error) return color.red;
            return color.blue;
        }};
            }
        }
        
        ${placeholder}
    `,
}