import useLayout from "Shared/Context/LayoutContext";
import { PeopleFinderSearch } from "Main/PeopleFinder/Search";
import React from "react";
import useLang from "Shared/Context/LangContext";

export const PeopleFinderPage = () => {
    const { updatePageTitle } = useLayout();
    const { curLangData } = useLang();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.Colleagues)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle]);


    return (
        <PeopleFinderSearch />
    )
}