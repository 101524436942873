export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date.getTime());
    result.setDate(result.getDate() + days);
    return result;
};

export const compareDates = (date1?: Date, date2?: Date): boolean => {
    if (!date1 && !date2) {
        return true;
    } else if (!date1 || !date2) {
        return false;
    } else {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }
};

export const getMonthEnd = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0, 0);
};

export const getMonthStart = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
};

export const getYearStart = (date: Date): Date => {
    return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
};

export const isInDateRangeArray = (date: Date, dateRange: Date[]): boolean => {
    for (const dateInRange of dateRange) {
        if (compareDates(date, dateInRange)) {
            return true;
        }
    }
    return false;
};

export const getDaysBetweenDates = (date1: Date, date2: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
};

export const getTimeString = (start: Date, str: string, end?: Date, hourStr?: string): string => {
    let timeString = str;

    const midnight = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
    );
    const compare = midnight.valueOf() === start.valueOf();

    if (!compare && end != null) {
        const [starthour, startmin] = start.toLocaleTimeString().split(":");
        const startTime = starthour + ":" + startmin;
        const [endhour, endmin] = end.toLocaleTimeString().split(":");
        const endTime = endhour + ":" + endmin;
        const text = hourStr ?? "";
        timeString = startTime + " - " + endTime + text;
    }
    return timeString;
};

export const isInTimeRange = (
    date: Date,
    startDate: Date,
    endDate: Date,
    str: string
) => {
    if (getTimeString(startDate, str, endDate) === str) return true;
    const [hour, minute] = date.toISOString().split("T")[1].split(":");
    const [starthour, startminute] = startDate.toISOString().split("T")[1].split(":");
    const [endhour, endminute] = endDate.toISOString().split("T")[1].split(":");
    const now = parseInt(hour.toString() + minute.toString());
    const start = parseInt(starthour.toString() + startminute.toString());
    const end = parseInt(endhour.toString() + endminute.toString());
    return now >= start && now <= end;
};

export const getDateRangeString = (start: Date, end: Date, lang: string): string => {
    let str = "";
    const startstr = start.toLocaleDateString(lang, { dateStyle: "short" });
    const enddate = new Date(end);
    const exactEnd = new Date(enddate.setDate(enddate.getDate() - 1));
    const today = new Date();

    const from = lang === "en-EN" ? "from " : "ab ";
    const since = lang === "en-EN" ? "since " : "seit ";

    if (end.getFullYear() === 2999) {
        str = start.getTime() > today.getTime() ? from : since;
        str += startstr;
    } else if (compareDates(start, exactEnd)) {
        str = startstr;
    } else
        str =
            startstr +
            " - " +
            exactEnd.toLocaleDateString(lang, { dateStyle: "short" });
    return str;
};

export const getQuarter = (date: Date) => {
    return Math.floor(date.getMonth() / 3) + 1;
}

export const checkIfLastMonthInQuarter = (date: Date) => {
    const month = date.getMonth() + 1;
    return month === 3 || month === 6 || month === 9 || month === 12;
}

export const checkIfDateIsInQuarter = (date: Date, quarter: number) => {
    return getQuarter(date) === quarter;
}

export const getFirstAndLastDayOfQuarter = (quarter: number, year: number) => {
    const from = new Date(year, (quarter - 1) * 3, 1);
    const to = new Date(year, from.getMonth() + 3, 0);
    return { from: from, to: to };
}