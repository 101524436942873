import styled from "styled-components";
import { breakpoint, color, font } from "Style/Globals";

export const ReportStyle = {
    Container: styled.div`
        position: relative;
        max-width: 730px;
        padding-top: 50px;
        padding-bottom: 60px;
        
        @media ${breakpoint.min801} {
            padding-top: 80px; 
        }
        
        @media ${breakpoint.min1300} {
            padding-top: 50px;
        }

        
    `,
    Left: styled.div`
        width: 100%;        
        
        @media ${breakpoint.min1300} {
            width: 60%;
        }

        @media ${breakpoint.min1500} {
            width: 700px;
        }
    `,
    Right: styled.div`
            display: block;
            width: 40%;
            padding-left: 50px;

        @media ${breakpoint.min1500} {
            width: calc(100% - 700px);
        }
    `,
    Grid: styled.div<{ printOnly?: boolean }>`
        display: ${props => props.printOnly ? "none" : "block"};

        @media print {
        display: ${props => props.printOnly ? "block" : "none"};
            padding: 30px 40px 20px;
        }
    `,
    GridHeadline: styled.div<{ printOnly?: boolean }>`
        display: ${props => props.printOnly ? "none" : "block"};
        font-size: 25px;
        color: ${color.black};
        font-family: ${font.bold};
        padding-bottom: 20px;

        & > span {
            display: inline-block;
            padding-top: 5px;
            font-size: 20px;
            float: right;
        }

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }

        @media print {
            display: ${props => props.printOnly ? "block" : "none"};
        }
    `,
    GridRowColumn: styled.div`
        position: relative;

        & > div {
            position: absolute;
            top: 3px;
            right: 0;
            width: 30px;
            text-align: center;

            @media ${breakpoint.min551} {
                width: 80px;
                top: -6px;
            }

            @media print {
                width: 80px;
                top: -6px;
            }
        }

        & > span {
            font-size: 15px;
        }
    `,
    Charts: styled.div`
        display: block;
        
        @media ${breakpoint.min801} {
            display: grid;
            gap: 10%;
            grid-template-columns: repeat(2, 45%);
        }

        
        @media ${breakpoint.min1300} {
            display: block
        }

        & > div + div {
            margin-top: 30px;
        
            @media ${breakpoint.min801} {
                margin-top: 0;
            }

            @media ${breakpoint.min1300} {
                margin-top: 30px;
            }
        }
    `,
    ReportRowButton: styled.div<{ printOnly?: boolean }>`
        text-align: right;
        padding:20px 0px 5px 5px;

        & > button + a {
            margin-left: 10px;
        }

        & > button {
            & > i {
                color: ${color.white};
                vertical-align: middle;
            }

            & > span {
                vertical-align: middle;
                display: inline-block;
                padding-left: 5px;
            }
        }

        & > a > button {
            & > i {
                color: ${color.white};
                vertical-align: middle;
            }

            & > span {
                vertical-align: middle;
                display: inline-block;
                padding-left: 5px;
            }
        }
    `,
}