import { Animation } from "./Style";
import { Icon } from "Shared/Components/Icon/Style";

export const ConfAnimation = (props: {
    size?: string;
    iconSize?: string;
    color?: string;
}) => {
    return (
        <Animation.Circle.Box size={props.size}>
            <Animation.Circle.WrapperRight>
                <Animation.Circle.Right color={props.color} />
            </Animation.Circle.WrapperRight>
            <Animation.Circle.WrapperLeft>
                <Animation.Circle.Left color={props.color} />
            </Animation.Circle.WrapperLeft>
            <Animation.Confirm.Icon color={props.color} >
                <Icon.Haken size={props.iconSize ?? "50px"} />
            </Animation.Confirm.Icon>
        </Animation.Circle.Box>
    )
}