import React from "react";
import { getQuarter } from "Shared/Functions/DateMath";
import { TempPodResData } from "Shared/Globals";

export interface PodReservationContextState {
    resData: TempPodResData;
    updateResData: (d: TempPodResData) => void;
    clearResData: () => void;
}

export const initResData: TempPodResData = {
    teamname: "",
    teamsize: undefined,
    quarter: getQuarter(new Date()),
}


export const PodReservationContext = React.createContext<PodReservationContextState>({
    resData: initResData,
    updateResData: () => {/* init */ },
    clearResData: () => {/* init */ },
})

export const PodReservationProvider = (props: { children: JSX.Element }) => {
    const [resData, setResData] = React.useState<TempPodResData>(initResData);

    const updateResData = React.useCallback((d: TempPodResData) => {
        setResData(d)
    }, [])

    const clearResData = React.useCallback(() => {
        updateResData(initResData);
    }, [updateResData]);

    const providerValue = React.useMemo(() => (
        { resData, updateResData, clearResData }
    ),
        [resData, updateResData, clearResData]
    );

    return (
        <PodReservationContext.Provider value={providerValue}>
            {props.children}
        </PodReservationContext.Provider>
    )
}

const usePodReservation = () => React.useContext(PodReservationContext);
export default usePodReservation;