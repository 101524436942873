import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint, color, defaultTransition, font } from "Style/Globals";


export const PageStyle = {
    Container: styled.div<{ expandNav: boolean }>`
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        padding-top: 85px;
        ${defaultTransition}

        @media ${breakpoint.min701} {
            padding-left: ${props => props.expandNav ? "190px" : "85px"};
        }

        @media ${breakpoint.min1050} {
            padding-left: ${props => props.expandNav ? "220px" : "115px"};
            padding-top: 115px;
        }
    `,

    ContainerInner: styled.main<{ expandNav: boolean, expandSidePanel: boolean }>`
        display: block;
        background-color: ${color.lightmodeBgContent};
        width: 100%;
        min-height: 100%;
        padding: 10px;
        position: relative;
        ${defaultTransition}

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgContent};
        }

        @media ${breakpoint.min701} {
            padding: 50px;
            padding-left: ${props => props.expandNav ? "150px" : "115px"};
            padding-bottom: 70px;
            padding-right: 50px;
            background-color: ${color.lightmodeBgContent};

            @media (prefers-color-scheme: dark) {
                background-color: ${color.darkmodeBgContent};
            }
        }

        @media ${breakpoint.min1050} {
            padding-right: ${props => props.expandSidePanel ? "440px" : "50px"};
        }

        @media ${breakpoint.min1300} {
            padding-right: ${props => props.expandSidePanel ? "540px" : "50px"};
        }
    `,
    Header: styled.header`
        display: block;
    `,
    Title: styled.h2`
        font-size: 40px;
        display: inline-block;
        border-top: 5px solid ${color.black};
        padding-top: 5px;
        font-family: ${font.bold};

        @media (prefers-color-scheme: dark) {
            border-color: ${color.white};
        }
    `,
    Heading: styled.h3<{ expandNav: boolean }>`
        font-size: 18px;
        background-color: ${color.SubMenuLightmode};
        padding: 5px;
        font-family: ${font.bold};
        margin-top: 10px;
        ${defaultTransition}

        @media ${breakpoint.min701} {
            padding-left: ${props => props.expandNav ? "150px" : "115px"};
            padding-right: 45px;
            margin-left: ${props => props.expandNav ? "-150px" : "-115px"};
            margin-top: 40px;
            margin-right: -50px;
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${color.SubMenuDarkmode};
        }
    `,
    Settings: styled.div`
        padding: 5px 0 0;
        max-width: 730px;
        margin: 0 -5px;

        @media ${breakpoint.min701} {
            padding: 33px 0 0;
        }
    `,
    SettingsRow: styled.div`
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        align-items: stretch;
    `,
    SettingsButton: styled.div`
        text-align: right;
        padding: 10px 5px 0 0;

        & > button + button {
            margin-left: 10px;
        }
    `,
    SettingsCheckbox: styled.div<{ width?: string }>`
        width: ${props => props.width ?? "auto"};
        padding-top: 17px;
        padding-left: 5px;
    `,
    SettingsTime: styled.div<{ width?: string }>`
        width: ${props => props.width ?? "auto"};
        display: flex;
        flex-wrap: wrap;
    `,
    Content: styled.div`
        display: block;
    `,
    Flex: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,
    Text: styled.p`
        font-size: 20px;
        font-family: ${font.regular};
        line-height: 1.5;

        @media ${breakpoint.min701} {   
            padding-top: 40px;
        }

        & > a {
            color: ${color.blue} !important;
            :hover {
                text-decoration: underline;
            }
        }
    `,
    Link: styled(Link)`
        display: block;
        color: ${color.blue} !important;
        font-size: 20px;
        font-family: ${font.regular};
        line-height: 1.5;

        :hover {
            text-decoration: underline;
        }
    `,
    FeedbackLink: styled.a`
        position: fixed;
        bottom: 20px;
        left: 20px;
        display: none;
        width: 50px;
        height: 50px;
        overflow: hidden;
        padding-left: 55px;
        padding-top: 6px;
        transition: 200ms ease-in;

        & > i {
            position: absolute;
            top: 0;
            left: 0;
        }

        & > span {
            display: block;
            text-transform: uppercase;
        }

        @media ${breakpoint.min701} {
            display: block;
        }

        :hover,
        :focus {
            color: ${color.blue} !important;
            @media ${breakpoint.min701} {
                width: 150px
            }
        }

        @media ${breakpoint.min1050} {
            left: 45px;
        }
    `,
    Bottom: styled.div<{ isMobile?: boolean, expandNav?: boolean }>`
        position: ${props => props.isMobile && props.expandNav ? "static" : "absolute"};
        display: ${props => ((props.isMobile && props.expandNav) || !props.isMobile) ? "block" : "none"};
        bottom: 10px;
        right: 40px;
        text-align: right;
        padding: 10px;
        padding-top: 0;
    `,
    BottomLink: styled.a<{ isMobile?: boolean }>`
        color: ${props => props.isMobile ? color.black : color.blue};
        text-decoration: underline;
        font-size: 12px;

        :focus,
        :active,
        :visited {
            color: ${props => props.isMobile ? color.black : color.blue};
        }

        :hover {
            color: ${props => props.isMobile ? color.black : color.blueHover};
        }

        & + span {
            display: inline-block;
            padding: 0 5px;
            font-size: 12px;
        }
    `,
    LangContainer: styled.div`
        padding: 10px 0 0;

        @media ${breakpoint.min701} {
            padding: 20px 0 0;
        }
    `
}