import React from "react";
import { Icon } from "Shared/Components";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import useTeamdays from "Shared/Context/TeamdaysContext";
import { compareDates } from "Shared/Functions/DateMath";
import { PodResData } from "Shared/Globals";
import { ExistingItemsStyle } from "./Style";
import { ExistingReservationsItem } from "./ExistingReservationsItem";
import { Table } from "Shared/Components/Table/Style";
import { TestId } from "Test/TestId";

export const ExistingReservations = (props: {
    podId: string;
    showDetails: boolean;
}) => {
    const [showDetails, setDetailsVisible] = React.useState(props.showDetails);
    const { curLangData } = useLang();
    const { podData } = useFloor();
    const { teamdays } = useTeamdays();

    const data = React.useMemo(() => {
        return podData.find((p) => p.position.id === props.podId);
    }, [podData, props.podId])

    const reservations = React.useMemo(() => {
        const res: PodResData[] = [];
        teamdays.selected.forEach((d) => {
            data?.reservations?.forEach((r) => {
                if (compareDates(r.day, d.date)) res.push(r);
            })
        })
        return res;
    }, [data?.reservations, teamdays.selected]);

    if (reservations && reservations.length > 0) {
        return (
            <ExistingItemsStyle.Container data-testid={TestId.existingReservations}>
                <ExistingItemsStyle.Header onClick={() => setDetailsVisible(!showDetails)} data-testid={`${TestId.existingReservations}_header`}>
                    <ExistingItemsStyle.HeaderIcon active={showDetails}>
                        <Icon.PfeilRechts isBold size={"35px"} />
                    </ExistingItemsStyle.HeaderIcon>
                    <ExistingItemsStyle.HeaderText>
                        {curLangData?.text.Reservations}
                    </ExistingItemsStyle.HeaderText>
                </ExistingItemsStyle.Header>
                {showDetails &&
                    <Table style={{ marginTop: "10px" }} data-testid={`${TestId.existingReservations}_table`}>
                        <ExistingReservationsItem header key={"header"} />
                        {reservations.map((res) => {
                            return <ExistingReservationsItem key={res.id} data={res} />
                        })}
                    </Table>
                }
            </ExistingItemsStyle.Container>
        )
    }

    return null;
}