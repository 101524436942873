import { Desk } from "Shared/Functions/Desk";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";

export enum Path {
    Start = "/",
    Booking = "/booking",
    AdminView = "/admin",
    AdminBlock = "block",
    AdminSection = "sections",
    AdminPods = "pods",
    MyBookings = "/my-bookings",
    Report = "/report",
    Confirm = "/check-in",
    PeopleFinder = "/people-finder",
    PeopleFinderSearch = "search",
    PeopleFinderSettings = "settings",
    Settings = "/settings"
}

export enum DeskState {
    available, booked, disabled, mine, colleague, blocked
}

export enum PodStateName {
    recommended = "recommended",
    notRecommended = "notRecommended",
    reserved = "reserverd",
    booked = "booked",
    available = "available",
    disabled = "disabled",
    mine = "mine",
    colleague = "colleague"
}

export enum DeskArea {
    GR = "Grete-Jost",
    ER = "Erdberg",
    MI = "Mitte",
    RO = "Rochus",
    RA = "Rasumofsky"
}

export enum FloorAbbr {
    ALL = "ALL",
    PAR1 = "PAR1",
    PAR2 = "PAR2",
    PAR3 = "PAR3",
    PAR4 = "PAR4",
    PAR5 = "PAR5",
    PAR6 = "PAR6",
    PAR7 = "PAR7",
    UZD2 = "UZD2",
}

export enum FloorName {
    ALL = "Alle Stockwerke",
    PAR1 = "UZ Post am Rochus - 1.OG",
    PAR2 = "UZ Post am Rochus - 2.OG",
    PAR3 = "UZ Post am Rochus - 3.OG",
    PAR4 = "UZ Post am Rochus - 4.OG",
    PAR5 = "UZ Post am Rochus - 5.OG",
    PAR6 = "UZ Post am Rochus - 6.OG",
    PAR7 = "UZ Post am Rochus - 7.OG",
    UZD2 = "UZ Donaustadt - 2.OG",
}

export enum FixedElementType {
    BigKitchen = "Lounge",
    SmallKitchen = "Teeküche"
}

export interface Floor {
    Id: string;
    Name: string;
}

export const Floors: { [id: string]: Floor; } = {
    "PAR1": {
        Id: "PAR1",
        Name: "UZ Post am Rochus - 1.OG"
    },
    "PAR2": {
        Id: "PAR2",
        Name: "UZ Post am Rochus - 2.OG"
    },
    "PAR3": {
        Id: "PAR3",
        Name: "UZ Post am Rochus - 3.OG"
    },
    "PAR4": {
        Id: "PAR4",
        Name: "UZ Post am Rochus - 4.OG"
    },
    "PAR5": {
        Id: "PAR5",
        Name: "UZ Post am Rochus - 5.OG"
    },
    "PAR6": {
        Id: "PAR6",
        Name: "UZ Post am Rochus - 6.OG"
    },
    "PAR7": {
        Id: "PAR7",
        Name: "UZ Post am Rochus - 7.OG"
    },
    "UZD2": {
        Id: "UZD2",
        Name: "UZ Donaustadt - 2.OG"
    }
};

export interface UserDeskData {
    date: Date;
    desk: Desk;
}

export interface BookingData {
    id?: string;
    seat: string;
    type: DeskState;
    userName?: string;
    date: Date;
    endDate: Date;
    confirmed?: boolean;
    deskGroupType?: number;
    units?: [];
}

export interface FloorBookingData {
    deskgroupBookings: Map<string, BookingData[]>,
    podBookings: Map<string, BookingData[]>
}

export interface PodResData {
    id?: string;
    podId: string;
    text: string;
    day: Date;
    capacity: number;
    reservedBy?: string;
}

export interface ExtendedPodResData {
    teamname: string;
    quarter?: number;
    year?: number;
    podId?: string;
    children?: string[];
}

export interface TempPodResData {
    teamname: string;
    teamsize?: number;
    quarter: number;
    daysPerWeek?: number;
    weekDays?: number[];
    weekInterval?: number;
    startDate?: Date;
    reservedBy?: string;
}

export interface UserProfileData {
    publicBookings: boolean;
    lastFloor: string;
    orgUnitIds: number[];
    selectedLang: string;
}

export enum LoadState {
    Loading,
    Ready,
    Error,
}

export interface UserData {
    name: string;
    id: string;
}

export interface ReportData {
    desk: string;
    date: Date;
    endDate?: Date;
    user?: UserData;
    deskState: DeskState;
}

export interface FurnishingData {
    dockingStation: boolean;
    monitors: number;
    text: string;
}

export interface DeskData {
    id: string;
    kimid: string;
    furnishing: FurnishingData;
    details?: Desk;
}

export interface DeskGroupData {
    name: string;
    position: string[];
    units?: number[];
    desks: DeskData[];
    bookings?: BookingData[];
    deskGroupType?: number;
}

export interface PodPosData {
    id: string;
    name: string;
    count: number;
    position: string[];
}

export interface FloorData {
    deskgroup: DeskGroupData[];
    fixed: [];
    pods: PodPosData[];
}

export interface PodData {
    position: PodPosData,
    reservations?: PodResData[],
    bookings?: BookingData[],
}

export enum FloorPage {
    podmgmt = "podmgmt",
    podmgmtNew = "podmgmtNew",
    admin = "admin",
    adminUnit = "adminUnit",
    colleague = "colleague",
    booking = "booking",
    myBooking = "myBooking",
}

export const availableFloors: DropdownOption[] = [
    { id: FloorAbbr.PAR1, text: FloorName.PAR1 },
    { id: FloorAbbr.PAR2, text: FloorName.PAR2 },
    { id: FloorAbbr.PAR3, text: FloorName.PAR3 },
    { id: FloorAbbr.PAR4, text: FloorName.PAR4 },
    { id: FloorAbbr.PAR5, text: FloorName.PAR5 },
    { id: FloorAbbr.PAR6, text: FloorName.PAR6 },
    { id: FloorAbbr.PAR7, text: FloorName.PAR7 },
    // { id: FloorAbbr.UZD2, text: FloorName.UZD2 },
];

export const availableHoursDE: DropdownOption[] = [
    { id: "1", text: "eine Stunde" },
    { id: "2", text: "zwei Stunden" },
    { id: "3", text: "drei Stunden" },
    { id: "4", text: "vier Stunden" },
    { id: "5", text: "fünf Stunden" },
    { id: "6", text: "sechs Stunden" }
]

export const availableHoursEN: DropdownOption[] = [
    { id: "1", text: "one hour" },
    { id: "2", text: "two hours" },
    { id: "3", text: "three hours" },
    { id: "4", text: "four hours" },
    { id: "5", text: "five hours" },
    { id: "6", text: "six hours" }
]

export const langOptionsDE: DropdownOption[] = [
    { id: "de-DE", text: "Deutsch" },
    { id: "en-EN", text: "Englisch" },
]

export const langOptionsEN: DropdownOption[] = [
    { id: "de-DE", text: "German" },
    { id: "en-EN", text: "English" },
]

export const weekIntervalOptionsDE: DropdownOption[] = [
    { id: "1", text: "jede Woche" },
    { id: "2", text: "jede zweite Woche" },
    { id: "3", text: "jede dritte Woche" },
    { id: "4", text: "jede vierte Woche" },
]

export const weekIntervalOptionsEN: DropdownOption[] = [
    { id: "1", text: "every week" },
    { id: "2", text: "every second week" },
    { id: "3", text: "every third week" },
    { id: "4", text: "every fourth week" },
]

export const seatTypeOptionsDE: DropdownOption[] = [
    { id: "0", text: "Normal" },
    { id: "1", text: "Bibliothek" },
    { id: "2", text: "Flexi" }
]

export const seatTypeOptionsEN: DropdownOption[] = [
    { id: "0", text: "Default" },
    { id: "1", text: "Library" },
    { id: "2", text: "Flexi" }
]