import { PodStateName } from "Shared/Globals";
import { color } from "Style/Globals";
import styled, { css } from "styled-components";

const defaultPodBg = (state: PodStateName, isActive?: boolean) => css`
   background-color: ${() => {
        if (state === PodStateName.recommended || state === PodStateName.available) return isActive ? color.greenActive : color.green;
        if (state === PodStateName.notRecommended) return isActive ? color.orangActive : color.orange;
        if (state === PodStateName.booked || state === PodStateName.reserved) return isActive ? color.redActive : color.red;
        if (state === PodStateName.colleague) return isActive ? color.blueActive : color.blue;
        if (state === PodStateName.mine) return isActive ? color.yellowActive : color.yellow;
        return color.black;
    }};

    @media (prefers-color-scheme: dark) {
          background-color: ${state === PodStateName.disabled && color.white};
    }
`;

const hoverPodBg = (state: PodStateName, isActive?: boolean) => css`
        background-color: ${() => {
        if (state === PodStateName.recommended || state === PodStateName.available) return isActive ? color.greenActive : color.greenHover;
        if (state === PodStateName.notRecommended) return isActive ? color.orangActive : color.orangeHover;
        if (state === PodStateName.booked || state === PodStateName.reserved) return isActive ? color.redActive : color.redHover;
        if (state === PodStateName.colleague) return isActive ? color.blueActive : color.blueHover;
        if (state === PodStateName.mine) return isActive ? color.yellowActive : color.yellowHover;
        return color.black;
    }};

    @media (prefers-color-scheme: dark) {
        background-color: ${state === PodStateName.disabled && color.white};
    }
`;

export const PodStyle = styled.div<{ position: IPodElement, state: PodStateName, isActive?: boolean }>`
        position: absolute;
        top: ${props => props.position.top};
        left: ${props => props.position.left};
        transform: rotate(${props => props.position.rotation});
        width: ${props => props.position.width};
        height: ${props => props.position.height};
        cursor: pointer;
        transition: 200ms ease-in;
        opacity: ${props => props.isActive ? "0.8" : "0.4"};

        :before {
            content: "";
            position: absolute;
            top:  ${props => props.position.squareTop};
            left:  ${props => props.position.squareLeft};
            width: ${props => props.position.squareWidth};
            height: ${props => props.position.squareHeight};
            transition: 200ms ease-in;
            ${props => defaultPodBg(props.state, props.isActive)};
        }

        :after {
            display: ${props => props.position.square2Top ? "block" : "none"};
            content: "";
            position: absolute;
            top:  ${props => props.position.square2Top};
            left:  ${props => props.position.square2Left};
            width: ${props => props.position.square2Width};
            height: ${props => props.position.square2Height};
            transition: 200ms ease-in;
            ${props => defaultPodBg(props.state, props.isActive)};
        }

        :hover,
        :focus {
            opacity: ${props => props.isActive ? "0.8" : "0.6"};

            :before,
            :after {
                ${props => hoverPodBg(props.state, props.isActive)};
            }
        }
    
        @media (prefers-color-scheme: dark) {
            filter: invert(1);
        }
    `;