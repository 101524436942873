import { Icon } from "Shared/Components/Icon/Style";
import { PagerStyle } from "./Style"

export const Pager = (props: {
    showPager?: boolean;
    totalPages: number;
    currentPage: number;
    pagingSize: number;
    onPageChange: (page: number) => void;
    id?: string;
}) => {

    if (!props.showPager) return null;

    const totalPages = props.totalPages;
    const isLastPage = props.currentPage === totalPages && totalPages > 0;
    const isFirstPage = props.currentPage === 1 && totalPages > 0;

    const pages = [];

    for (let i = 0, j = props.currentPage; i < totalPages * 2 && pages.length < props.pagingSize; i++) {

        j = j + Math.pow(-1, i) * i;
        if (j > 0 && j <= totalPages) {
            pages.push(j);
        }
    }
    pages.sort((a, b) => a - b);

    return (
        <PagerStyle.Container>
            <PagerStyle.Inner>
                <PagerStyle.Button
                    data-testid={`${props.id}_PrevBtn`}
                    isDisabled={isFirstPage}
                    onClick={!isFirstPage ? () => props.onPageChange(props.currentPage - 1) : undefined}
                >
                    <Icon.PfeilLinks isBold />
                </PagerStyle.Button>
                {pages.map((page) => {
                    const isCurrent = page === props.currentPage;
                    return (
                        <PagerStyle.Button data-testid={`${props.id}_BtnNum_${page}`}
                            key={page}
                            isCurrent={isCurrent}
                            onClick={() => props.onPageChange(page)}>
                            <span>{page}</span>
                        </PagerStyle.Button>
                    )
                })}
                <PagerStyle.Button
                    data-testid={`${props.id}_NextBtn`}
                    isDisabled={isLastPage}
                    onClick={!isLastPage ? () => props.onPageChange(props.currentPage + 1) : undefined}
                >
                    <Icon.PfeilRechts isBold />
                </PagerStyle.Button>
            </PagerStyle.Inner>
        </PagerStyle.Container>
    )
}