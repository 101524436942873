import React from "react";
import { Icon } from "Shared/Components";
import { Table } from "Shared/Components/Table/Style";
import useFloor from "Shared/Context/FloorContext";
import { useGraphApi } from "Shared/Context/GraphAPI";
import useLang from "Shared/Context/LangContext";
import { compareDates } from "Shared/Functions/DateMath";
import { DeskState } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { ExistingBookingsItem } from "./ExistingBookingsItem";
import { ExistingItemsStyle } from "./Style";

export const ExistingBookings = (props: {
    id: string;
    expand?: boolean;
    selectedDate: Date;
}) => {
    const [isExpanded, setExpanded] = React.useState(props.expand ?? false);
    const [unknownCount, setUnknownCount] = React.useState<number>(0);
    const { curLangData } = useLang();
    const { podData, deskGroupData } = useFloor();
    const graphApi = useGraphApi();

    const isPod = props.id.toLowerCase().includes("pod");

    const data = React.useMemo(() => {
        if (isPod) {
            return podData.find((p) => p.position.id === props.id);
        }
        else return deskGroupData.find((d) => props.id.includes(d.name));
    }, [deskGroupData, isPod, podData, props.id]);

    const bookings = React.useMemo(() => {
        return data?.bookings?.filter((b) => b.seat === props.id && compareDates(props.selectedDate, b.date) && b.type !== DeskState.disabled && b.type !== DeskState.blocked)
    }, [data?.bookings, props.id, props.selectedDate])

    React.useEffect(() => {
        if (bookings && bookings.length > 0 && isPod) {
            const proms = bookings.map((b) => graphApi.searchSpecificPerson(b.userName as string))
            Promise
                .all(proms)
                .then((res) => {
                    if (res && res.length > 0) {
                        let count = 0;
                        res.forEach((r) => {
                            if (!r.displayName) count++;
                        })
                        setUnknownCount(count);
                    }
                })
        }
    }, [bookings, graphApi, isPod]);

    if (bookings && bookings.length > 0) return (
        <ExistingItemsStyle.Container data-testid={TestId.existingBookings}>
            <ExistingItemsStyle.Header onClick={() => setExpanded(!isExpanded)} data-testid={`${TestId.existingBookings}_header`}>
                <ExistingItemsStyle.HeaderIcon active={isExpanded}>
                    <Icon.PfeilRechts isBold size={"35px"} />
                </ExistingItemsStyle.HeaderIcon>
                <ExistingItemsStyle.HeaderText>
                    {isExpanded ? curLangData?.text.BookingsHide : curLangData?.text.BookingsShow}
                </ExistingItemsStyle.HeaderText>
            </ExistingItemsStyle.Header>
            {isExpanded &&
                <Table style={{ marginTop: "10px" }} data-testid={`${TestId.existingBookings}_table`}>
                    <ExistingBookingsItem header key={"header"} />
                    {bookings.map((b) => <ExistingBookingsItem data={b} key={b.id} unknownCount={isPod ? unknownCount : undefined} />)}
                    {isPod && <ExistingBookingsItem unknownCount={unknownCount} key={"unknown"} />}
                </Table>
            }
        </ExistingItemsStyle.Container>
    )
    return null;
}