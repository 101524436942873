import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useLayout from "Shared/Context/LayoutContext";
import useUser from "Shared/Context/UserContext";
import { Path } from "Shared/Globals";
import { AdminPage } from "./AdminPage";
import { BookingPage } from "./BookingPage";
import { ConfirmPage } from "./ConfirmPage";
import { DefaultPage } from "./DefaultPage";
import { MyBookingsPage } from "./MyBookingsPage";
import { PeopleFinderPage } from "./PeopleFinderPage";
import { ReportPage } from "./ReportPage";
import { UserSettingsPage } from "./UserSettingsPage";
import { PageStyle } from "./Style";

export const RoutingPage = () => {
    const { expandNav, updateNav, isMobile, expandSidePanel } = useLayout();
    const { currentUser, isAdmin, isPodManager } = useUser();

    React.useEffect(() => {
        let isMounted = true
        const isExpanded = (!isMobile(1051) && !expandSidePanel) ? true : false
        if (isMounted) updateNav(isExpanded);
        return () => { isMounted = false }
    }, [isMobile, updateNav, expandSidePanel]);

    return (
        <PageStyle.Container expandNav={expandNav}>
            <DefaultPage>
                {currentUser &&
                    <Routes>
                        <Route path={Path.Start} element={<Navigate to={Path.Booking} />} />
                        <Route path={Path.Booking} element={<BookingPage />} />
                        <Route path={Path.PeopleFinder} element={<PeopleFinderPage />} />
                        <Route path={Path.AdminView + "/*"} element={(isAdmin || isPodManager) ? <AdminPage /> : <Navigate to={Path.Booking} />} />
                        <Route path={Path.Report} element={isAdmin ? <ReportPage /> : <Navigate to={Path.Booking} />} />
                        <Route path={Path.MyBookings} element={<MyBookingsPage />}>
                            <Route path={":bookingdate"} element={<MyBookingsPage />}>
                                <Route path={":bookingid"} element={<MyBookingsPage />} />
                            </Route>
                        </Route>
                        <Route path={Path.Confirm} element={<ConfirmPage />}>
                            <Route path={":seat"} element={<ConfirmPage />} />
                        </Route>
                        <Route path={Path.Settings} element={<UserSettingsPage />} />
                        <Route path={"*"} element={<Navigate to={Path.Booking} />} />
                    </Routes>
                }
            </DefaultPage>
        </PageStyle.Container>
    )
}