import useMyBookings from "Shared/Context/MyBookingsContext";
import React from "react";
import { compareDates, getTimeString } from "Shared/Functions/DateMath";
import { Path, BookingData } from "Shared/Globals";
import { Icon } from "Shared/Components";
import { BookingStyle } from "./Style";
import useLang from "Shared/Context/LangContext";

interface MenuItem {
    item: BookingData;
    children: BookingData[];
}

export const MyBookingsOverview = (props: React.PropsWithChildren<{
    selectedBooking?: BookingData;
}>) => {
    const [menu, setMenu] = React.useState<MenuItem[]>([])
    const { myCurrentBookings } = useMyBookings();
    const { curLang, curLangData } = useLang();

    React.useEffect(() => {
        setMenu((m) => {
            if (myCurrentBookings != null) {
                const arr = [...m];
                myCurrentBookings.forEach((b) => {
                    const checkArr = arr.filter((d) => compareDates(b.date, d.item.date));
                    if (checkArr.length === 0) arr.push({ item: b, children: [] });
                });
                arr.forEach((d, i) => {
                    const checkArr = myCurrentBookings.filter((b) => compareDates(b.date, d.item.date));
                    if (checkArr.length === 0) arr.splice(i, 1);
                    else d.children = checkArr;
                })
                return arr;
            }
            return [];
        })

    }, [myCurrentBookings]);

    const renderSubItem = (child: BookingData) => {
        return (
            <BookingStyle.OverviewSubListItem key={"child-" + child.id}>
                <BookingStyle.OverviewSubListItemLink to={Path.MyBookings + "/" + child.date.toLocaleDateString('de-DE').replace(/\./g, "-") + "/" + child.id}>
                    <Icon.PfeilRechts size={"35px"} isBold />
                    {getTimeString(
                        child.date,
                        curLangData?.text.FullTime as string,
                        child.endDate,
                        curLang?.id === 1 ? curLangData?.text.Clock : undefined
                    )}
                </BookingStyle.OverviewSubListItemLink>
                {props.selectedBooking?.id === child.id &&
                    <BookingStyle.OverviewSubListItemContent>{props.children}</BookingStyle.OverviewSubListItemContent>
                }
            </BookingStyle.OverviewSubListItem>
        )
    };

    return (
        <BookingStyle.Overview>
            <BookingStyle.OverviewList>
                {menu.map((m) => {
                    return (
                        <BookingStyle.OverviewListItem key={"parent-" + m.item.id}>
                            <BookingStyle.OverviewListItemLink
                                to={Path.MyBookings + "/" + m.item.date.toLocaleDateString('de-DE').replace(/\./g, "-")}
                            >
                                <Icon.PfeilRechts size={"40px"} isBold />
                                {m.item.date.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}
                            </BookingStyle.OverviewListItemLink>
                            <BookingStyle.OverviewSubList>
                                {m.children.map((child) => {
                                    return renderSubItem(child);
                                })}
                            </BookingStyle.OverviewSubList>
                        </BookingStyle.OverviewListItem>
                    )
                })}
            </BookingStyle.OverviewList>
        </BookingStyle.Overview>
    )
}