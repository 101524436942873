
import React from "react";
import { Icon } from "Shared/Components/Icon/Style"
import { TimepickerStyle } from "./Style"

export const Timefield = (props: {
    id: string;
    name: string;
    min: number;
    max: number;
    defaultValue?: string;
    setValue: (val: string) => void;
    steps?: number;
}) => {
    const [value, setValue] = React.useState<string>(props.defaultValue ?? "00");

    const calcValue = (val: string, up?: boolean, down?: boolean) => {
        let numval = parseInt(val);
        const step = props.steps ?? 1;
        if (up && numval < props.max) numval = numval + step;
        if (down && numval > props.min) numval = numval - step;
        if (numval < props.min) numval = props.min;
        if (numval > props.max) numval = props.max;
        val = numval.toString();
        if (val.length === 1) val = "0" + val;
        setValue(val);
        props.setValue(val);
    }

    return (
        <TimepickerStyle.Field>
            <TimepickerStyle.FieldButtonUp
                onClick={() => calcValue(value, true)}
                data-testid={`${props.id}Up`}
            >
                <Icon.PfeilOben size={"40px"} />
            </TimepickerStyle.FieldButtonUp>
            <TimepickerStyle.FieldValue>
                {value}
            </TimepickerStyle.FieldValue>
            <TimepickerStyle.FieldButtonDown
                onClick={() => calcValue(value, false, true)}
                data-testid={`${props.id}Down`}
            >
                <Icon.PfeilUnten size={"40px"} />
            </TimepickerStyle.FieldButtonDown>
        </TimepickerStyle.Field>
    )
}