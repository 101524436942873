import React from "react";

export interface Config {
    webServiceBaseAdress: string,
    appId: string,
    tenantId: string,
    scopes: string[],
    domainName: string,
    graphBaseAddress: string,
    instrumentationKey: string,
}


const ConfigContext = React.createContext<Config>({} as Config)

export const ConfigProvider = (props: { children: JSX.Element }) => {
    const [config, setConfig] = React.useState<Config>();

    React.useLayoutEffect(() => {
        let isMounted = true;
        fetch("webconf.json").then(r => r.json()).then(data => {
            if (isMounted) setConfig(data);
        });
        return () => { isMounted = false; }
    }, []);


    if (config) {
        return (
            <ConfigContext.Provider value={config}>
                {props.children}
            </ConfigContext.Provider>
        )
    }
    else
        return null;
}

const useConfig = () => React.useContext(ConfigContext);
export default useConfig;