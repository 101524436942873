import React from "react";
import { PodMgmtStyle } from "./Style"
import { ReservationList } from "./ReservationList";
import { Floor } from "Main/Floor/Floor";
import { availableFloors, FloorAbbr, FloorPage } from "Shared/Globals";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { useEffectOnce } from "Shared/Hooks/useEffectOnce";
import usePodReservation from "Shared/Context/PodReservationContext";
import useTeamdays from "Shared/Context/TeamdaysContext";
import { PodMgmtNav } from "./PodMgmtNav";
import { useParams } from "react-router-dom";
import { useApi } from "Shared/Context/API";
import { getFirstAndLastDayOfQuarter, getQuarter } from "Shared/Functions/DateMath";
import { decodeText } from "Shared/Functions/URI";

export const PodMgmt = () => {
    const [showMap, setMapVisible] = React.useState(false);
    const [activePod, setActivePod] = React.useState<string | undefined>(undefined);
    const [openAndResetForm, setFormOpen] = React.useState(false);
    const { clearResData } = usePodReservation();
    const { updateTeamdays } = useTeamdays();
    const { teamname, quarter } = useParams();
    const api = useApi();

    const onReset = React.useCallback(() => {
        setActivePod(undefined);
        setMapVisible(false);
        updateTeamdays({ selected: [], disabled: [] })
        clearResData();
    }, [clearResData, updateTeamdays]);

    useEffectOnce(() => {
        return () => {
            onReset()
        }
    })

    const resData = React.useMemo(() => {
        const name = decodeText(teamname as string);
        const selQ = parseInt(quarter as string);
        const thisQ = getQuarter(new Date());
        const thisYear = new Date().getFullYear();
        const selYear = selQ === 1 && thisQ === 4 ? thisYear + 1 : thisYear;

        const q = getFirstAndLastDayOfQuarter(selQ, selYear);
        const start = selQ !== thisQ ? q.from : new Date();

        return { from: start, to: q.to, name: name }
    }, [teamname, quarter])

    React.useEffect(() => {
        let isMounted = true;
        if (teamname != null && quarter != null) {
            api.Pod
                .getPodReservations(resData.from, resData.to, resData.name)
                .then((res) => {
                    if (res.length > 0) {
                        if (isMounted) {
                            setActivePod(res[0].podId);
                            setMapVisible(true);
                            updateTeamdays({
                                selected: res.map((r) => {
                                    return {
                                        date: r.day,
                                        id: r.id
                                    }
                                }),
                                disabled: []
                            })
                        }

                    }
                    else onReset();
                })
                .catch(() => onReset())
        } else {
            onReset();
        }

        return () => { isMounted = false }
    }, [api.Pod, onReset, quarter, resData.from, resData.name, resData.to, teamname, updateTeamdays])

    return (
        <PodMgmtStyle.Container>
            <PodMgmtNav
                activeEl={showMap ? 1 : 0}
                disabledEl={!showMap ? 1 : undefined}
                onClick={onReset}
            />
            {!showMap && <ReservationList
                onConfirmTeamDays={() => {
                    setActivePod(undefined);
                    setMapVisible(true)
                }}
                openAndResetForm={openAndResetForm}
                formOpen={() => setFormOpen(false)}
            />}
            {showMap &&
                <Floor
                    selectedFloor={availableFloors.find((f) => f.id === FloorAbbr.PAR2) as DropdownOption}
                    page={activePod != null ? FloorPage.podmgmt : FloorPage.podmgmtNew}
                    changeMgmtView={(openForm: boolean) => {
                        if (!openForm) onReset()
                        else {
                            setMapVisible(false);
                            setActivePod(undefined);
                            setFormOpen(true);
                        }
                    }}
                    highlightedDeskId={activePod}
                />
            }
        </PodMgmtStyle.Container>
    )
}