import React from "react";
import { Icon } from "Shared/Components";
import { TableRow } from "Shared/Components/Table/Style";
import { useGraphApi } from "Shared/Context/GraphAPI";
import useLang from "Shared/Context/LangContext";
import useUser from "Shared/Context/UserContext";
import { getTimeString } from "Shared/Functions/DateMath";
import { BookingData } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { ExistingItemsStyle } from "./Style";

export const ExistingBookingsItem = (props: {
    data?: BookingData;
    header?: boolean;
    unknownCount?: number;
}) => {
    const { curLangData } = useLang();
    const [name, setName] = React.useState<string | undefined>(undefined);
    const { currentUser } = useUser();
    const graphApi = useGraphApi();

    const unknownOrUndefined = React.useCallback(() => {
        if (props.unknownCount != null) return undefined;
        return curLangData?.text.Unknown;
    }, [curLangData?.text.Unknown, props.unknownCount]);

    React.useEffect(() => {
        let isMounted = true;
        setName(undefined);
        if (props.data?.userName) {
            if (props.data.userName.toLowerCase() === currentUser?.id.toLowerCase())
                setName(curLangData?.text.MyDesk as string);
            else {
                graphApi
                    .searchSpecificPerson(props.data.userName)
                    .then(person => { if (isMounted) setName(person.displayName) })
                    .catch(() => { if (isMounted) setName(unknownOrUndefined) })
            }
        }
        else setName(unknownOrUndefined);
        return () => { isMounted = false };
    }, [curLangData?.text.MyDesk, curLangData?.text.Unknown, currentUser?.id, graphApi, props.data?.userName, unknownOrUndefined])

    const renderUser = () => {
        if (name != null && (name === curLangData?.text.Unknown || name === curLangData?.text.MyDesk)) {
            return <ExistingItemsStyle.ContentCell id="name">{name}</ExistingItemsStyle.ContentCell>
        }
        if (name != null && name !== curLangData?.text.Unknown) {
            const uuser = props.data?.userName?.split(("@"))[0];
            return (
                <ExistingItemsStyle.ContentCell id="link"><a
                    href={`https://postat.sharepoint.com/sites/oskar/MeinBereich/ProfilSeite.aspx?accountname=i%3A0%23.f%7Cmembership%7C${uuser}%40post.at`}
                    target="_blank"
                    rel="noreferrer"
                    title={curLangData?.text.OSKAR}
                >{name}<Icon.ArrowRechts isBold size="20px" /></a></ExistingItemsStyle.ContentCell>
            )
        }
        return <ExistingItemsStyle.ContentCell id="empty"></ExistingItemsStyle.ContentCell>;
    }

    if (props.header) return (
        <TableRow
            isHeader
            minWidth={"100%"}
            gridTemplateColumns={"40% 60%"}
            padding={"5px"}
            data-testid={`${TestId.existingBookingsItem}_header`}
        >
            <ExistingItemsStyle.ContentCell>{curLangData?.text.Time}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>{curLangData?.text.Name}</ExistingItemsStyle.ContentCell>
        </TableRow>
    )

    if (props.data && name != null) return (
        <TableRow
            minWidth={"100%"}
            gridTemplateColumns={"40% 60%"}
            padding={"5px"}
            data-testid={`${TestId.existingBookingsItem}_rowName`}
        >
            <ExistingItemsStyle.ContentCell>{getTimeString(props.data.date, curLangData?.text.FullTime as string, props.data.endDate)}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>{renderUser()}</ExistingItemsStyle.ContentCell>
        </TableRow>
    )

    if (props.unknownCount && props.unknownCount > 0 && !props.data) return (
        <TableRow
            minWidth={"100%"}
            gridTemplateColumns={"40% 60%"}
            padding={"5px"}
            data-testid={`${TestId.existingBookingsItem}_rowUnknown`}
        >
            <ExistingItemsStyle.ContentCell>{curLangData?.text.FullTime}</ExistingItemsStyle.ContentCell>
            <ExistingItemsStyle.ContentCell>
                {curLangData?.text.Unknown}
                {props.unknownCount > 1 && ` (${props.unknownCount})`}
            </ExistingItemsStyle.ContentCell>
        </TableRow>
    )

    return null;
}