import React from "react";
import { DefaultButton } from "Shared/Components/DefaultButton/Style"
import { Dropdown } from "Shared/Components/Dropdown/Dropdown"
import { Icon } from "Shared/Components/Icon/Style"
import useLang from "Shared/Context/LangContext";
import { TimepickerStyle } from "./Style"
import { Timefield } from "./TimeField"

export const TimePicker = (props: {
    defaultText?: string;
    title?: string;
    width?: string;
    defaultValue?: string[];
    setTime: (time: string[]) => void;
    fontSize?: string;
    id: string;
    label?: string;
}) => {
    const [selectedTime, setSelectedTime] = React.useState<string[]>(props.defaultValue ?? ["00", "00"])
    const [closeDropdown, setDropdownClosed] = React.useState(false);
    const { curLangData } = useLang();

    const timer = React.useRef<number>();

    React.useEffect(() => {
        if (closeDropdown === true)
            timer.current = window.setTimeout(() => {
                setDropdownClosed(false);
            }, 500)

        return () => {
            window.clearTimeout(timer.current);
        }
    }, [closeDropdown])

    return (
        <Dropdown
            defaultText={props.defaultText ?? curLangData?.text.TimeSelect2 as string}
            title={props.title}
            options={[]}
            width={props.width}
            icon={<Icon.Uhr />}
            shrinkContent={closeDropdown === false ? undefined : closeDropdown}
            fontSize={props.fontSize}
            id={props.id}
            label={props.label}
        >
            <TimepickerStyle.Container>
                <Timefield
                    id={`${props.id}_hour`}
                    name={"Stunde"}
                    min={6}
                    max={20}
                    defaultValue={props.defaultValue != null ? props.defaultValue[0] : undefined}
                    setValue={(hour) => setSelectedTime([hour, selectedTime[1]])}
                />
                <TimepickerStyle.Divider>{":"}</TimepickerStyle.Divider>
                <Timefield
                    id={`${props.id}_minute`}
                    name={"Minute"}
                    min={0}
                    max={30}
                    defaultValue={props.defaultValue != null ? props.defaultValue[1] : undefined}
                    setValue={(minute) => setSelectedTime([selectedTime[0], minute])}
                    steps={30}
                />
            </TimepickerStyle.Container>
            <TimepickerStyle.Button>
                <DefaultButton
                    onClick={() => {
                        props.setTime(selectedTime);
                        setDropdownClosed(true);
                    }}
                    data-testid={`${props.id}_buttonConfirm`}
                >
                    {curLangData?.text.ConfirmTime}
                </DefaultButton>
            </TimepickerStyle.Button>
        </Dropdown>
    )
}