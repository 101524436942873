import React from "react";
import { PeopleSuggestionModel } from "Shared/Context/GraphAPI";
import { PeopleFinderSearchHeader } from "./SearchHeader"
import { PeopleFinderSearchResults } from "./SearchResults";

export const PeopleFinderSearch = () => {
    const [searchValue, setSearchValue] = React.useState<PeopleSuggestionModel>();
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();
    return (
        <>
            <PeopleFinderSearchHeader
                onSelectPerson={setSearchValue}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
            />
            {searchValue != null &&
                <PeopleFinderSearchResults
                    person={searchValue}
                    date={selectedDate}
                />
            }
        </>
    )
}