
import { AsideStyle } from "./Style";
import useLayout from "Shared/Context/LayoutContext";
import React from "react";
import { PageLinks } from "Main/Page/PageLinks";
import { Header } from "Main/Header/Header";
import { Nav } from "Main/Nav/Nav";
import { MyBookingsSection } from "Main/MyBookings/MyBookingsSection";

export const Aside = () => {
    const [navHeight, setNavHeight] = React.useState<number>(0);
    const { expandNav, isMobile } = useLayout();

    const nav = React.useRef<HTMLElement>(null);
    const headerHeight = 115;

    React.useEffect(() => {
        if (nav.current && expandNav) setNavHeight(nav.current.clientHeight + headerHeight);
    }, [expandNav]);

    return (
        <AsideStyle.Container
            expandNav={expandNav}
            expandedHeight={navHeight}
        >
            <Header />
            <Nav ref={nav} />
            {!isMobile(701) ? <MyBookingsSection /> : <PageLinks />}
        </AsideStyle.Container>
    )
}