import { FloorStyle } from "Main/Floor/Style"
import useLang from "Shared/Context/LangContext"
import { IText } from "Shared/Context/LangContextDTO"
import { DeskStateText } from "Shared/Functions/DeskStateText"
import { DeskState } from "Shared/Globals"
import { TestId } from "Test/TestId"

export const DeskStateRow = (props: {
    state: DeskState
}) => {
    const { curLangData } = useLang();

    return (
        <FloorStyle.DeskInfoRow
            data-testid={`${TestId.podinfo}_rowState`}
            deskState={props.state}
        >
            <strong>{curLangData?.text.State}</strong>
            <span><strong>{DeskStateText(curLangData?.text as IText, props.state)}</strong></span>
        </FloorStyle.DeskInfoRow>
    )
}