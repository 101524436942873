import { color, font } from "Style/Globals"
import { Loading } from "Shared/Components/Loading/Loading"
import { ChartStyle } from "./Style"

export const PieChart = (props: {
    containerWidth?: string;
    containerHeight?: string;
    data?: {} | any[];
    showLegend?: boolean;
}) => {
    if (!props.data) return null;
    else {
        const d = props.data as any[];
        return (
            <ChartStyle.Container width={props.containerWidth} height={props.containerHeight}>
                <ChartStyle.Pie
                    width={'100%'}
                    height={'100%'}
                    chartType={"PieChart"}
                    loader={<Loading position={"absolute"} />}
                    data={props.data}
                    options={{
                        colors: [color.palegraydark, color.yellow],
                        backgroundColor: "transparent",
                        tooltip: { trigger: 'none' },
                        fontName: font.bold,
                        fontSize: 30,
                        enableInteractivity: true,
                        chartArea: {
                            width: '100%',
                            height: '80%',
                        },
                        legend: "none",
                    }}
                />
                {props.showLegend &&
                    <ChartStyle.Legend>
                        <ChartStyle.LegendItem color={color.yellow}>
                            <span>{d[2][0]}</span>
                        </ChartStyle.LegendItem>
                        <ChartStyle.LegendItem color={color.palegraydark}>
                            <span>{d[1][0]}</span>
                        </ChartStyle.LegendItem>
                    </ChartStyle.Legend>
                }
            </ChartStyle.Container>
        )
    }
}