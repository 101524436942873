import { css } from 'styled-components';
import { color } from ".";

export const scrollStyle = css`
    scroll-behavior: smooth;
    scrollbar-color: ${color.yellow} ${color.lightmodeBgBody};

    @media (prefers-color-scheme: dark) {
        scrollbar-color: ${color.yellow} ${color.darkmodeBgBody};
    }

    &::-webkit-scrollbar {
        width: 10px;
        margin-left: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: ${color.lightmodeBgBody};

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgBody};
        }
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        cursor: default;
        background-color: ${color.yellow};
    }

    &::-webkit-scrollbar-corner {
        background-color: ${color.lightmodeBgBody};

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgBody};
        }
    }
`;