import useLayout from "Shared/Context/LayoutContext";
import { BookingStyle } from "Main/MyBookings/Style"
import React from "react";
import { Path } from "Shared/Globals";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";
import useUser from "Shared/Context/UserContext";

export const AdminNav = React.forwardRef<HTMLElement>((props, ref) => {
    const { expandNav } = useLayout();
    const { curLangData } = useLang();
    const { isAdmin, isPodManager } = useUser();
    return (
        <BookingStyle.SubMenu expandNav={expandNav} ref={ref}>
            {isAdmin && <>
                <BookingStyle.SubMenuItem>
                    <BookingStyle.SubMenuLink
                        to={Path.AdminBlock}
                        data-testid={`${TestId.adminNav}_seats`}
                    >
                        {curLangData?.text.AdminDesks}
                    </BookingStyle.SubMenuLink>
                </BookingStyle.SubMenuItem>
                <BookingStyle.SubMenuItem>
                    <BookingStyle.SubMenuLink
                        to={Path.AdminSection}
                        data-testid={`${TestId.adminNav}_sections`}
                    >
                        {curLangData?.text.AdminSection}
                    </BookingStyle.SubMenuLink>
                </BookingStyle.SubMenuItem>
            </>}
            {isPodManager &&
                <BookingStyle.SubMenuItem>
                    <BookingStyle.SubMenuLink
                        to={Path.AdminPods}
                        data-testid={`${TestId.adminNav}_pods`}
                    >
                        {curLangData?.text.AdminPods}
                    </BookingStyle.SubMenuLink>
                </BookingStyle.SubMenuItem>
            }
        </BookingStyle.SubMenu>
    )
});