import { mapFixedElements } from "Main/Floor/FixedElement";
import React from "react";
import { compareDates } from "Shared/Functions/DateMath";
import { BookingData, DeskGroupData, FloorAbbr, FloorData, FloorPage, PodData, PodPosData, PodResData } from "Shared/Globals";
import { useApi } from "./API";
import { Teamday } from "./TeamdaysContext";

export interface FloorContextState {
    getFloorData: (props: {
        selectedFloor: FloorAbbr,
        selectedDate: Date,
        page: FloorPage,
        selectedDates?: Teamday[],
    }) => void,
    fixedElements: IFixedElement[],
    podData: PodData[],
    deskGroupData: DeskGroupData[],
    isFloorDataLoading: boolean,
    floorDataError: string | undefined,
    clearData: (pod?: boolean, dg?: boolean) => void;
}

export const FloorContext = React.createContext<FloorContextState>({
    getFloorData: () => { },
    fixedElements: [],
    podData: [],
    deskGroupData: [],
    isFloorDataLoading: false,
    floorDataError: undefined,
    clearData: () => { }
})

export const FloorProvider = (props: { children: JSX.Element }) => {
    const [fixedElements, setFixedElements] = React.useState<IFixedElement[]>([]);
    const [podData, setPodData] = React.useState<PodData[]>([]);
    const [deskGroupData, setDeskGroupData] = React.useState<DeskGroupData[]>([]);
    const [isFloorDataLoading, setFloorDataLoading] = React.useState(false);
    const [floorDataError, setFloorDataError] = React.useState<string | undefined>();
    const api = useApi();

    const filterReservations = React.useCallback((data: PodResData[], selectedDates?: Teamday[]) => {
        if (selectedDates && selectedDates.length > 0) {
            const res: PodResData[] = []
            data.forEach((r) => {
                selectedDates.forEach((d) => {
                    if (compareDates(d.date, r.day)) res.push(r);
                })
            })
            return res.sort((a, b) => a.day.getTime() - b.day.getTime());
        }
        return data;
    }, []);

    const getPodResProm = React.useCallback((selectedFloor: FloorAbbr, selectedDate: Date, selectedDates?: Teamday[], teamName?: string) => {
        return (selectedDates != null && selectedDates.length > 0) ?
            api.Pod.getPodReservations(selectedDates[0].date, selectedDates[selectedDates.length - 1].date, teamName) :
            api.Pod.getPodReservationsByDate(selectedDate, selectedFloor);
    }, [api.Pod])

    const getPromCollection = React.useCallback((props: {
        selectedFloor: FloorAbbr,
        selectedDate: Date,
        page: FloorPage,
        selectedDates?: Teamday[],
    }) => {
        const positionProm = api.getFloorData(props.selectedFloor, props.page === FloorPage.podmgmt || props.page === FloorPage.podmgmtNew);
        const podResProm = getPodResProm(props.selectedFloor, props.selectedDate, props.selectedDates);
        const bookingsProm = api.getBookingData(props.selectedDate, props.selectedFloor);
        const emptyProm = Promise.resolve(undefined);
        if (props.page === FloorPage.adminUnit) return [positionProm, emptyProm, emptyProm];
        if (props.page === FloorPage.podmgmt || props.page === FloorPage.podmgmtNew) return [positionProm, podResProm, emptyProm];
        return [positionProm, podResProm, bookingsProm];
    }, [api, getPodResProm]);

    const handleFixedElements = (positions: []) => {
        setFixedElements(mapFixedElements(positions) ?? []);
    }

    const handlePodData = React.useCallback((podPositions: PodPosData[], reservations?: PodResData[], bookings?: BookingData[], selectedDates?: Teamday[]) => {
        if (podPositions.length > 0) {
            const podD: PodData[] = podPositions.map((p: PodPosData) => {
                let res = undefined;
                let b = undefined;

                if (reservations && reservations.length > 0) {
                    const filteredRes = filterReservations(reservations, selectedDates);
                    res = filteredRes.filter((r) => r.podId === p.id);
                }

                if (bookings) {
                    b = bookings.filter((b: BookingData) => b.seat === p.id)
                }

                return { position: p, reservations: res, bookings: b }
            });
            setPodData(podD);
        }
        else setPodData([]);
    }, [filterReservations]);

    const handleDGData = (dgPositions: DeskGroupData[], bookings?: BookingData[]) => {
        if (dgPositions.length > 0) {
            const tempData = dgPositions.map((p) => {
                p.bookings = [];
                bookings?.forEach((b) => {
                    if (b.seat.includes(p.name) && p.bookings) p.bookings.push(b)
                })
                return p;
            })
            setDeskGroupData(tempData)
        }
        else setDeskGroupData([]);
    };

    const getFloorData = React.useCallback((props: {
        selectedFloor: FloorAbbr,
        selectedDate: Date,
        page: FloorPage,
        selectedDates?: Teamday[],
    }) => {
        setFloorDataLoading(true);
        const promCollection = getPromCollection(props);
        Promise.all(promCollection)
            .then((res) => {
                if (res) setFloorDataError(undefined);

                const positions = res[0] as FloorData;
                const reservations = res[1] as PodResData[];
                const bookings = res[2] as BookingData[];

                if (positions) {
                    // Fixed-Elements
                    handleFixedElements(positions.fixed);

                    // Pods
                    handlePodData(positions.pods, reservations, bookings, props.selectedDates);

                    // Deskgroups
                    handleDGData(positions.deskgroup, bookings);
                }

            })
            .catch(() => setFloorDataError("") /* TODO */)
            .finally(() => setFloorDataLoading(false))
    }, [getPromCollection, handlePodData]);

    const clearData = React.useCallback((pod?: boolean, dg?: boolean) => {
        // TODO
    }, [])

    const providerValue = React.useMemo(() => (
        { getFloorData, fixedElements, podData, deskGroupData, isFloorDataLoading, floorDataError, clearData }
    ), [getFloorData, fixedElements, podData, deskGroupData, isFloorDataLoading, floorDataError, clearData])

    return (
        <FloorContext.Provider value={providerValue}>
            {props.children}
        </FloorContext.Provider>
    )
}

const useFloor = () => React.useContext(FloorContext);
export default useFloor;