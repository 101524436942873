import { Icon } from "../Icon/Style"
import { FilterButtonStyle } from "./Style"

interface FilterButtonProps {
    isActive: boolean;
    isDisabled?: boolean;
    notSelectable?: boolean;
    onClick: () => void;
    id: string;
    hasError?: boolean;
}

export const FilterButton = (props: React.PropsWithChildren<FilterButtonProps>) => {
    return (
        <FilterButtonStyle.Button
            isActive={props.isActive}
            isDisabled={props.isDisabled}
            notSelectable={props.notSelectable}
            onClick={props.onClick}
            type={"button"}
            data-testid={`${props.id}_button`}
            hasError={props.hasError}
        >
            {props.isActive && <Icon.Haken />}
            <span>{props.children}</span>
        </FilterButtonStyle.Button>
    )
}