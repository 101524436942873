import React from "react"
import { Datepicker, Dropdown, Loading } from "Shared/Components"
import { FinderStyle } from "./Style"
import useResizeObserver from "use-resize-observer/polyfilled";
import { PeopleSuggestionModel, useGraphApi } from "Shared/Context/GraphAPI";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const PeopleFinderSearchHeader = (props: {
    onSelectPerson: (data: PeopleSuggestionModel) => void;
    setSelectedDate: (date: Date | undefined) => void;
    selectedDate?: Date;
}) => {
    const [smallerView, setSmallerView] = React.useState(false);
    const [shrinkSearch, setSearchShrinked] = React.useState<boolean | undefined>(undefined);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>();
    const [peopleData, setPeopleData] = React.useState<PeopleSuggestionModel[] | undefined>();

    const { curLangData } = useLang();
    const settingsContainer = React.useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({ ref: settingsContainer });

    const graphApi = useGraphApi();
    const debounceTimer = React.useRef<number>();

    React.useEffect(() => {
        setSmallerView(width < 500);
    }, [width])

    React.useEffect(() => {
        return () => {
            window.clearTimeout(debounceTimer.current);
        }
    }, [])

    const onSearch = (value: string) => {
        setErrorMsg(undefined);
        if (value.length >= 2) {
            setPeopleData(undefined);
            window.clearTimeout(debounceTimer.current);
            debounceTimer.current = window.setTimeout(() => {
                graphApi.getPeopleSuggestions(value)
                    .then(setPeopleData)
                    .catch(() => setErrorMsg(curLangData?.error.PeopleSearch));
            }, 500)
        } else {
            setPeopleData([])
        }
    }

    const onSelect = (d: PeopleSuggestionModel) => {
        setSearchShrinked(true);
        if (peopleData != null) props.onSelectPerson(d);

        window.setTimeout(() => {
            setSearchShrinked(undefined);
        }, 100)
    }

    const getMinDate = () => {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - 1));
    }

    const renderPersonItem = (person: PeopleSuggestionModel, idx: number) => {
        const img = person.img.length > 0 ? person.img : "/images/Posticon-Avatar.png";
        return (
            <FinderStyle.SettingsPersonBtn
                key={person.userName}
                onClick={() => onSelect(person)}
                data-testid={`${TestId.peopleSelect}_buttonPerson${idx}`}
            >
                <FinderStyle.SettingsPersonImgContainer isAvatar={person.img.length === 0}>
                    <FinderStyle.SettingsPersonImg src={img} alt={person.displayName} />
                </FinderStyle.SettingsPersonImgContainer>
                <FinderStyle.SettingsPersonName>{person.displayName}</FinderStyle.SettingsPersonName>
            </FinderStyle.SettingsPersonBtn>
        )
    }

    const noItemsFound = curLangData?.info.NoPersonFound as string;

    return (
        <>
            <FinderStyle.Settings ref={settingsContainer} smallerView={smallerView}>
                <FinderStyle.SettingsInner size={smallerView ? "100%" : "40%"}>
                    <Dropdown
                        options={[]}
                        defaultText={""}
                        onSearch={onSearch}
                        searchOnChange
                        width={"100%"}
                        shrinkContent={shrinkSearch}
                        fontSize={"18px"}
                        id={TestId.peopleSelect}
                        label={curLangData?.text.PersonName as string}
                    >
                        <FinderStyle.SettingsDropdownContent>
                            {errorMsg != null &&
                                <FinderStyle.SettingsInfoText isError data-testid={`${TestId.peopleSelect}_error`}>{errorMsg}</FinderStyle.SettingsInfoText>
                            }
                            {peopleData == null && errorMsg == null &&
                                <Loading data-testid={`${TestId.peopleSelect}_loading`} position="absolute" size={"7px"} />
                            }
                            {peopleData != null && peopleData.length > 0 && peopleData.map((p, idx) => {
                                return renderPersonItem(p, idx);
                            })}
                            {peopleData != null && peopleData.length === 0 && errorMsg == null &&
                                <FinderStyle.SettingsInfoText data-testid={`${TestId.peopleSelect}_info`}>{noItemsFound}</FinderStyle.SettingsInfoText>
                            }
                        </FinderStyle.SettingsDropdownContent>
                    </Dropdown>
                </FinderStyle.SettingsInner>
                <FinderStyle.SettingsInner size={smallerView ? "100%" : "60%"}>
                    <Datepicker
                        setSelectedDate={props.setSelectedDate}
                        selectedDate={props.selectedDate}
                        width={"100%"}
                        simplified
                        minDate={getMinDate()}
                        defaultText={curLangData?.text.DateSelectOptional}
                        onReset={() => props.setSelectedDate(undefined)}
                        fontSize={"18px"}
                        id={TestId.datepicker}
                        label={curLangData?.text.Date as string}
                    />
                </FinderStyle.SettingsInner>
            </FinderStyle.Settings>
        </>
    )
}