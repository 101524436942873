import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import React from "react";
import useConfig from "./ConfigContext";
import { API, ApiContext } from "./API";
import { GraphAPI, GraphApiContext } from "./GraphAPI";

const LoginHandler = (props: { children: JSX.Element }) => {
    const { inProgress, instance, accounts } = useMsal();
    const config = useConfig();

    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0])
    }

    const api = React.useMemo(() =>
        new API(instance, config)
        , [config, instance])

    const graphApi = React.useMemo(() =>
        new GraphAPI(instance, config)
        , [config, instance])

    React.useEffect(() => {
        let isAuthenticated = accounts.length > 0;

        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            instance.loginRedirect({
                scopes: config.scopes,
                domainHint: config.domainName,
            })
        }
    }, [accounts, config, inProgress, instance]);

    return <>
        <AuthenticatedTemplate>
            <ApiContext.Provider value={api} >
                <GraphApiContext.Provider value={graphApi}>
                    {props.children}
                </GraphApiContext.Provider>
            </ApiContext.Provider>
        </AuthenticatedTemplate>
    </>
}

export const AuthenticationLayer = (props: { children: JSX.Element }) => {
    const config = useConfig();

    const pca = new PublicClientApplication({
        auth: {
            clientId: config.appId,
            authority: `https://login.microsoftonline.com/${config.tenantId}`
        }
    })

    return <MsalProvider instance={pca}>
        <LoginHandler>
            {props.children}
        </LoginHandler>
    </ MsalProvider>
}