import React from "react"
import { Form } from "./Style"

interface CheckboxProps {
    id: string;
    label: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    defaultChecked?: boolean;
    disabled?: boolean;
    title?: string;
    width?: string;
    fontSize?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function checkBox(props: CheckboxProps, ref) {
    return (
        <Form.CheckboxLabel title={props.title} htmlFor={props.id} width={props.width}>
            <Form.Checkbox
                ref={ref}
                type={"checkbox"}
                onChange={props.onChange}
                id={props.id}
                data-testid={props.id}
                defaultChecked={props.defaultChecked}
                checked={props.checked}
                disabled={props.disabled}
            />
            <Form.CheckboxCheckmark />
            <Form.CheckboxText size={props.fontSize}>{props.label}</Form.CheckboxText>
        </Form.CheckboxLabel>
    )
});