import React from "react";
import { Calendar } from "Shared/Components/Calendar/Calendar";
import { Dropdown } from "Shared/Components/Dropdown/Dropdown";
import { Icon } from "Shared/Components/Icon/Style";
import useLang from "Shared/Context/LangContext";
import { DatepickerStyle } from "./Style";

export interface DatepickerProps {
    width?: string;
    selectedDate?: Date;
    setSelectedDate: (date: Date) => void;
    onReset?: () => void;
    minDate?: Date;
    maxDate?: Date;
    disableWeekend?: boolean;
    defaultText?: string;
    simplified?: boolean;
    title?: string;
    nopadding?: boolean;
    openStatic?: boolean;
    fontSize?: string;
    id: string;
    label?: string;
    required?: boolean;
    error?: boolean;
}

export const Datepicker = (props: DatepickerProps) => {
    const [shrinkDropdown, setDropdownShrinked] = React.useState(false);
    const today = new Date();
    const { curLang, curLangData } = useLang();


    const onSelect = (date: Date) => {
        props.setSelectedDate(date);
        setDropdownShrinked(true);
        window.setTimeout(() => {
            setDropdownShrinked(false);
        }, 50)
    }

    return (
        <Dropdown
            nopadding={props.nopadding}
            openStatic={props.openStatic}
            defaultText={props.defaultText ?? curLangData?.text.DateSelect as string}
            title={props.title}
            options={[]}
            width={props.width}
            icon={
                <Icon.Kalender />
            }
            shrinkContent={shrinkDropdown === false ? undefined : shrinkDropdown}
            selectedOption={props.selectedDate != null ? { id: props.selectedDate.toLocaleDateString(curLang?.browser, { dateStyle: "long" }) } : undefined}
            onReset={props.onReset}
            fontSize={props.fontSize}
            id={props.id}
            label={props.label}
            required={props.required}
            error={props.error}
        >
            <DatepickerStyle.Container>
                <Calendar
                    onSelectDate={onSelect}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    selectedDates={props.selectedDate ? [props.selectedDate] : [today]}
                    disableWeekend={props.disableWeekend}
                    simplified={props.simplified}
                    id={props.id}
                />
            </DatepickerStyle.Container>
        </Dropdown>
    )
}