import React from "react";
import { useApi } from "./API";
import { ILangData, ITextData } from "./LangContextDTO";
import useUser from "./UserContext";

export const AvailLangs: ILangData[] = [
    { id: 1, browser: "de-DE", text: "DE" },
    { id: 2, browser: "en-EN", text: "EN" }
]

export interface LangContextState {
    curLang?: ILangData;
    curLangData?: ITextData;
    updateLang: (lang: ILangData) => void;
}

export const LangContext = React.createContext<LangContextState>({
    curLang: undefined,
    curLangData: undefined,
    updateLang: () => { /* init */ }
})

export const LangProvider = (props: { children: JSX.Element }) => {
    const [curLang, setCurLang] = React.useState<ILangData>();
    const [curLangData, setCurLangData] = React.useState<ITextData>();
    const api = useApi();
    const { profileData, setProfile } = useUser();

    React.useLayoutEffect(() => {
        let selectedLang = null;
        if (profileData?.selectedLang != null) {
            selectedLang = AvailLangs.find((a) => a.text === profileData?.selectedLang);
        }
        else {
            selectedLang = AvailLangs.find((a) => a.browser.includes(window.navigator.language));
        }
        setCurLang(selectedLang ?? AvailLangs[1])
    }, [profileData?.selectedLang]);

    React.useEffect(() => {
        if (curLang != null) {
            api
                .getTextStrings(curLang.text)
                .then(setCurLangData)
        }
    }, [api, curLang])

    const updateLang = React.useCallback((lang: ILangData) => {
        setProfile({ selectedLang: lang.text })
        setCurLang(lang);
    }, [setProfile]);

    const providerValue = React.useMemo(() => (
        { curLang, curLangData, updateLang }
    ), [curLang, curLangData, updateLang])

    return (
        <LangContext.Provider value={providerValue}>
            {props.children}
        </LangContext.Provider>
    )
}

const useLang = () => React.useContext(LangContext);
export default useLang;