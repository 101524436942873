import { IText } from "Shared/Context/LangContextDTO";
import { FloorPage, PodStateName } from "Shared/Globals";

export const PodStateText = (
    str: IText,
    page: FloorPage,
    state?: PodStateName,
    teamName?: string
) => {
    if (teamName) return teamName;
    if (state) {
        if (state === PodStateName.notRecommended) return str.StateNotRecommended;
        if (state === PodStateName.reserved) return str.StateReserved;
        if (state === PodStateName.mine) return page === FloorPage.podmgmt ? str.StateMyRes : str.StateMy;
        if (state === PodStateName.recommended) return str.StateRecommended;
        return str.StateFree
    }
}