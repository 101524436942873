import styled, { css, keyframes } from 'styled-components';
import { color } from 'Style/Globals';

const load = keyframes`
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
`;

const defaultStyles = css`
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${load} 1.8s infinite ease-in-out;
`;

const pseudoStyles = css`
    content: '';
    position: absolute;
    top: 0;
`;

export const LoadingStyle = {
    Outer: styled.div<{ visible?: boolean }>`
        position: absolute;
        inset: 0 0 0 0;
        background-color: ${props => props.visible ? color.whiteAlpha : color.transparent};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => props.visible ? color.flyoutOverlayBg : color.transparent};
        }
    `,
    Container: styled.div<{ position: string }>`
        position: ${props => props.position};
        top: 50%;
        left: 50%;
        width: 200px;
        height: 50px;
        transform: translate(-50%, -50%);
    `,
    Inner: styled.div<{ size?: string, color?: string }>`
        ${defaultStyles}
        color: ${props => props.color ?? color.yellow};
        font-size: ${props => props.size ?? "10px"};
        position: relative;
        margin: 0 auto;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;

        :before {
            ${defaultStyles}
            ${pseudoStyles}
            left: -3.5em;
            animation-delay: -0.32s;
        }

        :after {
            ${defaultStyles}
            ${pseudoStyles}
            left: 3.5em;
        }
    `,
};