import { Path } from "Shared/Globals";
import { Icon } from "Shared/Components";
import { NavStyle } from "./Style";

export const NavLink = (props: {
    pathTo: Path;
    text: string;
    expandNav?: boolean;
    icon: React.ReactNode;
    onClick?: () => void;
    id: string;
}) => {
    return (
        <NavStyle.Item expandNav={props.expandNav}>
            <NavStyle.Link
                to={props.pathTo}
                onClick={props.onClick}
                data-testid={props.id}
            >
                <NavStyle.Icon expandNav={props.expandNav}>
                    {props.icon}
                </NavStyle.Icon>
                <span>{props.text}</span>
                <NavStyle.Arrow>
                    <Icon.ArrowRechts size={"25px"} isBold />
                </NavStyle.Arrow>
            </NavStyle.Link>
        </NavStyle.Item>
    )
}