import useLayout from "Shared/Context/LayoutContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import { Floor } from "Main/Floor/Floor";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { compareDates } from "Shared/Functions/DateMath";
import { Desk } from "Shared/Functions/Desk";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { Path, BookingData, FloorPage } from "Shared/Globals";
import { InfoBox, InfoBoxType, Loading } from "Shared/Components";
import { MyBookingsInfo } from "Main/MyBookings/MyBookingsInfo";
import { MyBookingsOverview } from "Main/MyBookings/MyBookingsOverview";
import { MyBookingsTimeSubMenu } from "Main/MyBookings/MyBookingsTimeSubMenu";
import useLang from "Shared/Context/LangContext";

export const MyBookingsPage = () => {
    const [todaysBookings, setTodaysBookings] = React.useState<BookingData[]>([]);
    const [selectedBooking, setSelectedBooking] = React.useState<BookingData>();
    const [selectedFloor, setSelectedFloor] = React.useState<DropdownOption>();

    const { isMobile, updatePageTitle } = useLayout();
    const { myCurrentBookings, myBookingsLoading, myBookingsError } = useMyBookings();
    const { curLangData } = useLang();

    const navigate = useNavigate();
    const { bookingdate, bookingid } = useParams();

    React.useEffect(() => {
        let isMounted = true;
        if (isMounted && curLangData) updatePageTitle(curLangData.text.MyBookings)
        return () => { isMounted = false }
    }, [curLangData, updatePageTitle]);

    React.useEffect(() => {
        const selDate = bookingdate;
        if (selDate != null && myCurrentBookings != null) {
            const [day, month, year] = selDate.split("-");
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            const availableBookings = myCurrentBookings.filter((b) => compareDates(b.date, date));
            setTodaysBookings(availableBookings);
            const selId = bookingid;
            if (selId != null) {
                const selected = availableBookings.filter((b) => b.id === selId);
                if (selected.length > 0) {
                    setSelectedBooking(selected[0]);
                    const desk = new Desk(selected[0].seat);
                    const floor: DropdownOption = {
                        id: desk.Floor.Id,
                        text: desk.Floor.Name,
                    }
                    setSelectedFloor(floor);
                }
            } else {
                if (availableBookings != null && availableBookings.length > 0) navigate(Path.MyBookings + "/" + selDate + "/" + availableBookings[0].id)
            }
        } else {
            setTodaysBookings([]);
            setSelectedBooking(undefined);
            setSelectedFloor(undefined);
        }
    }, [navigate, myCurrentBookings, bookingdate, bookingid]);

    const renderDetails = () => {
        return selectedBooking != null &&
            <>
                {isMobile(1050) &&
                    <MyBookingsInfo
                        booking={selectedBooking}
                        allBookings={todaysBookings}
                    />
                }
                {selectedFloor != null &&
                    <Floor
                        selectedFloor={selectedFloor}
                        selectedDate={selectedBooking.date}
                        selectedEndDate={selectedBooking.endDate}
                        highlightedDeskId={selectedBooking.seat}
                        page={FloorPage.myBooking}
                    />
                }
            </>
    }

    const renderMobile = () => {
        return (
            <MyBookingsOverview selectedBooking={selectedBooking}>
                {renderDetails()}
            </MyBookingsOverview>
        )
    };

    const renderDesktop = () => {
        return (
            <>
                {todaysBookings.length > 1 && <MyBookingsTimeSubMenu todaysBookings={todaysBookings} />}
                {renderDetails()}
            </>
        )
    }

    if (myBookingsLoading === true) return <Loading position={"absolute"} size={"10px"} />;
    if (myBookingsLoading === false && myBookingsError != null) return <InfoBox type={InfoBoxType.error} text={myBookingsError} title={curLangData?.text.Attention} marginTop={"40px"} />;
    if (todaysBookings.length === 0 && myBookingsLoading === false) return <MyBookingsOverview />;
    return isMobile(701) ? renderMobile() : renderDesktop();
}