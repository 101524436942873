import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { defaultTransition, color, font, breakpoint } from "Style/Globals";

export const BookingStyle = {
    Section: styled.section<{ expandNav?: boolean }>`
        padding: ${props => props.expandNav ? "20px" : "10px"};
        padding-top: 0;
        ${defaultTransition}
        position: relative;
    `,
    SectionHeader: styled.header`
        position: relative;
        color: ${color.black};
        padding-left: 43px;
        padding-bottom: 10px;
    `,
    SectionHeaderTitle: styled.h2<{ expandNav?: boolean }>`
        font-size: 20px;
        opacity: ${props => props.expandNav ? "1" : 0};
        transition: 200ms ease-in;
        white-space: nowrap;
        overflow: ${props => props.expandNav ? "visible" : "hidden"};
        transition-delay: ${props => props.expandNav ? "200ms" : "0ms"};
    `,
    SectionHeaderIcon: styled.div<{ expandNav?: boolean }>`
        position: absolute;
        top: -2px;
        left: 5px;
        width: ${props => props.expandNav ? "0" : "100%"};
        text-align: center;
        transition: 200ms ease-in;
        transition-delay:  ${props => props.expandNav ? "0ms" : "200ms"};
    `,
    SectionLink: styled(NavLink)`
        display: block;
        padding-left: 3px;
        margin-right: 0;
        ${defaultTransition}
        overflow: hidden;

        & > span {
            display: block;
            position: relative;
            color: ${color.black};
            padding: 5px;
            padding-left: 20px;
            white-space: nowrap;
            ${defaultTransition}

            & > i {
                display: none;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
        }

        :hover {
            & > span {
                font-family: ${font.bold};
            }
        }

        &.active {
            margin-right: -30px;

            :before {
                content: "";
                border-left: 3px solid ${color.black};
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 10px;
                ${defaultTransition}
            }


            & > span {
                background-color: ${color.yellowHover};
                font-family: ${font.bold};

                & > i {
                    display: block;
                }
            }
        }
    `,
    SectionItem: styled.li<{ expandNav?: boolean }>`
        & > a.active {
            margin-right: ${props => props.expandNav && "-40px"};

            :before {
                left: ${props => props.expandNav && "20px"};
                bottom: ${props => props.expandNav && "20px"};
            }
        }
    `,
    SectionArrow: styled.div`
        position: absolute;
        top: 0;
        left: -4px;
    `,
    InfoContainer: styled.div<{ isActive?: boolean }>`
        transition: 200ms ease-in;
        margin: -10px;
        padding: 10px;
        overflow: hidden;
        height: ${props => props.isActive ? "auto" : "30px"};
        position: relative;
        background-color: ${props => props.isActive && color.lightmodeBgContent};

        @media (prefers-color-scheme: dark) {
            background-color: ${props => props.isActive && color.darkmodeBgContent};
        }

        & + & {
            margin-top: 20px;
        }
    
    `,
    Info: styled.div`
        @media ${breakpoint.min701} {
            padding-top: 40px;
            max-width: 500px;
        }
        @media ${breakpoint.min1050} {
            padding-top: 0;
        }
    `,
    InfoRow: styled.p<{ color?: string }>`
        display: block;
        font-size: 14px;
        color: ${props => props.color};

        @media ${breakpoint.min501} {
            display: flex;
        }

        & > strong {
            display: block;
            font-family: ${font.bold};
            width: 100%;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 16px;

            @media ${breakpoint.min501} {
                width: 40%;
            }
        }

        & > span {
            display: block;
            width: 100%;
            font-size: 16px;

            @media ${breakpoint.min501} {
                width: 60%;
            }
        }

        & > mark {
            background-color: ${color.transparent};
            font-size: 15px;
            line-height: 1.6;
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        & + & {
            margin-top: 10px;
        }
    `,
    InfoRowButton: styled.div`
        margin-top: 20px;
        height: auto;
        gap: 10px;
        display: flex;
        flex-direction: column;

        @media ${breakpoint.min501} {
            display: flex;
            flex-direction: row;
        }

        & > button {
            align-self: flex-start;
            width: 50%;

            @media ${breakpoint.min501} {
                align-self: center;
            }
        }
    `,
    Confirmation: styled.div<{ size: string }>`
        display: block;
        width: ${props => props.size};
        position: relative;
        min-height: 50px;
    `,
    ConfirmationContainer: styled.div`
        display: flex;
        width: 180px;
    `,
    ConfirmationButtonContainer: styled.div`
        width: 50%;

        & > button {
            width: 100%;
        }
    `,
    ConfirmationText: styled.div`
        display: inline-block;
        height: 50px;
        /* padding-top: 2px; */
        padding-left: 15px;
        vertical-align: top;
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 16px;
        width: 100px;
        font-family: ${font.bold};
        text-align:center;

        & > span {
            display: block;
            letter-spacing: .5px;
            border-bottom: 2px solid ${color.black};
            padding-bottom: 2px;
            margin-bottom: 2px;

            @media (prefers-color-scheme: dark) {
                border-color: ${color.white};
            }
        }
    `,
    InfoLink: styled(NavLink)`
        display: block;
        position: relative;
        font-size: 18px;
        overflow: hidden;
        height: 30px;
        padding-left: 20px;

        & > i {
            position: absolute;
            top: -3px;
            left: -9px;
            transition: 200ms ease-in;
        }

        &.active {
            font-family: ${font.bold};
            & > i {
                transform: rotate(90deg);
            }
        }
    `,
    InfoMap: styled.div`
        position: relative;
        min-height: 50px;
    `,
    SubMenu: styled.nav<{ expandNav?: boolean }>`
        display: flex;
        padding: 0 10px;
        margin: -10px -10px 0;
        background-color: ${color.SubMenuLightmode};
        ${defaultTransition}

        @media ${breakpoint.min701} {
            padding-left: ${props => props.expandNav ? "150px" : "115px"};
            padding-right: 45px;
            margin-left: ${props => props.expandNav ? "-150px" : "-115px"};
            margin-top: 30px;
            margin-right: -50px;
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${color.SubMenuDarkmode};
        }
    `,
    SubMenuItem: styled.li`
        & + & {
            margin-left: 30px;
        }
    `,
    SubMenuLink: styled(NavLink)`
        display: block;
        padding: 15px 0;
        position: relative;
        background-color: ${color.transparent};
        transition: 200ms ease-in;
        overflow: hidden;
        font-family: ${font.bold};
        font-size: 16px;

        @media ${breakpoint.min701} {
            font-size: 18px;
            }

        :after {
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            transform: rotate(45deg) translate(-50%, 0);
            background-color: ${color.black};
            transition: 200ms ease-in;
            opacity: 0;
            position: absolute;
            left: 50%;
            bottom: -8px;

            @media (prefers-color-scheme: dark) {
                background-color: ${color.yellow};
            }
        }

        :hover,
        :focus {
            padding: 20px 0 10px;
        }

        &.active {
            padding: 15px 0;
            :after {
                opacity: 1;
            }
        }
    `,
    Overview: styled.nav`
        @media ${breakpoint.min701} {
            padding-top: 40px;
        }
    `,
    OverviewList: styled.ul``,
    OverviewListItem: styled.li`
        background-color: ${color.lightmodeBgContent};

        @media (prefers-color-scheme: dark) {
            background-color: ${color.darkmodeBgContent};
        }

        & + & {
            margin-top: 20px;
            border-top: 1px dashed ${color.SubMenuLightmode};
            padding-top: 15px;

            @media (prefers-color-scheme: dark) {
                border-top-color: ${color.darkmodeBgBody};
            }
        }
    `,
    OverviewListItemLink: styled(NavLink)`
        display: block;
        padding: 10px;
        padding-left: 25px;
        font-size: 20px;
        font-weight: bold;
        position: relative;

        & > i {
            position: absolute;
            top: 3px;
            left: -14px;
        }

        &.active {
            pointer-events: none;

            & > i {
                transform: rotate(90deg);
            }
        }
    `,
    OverviewSubList: styled.ul``,
    OverviewSubListItem: styled.li``,
    OverviewSubListItemLink: styled(NavLink)`        
        display: block;
        padding: 5px 10px 5px 50px;
        font-size: 18px;
        font-weight: bold;
        position: relative;

        & > i {
            position: absolute;
            top: -1px;
            left: 15px;
        }

        &.active {
            pointer-events: none;
            
            & > i {
                transform: rotate(90deg);
            }
        }
    `,
    OverviewSubListItemContent: styled.div`
        padding: 10px 10px 10px 50px;
    `,
}