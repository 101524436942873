import styled, { css } from 'styled-components';
import { color, font } from 'Style/Globals';

const selected = css`
    position: relative;

    :before {
        content: "";
        display: block;
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-color: ${color.yellow};
        z-index: 1;
    }

    & > span {
        position: relative;
        z-index: 2;
        font-family: ${font.bold};
    }
`;

export const CalendarStyle = {
    Container: styled.div`
        padding: 10px;
    `,
    Months: styled.div`
        display: flex;
        padding: 20px 0;
        margin: 0 -2px;
    `,
    MonthsItem: styled.div`
        width: 8.333%;
        padding: 2px;
    `,
    MonthsItemInner: styled.div`
        padding-top: 100%;
        position: relative;
        width: 100%;
    `,
    MonthsButton: styled.button<{ selected?: boolean }>`
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        text-align: center;
        background: ${color.lightmodeBgBody};
        color: ${color.black};
        width: 100%;
        cursor: pointer;
        transition: 200ms ease-in;

        ${props => props.selected && selected}
        position: absolute;

        @media (prefers-color-scheme: dark) {
            background: ${color.darkmodeBgBody};
            color: ${props => props.selected ? color.black : color.white};
        }

        :hover,
        :focus {
            background-color: ${color.yellowHover};

            @media (prefers-color-scheme: dark) {
                color: ${color.black}
            }
        }
    `,
    SimpleMonth: styled.div`
        text-align: center;
        padding: 0 30px 20px;
        position: relative;
        width: 100%;
    `,
    SimpleMonthText: styled.div`
        font-family: ${font.bold};
        font-size: 20px;
        padding: 12px 0;
    `,
    Years: styled.div`
        padding: 0;
    `,
    YearsContainer: styled.div`        
        text-align: center;
        padding: 0 30px;
        position: relative;
        width: 100%;
    `,
    YearsChevron: styled.button<{ direction: string }>`
        height: 50px;
        width: 30px;
        overflow: hidden;
        position: absolute;
        top: 0;
        color: ${color.black};
        cursor: pointer;
        left: ${props => {
            if (props.direction === "prev") return "0";
            if (props.direction === "next") return "auto";
        }};
        right: ${props => {
            if (props.direction === "prev") return "auto";
            if (props.direction === "next") return "0";
        }};

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
        }

        & > i {
            position: absolute;
            left: ${props => {
            if (props.direction === "prev") return "-26px";
            if (props.direction === "next") return "auto";
        }};
            right: ${props => {
            if (props.direction === "prev") return "auto";
            if (props.direction === "next") return "-26px";
        }};
            top: -10px;
        }

        :hover,
        :focus {
            color: ${color.blue};
        }
    `,
    YearsYear: styled.button`
        display: inline-block;
        vertical-align: baseline;
        height: 50px;
        cursor: pointer;

        & > span {
            font-size: 16px;
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        :hover,
        :focus {
            & > span {
                color: ${color.blue};
            }
        }
    `,
    YearsYearSelected: styled.div`
        display: inline-block;
        vertical-align: baseline;
        height: 50px;
        margin: 0 20px;
        ${selected};


        & > span {
            display: inline-block;
            font-size: 24px;
            padding-top: 9px;
            font-family: ${font.bold};
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                text-shadow: 1px 1px 2px ${color.white},  -1px -1px 2px ${color.white};
            }
        }

        :before {
            width: 40px;
            height: 40px;
            left: 15%;
        }
    `,
    Table: styled.div`
        width: 100%;
        margin: 0;
    `,
    TableRow: styled.div`
        display: flex;
        margin: 0 -2px;
    `,
    TableRowItem: styled.div`
        width: 14.285%;
        padding: 2px;
    `,
    TableRowItemInner: styled.div`
        padding-top: 100%;
        position: relative;
        width: 100%;
    `,
    TableRowItemButton: styled.button<{
        selected?: boolean,
        weekend?: boolean,
        isInMonth?: boolean,
        disabled?: boolean,
    }>`
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        text-align: center;
        background: ${props => props.disabled ? color.lightgrey2 : color.lightmodeBgBody};
        color: ${color.black} !important;
        width: 100%;
        cursor: pointer;
        font-size: 16px;
        transition: 200ms ease-in;
        pointer-events: ${props => props.disabled && "none"};

        ${props => props.selected && selected}
        position: absolute;

        @media (prefers-color-scheme: dark) {
            background: ${props => props.disabled ? color.darkgrey1 : color.darkmodeBgBody};
            color: ${props => props.selected ? color.black : color.white} !important;
        }

        & > span {
            opacity: ${props => (props.disabled || !props.isInMonth) ? ".5" : "1"};
        }

        :hover,
        :focus {
            background-color: ${color.yellowHover};

            @media (prefers-color-scheme: dark) {
                color: ${color.black} !important;
            }

            & > span {
                opacity: 1;
            }
        }
    `,
    TableLabel: styled.label`
        display: block;
        width: 14.285%;
        padding: 2px;
        text-align: center;
        font-size: 14px;
        padding-bottom: 10px;
        font-family: ${font.bold};
    `,
}