import { ChartStyle } from "./Style"

export const BarChart = (props: {
    containerColor: string;
    barColor: string;
    size: string;
    text?: string;
    textColor?: string;
}) => {
    return (
        <ChartStyle.BarContainer color={props.containerColor}>
            <ChartStyle.Bar color={props.barColor} size={props.size}>
                {props.text &&
                    <ChartStyle.BarText color={props.textColor}>{props.text}</ChartStyle.BarText>
                }
            </ChartStyle.Bar>
        </ChartStyle.BarContainer>
    )
}