import styled from "styled-components";

export const ButtonRowStyle = {
    Container: styled.div<{ marginTop?: string }>`
        text-align: right;
        margin-top: ${props => props.marginTop ?? "0"};
    `,
    Button: styled.div<{ isSecondary?: boolean }>`
        display: inline-block;
        width: 50%;
        padding-right: ${props => props.isSecondary ? "5px" : "0"};
        padding-left: ${props => props.isSecondary ? "0" : "5px"};

        > button  {
            width: 100%;
        }
    `,
    Loading: styled.div`
        display: block;
        position: relative;
        height: 30px;
    `
}