import React from "react";
import ReactDOM from "react-dom";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import { GetScrollOffset } from "Shared/Hooks/scrollOffset";
import { useEffectOnce } from "Shared/Hooks/useEffectOnce";
import { TestId } from "Test/TestId";
import { Icon } from "../Icon/Style";
import { SidePanelStyle } from "./Style";

interface SidePanelProps {
    headline: string;
    onDismiss?: () => void;
    id?: string;
}


export const SidePanel = (props: React.PropsWithChildren<SidePanelProps>) => {
    const { expandSidePanel, updateSidePanel } = useLayout();
    const { curLangData } = useLang();
    const offset = GetScrollOffset();

    const isOffset = React.useMemo(() => {
        return offset > 115;
    }, [offset])

    useEffectOnce(() => {
        // close sidepanel if component is unmounted
        return () => { updateSidePanel(false); }
    });

    const onDismiss = React.useCallback(() => {
        const _onDismiss = props.onDismiss;
        updateSidePanel(false)
        if (_onDismiss) _onDismiss();
    }, [props.onDismiss, updateSidePanel])

    const panel = (
        <SidePanelStyle.Container
            isExpanded={expandSidePanel}
            isSticky={isOffset}
            data-testid={props.id ?? TestId.sidepanel}
        >
            <SidePanelStyle.Inner>
                <SidePanelStyle.Header>
                    <h3>{props.headline}</h3>
                </SidePanelStyle.Header>
                <SidePanelStyle.DismissBtn
                    type={"button"}
                    onClick={onDismiss}
                    title={curLangData?.text.Close}
                    data-testid={props.id ? `${props.id}_dismissbutton` : `${TestId.sidepanel}_dismissbutton`}>
                    <Icon.X size={"35px"} isBold />
                </SidePanelStyle.DismissBtn>
                {expandSidePanel && props.children &&
                    <SidePanelStyle.ScrollContent data-testid={props.id ? `${props.id}_content` : `${TestId.sidepanel}_content`}>
                        {props.children}
                    </SidePanelStyle.ScrollContent>
                }
            </SidePanelStyle.Inner>
        </SidePanelStyle.Container>
    )

    const element = document.getElementById("df_sidepanel");
    if (element) {
        return ReactDOM.createPortal(
            panel, element as Element,
        );
    }
    return null;
}