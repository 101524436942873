import React from "react";
import { ButtonRow, Datepicker, Dropdown, InfoBox, InfoBoxType, Inputfield, WeekdayPicker } from "Shared/Components"
import { FilterButton } from "Shared/Components/FilterButton/FilterButton";
import { useApi } from "Shared/Context/API";
import useLang from "Shared/Context/LangContext";
import usePodReservation from "Shared/Context/PodReservationContext";
import useTeamdays from "Shared/Context/TeamdaysContext";
import { checkIfLastMonthInQuarter, getFirstAndLastDayOfQuarter, getQuarter } from "Shared/Functions/DateMath";
import { weekIntervalOptionsDE, weekIntervalOptionsEN } from "Shared/Globals";
import { useEffectOnce } from "Shared/Hooks/useEffectOnce";
import { TestId } from "Test/TestId"
import { calcTeamDays } from "./calcTeamDays";
import { PodMgmtStyle } from "./Style"

export const TeamForm = (props: {
    onFormComplete: () => void;
    onDismiss: () => void;
}) => {
    const { resData, updateResData } = usePodReservation();
    const [hasValidationError, showValidationError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [info, setInfo] = React.useState<string | undefined>(undefined);
    const { curLang, curLangData } = useLang();
    const { updateTeamdays } = useTeamdays();
    const api = useApi();

    useEffectOnce(() => {
        return () => {
            showValidationError(false);
            setLoading(false);
            setInfo(undefined);
        }
    })

    const nextQuarter = React.useMemo(() => {
        const thisQ = getQuarter(new Date());
        return {
            number: thisQ === 4 ? 1 : thisQ + 1,
            year: new Date().getFullYear() + (thisQ === 4 ? 1 : 0),
            clickable: checkIfLastMonthInQuarter(new Date()),
        }
    }, [])

    const selectedQuarterYear = React.useMemo(() => {
        const thisYear = new Date().getFullYear();
        return (getQuarter(new Date()) !== resData.quarter && resData.quarter === 1) ? (thisYear + 1) : thisYear;
    }, [resData])

    const isTeamNameValid = React.useMemo(() => {
        const name = resData?.teamname;
        return name != null && name.length > 0
    }, [resData]);
    const isTeamSizeValid = React.useMemo(() => {
        const size = resData?.teamsize;
        return size != null && !isNaN(size) && size > 0 && size < 17;
    }, [resData]);
    const isDaysPerWeekValid = React.useMemo(() => {
        const days = resData?.daysPerWeek;
        return days != null && !isNaN(days) && days > 0 && days < 5;
    }, [resData]);
    const isWeekdaySelectValid = React.useMemo(() => {
        const weekdays = resData?.weekDays;
        return weekdays != null && weekdays.length === resData.daysPerWeek;
    }, [resData])
    const isIntervalValid = React.useMemo(() => {
        return resData?.weekInterval != null;
    }, [resData])
    const isStartDateValid = React.useMemo(() => {
        const date = resData?.startDate;
        const daterange = getFirstAndLastDayOfQuarter(resData.quarter, selectedQuarterYear);
        return date != null && date >= daterange.from && date <= daterange.to;
    }, [resData, selectedQuarterYear])

    const [showTeamDaysForm, setTeamDaysFormVisible] = React.useState(isDaysPerWeekValid || isWeekdaySelectValid || isIntervalValid || isStartDateValid);

    const checkIfReservationAlreadyExists = React.useCallback(() => {
        setLoading(true);
        const qDates = getFirstAndLastDayOfQuarter(resData.quarter, selectedQuarterYear);
        const from = qDates.from.getTime() < new Date().getTime() ? new Date() : qDates.from;
        api.Pod.getPodReservations(from, qDates.to, resData.teamname)
            .then((res) => {
                setError(undefined);
                setInfo(undefined);
                if (res && res.length > 0) setInfo(`${curLangData?.info?.PodResAlreadyExists1} ${curLangData?.info?.PodResAlreadyExists2}`);
                else {
                    setInfo(undefined);
                    setTeamDaysFormVisible(true);
                }
            })
            .catch(() => setError(curLangData?.error.ReservationCheck))
            .finally(() => setLoading(false))

    }, [api, resData, selectedQuarterYear, curLangData]);

    const validateFields = () => {
        if (isDaysPerWeekValid && isWeekdaySelectValid && isIntervalValid && isStartDateValid) {
            showValidationError(false);
            const teamdays = calcTeamDays(resData);
            if (teamdays.length > 0) {
                setInfo(undefined)
                updateTeamdays({ selected: teamdays, disabled: [] })
                props.onFormComplete();
            }
            else setInfo(curLangData?.info.NoDatesFound)
        }
        else showValidationError(true);
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isTeamNameValid && isTeamSizeValid) {
            showValidationError(false);
            if (!showTeamDaysForm) checkIfReservationAlreadyExists();
            else validateFields();
        } else showValidationError(true);
    }

    const handleWeekdaySelect = React.useCallback((day: number, remove?: boolean) => {
        const selectedDays = resData.weekDays ? [...resData.weekDays] : [];
        const maxDays = resData.daysPerWeek ?? 0;
        setInfo(undefined);
        if (remove) {
            const idx = selectedDays.indexOf(day);
            if (idx > -1) selectedDays.splice(idx, 1);
        }
        else if (selectedDays.length < maxDays) {
            selectedDays.push(day);
        }
        updateResData({ ...resData, weekDays: selectedDays })
    }, [resData, updateResData]);

    const intervalOptions = React.useMemo(() => {
        return curLang?.id === 1 ? weekIntervalOptionsDE : weekIntervalOptionsEN
    }, [curLang])

    const renderInfo = () => {
        if (info != null) return (
            <PodMgmtStyle.FormRow>
                <InfoBox
                    type={InfoBoxType.default}
                    htmlText={info}
                />
            </PodMgmtStyle.FormRow>
        )
    }

    const renderError = () => {
        if (error != null) return (
            <PodMgmtStyle.FormRow>
                <InfoBox
                    type={InfoBoxType.error}
                    text={error}
                />
            </PodMgmtStyle.FormRow>
        )
    }

    const renderButtons = () => {
        return <ButtonRow
            id={`${TestId.podresform}_button`}
            primaryText={!showTeamDaysForm ? curLangData?.text.NextStep as string : curLangData?.text.GoToOverview as string}
            primaryType={"submit"}
            onPrimaryClick={onSubmit}
            secondaryText={curLangData?.text.Cancel}
            secondaryType={"button"}
            onSecondaryClick={props.onDismiss}
            isLoading={isLoading}
            marginTop={"20px"}
            disablePrimary={info != null}
        />
    }

    return (
        <PodMgmtStyle.Form onSubmit={onSubmit}>
            <PodMgmtStyle.FormRow>
                <FilterButton
                    isActive={resData?.quarter === getQuarter(new Date())}
                    notSelectable={!nextQuarter.clickable || (resData?.quarter === getQuarter(new Date()) && showTeamDaysForm)}
                    isDisabled={resData?.quarter === nextQuarter.number && showTeamDaysForm}
                    onClick={() => updateResData({ ...resData, quarter: getQuarter(new Date()) })}
                    id={`${TestId.podresform}_quarter${getQuarter(new Date())}`}
                >
                    {`${curLangData?.text.Quarter} ${getQuarter(new Date())} ${new Date().getFullYear()}`}
                </FilterButton>
                <FilterButton
                    isActive={resData?.quarter === nextQuarter.number}
                    notSelectable={resData?.quarter === nextQuarter.number && showTeamDaysForm}
                    onClick={() => updateResData({ ...resData, quarter: nextQuarter.number })}
                    isDisabled={!nextQuarter.clickable || (resData?.quarter === getQuarter(new Date()) && showTeamDaysForm)}
                    id={`${TestId.podresform}_quarter${nextQuarter.number}`}
                >
                    {`${curLangData?.text.Quarter} ${nextQuarter.number} ${nextQuarter.year}`}
                </FilterButton>
            </PodMgmtStyle.FormRow>
            <PodMgmtStyle.FormRow>
                <Inputfield
                    label={curLangData?.text.TeamName2 as string}
                    id={`${TestId.podresform}_teamname`}
                    required
                    onChange={e => {
                        updateResData({ ...resData, teamname: e.target.value })
                        setInfo(undefined);
                    }}
                    defaultValue={resData.teamname}
                    error={hasValidationError && !isTeamNameValid}
                    readOnly={showTeamDaysForm}
                />
            </PodMgmtStyle.FormRow>
            <PodMgmtStyle.FormRow>
                <Inputfield
                    id={`${TestId.podresform}_teamsize`}
                    label={curLangData?.text.TeamSize as string}
                    onChange={e => updateResData({ ...resData, teamsize: parseInt(e.target.value) })}
                    type={"number"}
                    required
                    error={hasValidationError && !isTeamSizeValid}
                    defaultValue={resData.teamsize}
                    readOnly={showTeamDaysForm}
                    min={"1"}
                    max={"16"}
                />
            </PodMgmtStyle.FormRow>

            {showTeamDaysForm &&
                <>
                    <PodMgmtStyle.FormRow>
                        <Inputfield
                            id={`${TestId.podresform}_daysperweek`}
                            label={curLangData?.text.DaysPerWeek as string}
                            onChange={e => {
                                updateResData({ ...resData, daysPerWeek: parseInt(e.target.value) })
                                setInfo(undefined);
                            }}
                            type={"number"}
                            required
                            min={"1"}
                            max={"4"}
                            error={hasValidationError && !isDaysPerWeekValid}
                            defaultValue={resData.daysPerWeek}
                        />
                    </PodMgmtStyle.FormRow>
                    {isDaysPerWeekValid &&
                        <PodMgmtStyle.FormRow>
                            <WeekdayPicker
                                selectedDays={resData.weekDays ?? []}
                                onBtnClick={handleWeekdaySelect}
                                id={`${TestId.podresform}_weekdays`}
                                hideWeekend
                                hasError={hasValidationError && !isWeekdaySelectValid}
                                maxDays={resData.daysPerWeek}
                            />
                        </PodMgmtStyle.FormRow>
                    }
                    <PodMgmtStyle.FormRow>
                        <Dropdown
                            label={curLangData?.text.Interval}
                            id={`${TestId.podresform}_weekinterval`}
                            options={intervalOptions}
                            defaultText={curLangData?.text.SelectInterval as string}
                            required
                            error={hasValidationError && !isIntervalValid}
                            openStatic
                            nopadding
                            width={"100%"}
                            selectedOption={intervalOptions.find((o) => o.id === resData.weekInterval?.toString())}
                            onSelect={(option) => {
                                updateResData({ ...resData, weekInterval: parseInt(option.id) })
                                setInfo(undefined);
                            }}
                        />
                    </PodMgmtStyle.FormRow>
                    <PodMgmtStyle.FormRow>
                        <Datepicker
                            selectedDate={resData?.startDate}
                            setSelectedDate={(date) => {
                                updateResData({ ...resData, startDate: date });
                                setInfo(undefined);
                            }}
                            label={curLangData?.text.Startdate as string}
                            id={`${TestId.podresform}_startdate`}
                            width={"100%"}
                            disableWeekend
                            openStatic
                            simplified
                            nopadding
                            required
                            error={hasValidationError && !isStartDateValid}
                            minDate={getFirstAndLastDayOfQuarter(resData?.quarter, selectedQuarterYear).from}
                            maxDate={getFirstAndLastDayOfQuarter(resData?.quarter, selectedQuarterYear).to}
                        />
                    </PodMgmtStyle.FormRow>
                </>
            }

            {renderInfo()}
            {renderError()}

            {renderButtons()}
        </PodMgmtStyle.Form>
    )
}