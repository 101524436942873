import { Floor } from "Main/Floor/Floor";
import { BookingSettings } from "Main/Settings/BookingSettings";
import React from "react";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { FloorPage } from "Shared/Globals";

export const AdminSection = (props: { navHeight?: number }) => {
    const [selectedFloor, setSelectedFloor] = React.useState<DropdownOption>();
    return (
        <>
            <BookingSettings
                selectedFloor={selectedFloor}
                setSelectedFloor={setSelectedFloor}
                page={FloorPage.adminUnit}
            />

            {selectedFloor != null &&
                <Floor
                    selectedFloor={selectedFloor}
                    page={FloorPage.adminUnit}
                />
            }
        </>
    )
}