import { color, font } from "Style/Globals";
import styled from "styled-components";

export const ExistingItemsStyle = {
    Container: styled.div`
        margin: 20px 0;
    `,
    Header: styled.button`
        position: relative;
        cursor: pointer;
        padding-left: 15px;
        color: ${color.blue};
    `,
    HeaderIcon: styled.div<{ active: boolean }>`
        position: absolute;
        top: -6px;
        left: -13px;
        transform: rotate(${props => props.active ? "90deg" : "0"});
        transition: 200ms ease-in;
    `,
    HeaderText: styled.div`
        font-size: 18px;
        font-family: ${font.bold};
    `,
    ContentCell: styled.div` {
        & > a {
            display: inline-block;
            font-size: 16px;
            position: relative;
            padding-right: 25px;
            transition: 200ms ease-in;
            color: ${color.black};

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }

            & > i {
                position: absolute;
                top: 0;
                right: 0;
                transition: 200ms ease-in;
                color: ${color.black};

                @media (prefers-color-scheme: dark) {
                    color: ${color.white};
                }
            }

            &:hover {
                color: ${color.blueHover};

                & > i {
                    right: -5px;
                    color: ${color.blueHover};
                }
            }
        }
    `,
}