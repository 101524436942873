import { DefaultButton } from "../DefaultButton/Style";
import { Loading } from "../Loading/Loading";
import { ButtonRowStyle } from "./Style";

export const ButtonRow = (props: {
    primaryText: string;
    primaryType?: "button" | "submit" | "reset";
    onPrimaryClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    secondaryText?: string;
    secondaryType?: "button" | "submit" | "reset";
    onSecondaryClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    id: string;
    marginTop?: string;
    disablePrimary?: boolean;
}) => {
    return (
        <ButtonRowStyle.Container
            marginTop={props.marginTop}
            data-testid={`${props.id}_container`}
        >
            {props.secondaryText != null && props.onSecondaryClick != null &&
                <ButtonRowStyle.Button isSecondary>
                    <DefaultButton
                        type={props.secondaryType}
                        onClick={props.onSecondaryClick}
                        data-testid={`${props.id}_secondary`}
                        disabled={props.isDisabled || props.isLoading}
                        secondary
                    >
                        {props.secondaryText}
                    </DefaultButton>
                </ButtonRowStyle.Button>
            }
            <ButtonRowStyle.Button>
                <DefaultButton
                    type={props.primaryType}
                    onClick={props.onPrimaryClick}
                    data-testid={`${props.id}_primary`}
                    disabled={props.isDisabled || props.isLoading || props.disablePrimary}
                >
                    {props.primaryText}
                </DefaultButton>
            </ButtonRowStyle.Button>
            {props.isLoading &&
                <ButtonRowStyle.Loading data-testid={`${props.id}_loading`}>
                    <Loading position={"absolute"} />
                </ButtonRowStyle.Loading>
            }
        </ButtonRowStyle.Container>
    )
}