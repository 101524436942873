import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import React from "react";
import { Config } from "./ConfigContext";
import { Desk } from "Shared/Functions/Desk";
import { BookingData, FloorAbbr, FloorData, PodPosData, PodResData, UserProfileData } from "../Globals";
import { ITextData } from "./LangContextDTO";
import { encodeText } from "Shared/Functions/URI";

const getDateOnly = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

const toDoubleDigit = (n: number) => n.toString().padStart(2, "0");

const getDateString = (date: Date) => {
    return `${date.getFullYear()}-${toDoubleDigit(date.getMonth() + 1)}-${toDoubleDigit(date.getDate())}T${toDoubleDigit(date.getHours())}:${toDoubleDigit(date.getMinutes())}`;
}

export interface OrgUnitDTO {
    id: number,
    name: string,
    treeLevel?: number,
}

export interface DeskGroupDTO {
    deskGroupId: string,
    units: number[],
    deskGroupType?: number,
}

const convertResponse = (res: Response, customParser?: (key: any, value: any) => any) => {
    if (res.ok) {
        return res.text().then(t => JSON.parse(t, customParser));
    } else {
        if (res.bodyUsed) {
            return res.json().then(jres => Promise.reject(jres));
        }
        return Promise.reject();
    }
}

const convertBookingDataResponse = (res: Response) => {
    return convertResponse(res, (key, value) => {
        if (value && (key === "date" || key === "endDate"))
            return new Date(value);
        return value;
    });
}

const convertPodResDataResponse = (res: Response) => {
    return convertResponse(res, (key, value) => {
        if (value && (key === "day"))
            return new Date(value);
        return value;
    });
}


export class API {
    instance: IPublicClientApplication;
    config: Config;

    constructor(msalInstance: IPublicClientApplication, config: Config) {
        this.instance = msalInstance;
        this.config = config;
    }

    private fetchWithToken(input: RequestInfo, init?: RequestInit | undefined) {
        const innerRequestInit = init ?? {};
        return this.instance.acquireTokenSilent({
            scopes: [`api://${this.config.appId}/Bookings.Manage`],
            account: this.instance.getActiveAccount() as AccountInfo
        }).then((jresonse) => {
            const headers = new Headers(innerRequestInit.headers);
            headers.append('Authorization', `Bearer ${jresonse.accessToken}`);
            innerRequestInit.headers = headers;
            return fetch(input, innerRequestInit);
        })
    }

    private fetchWithTokenFromWS(input: string, init?: RequestInit | undefined) {
        return this.fetchWithToken(this.config.webServiceBaseAdress + input, init);
    }

    test() {
        return this.fetchWithTokenFromWS("/Seats/getMySeats")
    }

    async getFloorData(floor: FloorAbbr, noDG?: boolean): Promise<FloorData> {
        const jsonProm: Promise<FloorData> =
            fetch(`/data/floors/${floor}.json`)
                .then(res => res.json());

        if (noDG) return jsonProm;

        let apiProm: Promise<DeskGroupDTO[]>;

        if (floor === FloorAbbr.ALL) {
            apiProm = this.DeskGroup.getDeskGroups();
        } else {
            apiProm = this.DeskGroup.getDeskGroupsForFloor(floor);
        }

        return Promise.all([jsonProm, apiProm]).then(([jsonRes, apiRes]) => {
            jsonRes.deskgroup.forEach(group => {
                let matchingGrp = apiRes.find(dg => {
                    return dg.deskGroupId === group.name
                });
                if (matchingGrp) {
                    group.units = matchingGrp.units;
                    group.deskGroupType = matchingGrp.deskGroupType
                }
            });
            return jsonProm;
        });
    }

    getFixedElements(floor: FloorAbbr): Promise<string[][]> {
        return fetch(`/data/floors/${floor}.json`).then((res) => res.json()).then((jres) => jres.fixed);
    }

    getTextStrings(lang: string): Promise<ITextData> {
        const textProm = fetch(`/data/lang/${lang}.json`).then(r => r.json());
        const errorProm = fetch(`/data/lang/error${lang}.json`).then(r => r.json());
        const infoProm = fetch(`/data/lang/info${lang}.json`).then(r => r.json());
        return Promise
            .all([textProm, errorProm, infoProm])
            .then((res) => {
                return {
                    text: res[0],
                    error: res[1],
                    info: res[2]
                }
            })
    }

    getPodIds(): Promise<string[]> {
        return fetch("/data/floors/PAR2.json")
            .then(res => res.json())
            .then((jres) => jres.pods.map((p: any) => p.id));
    }

    getBookingData(date: Date, floor: FloorAbbr): Promise<BookingData[]> {
        return this.fetchWithTokenFromWS(`/Seats/${floor}/bookings?from=${getDateOnly(date)}`).then(convertBookingDataResponse)
    }

    getMyBookings(): Promise<BookingData[]> {
        return this.fetchWithTokenFromWS("/Seats/getMySeats")
            .then(convertBookingDataResponse);
    }

    bookSeat(desk: Desk, from: Date, to?: Date): Promise<BookingData> {
        if (to == null) {
            to = new Date(from);
            to.setDate(to.getDate() + 1);
        }

        return this.fetchWithTokenFromWS(`/Seats/${desk.Floor.Id}/${desk.UniqueId}/bookings`, {
            method: "POST",
            body: JSON.stringify({
                // from: from.toISOString(),
                // to: to.toISOString()
                from: getDateString(from),
                to: getDateString(to)
            }),
            headers: [
                ['Content-Type', 'application/json']
            ]
        }).then(convertBookingDataResponse);
    }

    unbookSeat(desk: Desk, bookingId: string): Promise<void> {
        return this.fetchWithTokenFromWS(`/Seats/${desk.Floor.Id}/${desk.UniqueId}/bookings/${bookingId}`, {
            method: "DELETE"
        })
            .then(res => !res.ok ? res.json()
                .then((jres) => Promise.reject(jres)) : Promise.resolve());
    }
    GetBookingsBySeat(desk: Desk, from: Date, to?: Date): Promise<BookingData[]> {
        return this.fetchWithTokenFromWS(`/Seats/${desk.Floor.Id}/${desk.UniqueId}/bookings?from=${getDateString(from)}${to == null ? "" : ("&to=" + getDateString(to))}`)
            .then(convertBookingDataResponse);
    }

    GetUserProfile(): Promise<UserProfileData> {
        return this.fetchWithTokenFromWS("/User")
            .then(convertResponse);
    }

    SaveUserProfile(profileData: UserProfileData) {
        return this.fetchWithTokenFromWS("/User", {
            method: "POST",
            body: JSON.stringify(profileData),
            headers: [
                ['Content-Type', 'application/json']
            ]
        })
            .then(r => {
                if (!r.ok) {
                    return Promise.reject();
                }
            });
    }

    GetBookingsByUser(userId: string, from?: Date, to?: Date): Promise<BookingData[]> {
        const params = new URLSearchParams();
        if (from != null) {
            params.append("from", getDateString(from))
        }
        if (to != null) {
            params.append("to", getDateString(to))
        }

        return this.fetchWithTokenFromWS(`/User/${userId}/bookings?${params.toString()}`)
            .then(convertBookingDataResponse);
    }

    confirmBooking(bookingId: string): Promise<void> {
        return this.fetchWithTokenFromWS(`/Seats/bookings/${bookingId}/confirm`, {
            method: "POST"
        })
            .then(res => !res.ok ?
                res.json()
                    .then((jres) => Promise.reject(jres)) :
                Promise.resolve());
    }

    public DeskGroup = {
        getDeskGroups: (): Promise<DeskGroupDTO[]> => {
            return this.fetchWithTokenFromWS(`/DeskGroup`)
                .then(res => res.json());
        },
        getDeskGroup: (id: string): Promise<DeskGroupDTO> => {
            return this.fetchWithTokenFromWS(`/DeskGroup/${id}`)
                .then(res => res.json());
        },
        getDeskGroupsForFloor: (floor: string): Promise<DeskGroupDTO[]> => {
            return this.fetchWithTokenFromWS(`/Floor/${floor}/deskGroups`)
                .then(res => res.json());
        },
        setOrgUnitAndDeskTypeForDeskGroup: (deskId: string, orgUnitId?: number[], dgType?: number): Promise<void> => {
            const body = {
                units: orgUnitId,
                deskGroupType: dgType,
            }
            return this.fetchWithTokenFromWS(`/DeskGroup/${deskId}`, {
                method: "POST",
                headers: [['Content-Type', 'application/json']],
                body: JSON.stringify(body)
            }).then(res => !res.ok ?
                res.json().then((jres) => Promise.reject(jres)) :
                Promise.resolve());
        }
    }

    public OrgUnit = {
        getOrgUnits: (): Promise<OrgUnitDTO[]> => {
            return this.fetchWithTokenFromWS(`/OrgUnit`).then(res => res.json());
        },
        getOrgUnit: (id: string): Promise<OrgUnitDTO> => {
            return this.fetchWithTokenFromWS(`/OrgUnit/${id}`).then(res => res.json());
        }
    };

    public Admin = {

        blockSeat: (desk: Desk, from: Date, to?: Date): Promise<BookingData> => {
            return this.fetchWithTokenFromWS(`/Admin/${desk.Floor.Id}/${desk.UniqueId}/block`, {
                method: "POST",
                body: JSON.stringify({
                    from: getDateString(from),
                    to: to != null ? getDateString(to) : null
                }),
                headers: [
                    ['Content-Type', 'application/json']
                ]
            }).then(convertBookingDataResponse);
        },

        unblockSeat: (desk: Desk, date: Date) => {
            return this.fetchWithTokenFromWS(`/Admin/${desk.UniqueId}/unblock(${getDateOnly(date)})`, {
                method: "POST"
            }).then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
            });
        },

        getReportData: (floor: string, fromDate: Date, toDate?: Date, seatType?: string[], units?: string[]): Promise<BookingData[]> => {
            const seatTypes = seatType != null ? (seatType.map((s) => `deskGroupTypes=${s}`)) : [];
            const oUnits = units != null ? (units.map((u) => `orgUnitIds=${u}`)) : [];

            let seatTypeString = "";
            let unitString = "";

            if (oUnits.length > 0) unitString = oUnits.join("&");

            if (seatTypes.length > 0) {
                seatTypeString = seatTypes.join("&");
                if (oUnits.length > 0) unitString = "&" + unitString;
            }

            const prefix = (seatTypes.length > 0 || oUnits.length > 0) ? "?" : "";

            const param = prefix + seatTypeString + unitString;

            const endPoint = `/Admin/${floor}/` + (toDate == null ?
                `getReportFor(${getDateOnly(fromDate)})` :
                `getReportForRange(${getDateOnly(fromDate)},${getDateOnly(toDate)})`) + param;
            return this.fetchWithTokenFromWS(endPoint)
                .then(convertBookingDataResponse);
        },


        addPodReservation: (data: PodResData): Promise<PodResData> => {
            return this.fetchWithTokenFromWS(`/Admin/pods`, {
                method: "POST",
                body: JSON.stringify(data, function (key, value) {
                    if (key === "day") {
                        return getDateString(this.day);
                    }
                    return value;
                }),
                headers: [
                    ['Content-Type', 'application/json']
                ]
            }).then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
                return convertPodResDataResponse(res);
            })
        }
    }

    public Pod = {
        getPodPositions: (floor: FloorAbbr): Promise<PodPosData[]> => {
            return fetch(`/data/floors/${floor}.json`).then((res) => res.json()).then((jres) => jres.pods);
        },

        getPodReservationsByDate: (date: Date, floor: FloorAbbr): Promise<PodResData[]> => {
            return this.fetchWithTokenFromWS(`/floor/${floor}/pods/getByDate(${getDateOnly(date)})`).then(convertPodResDataResponse)
        },

        getPodReservations: (from?: Date, to?: Date, teamname?: string): Promise<PodResData[]> => {
            const fromAddition = from != null ? `?from=${getDateOnly(from)}` : "";
            const sep1 = from != null ? "&" : "?";
            const toAddition = to != null ? `${sep1}to=${getDateOnly(to)}` : "";
            const sep2 = (from != null || to != null) ? "&" : "?";
            const teamAddition = teamname != null ? `${sep2}teamName=${encodeText(teamname)}` : "";
            return this.fetchWithTokenFromWS(`/Pods/${fromAddition + toAddition + teamAddition}`).then(convertPodResDataResponse)
        },

        deletePodReservation: (id: string): Promise<void> => {
            return this.fetchWithTokenFromWS(`/Pods/${id}`, {
                method: "DELETE"
            }).then(res => {
                if (!res.ok) {
                    return Promise.reject();
                }
            });
        },

        addPodReservations: (reservations: PodResData[]) => {
            const newRes = reservations.map((res) => { return { ...res, day: getDateString(res.day) } })
            return this.fetchWithTokenFromWS(`/Pods/`, {
                method: "POST",
                body: JSON.stringify(newRes),
                headers: [
                    ['Content-Type', 'application/json']
                ]
            }).then(res => !res.ok ? res.json()
                .then((jres) => Promise.reject(jres)) : Promise.resolve());
        }
    }
}

export const ApiContext = React.createContext<API>({} as API)

export const useApi = () => React.useContext(ApiContext);
