import React from "react";

export const useEffectOnce = (effect: () => void | (() => void)) => {
    const destroyFunc = React.useRef<void | (() => void)>();
    const effectCalled = React.useRef(false);
    const renderAfterCalled = React.useRef(false);

    if (effectCalled.current) {
        renderAfterCalled.current = true;
    }

    React.useEffect(() => {
        // only execute the effect first time around
        if (!effectCalled.current) {
            destroyFunc.current = effect();
            effectCalled.current = true;
        }

        return () => {
            // if the comp didn't render since the useEffect was called,
            if (!renderAfterCalled.current) {
                return;
            }
            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
    }, [/* this should be empty - otherwise it doesnt't work */]);
};