
import useUser from "Shared/Context/UserContext";
import { Checkbox } from "Shared/Components";
import { FinderStyle } from "./Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";

export const PeopleFinderSettings = () => {
    const { profileData, setProfile } = useUser();
    const { curLangData } = useLang();

    return (
        <FinderStyle.CheckboxContainer>
            <Checkbox
                id={TestId.peopleFinderSettingsCheckbox}
                label={curLangData?.info.Consent as string}
                defaultChecked={profileData?.publicBookings}
                onChange={() => setProfile({ publicBookings: !profileData?.publicBookings })}
            />
        </FinderStyle.CheckboxContainer>
    )
}