export const TestId = {
    floorselect: "df_floorselect",
    seattypeselect: "df_seattypeselect",
    datepicker: "df_datepicker",
    timecheckbox: "df_timecheckbox",
    timepicker: "df_timepicker",
    durationSelect: "df_durationselect",
    peopleFinderNav: "df_peoplefindernav",
    peopleSelect: "df_peopleselect",
    peopleFinderSettingsCheckbox: "df_peoplefinder_settingscheckbox",
    floor: "df_floormap",
    peopleFinderResult: "df_peoplefinderresult",
    nav: "df_nav",
    mybookingsnav: "df_mybookingsnav",
    mybookings: "df_mybookings",
    flyout: "df_flyout",
    fileUpload: "df_fileupload",
    adminNav: "df_adminnav",
    reportFloors: "df_reportfloorselect",
    reportDeskType: "df_reportdesktypeselect",
    reportStartDate: "df_reportstartdatepicker",
    reportEndDate: "df_reportenddatepicker",
    report: "df_report",
    deskinfo: "df_deskinfo",
    podinfo: "df_podinfo",
    deskgroupinfo: "df_deskgroupinfo",
    infobox: "df_infobox",
    pager: "df_pager",
    dropdown: "df_dropdown",
    langselect: "df_langselect",
    podresform: "df_podresform",
    sidepanel: "df_sidepanel",
    teamdays: "df_teamdays",
    fixedElement: "df_fixedElement",
    existingBookings: "df_existingBookings",
    existingBookingsItem: "df_existingBookingsItem",
    existingReservations: "df_existingReservations",
    existingReservationItem: "df_existingReservationItem",
    pod: "df_pod",
}