import React from "react";
import { getFirstAndLastDayOfQuarter } from "Shared/Functions/DateMath";
import { ExtendedPodResData } from "Shared/Globals";
import { useApi } from "./API";

export interface Teamday {
    date: Date;
    id?: string;
}

export interface TeamdaysItem {
    selected: Teamday[],
    disabled: Teamday[]
}

export interface TeamdaysContextState {
    teamdays: TeamdaysItem;
    updateTeamdays: (teamdays: TeamdaysItem) => void;
    getTeamdays: (data: ExtendedPodResData) => void;
    teamdaysLoading: boolean;
    teamdaysError: string | undefined;
}

export const TeamdaysContext = React.createContext<TeamdaysContextState>({
    teamdays: { selected: [], disabled: [] },
    updateTeamdays: () => { },
    getTeamdays: () => { },
    teamdaysLoading: false,
    teamdaysError: undefined,
})

export const TeamdaysProvider = (props: { children: JSX.Element }) => {
    const [teamdays, setTeamdays] = React.useState<TeamdaysItem>({ selected: [], disabled: [] });
    const [teamdaysLoading, setTeamdaysLoading] = React.useState(false);
    const [teamdaysError, setTeamdaysError] = React.useState<string | undefined>(undefined);

    const api = useApi();

    const sortDays = (arr: Teamday[]) => {
        return arr.sort((a, b) => a.date.getTime() - b.date.getTime());
    }

    const updateTeamdays = React.useCallback((teamdays: TeamdaysItem) => {
        setTeamdays({ selected: sortDays(teamdays.selected), disabled: sortDays(teamdays.disabled) });
    }, []);


    const getTeamdays = React.useCallback((data: ExtendedPodResData) => {
        setTeamdaysLoading(true);
        const q = getFirstAndLastDayOfQuarter(data.quarter as number, data.year as number);
        const startDate = q.from.getTime() < new Date().getTime() ? new Date() : q.from;
        api.Pod
            .getPodReservations(startDate, q.to, data.teamname)
            .then((res) => {
                setTeamdaysError(undefined);
                updateTeamdays({
                    selected: res.map((r) => {
                        return {
                            date: r.day,
                            id: r.id
                        }
                    }),
                    disabled: []
                })
            })
            .catch(() => setTeamdaysError("") /* TODO */)
            .finally(() => setTeamdaysLoading(false))
    }, [api.Pod, updateTeamdays])

    const providerValue = React.useMemo(() => (
        { teamdays, updateTeamdays, getTeamdays, teamdaysLoading, teamdaysError }
    ),
        [teamdays, updateTeamdays, getTeamdays, teamdaysLoading, teamdaysError]
    );

    return (
        <TeamdaysContext.Provider value={providerValue}>
            {props.children}
        </TeamdaysContext.Provider>
    )
}

const useTeamdays = () => React.useContext(TeamdaysContext);
export default useTeamdays;