import React from "react";
import { Checkbox } from "Shared/Components/Formfields";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { Icon } from "Shared/Components/Icon/Style";
import { MultiselectStyle } from "./Style";

export const MultiselectItem = (props: {
    option: DropdownOption,
    selectedOptions: string[],
    setSelectedOptions: (value: string[]) => void,
    id: string;
    disabled?: boolean;
}) => {
    const hasChildren = props.option.children && props.option.children?.length > 0;

    const checkIfChildrenAreSelected = () => {
        let selected = false;
        if (hasChildren) {
            props.option.children?.forEach((child) => {
                if (props.selectedOptions.includes(child.id)) selected = true;
            });
        }
        return selected;
    };

    const [showChildren, setChildrenVisible] = React.useState(checkIfChildrenAreSelected());

    const onChangeWithChildren = React.useCallback(() => {
        const _setSelectedOptions = props.setSelectedOptions;
        const children = props.option.children;
        const selOptArr = props.selectedOptions.map((o) => o);
        let count = 0;
        children?.forEach((child: any) => {
            const idx = selOptArr.indexOf(child.id);
            if (idx > -1) {
                selOptArr.splice(idx, 1);
                count++;
            }
        });

        if (count === 0 && children) {
            children?.forEach((child: any) => selOptArr.push(child.id));
        }

        setChildrenVisible(count === 0);
        _setSelectedOptions(selOptArr);
    }, [props.option.children, props.selectedOptions, props.setSelectedOptions]);

    const onChange = React.useCallback((option: DropdownOption) => {
        const _setSelectedOptions = props.setSelectedOptions;
        const selOptArr = props.selectedOptions.map((o) => o);
        const idx = selOptArr.indexOf(option.id);
        if (idx > -1) {
            selOptArr.splice(idx, 1);
        } else {
            selOptArr.push(option.id);
        }
        _setSelectedOptions(selOptArr);
    }, [props.selectedOptions, props.setSelectedOptions]);

    return (
        <MultiselectStyle.Item withChildren={hasChildren}>
            {hasChildren &&
                <MultiselectStyle.ItemParentButton
                    showChildren={showChildren}
                    onClick={() => setChildrenVisible(!showChildren)}
                    data-testid={`${props.id}_buttonParent_${props.option.id}`}
                >
                    {!showChildren && <Icon.PfeilRechts isBold size={"35px"} />}
                    {showChildren && <Icon.PfeilUnten isBold size={"35px"} />}
                </MultiselectStyle.ItemParentButton>
            }

            <Checkbox
                id={`${props.id}_${props.option.id}`}
                label={props.option.text ?? props.option.id}
                onChange={() => hasChildren ? onChangeWithChildren() : onChange(props.option)}
                checked={hasChildren ? checkIfChildrenAreSelected() : props.selectedOptions.includes(props.option.id)}
                disabled={props.disabled}
            />

            {showChildren &&
                <div>
                    {props.option.children?.map((child) => {
                        return (
                            <div key={`${props.id}_${child.id}`}><Checkbox
                                id={`${props.id}_${child.id}`}
                                label={child.text ?? child.id}
                                onChange={() => onChange(child)}
                                checked={props.selectedOptions.includes(child.id)}
                                disabled={props.disabled}
                            /></div>
                        )
                    })}
                </div>
            }
        </MultiselectStyle.Item>
    )
}