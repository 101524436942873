import { compareDates } from "Shared/Functions/DateMath";
import { PodData, PodPosData } from "Shared/Globals";

export const FilteredPodData = (
    podId: string,
    selectedDate: Date,
    podData?: PodData[]
) => {
    if (podData) {
        const data = podData.find((p) => p.position.id === podId);
        const position = data?.position as PodPosData;
        const reservation = data?.reservations?.find((r) => compareDates(r.day, selectedDate));
        const bookings = data?.bookings?.filter((booking) => compareDates(booking.date, selectedDate)) ?? [];
        return { position: position, reservations: reservation ? [reservation] : [], bookings: bookings }
    }

}