import { IError } from "../Context/LangContextDTO";

export const ErrorMsg = (
    msgId: number,
    admin?: boolean,
    del?: boolean,
    error?: IError
) => {
    if (error) {
        const errMsgs = [
            del ? error.BookingNotFound : error.BookingNotFoundConf,
            error.SeatAlreadyBooked,
            admin ? error.SeatAlreadyBlockedAdmin : error.SeatAlreadyBlockedUser,
            error.ConfirmationOutOfRange,
            error.PodAlreadyFull,
            error.PodAlreadyBookedByUser,
            error.InvalidSeatId,
            error.PodNotAvailableForBooking,
            error.NotAllowedToBookByCurrentUser,
            "",
            "",
            error.PodAlreadyReserved
        ];
        return errMsgs[msgId];
    }
    return null;
};