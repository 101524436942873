import { getDateRangeString, getTimeString } from "Shared/Functions/DateMath";
import { DeskState, BookingData, FloorPage, seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { FloorStyle } from "Main/Floor/Style";
import { TestId } from "Test/TestId";
import useLang from "Shared/Context/LangContext";
import React from "react";
import { Desk } from "Shared/Functions/Desk";
import useOrgUnits from "Shared/Context/OrgUnitContext";

export const DefaultInfo = (props: React.PropsWithChildren<{
    deskId: string;
    page: FloorPage;
    selectedFrom: Date;
    selectedTo?: Date;
    deskState?: DeskState;
    bookingData?: BookingData;
    seatType?: number;
    units?: number[];
}>) => {
    const { curLang, curLangData } = useLang();
    const { units } = useOrgUnits();

    const getBlockedData = () => {
        if (props.bookingData != null) {
            return getDateRangeString(props.bookingData.date, props.bookingData.endDate, curLang?.browser as string);
        }
    }

    const desk = new Desk(props.deskId);

    const seatTypes = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;

    const orgunits = React.useMemo(() => {
        return units?.filter((u) => props.units?.find((ou) => ou === u.id))
    }, [props.units, units]);

    const unitNames = React.useMemo(() => {
        if (orgunits != null && orgunits.length > 0) {
            return orgunits.sort((a, b) => {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();
                if (aName < bName) return -1;
                if (aName > bName) return 1;
                return 0;
            });
        }
        return []
    }, [orgunits])

    return (
        <>
            <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowStartDate`}>
                <strong>{curLangData?.text.Date}</strong>
                <span>{props.selectedFrom.toLocaleDateString(curLang?.browser, { dateStyle: "long" })}</span>
            </FloorStyle.DeskInfoRow>

            {props.page === FloorPage.admin && props.bookingData != null && props.deskState === DeskState.disabled &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowBlockedDate`}>
                    <strong>{curLangData?.text.BlockingPeriod}</strong>
                    <span>{getBlockedData()}</span>
                </FloorStyle.DeskInfoRow>
            }

            {props.page !== FloorPage.admin && props.deskState != null && props.deskState !== DeskState.disabled &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowTime`}>
                    <strong>{curLangData?.text.Time}</strong> <span>{getTimeString(
                        props.selectedFrom,
                        curLangData?.text.FullTime as string,
                        props.selectedTo,
                        curLang?.id === 1 ? curLangData?.text.Clock : undefined
                    )}</span>
                </FloorStyle.DeskInfoRow>
            }

            <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowFloor`}>
                <strong>{curLangData?.text.Location}</strong> <span>{desk.Floor.Name}</span>
            </FloorStyle.DeskInfoRow>

            {(desk.Area != null && desk.Area.length > 0) &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowArea`}>
                    <strong>{curLangData?.text.Area}</strong> <span>{desk.Area}</span>
                </FloorStyle.DeskInfoRow>
            }

            {props.seatType != null && props.seatType !== 0 && props.page !== FloorPage.adminUnit &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowSeatType`}>
                    <strong>{curLangData?.text.DeskType}</strong> <span>{seatTypes[props.seatType].text}</span>
                </FloorStyle.DeskInfoRow>
            }

            {props.units != null && props.units.length > 0 && unitNames.length > 0 &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowUnit`} style={{ paddingTop: "20px" }}>
                    <strong>{curLangData?.text.ReservedFor}</strong>
                    {unitNames.length > 1 ?
                        <ul>
                            {unitNames.map((u) => {
                                return <li key={u.id}>{u.name}</li>
                            })}
                        </ul>
                        :
                        <span>{unitNames[0].name}</span>
                    }
                </FloorStyle.DeskInfoRow>
            }

            {props.page === FloorPage.admin && props.children &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskinfo}_rowEndDate`} style={{ paddingTop: "20px" }}>
                    {props.children}
                </FloorStyle.DeskInfoRow>
            }
        </>
    )
}