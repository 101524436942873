import React from "react";
import { useNavigate } from "react-router-dom";
import { Flyout, Icon, InfoBox, InfoBoxType } from "Shared/Components";
import { TableRow } from "Shared/Components/Table/Style";
import { useApi } from "Shared/Context/API";
import useLang from "Shared/Context/LangContext";
import usePodReservation from "Shared/Context/PodReservationContext";
import { encodeText } from "Shared/Functions/URI";
import { ExtendedPodResData } from "Shared/Globals"
import { PodMgmtStyle } from "./Style";

export const ReservationListItem = (props: {
    data?: ExtendedPodResData;
    isHeader?: boolean;
    onDelete?: () => void;
    containerWidth: number;
}) => {
    const { curLangData } = useLang();
    const [showFlyout, setFlyoutVisible] = React.useState(false);
    const [isDeleting, setDeleting] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const { clearResData } = usePodReservation();
    const api = useApi();

    let navigate = useNavigate();

    const onDismiss = () => {
        setError(undefined);
        setFlyoutVisible(false);
    }

    const deleteRes = () => {
        setDeleting(true);
        const deletingProms = props.data?.children?.map(api.Pod.deletePodReservation);
        if (deletingProms && deletingProms.length > 0) {
            Promise
                .all(deletingProms)
                .then(() => {
                    onDismiss();
                    if (props.onDelete) props.onDelete();
                })
                .catch(() => setError(curLangData?.error.PodAdminBooking))
                .finally(() => setDeleting(false))
        }
    }

    if (props.isHeader) return (
        <TableRow
            minWidth={"600px"}
            isHeader
            gridTemplateColumns={props.containerWidth > 700 ? "1fr 150px 200px 150px" : "repeat(4, 25%)"}
            style={{ paddingLeft: "50px", position: "relative" }}
        >
            <PodMgmtStyle.TeamListCell>
                <span>{curLangData?.text.TeamName2}</span>
            </PodMgmtStyle.TeamListCell>
            <PodMgmtStyle.TeamListCell>
                <span>{curLangData?.text.Quarter}</span>
            </PodMgmtStyle.TeamListCell>
            <PodMgmtStyle.TeamListCell>
                <span>{curLangData?.text.PodId}</span>
            </PodMgmtStyle.TeamListCell>
            <PodMgmtStyle.TeamListCell>
                <span>{curLangData?.text.Teamdays}</span>
            </PodMgmtStyle.TeamListCell>
        </TableRow>
    )
    return (
        <>
            <TableRow
                minWidth={"600px"}
                gridTemplateColumns={props.containerWidth > 700 ? "1fr 150px 200px 150px" : "repeat(4, 25%)"}
                style={{ paddingLeft: "50px", position: "relative" }}
            >
                <PodMgmtStyle.TeamListCell>
                    <button type={"button"} onClick={() => {
                        clearResData();
                        navigate(`${encodeText(props.data?.teamname as string)}/${props.data?.quarter}`)
                    }}>
                        {props.data?.teamname}
                    </button>
                </PodMgmtStyle.TeamListCell>
                <PodMgmtStyle.TeamListCell>
                    <span>{props.data?.quarter}</span>
                </PodMgmtStyle.TeamListCell>
                <PodMgmtStyle.TeamListCell>
                    <span>{props.data?.podId}</span>
                </PodMgmtStyle.TeamListCell>
                <PodMgmtStyle.TeamListCell>
                    <span>{props.data?.children?.length}</span>
                </PodMgmtStyle.TeamListCell>
                <PodMgmtStyle.TeamListDelBtn>
                    <button
                        type={"button"}
                        onClick={() => setFlyoutVisible(true)}
                        title={curLangData?.text.DeleteReservation2}
                    ><Icon.Papierkorb size={"23px"} /></button>
                </PodMgmtStyle.TeamListDelBtn>
            </TableRow>
            {showFlyout &&
                <Flyout
                    visible
                    onDismiss={onDismiss}
                    title={curLangData?.text.DeleteReservation2}
                    width={"500px"}
                    onConfirm={deleteRes}
                    onConfirmText={curLangData?.text.Delete}
                    isLoading={isDeleting}
                >
                    {!error &&
                        <PodMgmtStyle.FlyoutText>
                            <p>{curLangData?.text.TeamName2}: <strong>{props.data?.teamname}</strong> | {curLangData?.text.Quarter}: <strong>{props.data?.quarter}</strong></p>
                            <p>{curLangData?.info.DelPodRes}</p>
                        </PodMgmtStyle.FlyoutText>
                    }
                    {error &&
                        <InfoBox
                            type={InfoBoxType.error}
                            text={error}
                            title={curLangData?.text.Attention}
                            marginTop={"20px"}
                        />
                    }
                </Flyout>
            }
        </>
    )
}