import { css } from 'styled-components';
import { color } from '.';

export const placeholder = css`
    &::-webkit-input-placeholder {
        color: ${color.placeHolderLight};

        @media (prefers-color-scheme: dark) {
            color: ${color.placeHolderDark} !important;
        }
    }

    &:-moz-placeholder {
        color: ${color.placeHolderLight};
        opacity: 1;

        @media (prefers-color-scheme: dark) {
            color: ${color.placeHolderDark} !important;
        }
    }

    &::-moz-placeholder {
        color:  ${color.placeHolderLight};
        opacity: 1;

        @media (prefers-color-scheme: dark) {
            color: ${color.placeHolderDark} !important;
        }
    }

    &:-ms-input-placeholder {
        color:  ${color.placeHolderLight} !important;

        @media (prefers-color-scheme: dark) {
            color: ${color.placeHolderDark} !important;
        }
    }
`