import { FloorStyle } from "Main/Floor/Style";
import React from "react";
import { ButtonRow, InfoBox, InfoBoxType, Loading, Multiselect } from "Shared/Components";
import { Dropdown, DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import { useApi } from "Shared/Context/API";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import useOrgUnits from "Shared/Context/OrgUnitContext";
import { Desk } from "Shared/Functions/Desk";
import { seatTypeOptionsDE, seatTypeOptionsEN } from "Shared/Globals";
import { TestId } from "Test/TestId";

export const DeskGroupInfoAdmin = (props: {
    id: string;
    onDismiss: () => void;
    onSave: () => void;
}) => {
    const [selectedUnits, setSelectedUnits] = React.useState<DropdownOption[] | undefined>();
    const [isLoading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>();
    const { level2Units, unitsLoading } = useOrgUnits();
    const { curLang, curLangData } = useLang();
    const { deskGroupData } = useFloor();
    const api = useApi();

    const seatTypeOptions = curLang?.id === 1 ? seatTypeOptionsDE : seatTypeOptionsEN;
    const [selectedSeatType, setSelectedSeatType] = React.useState<DropdownOption>(seatTypeOptions[0]);

    const data = React.useMemo(() => {
        return deskGroupData.find((d) => d.name === props.id)
    }, [deskGroupData, props.id])

    React.useEffect(() => {
        setSelectedUnits(undefined);
        setSelectedSeatType(seatTypeOptions[0]);
        if (data?.units != null && data.units.length > 0 && level2Units != null) {
            const selUnits: DropdownOption[] = []
            data.units.forEach((unit) => {
                const findunit = level2Units.find((u) => Number.parseInt(u.id) === unit);
                if (findunit) selUnits.push(findunit);
            })
            setSelectedUnits(selUnits.length > 0 ? selUnits : undefined)
        } else if (data?.deskGroupType != null && data.deskGroupType !== parseInt(seatTypeOptions[0].id)) {
            setSelectedSeatType(seatTypeOptions[data.deskGroupType])
        }
    }, [level2Units, data?.units, data?.deskGroupType, seatTypeOptions])

    const changeSeatType = React.useCallback((type: DropdownOption) => {
        setErrorMsg(undefined);
        setSelectedSeatType(type);
        if (type !== seatTypeOptions[0]) setSelectedUnits(undefined);
    }, [seatTypeOptions]);

    const changeOrgUnit = React.useCallback((units: DropdownOption[]) => {
        setErrorMsg(undefined);
        setSelectedUnits(units);
        if (units.length > 0) setSelectedSeatType(seatTypeOptions[0]);
    }, [seatTypeOptions]);

    const deskData = React.useMemo(() => {
        if (data && data.desks.length > 0) return new Desk(data.desks[0].id);
    }, [data])


    const onDismiss = (dismiss?: boolean) => {
        setLoading(false);
        setErrorMsg(undefined);

        if (dismiss) props.onDismiss();
        else props.onSave();
    }

    const onSave = () => {
        setLoading(true);
        setErrorMsg(undefined);
        const units = selectedUnits?.map((unit) => Number.parseInt(unit.id));
        api.DeskGroup.setOrgUnitAndDeskTypeForDeskGroup(data?.name as string, units != null && units?.length > 0 ? units : undefined, parseInt(selectedSeatType.id))
            .then(() => onDismiss())
            .catch(err => {
                let msg = curLangData?.error.AdminSection;
                if (err && err.messageId === 12) msg = curLangData?.info.SeatTypeInfo;
                setErrorMsg(msg)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <FloorStyle.DeskInfoRow data-testid={`${TestId.deskgroupinfo}_rowFloor`}>
                <strong>{curLangData?.text.Location}</strong> <span>{deskData?.Floor.Name}</span>
            </FloorStyle.DeskInfoRow>

            {(deskData?.Area != null && deskData.Area.length > 0) &&
                <FloorStyle.DeskInfoRow data-testid={`${TestId.deskgroupinfo}_rowArea`}>
                    <strong>{curLangData?.text.Area}</strong> <span>{deskData.Area}</span>
                </FloorStyle.DeskInfoRow>
            }

            <FloorStyle.DeskInfoRow data-testid={`${TestId.deskgroupinfo}_rowCapacity`}>
                <strong>{curLangData?.text.DeskCount}</strong> <span>{data?.desks.length}</span>
            </FloorStyle.DeskInfoRow>
            <FloorStyle.DeskInfoForm>
                <FloorStyle.DeskInfoFormRow data-testid={`${TestId.deskgroupinfo}_rowUnit`}>
                    {unitsLoading && <Loading position="absolute" />}
                    {level2Units != null && !unitsLoading &&
                        <Multiselect
                            options={level2Units}
                            label={curLangData?.text.Auth}
                            selectedOptions={selectedUnits}
                            nopadding
                            openStatic
                            width={"100%"}
                            id={`${TestId.deskgroupinfo}_rowUnitSelect`}
                            onSelect={changeOrgUnit}
                            defaultText={curLangData?.text.OrgUnitSelect as string}
                        />
                    }
                    <FloorStyle.DeskInfoFormRow data-testid={`${TestId.deskgroupinfo}_rowSeatType`} style={{ marginTop: "20px" }}>
                        <Dropdown
                            width={"100%"}
                            id={`${TestId.deskgroupinfo}_rowSeatTypeSelect`}
                            options={seatTypeOptions}
                            defaultText={""}
                            label={curLangData?.text.DeskType}
                            nopadding
                            openStatic
                            selectedOption={selectedSeatType}
                            onSelect={changeSeatType}
                        />
                    </FloorStyle.DeskInfoFormRow>
                </FloorStyle.DeskInfoFormRow>
            </FloorStyle.DeskInfoForm>

            <InfoBox
                type={errorMsg === curLangData?.info.SeatTypeInfo ? InfoBoxType.error : InfoBoxType.default}
                text={curLangData?.info.SeatTypeInfo}
                marginTop={"20px"}
            />

            {errorMsg != null && errorMsg !== curLangData?.info.SeatTypeInfo &&
                <InfoBox
                    type={InfoBoxType.error}
                    text={errorMsg.toString()}
                    marginTop={"20px"}
                />
            }

            <ButtonRow
                id={`${TestId.podinfo}_button`}
                primaryText={curLangData?.text.Save as string}
                primaryType={"button"}
                onPrimaryClick={onSave}
                secondaryText={curLangData?.text.Cancel as string}
                secondaryType={"button"}
                onSecondaryClick={() => onDismiss(true)}
                marginTop={"20px"}
                isLoading={isLoading}
                disablePrimary={errorMsg === curLangData?.info.SeatTypeInfo}
            />
        </>
    )
}