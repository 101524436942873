import useLang from "Shared/Context/LangContext"
import { IText } from "Shared/Context/LangContextDTO";
import { PodStateText } from "Main/Floor/Pod/Functions/PodStateText";
import { FloorPage, PodData, PodStateName } from "Shared/Globals";
import { TestId } from "Test/TestId";
import { FloorStyle } from "Main/Floor/Style"

export const PodStateRow = (props: {
    data: PodData;
    page: FloorPage;
    state: PodStateName;
}) => {
    const { curLangData } = useLang();

    return (
        <FloorStyle.DeskInfoRow
            data-testid={`${TestId.podinfo}_rowState`}
            podState={props.state}
        >
            <strong>{curLangData?.text.State}</strong>
            <span><strong>{PodStateText(curLangData?.text as IText, props.page, props.state)}</strong></span>
        </FloorStyle.DeskInfoRow>
    )
}