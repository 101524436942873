import useLayout from "Shared/Context/LayoutContext";
import useMyBookings from "Shared/Context/MyBookingsContext";
import useUser from "Shared/Context/UserContext";
import React from "react";
import { Path } from "Shared/Globals";
import { Icon } from "Shared/Components";
import { NavLink } from "./NavLink";
import { NavStyle } from "./Style";
import useLang from "Shared/Context/LangContext";
import { TestId } from "Test/TestId";

export const Nav = React.forwardRef<HTMLElement>((props, ref) => {
    const { myCurrentBookings } = useMyBookings();
    const { isAdmin, isPodManager } = useUser();
    const { expandNav, updateNav, isMobile } = useLayout();
    const { curLangData } = useLang();

    if (curLangData) return (
        <NavStyle.Container expandNav={expandNav} ref={ref}>
            <NavStyle.List>
                {isMobile(701) && myCurrentBookings != null && myCurrentBookings?.length > 0 &&
                    <NavLink
                        pathTo={Path.MyBookings}
                        text={curLangData.text.MyBookings}
                        expandNav={expandNav}
                        icon={<Icon.KalenderHaken isBold />}
                        onClick={() => updateNav(false)}
                        id={`${TestId.nav}_itemMeineBuchungen`}
                    />
                }
                <NavLink
                    pathTo={Path.Booking}
                    text={curLangData.text.Booking}
                    expandNav={expandNav}
                    icon={<Icon.StandortPin isBold />}
                    onClick={isMobile(701) ? () => updateNav(false) : undefined}
                    id={`${TestId.nav}_itemPlatzbuchen`}
                />
                <NavLink
                    pathTo={Path.PeopleFinder}
                    text={curLangData.text.Colleagues}
                    expandNav={expandNav}
                    icon={<Icon.Mitarbeiter isBold />}
                    onClick={isMobile(701) ? () => updateNav(false) : undefined}
                    id={`${TestId.nav}_itemKolleginnen`}
                />
                <NavLink
                    pathTo={Path.Settings}
                    text={curLangData.text.Settings}
                    expandNav={expandNav}
                    icon={<Icon.Einstellungen isBold />}
                    onClick={isMobile(701) ? () => updateNav(false) : undefined}
                    id={`${TestId.nav}_itemEinstellungen`}
                />
                {(isAdmin || isPodManager) &&
                    <NavLink
                        pathTo={Path.AdminView}
                        text={curLangData.text.Admin}
                        expandNav={expandNav}
                        icon={<Icon.Werkzeug isBold />}
                        onClick={isMobile(701) ? () => updateNav(false) : undefined}
                        id={`${TestId.nav}_itemVerwaltung`}
                    />
                }
                {isAdmin &&
                    <NavLink
                        pathTo={Path.Report}
                        text={curLangData.text.Report}
                        expandNav={expandNav}
                        icon={<Icon.Tortendiagramm isBold />}
                        onClick={isMobile(701) ? () => updateNav(false) : undefined}
                        id={`${TestId.nav}_itemReport`}
                    />
                }
            </NavStyle.List>
        </NavStyle.Container>
    );
    return null;
});