import React from "react";
import ReactDOM from "react-dom";
import { FlyoutContent } from "./FlyoutContent";
import { FlyoutStyle } from "./Style";

interface FlyoutProps {
    visible: boolean;
    onDismiss: () => void;
    title?: string;
    isBlocking?: boolean;
    width?: string;
    onConfirm?: () => void;
    onConfirmText?: string;
    onConfirmAnimation?: boolean;
    onConfirmAnimationEnded?: () => void;
    isLoading?: boolean;
    id?: string;
}

export const Flyout = (props: React.PropsWithChildren<FlyoutProps>) => {
    const [showAnimation, setAnimation] = React.useState(props.onConfirmAnimation);

    const endAnimation = React.useCallback(() => {
        const _endAnimation = props.onConfirmAnimationEnded;
        if (_endAnimation) _endAnimation();
    }, [props.onConfirmAnimationEnded])

    React.useEffect(() => {
        let timer = -1
        if (props.onConfirmAnimation) {
            setAnimation(true);
            timer = window.setTimeout(() => {
                endAnimation();
            }, 2500);
        }
        return () => {
            if (timer >= 0) {
                window.clearTimeout(timer);
            }
        }
    }, [endAnimation, props.onConfirmAnimation, props.onConfirmAnimationEnded])

    const onDismiss = React.useCallback(() => {
        props.onDismiss();
    }, [props]);

    const modal = (
        <FlyoutStyle.Overlay
            onScroll={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.bubbles = false;
            }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.bubbles = false;
                if (!props.isBlocking) {
                    onDismiss();
                }
            }}
            data-testid={props.id ? `${props.id}_modalOverlay` : undefined}
        >
            <FlyoutStyle.Container
                size={props.width}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                data-testid={props.id ? `${props.id}_container` : undefined}
            >
                <FlyoutStyle.Inner>
                    <FlyoutContent
                        onConfirm={props.onConfirm}
                        showAnimation={showAnimation}
                        isLoading={props.isLoading}
                        onDismiss={onDismiss}
                        onConfirmText={props.onConfirmText}
                        title={props.title}
                        id={props.id}
                    >
                        {props.children}
                    </FlyoutContent>
                </FlyoutStyle.Inner>
            </FlyoutStyle.Container>
        </FlyoutStyle.Overlay>
    );

    if (props.visible) {
        return ReactDOM.createPortal(
            modal, document.getElementById("modal") as Element,
        );
    }
    else return null;
}