export const breakpoint = {
    min501: "only screen and (min-width: 501px)",
    min551: "only screen and (min-width: 551px)",
    min701: "only screen and (min-width: 701px)",
    min801: "only screen and (min-width: 801px)",
    min1050: "only screen and (min-width: 1050px)",
    min1300: "only screen and (min-width: 1300px)",
    min1450: "only screen and (min-width: 1450px)",
    min1500: "only screen and (min-width: 1500px)",
    min1600: "only screen and (min-width: 1600px)",
}