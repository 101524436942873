import React from "react";
import { Flyout, InfoBox, InfoBoxType, SidePanel } from "Shared/Components";
import { DropdownOption } from "Shared/Components/Dropdown/Dropdown";
import useFloor from "Shared/Context/FloorContext";
import useLang from "Shared/Context/LangContext";
import useLayout from "Shared/Context/LayoutContext";
import usePodReservation from "Shared/Context/PodReservationContext";
import useTeamdays from "Shared/Context/TeamdaysContext";
import { PodState } from "Main/Floor/Pod/Functions/PodState";
import { ExtendedPodResData, FloorAbbr, FloorPage, Path, PodStateName } from "Shared/Globals";
import { useEffectOnce } from "Shared/Hooks/useEffectOnce";
import { TestId } from "Test/TestId";
import useResizeObserver from "use-resize-observer/polyfilled";
import { FixedElement } from "./FixedElement";
import { Pod } from "./Pod/Pod";
import { PodInfoAdmin } from "./Pod/Info/PodInfoAdmin";
import { PodInfoMgmt } from "./Pod/Info/PodInfoMgmt";
import { PodInfoUser } from "./Pod/Info/PodInfoUser";
import { FloorStyle } from "./Style";
import { DeskGroup } from "./Desk/DeskGroup";
import { DeskGroupInfoAdmin } from "./Desk/Info/DeskGroupInfoAdmin";
import { DeskInfoAdmin } from "./Desk/Info/DeskInfoAdmin";
import { DeskInfoUser } from "./Desk/Info/DeskInfoUser";
import { useNavigate } from "react-router-dom";

export interface FloorProps {
    selectedFloor: DropdownOption;
    selectedDate?: Date;
    selectedEndDate?: Date;
    selectedSeatTypes?: DropdownOption[];
    notScrollable?: boolean;
    highlightedDeskId?: string;
    scrollHeight?: number;
    changeMgmtView?: (openForm: boolean) => void;
    page: FloorPage;
    activePodResData?: ExtendedPodResData;
}

interface IActiveElement {
    pod?: string;
    desk?: string;
    deskgroup?: string;
}

const initEl = {
    pod: undefined,
    desk: undefined,
    deskgroup: undefined
}

export const Floor = (props: FloorProps) => {
    const [activeElement, setActiveElement] = React.useState<IActiveElement>(initEl);
    const [noPodsFlyout, setNoPodsFlyout] = React.useState(false);

    const { curLangData } = useLang();
    const { getFloorData, fixedElements, podData, deskGroupData, floorDataError, clearData } = useFloor();
    const { resData, updateResData } = usePodReservation();
    const { teamdays } = useTeamdays();
    const { isMobile, updateSidePanel } = useLayout();

    const noPodsInfoCount = React.useRef<number>(0);

    let navigate = useNavigate();

    const floorContainer = React.useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({ ref: floorContainer });

    useEffectOnce(() => {
        return () => {
            clearData(true, true);
            setNoPodsFlyout(false);
        }
    });

    React.useEffect(() => {
        let isMounted = true;
        if (props.highlightedDeskId && props.page === FloorPage.podmgmt && isMounted) {
            setActiveElement({ ...initEl, pod: props.highlightedDeskId })
            updateSidePanel(true);
        }
        if (props.highlightedDeskId && (props.page === FloorPage.myBooking || props.page === FloorPage.colleague) && isMounted && !isMobile(1050)) {
            const isPod = props.highlightedDeskId.includes("POD");
            if (isPod && podData != null) {
                setActiveElement({ ...initEl, pod: props.highlightedDeskId })
                updateSidePanel(true);
            }
            else if (!isPod) {
                setActiveElement({ ...initEl, desk: props.highlightedDeskId })
                updateSidePanel(true);
            }

        }
        return () => { isMounted = false }
    }, [isMobile, podData, props.highlightedDeskId, props.page, updateSidePanel])

    const sidePanelTitle = React.useMemo(() => {
        if (activeElement.pod != null) return podData.find((p) => p.position.id === activeElement.pod)?.position.name as string;
        if (activeElement.desk != null) return activeElement.desk;
        if (activeElement.deskgroup != null) return deskGroupData.find((d) => d.name === activeElement.deskgroup)?.name as string;
        return "";
    }, [activeElement.desk, activeElement.deskgroup, activeElement.pod, deskGroupData, podData]);

    const loadData = React.useCallback(() => {
        getFloorData({
            selectedFloor: props.selectedFloor.id as FloorAbbr,
            selectedDate: props.selectedDate ?? new Date(),
            page: props.page,
            selectedDates: teamdays.selected.length > 0 ? teamdays.selected : undefined,
        });

    }, [getFloorData, props.page, props.selectedDate, props.selectedFloor.id, teamdays.selected])

    React.useEffect(() => {
        loadData();
    }, [loadData])


    React.useEffect(() => {
        let isMounted = true;
        if (podData.length > 0) {
            let count = 0;
            podData.forEach((pod) => {
                if (PodState(pod, props.page, resData) === PodStateName.reserved) count++;
            })
            const notAvail = count === podData.length;
            if (notAvail) noPodsInfoCount.current++;
            if (isMounted) setNoPodsFlyout(notAvail && noPodsInfoCount.current < 2 && props.page === FloorPage.podmgmtNew);
        }
        return () => { isMounted = false }
    }, [podData, resData, props.page])

    const onDismissSidePanel = (updatePanel?: boolean) => {
        if (updatePanel) updateSidePanel(false);
        setActiveElement(initEl);
    }

    const renderPodInfo = () => {
        if (props.page === FloorPage.admin && activeElement.pod != null) return (
            <PodInfoAdmin
                podId={activeElement.pod}
                selectedFrom={props.selectedDate as Date}
                onDismiss={() => onDismissSidePanel(true)}
                onSave={() => {
                    loadData();
                    onDismissSidePanel(true);
                }}
            />
        );

        if ((props.page === FloorPage.podmgmtNew || props.page === FloorPage.podmgmt) && activeElement.pod != null) return (
            <PodInfoMgmt
                podId={activeElement.pod}
                onDismiss={(backToResList) => {
                    onDismissSidePanel(true)
                    if (backToResList) navigate(`${Path.AdminView}/${Path.AdminPods}`);
                }}
                page={props.page}
                highlightedDeskId={props.highlightedDeskId}
            />
        )

        if ((props.page === FloorPage.booking || props.page === FloorPage.colleague || props.page === FloorPage.myBooking) && activeElement.pod != null) return (
            <PodInfoUser
                podId={activeElement.pod}
                selectedFrom={props.selectedDate as Date}
                onDismiss={() => onDismissSidePanel(true)}
                page={props.page}
                highlightedDeskId={props.highlightedDeskId}
            />
        )
    }

    const renderDeskInfo = () => {
        if (props.page === FloorPage.adminUnit && activeElement.deskgroup != null) {
            return (
                <DeskGroupInfoAdmin
                    id={activeElement.deskgroup}
                    onDismiss={() => {
                        updateSidePanel(false)
                    }}
                    onSave={() => {
                        updateSidePanel(false);
                        loadData();
                    }}
                />
            )
        }

        if (props.page === FloorPage.admin && activeElement.desk != null) {
            return (
                <DeskInfoAdmin
                    deskid={activeElement.desk}
                    selectedDate={props.selectedDate as Date}
                    onDismiss={(reload) => {
                        if (reload) loadData();
                        else onDismissSidePanel(true);
                    }}
                />
            )
        }

        if ((props.page === FloorPage.booking || props.page === FloorPage.colleague || props.page === FloorPage.myBooking) && activeElement.desk != null) {
            return (
                <DeskInfoUser
                    deskid={activeElement.desk}
                    selectedDate={props.selectedDate as Date}
                    selectedToDate={props.selectedEndDate}
                    page={props.page}
                    highlightedDeskId={props.highlightedDeskId}
                    onDismiss={() => onDismissSidePanel(true)}
                />
            )
        }
    }

    if (floorDataError) return (
        <InfoBox
            type={InfoBoxType.error}
            text={floorDataError}
            title={curLangData?.text.Attention}
            marginTop={"40px"}
        />
    );

    return (
        <>
            <FloorStyle.Container
                notScrollable={props.notScrollable}
                scrollHeight={props.scrollHeight ? props.scrollHeight + "px" : undefined}
                data-testid={TestId.floor}
                ref={floorContainer}
                width={width}
            >

                <FloorStyle.Image
                    floor={props.selectedFloor.id as FloorAbbr}
                    notScrollable={props.notScrollable}
                    containerWidth={width}
                >
                    {fixedElements && fixedElements.map((feData, i) => {
                        let count = i;
                        return <FixedElement
                            key={feData.desc + count}
                            data={feData}
                            notScrollable={props.notScrollable}
                        />
                    })}

                    {props.page !== FloorPage.adminUnit &&
                        (props.selectedSeatTypes == null || props.selectedSeatTypes.length === 0) &&
                        podData?.map((pod) => {
                            return (
                                <Pod
                                    podId={pod.position.id}
                                    key={pod.position.id}
                                    onClick={(podId, reload) => {
                                        if (reload) loadData();
                                        if (!(props.page === FloorPage.myBooking && isMobile(701))) {
                                            setActiveElement({ ...initEl, pod: podId });
                                            updateSidePanel(true);
                                        }
                                    }}
                                    date={props.selectedDate}
                                    highlightedDeskId={props.highlightedDeskId}
                                    isActive={pod.position.id === activeElement.pod}
                                    page={props.page}
                                />
                            )
                        })}

                    {(props.page !== FloorPage.podmgmt && props.page !== FloorPage.podmgmtNew) && deskGroupData.map(dg => {
                        return (
                            <DeskGroup
                                key={dg.name}
                                page={props.page}
                                selectedFloor={props.selectedFloor}
                                selectedFrom={props.selectedDate}
                                selectedSeatTypes={props.selectedSeatTypes}
                                id={dg.name}
                                onSelect={(id, dg) => {
                                    if (dg) {
                                        setActiveElement({ ...initEl, deskgroup: id })
                                        updateSidePanel(true);
                                    }
                                    else if (!(props.page === FloorPage.myBooking && isMobile(701))) {
                                        setActiveElement({ ...initEl, desk: id });
                                        updateSidePanel(true);
                                    }
                                }}
                                highlightedDeskId={props.highlightedDeskId}
                                activeEl={activeElement.deskgroup ?? activeElement.desk}
                                containerWidth={width}
                            />
                        )
                    })}
                </FloorStyle.Image>
            </FloorStyle.Container>

            {noPodsFlyout && props.page === FloorPage.podmgmtNew &&
                <Flyout
                    visible
                    onDismiss={() => setNoPodsFlyout(false)}
                    onConfirm={() => {
                        setNoPodsFlyout(false);
                        updateResData({
                            ...resData,
                            daysPerWeek: undefined,
                            weekDays: undefined,
                            startDate: undefined,
                            weekInterval: undefined,
                        });
                        if (props.changeMgmtView) props.changeMgmtView(true);
                    }}
                    onConfirmText={curLangData?.text.BackToForm}
                    width={isMobile(500) ? "95vw" : "500px"}
                >
                    <InfoBox
                        type={InfoBoxType.default}
                        htmlText={curLangData?.info.NoPodAvailable}
                    />
                </Flyout>
            }

            <SidePanel
                headline={sidePanelTitle}
                onDismiss={onDismissSidePanel}
            >
                {renderPodInfo()}
                {renderDeskInfo()}
            </SidePanel>
        </>
    )
}