import { color, font, placeholder } from "Style/Globals";
import styled from "styled-components";

export const InputfieldStyle = {
    Container: styled.div`
        position: relative;
        padding-top: 5px;
    `,
    Label: styled.label`
        top: 0;
        left: 10px;
        padding: 0 5px;
        position: absolute;
        background-color: ${color.white};
        white-space: nowrap;
        text-overflow: ellipsis;
        letter-spacing: 2px;
        color: ${color.black};
        height: 1.2em;
        display: block;
        overflow: hidden;
        font-size: 14px;
        max-width: 91%;
        margin-top: -4px;
        font-family: ${font.bold};

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            background-color: ${color.black};
        }
    `,
    Field: styled.input<{ error?: boolean, padding?: string }>`
        padding: ${props => props.padding ?? "13px"};
        border-width: 2px;
        border-style: solid;
        border-color: ${props => {
            if (props.readOnly) return color.buttonDisabled;
            if (props.error) return color.red;
            return color.black;
        }};
        width: 100%;
        font-size: 16px;
        font-family: ${font.bold};
        background-color: ${color.white};

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none; 
            margin: 0;
        }

        @media (prefers-color-scheme: dark) {
            color: ${color.white};
            border-color: ${props => {
            if (props.readOnly) return color.buttonDisabled;
            if (props.error) return color.red;
            return color.white;
        }};
            background-color: ${color.black};
        }

        :focus {
            outline: none;
            border-color: ${props => {
            if (props.readOnly) return color.buttonDisabled;
            if (props.error) return color.red;
            return color.blue;
        }};
            border-radius: 0;

            @media (prefers-color-scheme: dark) {
                border-color: ${props => {
            if (props.readOnly) return color.buttonDisabled;
            if (props.error) return color.red;
            return color.blue;
        }};
            }
        }

        ${placeholder}
    `,
}