import styled from "styled-components";
import { color, defaultTransition, font } from "Style/Globals";
import { InfoBoxType } from "./InfoBox";

export const InfoStyle = {
    Container: styled.div<{
        type: InfoBoxType,
        marginTop?: string,
        isAside?: boolean,
        expandNav?: boolean,
        small?: boolean;
    }>`
        border-width: 3px;
        border-style: solid;
        border-color: ${props => {
            if (props.isAside && props.expandNav === false) return color.transparent;
            if (props.isAside) return color.black;
            if (props.type === InfoBoxType.default) return color.yellow;
            if (props.type === InfoBoxType.error) return color.red;
            return color.green;
        }};
        position: relative;
        overflow: hidden;
        padding: ${props => {
            if (props.isAside && props.expandNav === false) return "0";
            if (props.small) return "10px";
            return "20px 20px 20px 110px"
        }};
        min-height: ${props => props.small ? "90px" : "110px"};
        max-width: 500px;
        margin-top: ${props => props.marginTop ?? "0"};
        ${defaultTransition}
    `,
    Icon: styled.div<{ type: InfoBoxType, isAside?: boolean, small?: boolean; }>`
        position: absolute;
        top: ${props => props.small ? "50%" : "5px"};
        left: ${props => props.small ? "50%" : "0"};
        transform: ${props => props.small ? "translate(-50%, -50%)" : "translate(0,0)"};
        opacity: ${props => props.small ? ".5" : "1"};
        color: ${props => {
            if (props.isAside) return color.black;
            if (props.type === InfoBoxType.default) return color.yellow;
            if (props.type === InfoBoxType.error) return color.red;
            return color.green;
        }};
    `,
    Text: styled.div<{ isAside?: boolean }>`
        font-size: 16px;
        line-height: 1.5;
        color: ${props => props.isAside && color.black};
        position: relative;

        & > mark {
            font-family: ${font.bold};
            text-transform: uppercase;
            display: inline-block;
            padding-right: 5px;
            background-color: ${color.transparent};
            font-style: normal;

            @media (prefers-color-scheme: dark) {
                color: ${color.white};
            }
        }

        & > strong {
            font-family: ${font.bold};
            font-weight: normal;
        }

        & > a {
            font-family: ${font.bold};
            color: ${color.blue};
            text-decoration: underline;

            :hover {
                text-decoration: none;
                color: ${color.blueHover};
            }
        }
    `,
    Buttons: styled.div``,
}