import styled, { css, keyframes } from "styled-components";
import { color } from "Style/Globals";

const CircleWrapper = css`
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
`;

const CircleHole = css`
    width: 200%;
    height: 100%;
    border: 3px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(-135deg);
`;

const circleLeft = keyframes`
    0%,
    50% {
        transform: rotate(-135deg);
    }
    100% {
        transform: rotate(45deg);
    }
`;

const circleRight = keyframes`
    0% {
        transform: rotate(-135deg);
    }
    50%,
    100% {
        transform: rotate(45deg);
    }
`;

const iconOpacity = keyframes`
    0%, 50% {opacity: 0}
    100% {opacity: 1}
`;

export const Animation = {
    Circle: {
        Box: styled.div<{ size?: string }>`
            width: ${props => props.size ?? "50px"};
            height: ${props => props.size ?? "50px"};
            margin: auto;
            position: relative;
            box-sizing: content-box;

            & > * {
            box-sizing: content-box;
            }
        `,
        WrapperRight: styled.div`
            ${CircleWrapper}
            right: 0;
        `,
        WrapperLeft: styled.div`
            ${CircleWrapper}
            left: 0;
        `,
        Right: styled.div<{ color?: string }>`
            ${CircleHole}
            border-top: 3px solid ${props => props.color ?? color.yellow};
            border-right: 3px solid ${props => props.color ?? color.yellow};
            right: 1px;
            animation: ${circleRight} 1s linear forwards;
        `,
        Left: styled.div<{ color?: string }>`
            ${CircleHole}
            border-bottom: 3px solid ${props => props.color ?? color.yellow};
            border-left: 3px solid ${props => props.color ?? color.yellow};
            left: 1px;
            animation: ${circleLeft} 1s linear forwards;
        `,
    },
    Confirm: {
        Icon: styled.div<{ color?: string }>`
            position: absolute;
            top: 45%;
            left: 45%;
            transform: translate(-50%, -50%);
            opacity: 0;
            animation: ${iconOpacity} 1s linear forwards;

            & > i:before {
                color: ${props => props.color ?? color.yellow};
            }
        `,
    }
}