import React from "react";
import { DeskState, BookingData } from "Shared/Globals";
import { PieChart } from "Shared/Components";
import useLang from "Shared/Context/LangContext";

export const ReportChartCheckIn = (props: {
    resData?: BookingData[];
    containerWidth?: string;
    containerHeight?: string;
}) => {
    const [pieData, setPieData] = React.useState<{} | any[]>();
    const { curLangData } = useLang();

    React.useEffect(() => {
        const data: any[] = [["Status", "Prozent"]];

        if (props.resData) {
            const all = props.resData.filter(a => a.type === DeskState.booked);
            if (all.length > 0) {
                const checkedIn = all.filter((a) => a.confirmed).length;
                const notCheckedIn = all.length - checkedIn;

                data.push([curLangData?.text.NotCheckedIn, (notCheckedIn / all.length) * 100]);
                data.push([curLangData?.text.CheckedIn, (checkedIn / all.length) * 100]);

                setPieData(data);
            } else {
                setPieData(undefined);
            }
        }
    }, [props.resData, curLangData])

    return (
        <PieChart data={pieData} showLegend containerHeight={props.containerHeight} containerWidth={props.containerWidth} />
    )
}