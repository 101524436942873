import React from "react";
import { useApi } from "./API";
import { BookingData } from "Shared/Globals";
import useUser from "./UserContext";
import useLang from "./LangContext";

export interface MyBookingsContextState {
    myCurrentBookings: BookingData[] | undefined;
    updateMyBookings: () => void;
    myBookingsLoading: boolean;
    myBookingsError: string | undefined;
}


export const MyBookingsContext = React.createContext<MyBookingsContextState>({
    myCurrentBookings: undefined,
    updateMyBookings: () => { /* init */ },
    myBookingsLoading: false,
    myBookingsError: undefined,
})

export const MyBookingsProvider = (props: { children: JSX.Element }) => {
    const [myCurrentBookings, setMyCurrentBookings] = React.useState<BookingData[]>();
    const [myBookingsLoading, setMyBookingsLoading] = React.useState(false);
    const [myBookingsError, setMyBookingsError] = React.useState<string | undefined>(undefined);
    const api = useApi();
    const { currentUser } = useUser();
    const [reloadMyBookings, setReloadMyBookings] = React.useState(0);
    const { curLangData } = useLang();

    const setMyCurrentbookingsSorted = React.useCallback((baseData: BookingData[]) => {
        if (baseData?.length > 0) {
            setMyCurrentBookings([...baseData].sort((a, b) => {
                if (a.date < b.date) return -1;
                if (a.date > b.date) return 1;
                return 0;
            }));
        }
        else {
            setMyCurrentBookings(undefined);
        }
    }, [])

    React.useEffect(() => {
        if (currentUser != null) {
            setMyBookingsLoading(true);
            api
                .getMyBookings()
                .then((b) => {
                    setMyCurrentbookingsSorted(b);
                    setMyBookingsLoading(false);
                    setMyBookingsError(undefined);
                })
                .catch(() => {
                    setMyBookingsLoading(false);
                    setMyBookingsError(curLangData?.error.MyBookings);
                })
        }
    }, [api, currentUser, setMyCurrentbookingsSorted, reloadMyBookings, curLangData]);

    const updateMyBookings = React.useCallback(() => {
        setReloadMyBookings(b => b + 1);
    }, []);

    const providerValue = React.useMemo(() => (
        {
            myCurrentBookings,
            updateMyBookings,
            myBookingsLoading,
            myBookingsError,
        }), [myCurrentBookings, updateMyBookings, myBookingsLoading, myBookingsError]);

    return (
        <MyBookingsContext.Provider value={providerValue}>
            {props.children}
        </MyBookingsContext.Provider>
    )
}

const useMyBookings = () => React.useContext(MyBookingsContext);
export default useMyBookings;