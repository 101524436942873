import styled from 'styled-components'
import { breakpoint, color, defaultTransition, font } from "Style/Globals";

export const HeaderStyle = {
    Container: styled.header<{ expandNav?: boolean }>`
        background-color: ${color.yellow};
        padding: 10px;
        padding-top: 0;
        color: ${color.black};
        position: relative;
        ${defaultTransition}

        @media ${breakpoint.min701} {
            padding: ${props => props.expandNav ? "20px" : "10px"};
            padding-top: 0;
        }

        :before {
            content: "";
            position: absolute;
            top: 40px;
            left: 10px;
            right: 10px;
            border-bottom: 3px solid #000000;
            ${defaultTransition}

            @media ${breakpoint.min701} {
                top: 65px;
                left: ${props => props.expandNav ? "20px" : "10px"};
                right: ${props => props.expandNav ? "20px" : "10px"};
            }
        }
    `,
    Title: styled.h1<{ expandNav?: boolean }>`
        font-size: 25px;
        text-align: left;
        font-family: ${font.bold};
        text-transform: uppercase;
        padding: 5px 0;
        padding-left: 45px;

        @media ${breakpoint.min701} {
            padding: 0;
            text-align: center;
            margin: ${props => props.expandNav ? "0 20px" : 0};
        }
    `,
    Icon: styled.div`
        width: auto;
        position: absolute;
        top: 6px;
        left: 3px;

        @media ${breakpoint.min701} {
            position: static;
            text-align: center;
            width: 100%;
        }
    `,
    Button: styled.button<{ expandNav?: boolean }>`
        display: block;
        position: absolute;
        top: 67px;
        left:  ${props => props.expandNav ? "6px" : "-3px"};
        transform: ${props => props.expandNav ? "rotate(0)" : "rotate(180deg)"};
        ${defaultTransition}
    `,
    Subtitle: styled.h2<{ hasPadding?: boolean }>`
        display: block;
        font-family: ${font.bold};
        font-size: 25px;
        padding-top: 5px;
        text-align: right;
        padding-right: ${props => props.hasPadding ? "35px" : "0"};
    `,
    MobileButton: styled.button<{ expandNav?: boolean }>`
        display: block;
        position: absolute;
        top: 49px;
        left: auto;
        right: 10px;
        transform: ${props => props.expandNav ? "rotate(0deg)" : "rotate(360deg)"};
        ${defaultTransition}
        width: 25px;
        height: 25px;
        z-index: 1;
        padding: 4px 0;

        > i {
            display: block;
            width: 100%;
            padding-top: 4px;
            border-bottom: 3px solid ${color.black};

            :first-child {
                padding-top: 0;
            }
        }
    `,
}